<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent transition="slide-x-reverse-transition">
      <v-card>
        <v-card-text class="pt-6">
          <h3 class="black--text">{{ $t("components.bookingForm.returnBooking.title") }}</h3>
        </v-card-text>
        <v-card-text>
          <v-row class="my-2">
            <v-col class="py-0">
              <v-menu :close-on-content-click="false" :nudge-bottom="44" v-model="datePickerOpen">
                <template v-slot:activator="{ on }">
                  <validated-field
                    v-slot="{ attrs }"
                    v-model="localeDate"
                    :validator="$v.date"
                    :messages="errors"
                  >
                    <v-text-field
                      outlined
                      filled
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="localeDate"
                    />
                  </validated-field>
                </template>
                <v-date-picker
                  no-title
                  show-adjacent-months
                  :min="datePickerMinDate"
                  :locale="$i18n.locale"
                  v-model="date"
                  @change="datePickerOpen = false"
                />
              </v-menu>
            </v-col>
            <v-col class="py-0">
              <validated-field
                v-slot="{ attrs }"
                v-model="timeMask"
                :validator="$v.timeMask"
                :messages="errors"
              >
                <v-text-field
                  outlined
                  filled
                  hide-details
                  v-bind="attrs"
                  v-model="timeMask"
                  @focus="handleTimeFocus"
                  @blur="handleTimeBlurEvent"
                />
              </validated-field>
            </v-col>
            <v-col cols="12" class="mt-1" v-if="$v.timeMask.$invalid || date < now">
            <v-row>
              <v-col cols="6" class="py-0">
                <span class="error-text" v-if="date < now">{{
                  $t("components.bookingForm.section.date_error")
                }}</span>
              </v-col>
              <v-col cols="6" class="py-0">
                <span class="error-text" v-if="$v.timeMask.$invalid">{{
                  $t("components.bookingForm.section.time_error")
                }}</span>
              </v-col>
            </v-row>
          </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="px-6 pb-4 d-flex justify-space-between">
          <v-btn class="pl-0" plain @click="cancelReturnBooking">
            {{ $t("components.bookingForm.returnBooking.cancel") }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="handleSubmitReturnBooking"
            :disabled="$v.$invalid"
          >
            {{ $t("components.bookingForm.returnBooking.confirm") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment-timezone';
// import dayjs from '@/daysjs';
import { vMaska, Mask } from 'maska';

// import PickupTime from '@/views/shared/inputs/PickupTime.vue';
// import { isNotInThePast } from '../../../validation/validationMethods';

const NOW = moment();
const TIME_ASAP = 'ASAP';
const DATE_FORMAT_YMD = 'YYYY-MM-DD';
const DATE_FORMAT_LOCALE = 'LL';
const TIME_FORMAT_HMS = 'HH:mm';

export default {
  name: 'ReturnBookingModal',
  directives: { maska: vMaska },
  components: {},
  props: {
    preDate: {
      type: String,
      default: '',
    },
    preTime: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      timeMask: NOW.add(10, 'minute').format(TIME_FORMAT_HMS),
      date: NOW.format(DATE_FORMAT_YMD),
      now: NOW.format(DATE_FORMAT_YMD),
      datePickerMinDate: NOW.format(DATE_FORMAT_YMD),
      datePickerOpen: false,
      errors: {
        address: 'Invalid {attribute}',
        timeMask: 'Time cannot be in the past',
      },
    };
  },
  methods: {
    openDialog() {
      this.compareDates();
      this.dialog = true;
    },
    handleSubmitReturnBooking() {
      this.dialog = false;
    },
    cancelReturnBooking() {
      this.dialog = false;
      this.$emit('cancelReturnBooking');
    },
    maskInput(ele) {
      if (ele.keyCode === 32) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }
      const val = ele.target.value.toUpperCase().trim();
      if (!val) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }

      const mask = new Mask({ mask: '##:##' });
      if (val !== TIME_ASAP && val.length >= 4) {
        this.time = mask.masked(val);
        this.timeMask = mask.masked(val);
        this.pickupTime = `${this.date}T${this.time}`;
      } else if (val.length === 1) {
        this.time = mask.masked(`0${val}:00`);
        this.timeMask = mask.masked(`0${val}:00`);
        this.pickupTime = `${this.date}T${this.time}`;
      } else if (val.length === 2) {
        this.time = mask.masked(`${val}:00`);
        this.timeMask = mask.masked(`${val}:00`);
        this.pickupTime = `${this.date}T${this.time}`;
      }

      if (!moment(this.time, 'HH:mm', true).isValid()) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
      }
    },
    handleTimeBlurEvent(ele) {
      if (ele.target.value.toUpperCase() === '') {
        this.timeMask = TIME_ASAP;
        this.time = TIME_ASAP;
      }
      this.maskInput(ele);
    },
    handleTimeFocus(ele) {
      if (ele.target.value === TIME_ASAP) {
        this.timeMask = '';
        this.time = '';
      } else {
        ele.target.select();
      }
    },
    compareDates() {
      this.date = this.preDate;
      this.timeMask = this.preTime === 'ASAP'
        ? this.timeMask
        : moment(`${this.date} ${this.preTime}`).add(10, 'minute').format(TIME_FORMAT_HMS);
    },
  },
  computed: {
    localeDate() {
      return moment(this.date).format(DATE_FORMAT_LOCALE);
    },
    selectedReturnDate() {
      const pickupTime = moment(`${this.date} ${this.timeMask}`).format('YYYY-MM-DDTHH:mm:ss');
      this.$emit('selectedReturnDate', pickupTime);
      return pickupTime;
    },
    minimumDateTime() {
      if (this.preTime === 'ASAP') {
        return moment(`${this.preDate} ${moment().format(TIME_FORMAT_HMS)}`).format('YYYY-MM-DDTHH:mm:ss');
      }
      return moment(`${this.preDate} ${this.preTime}`).format('YYYY-MM-DDTHH:mm:ss');
    },
  },
  validations() {
    const date = async (val) => val >= this.now;

    const validations = {
      timeMask: {
        validateTime: (value) => {
          if (!value) {
            return true;
          }
          if (value.toUpperCase() === TIME_ASAP) {
            return true;
          }
          if (value.length > 4) {
            const isBefore = moment(this.minimumDateTime).isBefore(this.selectedReturnDate);
            return isBefore;
          }
          return true;
        },
      },
      date: {
        date,
      },
    };

    return validations;
  },
};
</script>
