var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"d-flex justify-start align-center pt-0 pb-0",class:{ 'rules-col': (_vm.item.restriction.length > 1 && _vm.indexRes === 0) },attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"radio-week",attrs:{"row":"","hide-details":""},on:{"change":_vm.updateRestriction},model:{value:(_vm.restriction.isRestriction),callback:function ($$v) {_vm.$set(_vm.restriction, "isRestriction", $$v)},expression:"restriction.isRestriction"}},[_c('v-radio',{staticClass:"no-restriction mt-3",attrs:{"label":_vm.$t('components.travelRestrictionDateTimePicker.noRestriction'),"value":false},on:{"click":function($event){return _vm.removeRule(_vm.item, _vm.index, _vm.indexRes)}}}),_c('v-radio',{staticClass:"no-restriction mt-3",attrs:{"label":_vm.$t('components.travelRestrictionDateTimePicker.fullDay'),"value":"fullDay","disabled":_vm.hasFullDay(_vm.item)},on:{"click":function($event){return _vm.$forceUpdate()}}}),_c('v-radio',{staticClass:"time-selector",attrs:{"disabled":_vm.restriction.isDisabled,"value":true},on:{"click":function($event){return _vm.$forceUpdate()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-column",class:{ 'pb-n2': _vm.item.restriction.length >= 2, 'radio-disabled': _vm.restriction.isDisabled }},[_c('div',{staticClass:"d-flex initial-time-selector mr-0"},[_c('v-text-field',{staticClass:"time-radio mr-2 employee-group-form-input clock-time-input--no-icon",class:{
                'clock-time-input--light-text': !_vm.restriction.isRestriction,
                'clock-time-input--dark-text': _vm.restriction.isRestriction,
              },attrs:{"outlined":"","placeholder":"00:00","background-color":"#ffffff","hide-details":"","min":"00:00","max":"23:59","type":"time"},on:{"change":_vm.updateRestriction},model:{value:(_vm.restriction.startTime),callback:function ($$v) {_vm.$set(_vm.restriction, "startTime", $$v)},expression:"restriction.startTime"}}),_c('v-text-field',{staticClass:"time-radio employee-group-form-input clock-time-input--no-icon",class:{
              'invalid-from': !_vm.restriction.isValid,
              'clock-time-input--light-text': !_vm.restriction.isRestriction,
              'clock-time-input--dark-text': _vm.restriction.isRestriction,
            },attrs:{"outlined":"","placeholder":"00:00","error-messages":_vm.restriction.isValid ? _vm.$t('components.travelRestrictionDateTimePicker.validFromTime') : 'test',"background-color":"#ffffff","hide-details":"","type":"time","min":"00:00","max":"23:59"},on:{"input":(val) => _vm.validateField(val, _vm.restriction.startTime, _vm.restriction),"change":_vm.updateRestriction},model:{value:(_vm.restriction.endTime),callback:function ($$v) {_vm.$set(_vm.restriction, "endTime", $$v)},expression:"restriction.endTime"}})],1)])]},proxy:true}])})],1),(_vm.indexRes > 0)?_c('v-btn',{attrs:{"color":"success","size":"x-small","icon":"","plain":""},on:{"click":function($event){return _vm.deleteRule(_vm.item, _vm.index, _vm.indexRes)}}},[_c('v-icon',[_vm._v("mdi-minus-circle-outline")])],1):_vm._e(),(_vm.indexRes === 0  && _vm.item.restriction.length < 2)?_c('v-btn',{class:{ 'hidden': !_vm.canAddRule(_vm.item, _vm.indexRes)  },attrs:{"color":"success","icon":"","plain":""},on:{"click":function($event){return _vm.addNewTimeSlot(_vm.item)}}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }