import { business } from "@icabbi/api-gateway-client";
import apiInstance from "@/helpers/apiInstance";

const defaultState = {
  fareEstimationServiceClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  fareEstimationServiceClient:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.fareEstimationServiceClient) {
        state.fareEstimationServiceClient = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: business.FareEstimatorConnector,
        });
      }
      return state.fareEstimationServiceClient;
    },
};

/**
 * @param {FareEstimateQuery} query
 * @returns {FareResponceApiDto}
 */
async function getFareEstimation(context, query) {
  console.log("getFareEstimation", query);

  return await context.getters.fareEstimationServiceClient(context).getFareEstimation(
    query,
    query.corporationId,
    query.paymentMethodType,
  );
}

export default {
  namespaced: true,
  getters,
  actions: {
    getFareEstimation,
  },
  state: defaultState,
};
