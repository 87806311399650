import { business } from "@icabbi/api-gateway-client";
import apiInstance from "@/helpers/apiInstance";

const defaultState = {
  paymentMethodServiceConnector: null,
};

const getters = {
  paymentMethodServiceConnector:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.paymentMethodServiceConnector) {
        state.paymentMethodServiceConnector = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: business.PaymentMethodServiceConnector,
        });
      }
      return state.paymentMethodServiceConnector;
    },
};

/**
 * @param {PaymentMethodQuery} query
 * @returns {PaymentListApiDto[]}
 */
function getPaymentMethods(context, query) {
  console.log("getPaymentMethods", query);

  return context.getters
    .paymentMethodServiceConnector(context)
    .list({ corporationId: query.corporationId, paymentProviderName: query.paymentProviderName });
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions: {
    getPaymentMethods,
  },
};
