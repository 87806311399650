<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mt-1"
        small
        v-on="on"
        v-bind="attrs"
        :class="{ 'employee-field-icon': validator.$error }"
      >mdi-help-circle-outline</v-icon>
    </template>
    <span>{{ `${getError}` }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'error-tooltip',
  props: {
    validator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getError() {
      if (!this.validator?.required) {
        return this.$t('components.errorToolTip.validation.required');
      }

      if (!this.validator?.minLength) {
        return this.$t('components.errorToolTip.validation.minLength', { attribute: this.validator.$params.minLength.min });
      }

      if (!this.validator?.email) {
        return this.$t('components.errorToolTip.validation.email');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-field-icon {
  color: #EB5757 !important;
}
</style>
