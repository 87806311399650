<template>
  <div class="d-full">
    <v-row justify="space-between" class="align-center pt-6 group-form-header pl-4 pr-7" no-gutters>
      <v-col
        md="5"
        cols="auto"
        class="d-flex align-center col-without-top-padding col-without-bottom-padding"
      >
        <div class="d-flex flex-column mr-3">
          <p class="week-time">{{ $t("components.employeeGroupForm.groupName") }}</p>
          <span class="week-time-description">{{
            $t("components.employeeGroupForm.groupNameDescription")
          }}</span>
        </div>
        <v-text-field
          v-model="groupName"
          clearable
          outlined
          hide-details
          :placeholder="$t('components.employeeGroupForm.userGroup')"
          :height="38"
          :disabled="isGroupCreated && !selectedGroup"
          background-color="#ffffff"
          class="mr-3 bs-search-item"
          maxLength="30"
        ></v-text-field>
        <span v-show="groupName && !loading" class="save-group" @click="saveGroup">{{
          $t("components.employeeGroupForm.save")
        }}</span>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
      </v-col>
      <v-col md="6" cols="auto" class="ml-3 d-flex align-center">
        <v-combobox
          ref="employeeSearchField"
          multiple
          outlined
          hide-details
          append-icon=""
          clearable
          item-text="customField"
          class="mr-3 employee-search"
          v-model="selectedEmployees"
          :placeholder="$t('components.employeeGroupForm.employeeGroup')"
          :items="filteredEmployees"
          @input="onEmployeeSelect"
        ></v-combobox>
        <v-btn
          :height="38"
          class="add-user-group"
          outlined
          dense
          :disabled="!this.selectedEmployees.length"
          @click="assignEmploeesToGroup()"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t("components.employeeGroupForm.addEmployee") }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="mt-6">
        <v-divider class="divider-line" />
      </v-col>
    </v-row>
    <div class="mb-6">
      <employee-group-table
        v-if="createdGroupId"
        ref="employeeGroupTable"
        :employeeGroupId="createdGroupId"
        @deleteEmployeeGroup="(val) => assignEmploeesToGroup(val.id)"
        @allEmployeesInGroup="allEmployeesInGroup = $event"
      />
    </div>

    <v-row
      v-show="showGroupRestrictions"
      class="align-start py-6 group-restrictions px-4"
      :class="{ 'disabled-restriction-form': !isGroupCreated }"
      no-gutters
    >
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <span>{{ $t("components.employeeGroupForm.groupRestrictions") }}</span>
          <v-btn
            text
            plain
            class="text-capitalize text-decoration-underline week-time-description"
            @click="callResetBack()"
          >
            {{ $t("components.employeeGroupForm.resetRestriction") }}
          </v-btn>
        </div>
      </v-col>

      <!-- TO ADD in another story but this is already done stuff -->
      <!-- <v-col cols="12" class="my-6">
        <v-divider class="divider-line"/>
      </v-col>
      <v-col cols="12"></v-col>
      <v-col cols="4">
        <p class="week-time">{{ $t('components.employeeGroupForm.restrictionName') }}</p>
        <span class="week-time-description">{{ $t('components.employeeGroupForm.restrictionNameDescription') }}</span>
      </v-col>
      <v-col md="3" cols="auto" class="d-flex align-center col-without-top-padding col-without-bottom-padding">
        <v-checkbox
          v-model="disableRestrictionTemplate"
          :disabled="getTravelRestrictionId ? true : false"
        ></v-checkbox>
        <infinite-list-autocomplete
          :data="restrictionList"
          :listSearchParams="restrictionListParams"
          :totalCount="restrictionTotalCount.totalCount"
          :disabled="!disableRestrictionTemplate"
          placeholder="Select existing restriction"
          @selectItem="(val) => restrictionTemplate = val"
          @intersect="increaceIntersection"
        />
        <span
          v-if="disableRestrictionTemplate && !getTravelRestrictionId"
          class="save-group ml-2"
          @click="createRestriction()"
        >
          {{ $t('components.employeeGroupForm.save') }}
        </span>
      </v-col> -->
      <v-col cols="12" class="my-6">
        <v-divider class="divider-line" />
      </v-col>
      <v-col cols="3">
        <p class="week-time">{{ $t("components.employeeGroupForm.time") }}</p>
        <span class="week-time-description">{{
          $t("components.employeeGroupForm.timeDescription")
        }}</span>
      </v-col>
      <v-col cols="9">
        <travel-restriction-date-time-picker
          ref="date-time-picker"
          :data="groupRules.dateTime"
          @updateDateTimeRestrictions="updateDateTimeRestrictions"
          @updateOriginalValue="updateOriginalValue"
        />
      </v-col>
      <!-- disable locations until we can edit them -->
      <!-- <v-col cols="12" class="mb-6">
        <v-divider class="divider-line"/>
      </v-col> -->
      <!-- <v-col :class="{ 'disabled-restriction-form': getTravelRestrictionRuleId('location') }" cols="4">
        <p class="week-time">{{ $t('components.employeeGroupForm.location') }}</p>
        <span class="week-time-description">{{ $t('components.employeeGroupForm.locationDescription') }}</span>
      </v-col>
      <v-col :class="{ 'disabled-restriction-form': getTravelRestrictionRuleId('location') }" cols="6" class="d-flex align-center">
        <address-autocomplete
          class="mr-2"
          backgroundColor="#ffffff"
          :prependText="$t('components.employeeGroupForm.from')"
          :initial="groupRules.locationFrom"
          @change="(event) => changeLocation('locationFrom', event)"
        />
        <address-autocomplete
          backgroundColor="#ffffff"
          :prependText="$t('components.employeeGroupForm.to')"
          :initial="groupRules.locationTo"
          @change="(event) => changeLocation('locationTo', event)"
          />
      </v-col>
      <v-col :class="{ 'disabled-restriction-form': getTravelRestrictionRuleId('location') }" cols="auto" class="d-flex align-self-center">
        <span
          v-if="groupRules.locationFrom.description || groupRules.locationTo.description && !isSavedEntities['location']"
          class="save-group ml-2"
          @click="manageRules('location')"
        >
          {{ $t('components.employeeGroupForm.save') }}
        </span>
      </v-col> -->
      <v-col cols="12" class="my-6">
        <v-divider class="divider-line" />
      </v-col>
      <v-col cols="3">
        <p class="week-time">{{ $t("components.employeeGroupForm.value") }}</p>
        <span class="week-time-description">{{
          $t("components.employeeGroupForm.valueDescription")
        }}</span>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <v-text-field
          outlined
          filled
          hide-details
          background-color="#ffffff"
          class="distance-field mr-2 employee-group-form-input"
          type="number"
          min="0"
          v-model.number="groupRules.maxAmount.amount"
          @input="isSavedEntities['maxAmount'] = false"
        >
          <template v-slot:prepend-inner>
            <span class="currency-symbol">{{ currencySymbol }}</span>
          </template>
        </v-text-field>

        <v-select
          v-model="groupRules.maxAmount.maxAmountRuleType"
          item-value="value"
          :items="stepArray"
          outlined
          hide-details
          class="distance-field employee-group-form-input"
          background-color="#ffffff"
          @change="isSavedEntities['maxAmount'] = false"
          append-icon="mdi-chevron-down"
        />
      </v-col>
      <v-col cols="12" class="my-6">
        <v-divider class="divider-line" />
      </v-col>
      <v-col cols="3">
        <p class="week-time">{{ $t("components.employeeGroupForm.frequency") }}</p>
        <span class="week-time-description">{{
          $t("components.employeeGroupForm.frequencyDescription")
        }}</span>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <v-text-field
          outlined
          filled
          hide-details
          type="number"
          background-color="#ffffff"
          class="distance-field mr-2 employee-group-form-input"
          min="0"
          v-model.number="groupRules.frequency.maxNumberOfBookings"
          @input="isSavedEntities['frequency'] = false"
          append-icon="mdi-chevron-down"
        />

        <v-select
          v-model="groupRules.frequency.frequencyRuleType"
          item-value="value"
          :items="stepArray"
          outlined
          hide-details
          filled
          class="distance-field employee-group-form-input"
          background-color="#ffffff"
          @change="isSavedEntities['frequency'] = false"
          append-icon="mdi-chevron-down"
        />
      </v-col>
    </v-row>
    <div v-if="showGroupRestrictions" class="d-flex pa-3 justify-end footer-submit">
      <v-btn
        :disabled="!disabledSubmitGroup"
        dense
        class="submit-btn text-capitalize"
        @click="closeGroup"
        >{{ $t("components.employeeGroupForm.saveGroup") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import _, { sortBy, uniqBy } from "lodash";
import { mapGetters } from "vuex";
import { notifyWarn } from "@/helpers/notification";
import { bookingChannelComputed } from "@/mixins/bookingChannel.mixins";
import { initTravelRestrictionEntity } from "@/mixins/travelRestriction.mixins";
import { entityLoader } from "@/mixins/entityLoader.mixins";
import { getRoles } from "@/mixins/authentication.mixins";
import TravelRestrictionDateTimePicker from "@/components/travelRestrictions/TravelRestrictionDateTimePicker.vue";
import { weekRestriction } from "@/components/travelRestrictions/helpers/weekRestriction";
import EmployeeGroupTable from "./EmployeeGroupTable.vue";

const defaultSearchState = {
  page: 0,
  maxPerPage: 10,
  sort: "familyName",
};

export default {
  name: "employee-group-form",
  mixins: [
    getRoles,
    bookingChannelComputed,
    initTravelRestrictionEntity,
    entityLoader,
    weekRestriction,
  ],
  components: {
    TravelRestrictionDateTimePicker,
    EmployeeGroupTable,
  },
  props: {
    selectedGroup: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    showGroupRestrictions: true, // Hide group restriction editing for time being (see BS-675)
    groupName: "",
    newRestriction: "",
    restrictionTemplate: null,
    page: 0,
    loading: false,
    createdGroupId: null,
    isGroupCreated: false,
    disableRestrictionTemplate: false,
    employeeSearchParams: defaultSearchState,
    selectedEmployees: [],
    employeesList: [],
    allEmployeesInGroup: [],
  }),
  watch: {
    selectedGroup: {
      handler(val) {
        if (!val) return;

        this.createdGroupId = val.id;
        this.isGroupCreated = true;
        this.groupName = val.name;
        this.$store.commit("employee/setCreatedGroup", { name: val.name, id: val.id });

        if (val.restrictionId) this.setRules(val.restrictionId);
      },
      immediate: true,
    },
    getSelectedCorporationId: {
      async handler(val) {
        this.employeeSearchParams.page = 0;
        this.employeeSearchParams.corporationId = val;
        await this.loadEmployees();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.employeeSearchParams.corporationId = this.getSelectedCorporationId || null;
    this.createdGroupId = null;
  },
  computed: {
    ...mapGetters("corporations", ["getSelectedCorporationId"]),
    ...mapGetters("travelRestriction", ["getResettedRestrictions"]),
    headers() {
      return [
        {
          text: this.$t("components.employeeList.list.name"),
          align: "left",
          sortable: true,
          value: "givenName",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.email"),
          align: "left",
          sortable: true,
          value: "email",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.phone"),
          align: "left",
          sortable: true,
          value: "phone",
          class: "text-uppercase",
        },
        {
          value: "action",
          width: "10%",
          sortable: false,
          align: "end",
        },
      ];
    },
    disabledSubmitGroup() {
      return this.isGroupCreated && this.createdGroupId;
    },
    stepArray() {
      return [
        { text: this.$t("components.employeeGroupForm.daily"), value: "day" },
        { text: this.$t("components.employeeGroupForm.weekly"), value: "week" },
        { text: this.$t("components.employeeGroupForm.monthly"), value: "month" },
      ];
    },
    filteredEmployees() {
      return this.employeesList.filter(
        (el) => !this.allEmployeesInGroup.find((emp) => emp.id === el.id)
      );
    },
  },
  methods: {
    changeLocation(type, event) {
      this.isSavedEntities.location = false;
      this.groupRules[type] = event;
    },
    async loadEmployees() {
      this.employeeSearchParams.page = 0;
      this.employeeSearchParams.maxPerPage = Number.MAX_SAFE_INTEGER;

      const result = await this.getListFromPagination("employee/listEmployees", {
        ...this.employeeSearchParams,
      });

      if (result) {
        this.employeesList = sortBy(this.data, "givenName");
        this.employeesList = this.data.map((el) => {
          // eslint-disable-next-line no-param-reassign
          el.customField = `${el.givenName} (${el.email})`;

          return el;
        });
      }
    },
    onEmployeeSelect(val) {
      this.selectedEmployees = uniqBy([...val, ...this.selectedEmployees], "id");
    },
    async closeGroup() {
      await this.manageRules("frequency");
      await this.manageRules("maxAmount");

      const { dateTime } = this.groupRules;
      dateTime.forEach((date) => {
        if (!_.isEqual(date.dateTimeSlots, this.originalValues[date.dateTimeRuleType])) {
          date.dateTimeSlots.forEach((slot) => {
            // eslint-disable-next-line no-param-reassign
            delete slot.isRestriction;
          });
          this.setDateTime(date);
        }
      });
      if (this.getResettedRestrictions) {
        const result = await this.resetTravelRestrictions();

        if (result.length) {
          this.$store.commit("travelRestriction/setCreatedRestrictionId", null);
          this.$store.commit("travelRestriction/resetRuleIds");

          this.$notify({
            type: "success",
            title: "Group saved",
            text: "Group saved successfully",
          });
        }
      }

      this.$emit("closeGroup");
      this.$store.commit("travelRestriction/resetAction", false);
    },
    async callResetBack() {
      this.$store.commit("travelRestriction/resetAction", true);
      this.$refs["date-time-picker"].resetWeeks();
      this.resetGroupRules();
      this.$forceUpdate();
    },
    async assignEmploeesToGroup(removeItems = null) {
      if (!this.selectedEmployees) return;

      const ids = this.selectedEmployees.map((el) => el.id);

      const params = {
        employeeGroupId: this.createdGroupId,
        requestParams: {
          addedEmployeeIds: !removeItems ? ids : [],
          removedEmployeeIds: removeItems ? [removeItems] : [],
        },
      };

      const result = await this.$store.dispatch("employee/updateEmployeeGroupEmployees", params);

      if (result) {
        this.$refs.employeeGroupTable.loadEmployeeGroupMembers(this.createdGroupId);
        this.$refs.employeeGroupTable.loadAllEmployeeGroupMembers();

        this.$refs.employeeSearchField.lazyValue = [];
        this.selectedEmployees = [];
      }
    },
    async saveGroup() {
      this.loading = true;

      if (this.selectedGroup) {
        await this.$store.dispatch("employee/updateEmployeeGroup", {
          id: this.createdGroupId,
          name: this.groupName,
        });

        this.loading = false;
        return;
      }

      if (this.getSelectedCorporationId) {
        const result = await this.$store.dispatch("employee/createEmployeeGroup", {
          name: this.groupName,
          corporationId: this.getSelectedCorporationId,
        });

        if (result) {
          this.newRestriction = `${this.groupName}`;
          this.createdGroupId = result.id;

          this.isGroupCreated = true;

          this.$store.commit("employee/setCreatedGroup", {
            id: this.createdGroupId,
            name: this.newRestriction,
          });
          this.loading = false;
        }

        return;
      }

      notifyWarn({
        title: this.$t("components.employeeGroupForm.missedCorporation"),
        message: this.$t("components.employeeGroupForm.selectCorporation"),
      });
    },
    increaceIntersection() {
      this.restrictionListParams.page += 1;
      this.intersection = true;
    },
    // eslint-disable-next-line consistent-return
    createRestriction() {
      if (!this.getSelectedCorporationId)
        return notifyWarn(
          this.$t("components.employeeGroupForm.selectCorporation"),
          this.$t("components.employeeGroupForm.missedCorporation")
        );

      if (this.restrictionTemplate) {
        this.disableRestrictionTemplate = false;
        this.copyExistingRestriction(this.restrictionTemplate.id, {
          name: this.newRestriction,
          corporationId: this.getSelectedCorporationId,
        });
      }
    },
    updateDateTimeRestrictions(rule) {
      const dateTimeRules = this.groupRules.dateTime;
      const existingDateTimeRule = dateTimeRules.find((el) => rule.day === el.dateTimeRuleType);
      if (existingDateTimeRule) {
        existingDateTimeRule.dateTimeSlots = rule.restriction;
      } else {
        this.groupRules.dateTime.push({
          dateTimeRuleType: rule.day,
          dateTimeSlots: rule.restriction,
          type: "dateTime",
        });
      }
    },
    updateOriginalValue(day, value) {
      this.originalValues[day] = value;
    },
  },
};
</script>

<style lang="scss">
.employee-search {
 & input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
 }

 & fieldset {
  background-color: #ffffff !important;
 }

 & .v-select__selection--comma {
   font-family: 'Inter', sans-serif;
   font-weight: 500;
 }
}
.employee-group-header thead tr {
  background-color: #fcfcfc !important;
}

.disabled-restriction-form {
  opacity: 0.6;
  pointer-events: none;
}

.no-restriction label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #333333;
}
</style>

<style lang="scss" scoped>
.group-form-header {
  margin-top: 1px;
  background: #fcfcfc;
  width: 100%;
}

.divider-line {
  border-color: #eeeeee;
}

.add-user-group {
  text-transform: none !important;
}

.save-group {
  color: #47d061;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  text-decoration-line: underline;
  cursor: pointer;
}

.distance-field {
  border-radius: 6px;

  span {
    color: #888888;
  }
}

.currency-symbol {
  margin-top: 5px;
}

.group-restrictions {
  background: #f8f8f8;
  border-radius: 8px;
}

.week-time,
.week-day-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #333333;
  margin-bottom: 5px;
}

.week-time-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #888888;
}

.noData-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.005em;
  color: #333333;
}

.noData-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.005em;
  color: #888888;
}

.footer-submit {
  background-color: #ffffff;
  border-radius: 8px;
}

.submit-btn {
  background-color: #333333 !important;
  border-radius: 6px;
  color: #ffffff;
}
</style>
