<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-progress-circular v-if="loading" indeterminate />
    </v-layout>
  </v-container>
</template>

<script>
import { Authentication } from '@icabbi/vue-authentication';
import { mapGetters } from 'vuex';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import { getRoles } from '@/mixins/authentication.mixins';
import { CHANNEL_ADMIN, CORP_ADMIN } from '@/helpers/userRoles';

export default {
  mixins: [bookingChannelComputed, getRoles],
  async mounted() {
    if (this.isValidCallback(this.$route.query)) {
      this.loading = true;

      try {
        await Authentication(this.$store)
          .exchangeCodeForToken(this.$route.query.code, this.loginContext, this.oidcClientId, this.authCallbackUrl);
      } catch (e) {
        this.error(e);
        return;
      }

      this.loading = false;

      this.$store.dispatch('authentication/clearLoginContext');

      this.loadDefaultPage();
    } else {
      this.error();
    }
  },
  data() {
    return {
      invalidCallback: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      loginContext: 'authentication/loginContext',
      token: 'authentication/token',
    }),
  },
  methods: {
    isValidCallback(query) {
      return query.state
        && this.loginContext
        && this.loginContext.state
        && !query.error
        && query.state === this.loginContext.state;
    },
    error() {
      this.$router.push('/');
    },
    loadDefaultPage() {
      this.userRoles.every((role) => {
        switch (role.name) {
          case CORP_ADMIN:
            localStorage.removeItem('corporationName');
            this.$router.push({ name: 'trip-list' });
            return false;
          case CHANNEL_ADMIN:
            localStorage.removeItem('corporationName');
            this.$router.push({ name: 'accounts' });
            return false;
          default:
            return true;
        }
      });
    },
  },
};
</script>
