import { Authentication } from '@icabbi/vue-authentication';
import assert from 'assert';
import { notifyError, notifyGeneralError } from './notification';
import { errorCodeHandler } from './errorCodeHandler';

// TODO finish this when BE will be allowed to do
// function handleLanguage(config, getters) {
//   const language = getters['language/selectedLanguage'];
//   if (language) {
//     // eslint-disable-next-line no-param-reassign
//     config.headers = { ...config.headers, 'x-lang': language };
//   }
//   return config;
// }

const apiInstance = ({ dispatch, getters, Connector }) => {
  assert(dispatch, 'dispatch is required');
  assert(getters, 'getters is required');
  assert(Connector, 'Connector is required');
  const authentication = Authentication({ dispatch, getters });
  const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
  const serviceConnector = new Connector({
    baseURL: process.env.VUE_APP_API_GATEWAY_URL,
    timeout: parseInt(process.env.VUE_APP_HTTP_REQUEST_TIMEOUT, 10),
  });
  serviceConnector.httpClient.interceptors.response.use(undefined, (error) => authenticationFailureInterceptor(error));
  serviceConnector.httpClient.interceptors.request.use((config) => bearerTokenInterceptor(config));
  // serviceConnector.httpClient.interceptors.request.use((config) => handleLanguage(config, getters));
  return serviceConnector;
};

export const apiCall = (call) => async (context, params) => {
  try {
    const result = await call(context, params);
    return result === '' ? true : result;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    if(params?.returnErrorDetails) return error;
    if (params?.errorHandler) params.errorHandler(error);
    else {
      const result = errorCodeHandler(context, error.code);

      if (!result) return false;

      if (!error.displayMessage && !error.displayTitle) return notifyGeneralError(error);

      notifyError(error.displayMessage, `${error.displayTitle} - ${error.httpStatusCode}`);
    }
    return false;
  }
};

export default apiInstance;
