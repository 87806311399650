<template>
  <dialog-wrapper ref="dialogConfirm" :width=400>
    <template v-slot:dialogContent>
      <v-card>
        <slot></slot>
        <v-card-actions>
          <v-btn
            class="btn btn-flat"
            text
            @click="handleCancelButton"
          >
            {{cancelButtonText ? cancelButtonText : $t('global.cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-danger"
            depressed
            @click="handleOkButton"
          >
            {{okButtonText ? okButtonText : $t('global.ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </dialog-wrapper>
</template>

<script>
import DialogWrapper from '@/components/elements/DialogWrapper.vue';

export default {
  name: 'ConfirmDialog',
  components: { DialogWrapper },
  props: {
    okButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleOkButton() {
      this.$emit('handleOkButton', this.data);
    },
    handleCancelButton() {
      this.$emit('handleCancelButton');
    },
    close() {
      this.$refs.dialogConfirm.close();
    },
    open() {
      this.$refs.dialogConfirm.openDialog();
    },
  },
};
</script>
