<template>
    <div class="bs-table-table">
      <v-row no-gutters>
        <v-col class="col-without-top-padding table-color">
          <v-data-table
            class="bs-table elevation-0"
            calculate-widths
            :items="employees"
            :headers="tableHeader"
            :options.sync="options"
            :page.sync="listRequest.page"
            :loading="employeesLoading"
            :server-items-length="totalPassengers"
            :multi-sort="false"
            :must-sort="true"
            :single-expand="true"
            :no-data-text="$t(`global.noData`)"
            :no-results-text="$t(`global.noResults`)"
            hide-default-footer
            @update:options="handleUpdateOptions"
            @page-count="pageCount = $event"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>

            <template v-slot:item.givenName="{ item }">
              {{ item.givenName }} {{ item.familyName }}
            </template>

            <template v-slot:item.role="{ item }">
              {{ $t(`components.employeeList.role.${item.roles[0].name}`) }}
            </template>

            <template v-slot:item.email="{ item }">
              {{ item.email }}
            </template>

            <template v-slot:item.group="{ item }">
              <span v-if="item.businessPassengerGroups.length > 0">
                {{ item.businessPassengerGroups.map((el) => el.name).join(', ') }}
              </span>
            </template>

            <template v-slot:item.passenger.phoneNumber="{ item }">
              {{ item.passenger.phoneNumber }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="selectedTab === 'employee'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <email-icon v-on="on" v-bind="attrs" @click="resendEmailInvitation(item)" class="mr-1 icon-control-list"/>
                </template>
                <span>{{ $t(`components.employeeList.tooltips.${selectedTab}.resendMail`) }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <edit-icon @click="editEmployeeClick(item)" v-on="on" v-bind="attrs" class="mr-1 icon-control-list"/>
                </template>
                <span>{{ $t(`components.employeeList.tooltips.${selectedTab}.editEmployee`) }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <delete-icon @click="deleteEmployeeClick(item)" v-on="on" v-bind="attrs" class="mr-1 icon-control-list"/>
                </template>
                <span>{{ $t(`components.employeeList.tooltips.${selectedTab}.deleteEmployee`) }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
            <v-row no-gutters class="bs-per-page">
              <span>
                {{ $t(`global.perPage`) }}
              </span>
              <span
                v-for="item  in [10, 20, 30]"
                :key="item"
                class="px-1"
                :class="{ 'active-page-count': item === itemsPerPage }"
                @click="itemsPerPage = item"
              >
                {{ item }}
              </span>
            </v-row>
            <simple-paginator
              :page="listRequest.page + 1"
              :has-more="listRequest.hasMoreBookings"
              @goToFirst="this.firstPage"
              @previousPage="this.previousPage"
              @nextPage="nextPage"
            />
          </div>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { longDateTime } from '@/helpers/dateTime';
import { iconLoader } from '@/helpers/iconsLoader';

import SimplePaginator from "@/components/elements/SimplePaginator.vue";

export default {
  name: 'EmployeeList',
  components: {
    ...iconLoader.elements,
    SimplePaginator,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    employeesLoading: {
      type: Boolean,
      default: false,
    },
    totalPassengers: {
      type: Number,
      default: 0,
    },
    tableHeader: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: String,
      default: 'employee',
    },
  },
  data() {
    return {
      options: {},
      listRequest: {
        page: 0,
        hasMoreBookings: true,
      },
      itemsPerPage: 10,
    };
  },
  watch: {
    employees() {
      if (this.employees.length < this.itemsPerPage) {
        this.listRequest.hasMoreBookings = false;
      } else {
        this.listRequest.hasMoreBookings = true;
      }
    },
    itemsPerPage() {
      this.loadData();
    },
    selectedTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        this.options.itemsPerPage = 10;
      }
    },
  },
  methods: {
    handleUpdateOptions(options) {
      this.options = options;
      this.loadData();
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.loadData();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.loadData();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.loadData();
    },
    loadData() {
      this.$emit('loadData', {
        page: this.listRequest.page,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        itemsPerPage: this.itemsPerPage,
      });
    },
    formatDate(date) {
      return longDateTime(date);
    },
    deleteEmployeeClick(item) {
      this.$emit('deleteEmployeeClick', item);
    },
    async resendEmailInvitation(item) {
      this.$emit('resendEmailClick', item);
    },
    editEmployeeClick(item) {
      this.$emit('editEmployeeClick', item);
    },
  },
};
</script>

<style lang="scss">
.passenger-status {
  color: #47d061 !important;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
}
</style>
