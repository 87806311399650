import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  driversServiceConnector: null,
  drivers: [],
};

const storeGetters = {
  driversServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.driversServiceConnector) {
      state.driversServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.DriverStatesCommandConnector });
    }
    return state.driversServiceConnector;
  },
  availableDrivers: (state) => state.drivers.data,
};

const actions = {
  getDrivers: apiCall(async (context, params) => {
    const result = await context.getters
      .driversServiceConnector(context)
      .requestDriverStates(params);

    context.commit('setDrivers', result);
    return result;
  }),
};

const mutations = {
  setDrivers(state, drivers) {
    state.drivers = drivers;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: storeGetters,
  actions,
  mutations,
};
