import { business, Ic2BookingServiceClient } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  bookingCommandConnector: null,
  ic2BookingServiceClient: null,
  fleetAccountServiceClient: null,
  duplicateBooking: false,
  address: {
    pickup: null,
    dropoff: null,
    waypoints: [],
  },
};

const getters = {
  // eslint-disable-next-line no-shadow
  bookingCommandConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.bookingCommandConnector) {
      state.bookingCommandConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.BookingCommandConnector });
    }
    return state.bookingCommandConnector;
  },
  // eslint-disable-next-line no-shadow
  ic2BookingServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.ic2BookingServiceClient) {
      state.ic2BookingServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: Ic2BookingServiceClient });
    }
    return state.ic2BookingServiceClient;
  },
  // eslint-disable-next-line no-shadow
  fleetAccountServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.fleetAccountServiceClient) {
      state.fleetAccountServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.FleetAccountServiceConnector });
    }
    return state.fleetAccountServiceClient;
  },
  getDuplicateBooking: (state) => state.duplicateBooking,
  getAddresses: (state) => state.address,
};

const actions = {
  cancelBooking: apiCall((context, bookingId) => context.getters.bookingCommandConnector(context).cancelBooking({ bookingId })),
  requestBooking: apiCall((context, payload) => context.getters.bookingCommandConnector(context).requestBooking({ data: payload })),
  confirmBooking: apiCall((context, bookingId) => context.getters.bookingCommandConnector(context).confirmBooking({ bookingId })),
  requestBookingUpdate: apiCall((context, { bookingId, bookingData }) => context.getters.bookingCommandConnector(context).updateBooking(bookingId, bookingData)),
  confirmBookingUpdate: apiCall((context, bookingId) => context.getters.bookingCommandConnector(context).confirmBookingUpdate(bookingId)),
  confirmPayment: apiCall(async (context, authorizationClientSecret) => {
    const stripe = await context.rootGetters['paymentMethods/stripeClient'];
    const confirm = await stripe.confirmCardPayment(authorizationClientSecret);
    return !confirm.error?.code;
  }),
  getFleetAccounts: apiCall((context, { corporationId, fleetId }) => context.getters.fleetAccountServiceClient(context).listFleetAccounts({ corporationId, fleetId })),
  getAccountFields: apiCall((context, params) => context.getters.ic2BookingServiceClient(context).getAccountFields(params)),
};

const mutations = {
  setDuplicateBooking(state, value) {
    state.duplicateBooking = value;
  },
  setAddress(state, { type, address }) {
    state.address[type] = address;
  },
  resetAddress(state) {
    state.address = {
      pickup: null,
      dropoff: null,
      waypoints: [],
    };
  }
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
