<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat class="pa-5">
        <v-card-title>
          <div class="d-flex justify-space-between w-full">
            <span class="pb-2 form-title">{{corporation ? $t('components.corporationsForm.title.edit') : $t('components.corporationsForm.title.add')}}</span>
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.corporationsForm.form.name')}}</h5>
              <v-text-field
                outlined
                filled
                hide-details
                :placeholder="$t('components.corporationsForm.form.name_placeholder')"
                :class="{ 'input-field-error': $v.name.$error }"
                background-color="#f8f8f8"
                v-model.trim="$v.name.$model">
                <template v-if="$v.name.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.name"/>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.corporationsForm.form.contact_email')}}</h5>
              <v-text-field
                outlined
                filled
                hide-details
                :placeholder="$t('components.corporationsForm.form.contact_email')"
                :class="{ 'input-field-error': $v.contactEmail.$error }"
                v-model.trim="$v.contactEmail.$model">
                <template v-if="$v.contactEmail.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.contactEmail"/>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.corporationsForm.form.language')}}</h5>
              <v-select
                :items="languageOptions"
                v-model="$v.language.$model"
                class="language-field"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="value"
                outlined
                hide-details
                background-color="#f8f8f8"
                :class="{ 'input-field-error': $v.language.$error }"
              >
                <template v-if="$v.language.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.language"/>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <linked-account-form
            v-for="(fleetAccount, idx) in fleetAccounts"
            v-bind:key="idx"
            :fleets="fleets"
            :fleetAccount="fleetAccount"
            :fleetAccounts="fleetAccounts"
            :index="idx"
            @deleteFleetAccount="deleteFleetAccount"
            @deleteFleetAccountLocal="deleteFleetAccountLocal"
            @validateAccountNumber="validateAccountNumber"
            @invalidateAccountNumber="invalidateAccountNumber"
          />
          <v-row>
            <v-col>
              <v-btn
                class="btn btn-flat"
                height="36px"
                @click="addMoreAccount"
                v-if="fleetAccounts.length < fleets.length"
                depressed>
                <v-icon left>mdi-plus</v-icon>
                Add Linked Account
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-4">
          <v-btn
            class="btn btn-flat"
            text
            @click="closeDialog"
          >
            {{$t('components.corporationsForm.actionButtons.cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-default"
            :disabled="disableSubmit"
            @click="handleSaveAction"
          >
            {{$t('components.corporationsForm.actionButtons.add')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <dialog-wrapper ref="confirmDeleteDialog" :width=350  @closeDialog="deleteFleetAccountLocal" />
  </loader>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';
import { required, email } from 'vuelidate/lib/validators';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import ErrorTooltip from '@/components/elements/ErrorTooltip.vue';
import { notifySuccess } from '@/helpers/notification';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';

import { languageList } from '@/mixins/language.mixins';
import { listFleetAccounts } from '@/mixins/fleetAccounts.mixins';
import LinkedAccountForm from './LinkedAccountForm.vue';

export default {
  name: 'CorporationsForm',
  components: {
    ...iconLoader.elements, ErrorTooltip, LinkedAccountForm, DialogWrapper,
  },
  mixins: [entityLoader, languageList, listFleetAccounts],
  props: {
    corporation: {
      type: Object,
    },
    currentPage: {
      type: Number,
    },
    fleets: {
      type: Array,
    },
  },
  data() {
    return {
      name: '',
      contactEmail: '',
      language: '',
      isLoading: false,
      page: 0,
      hasFleetAccount: true,
    };
  },
  validations: {
    name: { required },
    language: { required },
    contactEmail: { required, email, minLength: 2 },
  },
  watch: {
    corporation() {
      this.initCorporationData();
    },
  },
  mounted() {
    this.initCorporationData();
    this.populateLanguagesSelectList();
  },
  computed: {
    disableSubmit() {
      const fleetAccountsValid = this.fleetAccounts.reduce((acc, current) => {
        if (!acc) return acc;
        return current.isValid;
      }, true);

      if (!fleetAccountsValid || !this.fleetAccounts.length) return true;
      return this.$v.$invalid;
    },
  },
  methods: {
    initCorporationData() {
      if (this.corporation) {
        this.name = this.corporation.name;
        this.language = this.corporation.communicationLanguage;
        this.contactEmail = this.corporation.contactEmail;
        this.listFleetAccounts(this.corporation.id);
      } else {
        this.name = '';
        this.language = '';
        this.contactEmail = '';
      }
    },
    handleSaveAction() {
      if (this.corporation) {
        this.editAccount();
      } else {
        this.addAccount();
      }
    },
    // eslint-disable-next-line consistent-return
    async addAccount() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }
      try {
        this.isLoading = true;
        const result = await this.$store.dispatch('corporations/createCorporation', {
          name: this.name,
          communicationLanguage: this.language,
          contactEmail: this.contactEmail,
        });
        if (result) {
          await this.saveAllFleetAccounts(result.id);
          notifySuccess(this.$t('components.corporationsForm.notification.message.new'));
          this.$emit('corporationAdded', { page: this.currentPage });
          this.closeDialog();
        }
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line consistent-return
    async editAccount() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }
      try {
        this.isLoading = true;
        const data = {
          name: this.name,
          communicationLanguage: this.language,
          contactEmail: this.contactEmail,
          id: this.corporation.id,
        };
        const result = await this.actionPost('corporations/updateCorporation', data);
        if (result) {
          await this.saveAllFleetAccounts(data.id);
          notifySuccess(this.$t('components.corporationsForm.notification.message.edit'));
          this.$emit('corporationUpdated', { page: this.page });
          this.closeDialog();
        }
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    addMoreAccount() {
      this.fleetAccounts.push({});
    },
    deleteFleetAccountLocal(idx) {
      this.fleetAccounts = this.fleetAccounts.filter((fleetAccount, index) => (typeof idx === 'object' ? fleetAccount.id !== idx.id : index !== idx));
    },
    deleteFleetAccount({ fleetAccount, index }) {
      if (this.corporation && fleetAccount.id) {
        this.$refs.confirmDeleteDialog.openDialog({ type: 'delete-fleet-account', id: fleetAccount.id });
      } else {
        this.deleteFleetAccountLocal(index);
      }
    },
  },
};
</script>

<style scoped>
.v-input {
  font-weight: normal;
  font-size: 14px !important;
  color: #000 !important;
}

.language-field {
  background: #F8F8F8;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #EEEEEE;
  letter-spacing: -0.005em;
  color: #333333;
}
</style>
