<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <div class="bs-table-header">
      <v-row class="mb-3">
        <v-col cols="12" class="col-without-bottom-padding px-8 mt-6 mb-3">
          <h1 class="bs-header-title">
            {{ $t("components.accountsImport.results.title") }}
          </h1>
        </v-col>
        <v-row  class="d-flex justify-end mb-3 px-8">
          <v-col cols="6" class="d-flex align-start col-without-top-padding col-without-bottom-padding">
            <p class="mb-0">Total rows : {{ totalRows }}, Total Failed: {{ importResults.length }}</p>
          </v-col>
          <v-col sm="4" md="4" lg="4" class="d-flex align-end col-without-top-padding col-without-bottom-padding justify-end">
            <v-text-field
              :label="$t('components.corporationsListTable.search')"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              hide-details
              clearable
              class="mr-2 bs-search-item"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-row>
    </div>
    <div class="bs-table-table">
      <v-row no-gutters>
        <v-col class="col-without-top-padding table-color">
          <v-data-table
            class="bs-table elevation-0"
            calculate-widths
            :items="importResults"
            :headers="headers"
            :server-items-length="(importResults - 1)"
            :loading="resultsLoading"
            :multi-sort="false"
            :must-sort="true"
            :single-expand="true"
            :page.sync="page"
            :options.sync="options"
            :items-per-page="itemsPerPage"
            :no-data-text="$t(`global.noData`)"
            :no-results-text="$t(`global.noResults`)"
            hide-default-footer
            @page-count="pageCount = $event"
            :expanded.sync="expanded"
            item-key="payload.dispatchAccountNumber"
            show-expand
          >
            <template v-slot:item.payload.row="{ item }">
              {{ item.payload.row }}
            </template>
            <template v-slot:item.payload.accountName="{ item }">
              {{ item.payload.accountName }}
            </template>
            <template v-slot:item.payload.communicationLanguage="{ item }">
              {{ item.payload.communicationLanguage }}
            </template>
            <template v-slot:item.payload.contactEmail="{ item }">
              {{ item.payload.contactEmail }}
            </template>
            <template v-slot:item.payload.dispatchAccountNumber="{ item }">
              {{ item.payload.dispatchAccountNumber }}
            </template>
            <template v-slot:item.payload.businessName="{ item }">{{ item.payload.givenName }} {{ item.payload.familyName }}</template>
            <template v-slot:item.wasSuccess="{ item }">
              <v-chip label small color="success" text-color="white" v-if="item.wasSuccess">SUCCESS</v-chip>
              <v-chip label small color="error" text-color="white" v-if="!item.wasSuccess">ERROR</v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
              <v-row>
                <ImportResultDetails :result="item.passenger" title="Passenger"></ImportResultDetails>
                <ImportResultDetails :result="item.businessAccount" title="Business Account"></ImportResultDetails>
                <ImportResultDetails :result="item.businessDispatchAccount" title="Business Dispatch Account"></ImportResultDetails>
                <ImportResultDetails :result="item.businessPassenger" title="Business Passenger"></ImportResultDetails>
              </v-row>
              </td>
            </template>
          </v-data-table>
          <div class="d-flex justify-space-between align-center py-2 px-4 bs-table-footer">
            <v-row no-gutters class="bs-per-page">
              <span>
                {{ $t(`global.perPage`) }}
              </span>
              <span
                v-for="item  in [10, 20, 30]"
                :key="item"
                class="px-1"
                :class="{ 'active-page-count': item === options.itemsPerPage }"
                @click="options.itemsPerPage = item"
              >
                {{ item }}
              </span>
            </v-row>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
              color="#f2f2f2"
              class="bs-pagination"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';
import { longDateTime } from '@/helpers/dateTime';
import { languageList } from '@/mixins/language.mixins';
import PQueue from 'p-queue';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import ImportResultDetails from '@/components/elements/ImportResultDetails.vue';

export default {
  props: {
    importData: {
      type: Array,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  components: { ...iconLoader.elements, ImportResultDetails },
  mixins: [languageList, bookingChannelComputed],
  data() {
    return {
      page: 1,
      pageCount: 0,
      options: {},
      expanded: [],
      itemsPerPage: 10,
      search: null,
      modalData: null,
      isProd: false,
      resultsLoading: false,
      importResults: [],
    };
  },
  watch: {
    search(val) {
      if (!val) this.search = '';
      if (this.search.length >= 2 || this.search.length === 0) {
        this.loadData();
      }
      this.page = 1;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('components.corporationsListTable.table-headers.row'),
          align: 'left',
          sortable: true,
          value: 'payload.row',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.accountName'),
          align: 'left',
          sortable: true,
          value: 'payload.accountName',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.businessUser'),
          align: 'left',
          sortable: true,
          value: 'payload.businessName',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.communication-lang'),
          align: 'left',
          sortable: true,
          value: 'payload.communicationLanguage',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.main-contact'),
          align: 'left',
          sortable: true,
          value: 'payload.contactEmail',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.code'),
          align: 'left',
          sortable: true,
          value: 'payload.dispatchAccountNumber',
          class: 'text-uppercase',
        },
        {
          align: 'left',
          sortable: true,
          value: 'wasSuccess',
          class: 'text-uppercase',
        },
      ];
    },
  },
  created() {
    this.importAccounts();
  },
  methods: {
    formatDate(date) {
      return longDateTime(date);
    },
    async importAccounts() {
      const numUsers = this.importData.length;
      this.importing = true;
      this.showProgress = true;

      const queue = new PQueue({ concurrency: 5 });
      let count = 0;

      const progress = () => {
        this.importProgress = ++count / numUsers * 100;
      };
      const mapper = (data) => () => this.importRecord(data);

      queue.on('next', progress.bind(this));
      await queue.addAll(this.importData.map(mapper.bind(this)));
      this.importing = false;
    },
    async importRecord(record) {
      const payload = {
        bookingChannelId: this.getChannelId,
        ...record,
      };
      const res = await this.$store.dispatch('businessAccount/importRecord', payload);
      if (!res.wasSuccess) {
        res.payload = record;
        this.importResults.push(res);
      }
    },
    preventNav(event) {
      event.preventDefault();
      event.returnValue = '';
    },
  },
};
</script>
