<template>
  <loader :is-loading="loading" :fullPage="true" color="primary">
    <v-container fluid class="align-start align-content-start fill-height">
      <div class="bs-table-header pt-10">
        <v-row justify="space-between" class="align-center mb-3 px-4">
          <v-row  class="d-flex justify-end mb-3 px-8">
            <v-col md="7" lg="7" class="col-without-top-padding col-without-bottom-padding bs-header-title">
              {{ $t('components.usersList.userList.title') }}
            </v-col>
            <v-col md="5" lg="5" class="d-flex align-center justify-end col-without-top-padding col-without-bottom-padding">
              <v-text-field
                v-model="dynamicSearch"
                :label="$t('components.usersList.search')"
                prepend-inner-icon="mdi-magnify"
                clearable
                outlined
                hide-details
                :height="38"
                class="mr-3 bs-search-item"
              ></v-text-field>
              <corporations-select
                v-if="getIsAdmin"
                :prefill="false"
                :is-clearable="true"
                class="mr-3"
              />
              <v-btn
                class="add-bs-button"
                color="#333333"
                :height="40"
                depressed
                @click="addUserClick()"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t(`components.usersList.userList.${getTabAction().tab}.add`) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <div class="bs-table-table">
        <v-row no-gutters>
          <v-col class="col-without-top-padding table-color">
            <v-data-table
              class="bs-table elevation-0"
              calculate-widths
              sort-by="givenName"
              :sort-desc="true"
              :items="users"
              :headers="headers"
              :server-items-length="totalUsers"
              :loading="usersLoading"
              :multi-sort="false"
              :must-sort="true"
              :single-expand="true"
              :page.sync="listRequest.page"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :no-data-text="$t(`global.noData`)"
              :no-results-text="$t(`global.noResults`)"
              hide-default-footer
              @update:options="handleUpdateOptions"
            >
              <template v-slot:item.givenName="{ item }">
                {{ getFullName(item) }}
              </template>
              <template v-slot:item.action="{ item }">
                <edit-icon @click="editUserClick(item)" class="mr-1 icon-control-list"/>
                <delete-icon @click="deleteUserClick(item)" class="mr-1 icon-control-list"/>
              </template>
              <template v-slot:item.roles="{ item: { roles } }">
                {{ getUserRoles(roles) }}
              </template>
              <template v-slot:item.createdAt="{ item: { createdAt } }">
                {{ formatDate(createdAt) }}
              </template>
              <template v-slot:item.status="{ item: { status } }">
                <div :class="`status-container py-1 px-2 status-${status}`">
                  {{ status.toUpperCase() }}
                </div>
              </template>
            </v-data-table>
            <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
              <v-row no-gutters class="bs-per-page">
                <span>
                  {{ $t(`global.perPage`) }}
                </span>
                <span
                  v-for="item  in [10, 20, 30]"
                  :key="item"
                  class="px-1"
                  :class="{ 'active-page-count': item === itemsPerPage }"
                  @click="itemsPerPage = item"
                >
                  {{ item }}
                </span>
              </v-row>
              <simple-paginator
                :page="listRequest.page + 1"
                :has-more="listRequest.hasMoreBookings"
                @goToFirst="this.firstPage"
                @previousPage="this.previousPage"
                @nextPage="nextPage"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </loader>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { iconLoader } from '@/helpers/iconsLoader';
import { longDateTime } from '@/helpers/dateTime';
import CorporationsSelect from '@/components/corporations/CorporationsSelect.vue';
import SimplePaginator from "@/components/elements/SimplePaginator.vue";

export default {
  name: 'UsersList',
  components: {
    ...iconLoader.elements,
    CorporationsSelect,
    SimplePaginator
  },
  props: {
    usersLoading: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    totalUsers: {
      type: Number,
      required: true,
    },
    noUsersExist: {
      type: Boolean,
    },
  },
  data() {
    return {
      screenName: 'user-list',
      dynamicSearch: null,
      options: {},
      deleteDialogs: {},
      listRequest: {
        page: 0,
        hasMoreBookings: true,
      },
      itemsPerPage: 10,
      loading: false,
      tab: 0,
      tabs: [
        { tab: 'users' },
      ],
    };
  },
  created() {
    this.debouncedLoad = _.debounce(this.load, 500);
  },
  watch: {
    users() {
      if (this.users.length < this.itemsPerPage) {
        this.listRequest.hasMoreBookings = false;
      } else {
        this.listRequest.hasMoreBookings = true;
      }
    },
    getSelectedCorporationId: {
      handler() {
        this.load();
      },
    },
    itemsPerPage() {
      this.load();
    },
    totalUsers() {
      this.options.pageCount = Math.round(this.totalUsers / this.itemsPerPage) + 1;
    },
    dynamicSearch(val) {
      if (val === '') this.dynamicSearch = null;

      this.listRequest.page = 0;
      this.debouncedLoad();
    },
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    ...mapGetters('users', ['getIsAdmin']),
    headers() {
      return [
        {
          text: this.$t('components.usersList.userList.list.name'),
          align: 'left',
          sortable: true,
          value: 'givenName',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.usersList.userList.list.email'),
          align: 'left',
          sortable: true,
          value: 'email',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.usersList.userList.list.created_at'),
          align: 'left',
          sortable: true,
          value: 'createdAt',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.usersList.userList.list.role'),
          align: 'left',
          sortable: true,
          value: 'roles',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.usersList.userList.list.status'),
          align: 'left',
          sortable: true,
          value: 'status',
          class: 'text-uppercase',
        },
        {
          value: 'action',
          width: '10%',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
  methods: {
    formatDate(date) {
      return longDateTime(date);
    },
    getUserRoles(roles) {
      return _.uniq(roles.map((role) => this.$t(`components.usersList.userList.list.roles.${role.name}`))).join(', ');
    },
    addUserClick() {
      this.$emit('addUserClick');
    },
    editUserClick(item) {
      this.$emit('editUserClick', item);
    },
    deleteUserClick(item) {
      this.$emit('deleteUserClick', item);
    },
    getFullName(item) {
      return `${item.givenName} ${item.familyName}`;
    },
    getTabAction() {
      return this.tabs[this.tab];
    },
    handleUpdateOptions(options) {
      this.options = options;
      this.load();
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.load();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.load();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.load();
    },
    async load() {
      const { sortBy, sortDesc } = this.options;
      const sort = sortBy[0] ?? '-createdAt';
      this.$emit('loadData', {
        page: this.listRequest.page,
        dynamicSearch: this.dynamicSearch,
        maxPerPage: this.itemsPerPage,
        sort: sortDesc[0] ? `-${sort}` : sort,
        corporationId: this.getSelectedCorporationId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.datatable-title {
  font-weight: 700;
}
.hr-line {
  height:3px;
  background-color: #000000;
}
.no-employees-container {
  flex-flow: column;
  align-content: center;
  .header-list-button {
    max-width: 250px;
    min-width: 250px !important;
    margin-bottom: 50px;
  }
  .no-employees-col {
    display: flex;
    justify-content: center;
  }
  .no-employees-icon {
    color: #247DFF;
    width: 80px;
    margin-left: 20px;
    margin-top: 50px;
  }
}
</style>
