import ReturnBookingModal from '@/components/booking/components/ReturnBookingModal.vue';

// eslint-disable-next-line import/prefer-default-export
export const returnBookingMixin = {
  name: 'ReturnBookingCommands',
  components: {
    ReturnBookingModal,
  },
  data() {
    return {
      bookReturnBooking: false,
      modalInstanse: ReturnBookingModal,
    };
  },
  watch: {
    bookReturnBooking: {
      handler(value) {
        if (value) {
          this.$refs.retunBookingForm.openDialog();
        } else {
          this.$refs.retunBookingForm.cancelReturnBooking();
          this.bookReturnBooking = false;
        }
      },
    },
  },
  methods: {
    async createReturnBooking(booking, returnPickupTime) {
      const returnBooking = {
        ...booking,
        origin: booking.destination,
        destination: booking.origin,
        pickupTime: returnPickupTime,
      };

      const bookingResponse = await this.$store.dispatch('bookings/requestBooking', {
        ...returnBooking,
        isDuplicateAcknowledged: this.getDuplicateBooking,
      });

      if (
        bookingResponse?.requiresConfirmation
          && !(await this.$store.dispatch(
            'bookings/confirmPayment',
            bookingResponse.authorizationClientSecret,
          ))
      ) {
        this.creatingBooking = false;
        return;
      }
      if (bookingResponse?.requiresBookingConfirmation === false) {
        this.$emit('created', bookingResponse);
        return;
      }

      if (
        !bookingResponse
          || !(await this.$store.dispatch('bookings/confirmBooking', bookingResponse.bookingId))
      ) {
        this.creatingBooking = false;
        return;
      }
      this.$emit('created', bookingResponse);
    },
  },
};
