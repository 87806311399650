import { mapGetters } from 'vuex';
import { initTravelRestrictionEntity } from '@/mixins/travelRestriction.mixins';

// eslint-disable-next-line import/prefer-default-export
export const weekRestriction = {
  mixins: [initTravelRestrictionEntity],
  data: () => ({
    initialState: [
      {
        day: 'monday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'tuesday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'wednesday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'thursday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'friday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'saturday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'sunday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
    ],
    weekList: [
      {
        day: 'monday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'tuesday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'wednesday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'thursday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'friday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'saturday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
      {
        day: 'sunday',
        restriction: [{
          startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
        }],
        isRestriction: false,
        isDisabled: false,
        isValid: false,
      },
    ],
    originalValues: {
      monday: [{
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
      tuesday: [{
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
      wednesday: [{
        isRestriction: false,
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
      thursday: [{
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
      friday: [{
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
      saturday: [{
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
      sunday: [{
        startTime: {
          hours: 0,
          minutes: 0,
        },
        endTime: {
          hours: 0,
          minutes: 0,
        },
      }],
    },
  }),
  computed: {
    ...mapGetters('travelRestriction',
      ['getTravelRestrictionId', 'getTravelRestrictionRuleId']),
  },
  methods: {

  },
};
