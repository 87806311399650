<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <EmployeeListHeader
      @search="patchDynamicSearchFiled($event)"
      @activeTab="selectedTab = $event"
      @import="showImportDialog = true"
      @exportBusinessBookingRecipientsList="exportBusinessBookingRecipientsList($event)"
      @addEmployeeClick="addEmployeeClick()"
      @addEmployeeGroupClick="addEmployeeGroupClick()"
    />

    <EmployeeList
      v-if="selectedTab && selectedTab.tab !== 'groups'"
      :employees="getEntities(selectedTab.tab)"
      :employeesLoading="getLoading(selectedTab.tab)"
      :selectedTab="selectedTab.tab"
      :tableHeader="getHeader(selectedTab.tab)"
      :totalPassengers="getTotalPassengers(selectedTab.tab)"
      @loadData="handleLoadByOptions"
      @resendEmailClick="resendEmailClick($event)"
      @editEmployeeClick="editEmployeeClick($event)"
      @deleteEmployeeClick="deleteEmployeeClick($event)"
    />

    <EmployeeListGroups
      v-else
      ref="employeeListGroups"
      :groupsLoading="businessGuestsLoading"
      :groups="businessPassengerGroups"
      :totalGroups="totalBusinessPassengerGroups"
      :tableHeader="groupHeader"
      @loadData="handleLoadByOptions"
      @deleteEmployeeGroupClick="deleteEmployeeGroupClick($event)"
    />


    <create-employee-form
      ref="employeeDialog"
      :employeeData="employee"
      :mode="mode"
      @closeDialog="closeDialog"
    />

    <create-guest-form
      ref="guestDialog"
      :guestData="guest"
      :mode="mode"
      @closeDialog="closeDialog"
    />

    <dialog-wrapper
      ref="dialogWrapper"
      :width="dialogWidth"
      @closeDialog="refreshData(selectedTab.tab)"
    />

    <v-dialog persistent width="680" :value="showImportDialog">
      <employee-import @cancel="showImportDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import DialogWrapper from '@/components/elements/DialogWrapper.vue';
import EmployeeListHeader from "@/components/people/v2/EmployeeListHeader.vue";
import EmployeeList from "@/components/people/v2/EmployeeList.vue";
import EmployeeListGroups from "@/components/people/v2/EmployeeListGroups.vue";

import EmployeeImport from "@/components/people/EmployeeImport.vue";
import CreateEmployeeForm from "@/components/people/v2/form/CreateEmployeeForm.vue";
import CreateGuestForm from "@/components/people/v2/form/CreateGuestForm.vue";

import { businessPassenger, exportPassengers } from "@/mixins/passenger.mixins";
import { entityLoader } from "@/mixins/entityLoader.mixins";

export default {
  components: {
    EmployeeListHeader,
    CreateEmployeeForm,
    CreateGuestForm,
    EmployeeImport,
    EmployeeList,
    EmployeeListGroups,
    DialogWrapper,
  },
  mixins: [businessPassenger, exportPassengers, entityLoader],
  data() {
    return {
      employee: null,
      guest: null,
      mode: "add",
      dialogWidth: 600,
      showImportDialog: false,
      selectedTab: null,
    };
  },
  computed: {
    tableHeader() {
      return [
        {
          text: this.$t("components.employeeList.list.createdAt"),
          align: "left",
          sortable: true,
          value: "createdAt",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.name"),
          align: "left",
          sortable: true,
          value: "givenName",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.email"),
          align: "left",
          sortable: false,
          value: "email",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.group"),
          align: "left",
          sortable: false,
          value: "group",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.phone"),
          align: "left",
          sortable: false,
          value: "passenger.phoneNumber",
          class: "text-uppercase",
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    guestTableHeader() {
      return [
        {
          text: this.$t("components.employeeList.list.createdAt"),
          align: "left",
          sortable: true,
          value: "createdAt",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.name"),
          align: "left",
          sortable: true,
          value: "givenName",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.phone"),
          align: "left",
          sortable: false,
          value: "phoneNumber",
          class: "text-uppercase",
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    groupHeader() {
      return [
        {
          text: this.$t("components.employeeList.list.createdAt"),
          align: "left",
          sortable: true,
          value: "createdAt",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeGroupForm.groupName"),
          align: "left",
          sortable: true,
          value: "name",
          class: "text-uppercase",
        },
        {
          text: this.$t('components.employeeGroupForm.account'),
          align: 'left',
          sortable: false,
          value: 'businessAccount.name',
          class: 'text-uppercase',
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    ...mapGetters("corporations", ["getSelectedCorporationId"]),
  },
  watch: {
    selectedTab(val) {
      if (val) {
        this.initCallPassengers(val.tab);
      }
    },
    // Since for the corporationAdmin tab, the data is not fetched by the initCallPassengers method,
    // we need to manually filter the tabs and call the getCorporationAdmins method to fetch the data.
    // This is only needed when the user changes the selected corporation, to refresh the list shown in the table.
    async getSelectedCorporationId(val) {
      if (val) {
        if (this.selectedTab.tab === "groups") {
          this.searchPassengerGroupsObject.corporationId = val;
        }
        this.initCallPassengers(this.selectedTab.tab);
      } else {
        if (this.selectedTab.tab === "groups") {
          this.searchPassengerGroupsObject.corporationId = null;
        }
        // When a fleet manager clears the corporation selection, re-fetch the data of the selected tab
        this.initCallPassengers(this.selectedTab.tab);
      }
    },
  },
  methods: {
    closeDialog(submitted) {
      // Resets the employee data to make sure the form watcher is triggered when the dialog is opened again
      this.employee = null;
      if (submitted) {
        this.initCallPassengers(this.selectedTab.tab);
      }
    },
    patchDynamicSearchFiled(value) {
      if (this.selectedTab.tab === "employee") {
        this.searchObject.dynamicSearch = value;
        this.searchObject.page = 0;
      } else if (this.selectedTab.tab === "guest") {
        this.searchGuestObject.dynamicSearch = value;
        this.searchGuestObject.page = 0;
      } else if (this.selectedTab.tab === "groups") {
        this.searchPassengerGroupsObject.dynamicSearch = value;
        this.searchPassengerGroupsObject.page = 0;
      }
    },
    handleLoadByOptions(options) {
      const { page, sortBy, sortDesc, itemsPerPage } = options;

      if (this.selectedTab.tab === "employee") {
        const order = sortBy[0] || "createdAt";
        this.searchObject = {
          ...this.searchObject,
          page: page,
          maxPerPage: itemsPerPage,
          orderBy: sortDesc[0] ? `-${order}` : order,
        };
      } else if (this.selectedTab.tab === "guest") {
        const order = sortBy[0] || "createdAt";
        this.searchGuestObject = {
          ...this.searchGuestObject,
          page: page,
          maxPerPage: itemsPerPage,
          orderBy: sortDesc[0] ? `-${order}` : order,
        };
      } else if (this.selectedTab.tab === "groups") {
        const order = sortBy[0] || "createdAt";
        this.searchPassengerGroupsObject = {
          ...this.searchPassengerGroupsObject,
          page: page,
          maxPerPage: itemsPerPage,
          sort: sortDesc[0] ? `-${order}` : order,
        };
      }
    },
    async resendEmailClick(employee) {
      this.dialogWidth = 400;
      this.$refs.dialogWrapper.openDialog({
        type: "resend-email",
        id: employee.id,
        corporationId: employee.corporationId || employee.businessAccountId,
      });
    },
    async deleteEmployeeClick(employee) {
      this.dialogWidth = 400;

      const endpoint = () => {
        switch (this.selectedTab.tab) {
          case "employee":
            return "delete-user";
          case "guest":
            return "delete-guest";
          default:
            // Since groups cannot be deleted from this view, we should never reach this case
            return "";
        }
      };

      this.$refs.dialogWrapper.openDialog({
        type: endpoint(),
        id: employee.id,
      });
    },
    deleteEmployeeGroupClick(group) {
      this.dialogWidth = 400;
      this.$refs.dialogWrapper.openDialog({ type: "delete-group", id: group.id });
    },
    addEmployeeClick() {
      this.mode = "add";
      this.employee = null;

      if (this.selectedTab.tab === "guest") {
        this.employee = {
          role: "guest",
        };

        this.$refs.guestDialog.guestDialogModal = true;
        return;
      }

      this.$refs.employeeDialog.employeeDialogModal = true;
    },
    addEmployeeGroupClick() {
      this.$refs.employeeListGroups.toggleGroupCreation();
    },
    async editEmployeeClick(employee) {

      if (this.selectedTab.tab === "guest") {
        this.guest = this.guestPassengers.find((guest) => guest.id === employee.id);

        this.mode = "edit";
        this.$refs.guestDialog.guestDialogModal = true;
        return;
      }

      if (this.selectedTab.tab === "employee") {
        this.employee = this.businessPassengers.find((businessPassenger) => businessPassenger.id === employee.id);

        this.mode = "edit";
        this.$refs.employeeDialog.employeeDialogModal = true;
      }
    },
    getEntities(selectedTab) {
      switch (selectedTab) {
        case "employee":
          return this.businessPassengers;
        case "guest":
          return this.guestPassengers;
        default:
          return [];
      }
    },
    getLoading(selectedTab) {
      switch (selectedTab) {
        case "employee":
          return this.businessPassengersLoading;
        case "guest":
          return this.guestPassengersLoading;
        default:
          return false;
      }
    },
    getHeader(selectedTab) {
      switch (selectedTab) {
        case "employee":
          return this.tableHeader;
        case "guest":
          return this.guestTableHeader;
        case "groups":
          return this.groupHeader;
        default:
          return [];
      }
    },
    getTotalPassengers(selectedTab) {
      switch (selectedTab) {
        case "employee":
          return this.totalBusinessPassengers;
        case "guest":
          return this.totalGuestPassengers;
        default:
          return 0;
      }
    },
    refreshData(tab) {
      this.initCallPassengers(tab);
    },
  },
};
</script>
