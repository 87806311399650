import {
  minLength, maxLength, required, email,
} from 'vuelidate/lib/validators';
import isValidString from './validationMethods';


export default {
  employee: {
    givenName: {
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    familyName: {
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    email: {
      minLength: minLength(3),
      required,
      email,
    },
    phone: {
      required,
    },
  },
  guest: {
    givenName: {
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    familyName: {
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    email: {
      minLength: minLength(3),
      email,
    },
  },
  corporationAdmin: {
    givenName: {
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    familyName: {
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    email: {
      minLength: minLength(3),
      required,
      email,
    },
  },
  phoneNumber: {
    required,
    minLength: minLength(10),
  },
};
