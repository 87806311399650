import moment from 'moment';

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const dateFormatDay = 'DD MMMM YYYY';

export const localDateTime = (dateTime) => moment(dateTime).format(dateTimeFormat);

export const longDateTimeDay = (dateTime) => moment(dateTime).format(dateFormatDay);

export const longDateTime = (dateTime) => moment(dateTime).format('lll');

export const localDate = (dateTime) => moment(dateTime).format('L');

export const longDateTimeSeconds = (dateTime) => moment(dateTime).format('ll LTS');

export const longDateTimeUTC = (dateTime) => moment(dateTime).utc().format('ll');

export const time = (dateTime) => moment(dateTime).format('HH:mm');
