<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat id="employee-card" class="pa-5">
        <v-card-title id="employee-title" class="form-title">
          <span>{{ $t(`components.employeeForm.${mode}.title`) }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="forceClear()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text id="employee-form" class="form-content">
          <v-row>
            <v-col cols="12">
              <span class="text-uppercase input-label">{{
                $t("components.employeeForm.default.account")
              }}</span>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="activeCorporation"
                class="account-search"
                icon="$icons_product_corporation"
                outlined
                filled
                hide-details
                item-text="name"
                item-value="id"
                return-object
                :disabled="isEditMode"
                :items="accountsList"
                :loading="accountsLoading"
                :search-input.sync="accountSearch"
                :placeholder="$t('components.employeeForm.default.accountPlaceholder')"
                @change="onSelectAccount" 
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6">
              <span class="text-uppercase input-label pb-2">{{
                $t(`components.employeeForm.default.firstName`)
              }}</span>

              <v-text-field
                :placeholder="$t(`components.employeeForm.default.givenName`)"
                :class="{ 'employee-field-error': $v.employee.givenName.$error }"
                v-model="employee.givenName"
                class="employee-field mt-5"
                height="44"
                outlined
                required
                clearable
                hide-details
                @input="$v.employee.givenName.$touch()"
                @blur="$v.employee.givenName.$touch()"
              >
                <template v-if="$v.employee.givenName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.employee.givenName" />
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <span class="text-uppercase input-label pb-2">{{
                $t(`components.employeeForm.default.lastName`)
              }}</span>

              <v-text-field
                v-model="employee.familyName"
                :class="{ 'employee-field-error': $v.employee.familyName.$error }"
                :placeholder="$t(`components.employeeForm.default.familyName`)"
                class="employee-field mt-5"
                outlined
                clearable
                required
                hide-details
                no-filter
                @input="$v.employee.familyName.$touch()"
                @blur="$v.employee.familyName.$touch()"
              >
                <template v-if="$v.employee.familyName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.employee.familyName" />
                </template>
              </v-text-field>
            </v-col>

            <v-col>
              <span class="text-uppercase input-label">{{
                $t(`components.employeeForm.default.email`)
              }}</span>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="employee.email"
                :placeholder="$t(`components.employeeForm.default.emailPlaceholder`)"
                :class="{ 'employee-field-error': $v.employee.email.$error }"
                class="employee-field email-input"
                outlined
                required
                clearable
                hide-details
                no-filter
                @input="$v.employee.email.$touch()"
                @blur="$v.employee.email.$touch()"
              >
                <template v-if="$v.employee.email.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.employee.email" />
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <span class="input-label">{{ $t(`components.employeeForm.default.phone`) }}</span>
            </v-col>

            <v-col cols="3">
              <v-combobox
                ref="countryCodeInput"
                class="employee-field"
                outlined
                filled
                hide-details
                hide-no-data
                append-icon=""
                :disabled="mode === 'edit'"
                :cache-items="false"
                :item-text="(item) => [item.dialCode, item.name]"
                :value="selectedCountryCode"
                :rules="[(v) => !!v || $t('components.bookingForm.validation.required')]"
                :items="allCountries"
                :placeholder="$t('components.userAutoComplete.placeholderCountryCode')"
                @input="onDialCodeSelectedFromList($event)"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content class="d-flex">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-title>{{ item.dialCode }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="{ item }">
                  {{ item.dialCode || item }}
                </template>

                <template v-slot:append>
                  <v-tooltip
                    v-if="shouldShowInvalidCountryCodeError"
                    bottom
                    color="error"
                    dark
                    nudge-bottom="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <p class="mb-0">
                      {{ $t("components.bookingForm.validation.invalidCountryCode") }}
                    </p>
                  </v-tooltip>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="9">
              <v-text-field
                ref="employeePhoneInput"
                v-model="employee.phone"
                class="employee-field"
                :disabled="isEditMode"
                :placeholder="$t('components.userAutoComplete.placeholderPhone')"
                :rules="[
                  (v) => !!v || $t('components.bookingForm.validation.required'),
                  (v) =>
                    /^[+0-9]*$/.test(v) ||
                    $t('components.bookingForm.validation.mobile'),
                ]"
                outlined
                required
                clearable
                hide-details
                no-filter
              >
                <template v-slot:append>
                  <v-tooltip
                    v-if="$refs.employeePhoneInput && $refs.employeePhoneInput.hasError"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <p
                      v-for="(error, index) in $refs.employeePhoneInput.errorBucket"
                      :key="index"
                      class="mb-0"
                    >
                      {{ $t(error) }}
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <span class="text-uppercase input-label">{{
                $t("components.employeeForm.default.group")
              }}</span>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="employee.employeeGroupId"
                outlined
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                :loading="groupsLoading"
                :items="selectedAccountGroups.list"
                item-text="name"
                item-value="id"
                class="employee-field"
                :placeholder="$t('components.employeeForm.default.groupPlaceholder')"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-4">
          <v-btn class="text-capitalize" color="grey darken-1" text @click="forceClear()">
            {{ $t(`components.employeeForm.${mode}.cancel`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize white--text"
            color="#333333"
            depressed
            :disabled="$v.employee.$invalid || !isPhoneValid || isLoading || isFormChanged"
            @click="submitDialog"
          >
            {{ $t(`components.employeeForm.${mode}.agree`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </loader>
</template>

<script>
import { mapGetters } from "vuex";
import { isEqual } from "lodash";

import { getRoles } from "@/mixins/authentication.mixins";
import employeeValidation from "@/helpers/rules/employeeValidation";
import { languageComputed, languageMethods } from "@/mixins/language.mixins";
import { businessPassenger } from "@/mixins/passenger.mixins";
import allCountries from "country-codes-list";
import { phoneNumberValidation } from "@/helpers/rules/phoneNumberValidation";
import { PhoneNumberUtil } from "@/core/util/PhoneNumberUtil";

import ErrorTooltip from "@/components/elements/ErrorTooltip.vue";

const blankState = {
  businessAccountId: null,
  givenName: "",
  familyName: "",
  phone: "",
  email: "",
  role: "passenger",
  employeeGroupId: undefined,
  corporationId: null,
  communicationLanguage: "en",
};

const defaultAccountsSearchParams = {
  page: 0,
  maxPerPage: 10,
  sort: 'name',
};

export default {
  name: "CreateEmployeeForm",
  mixins: [languageComputed, languageMethods, businessPassenger, getRoles],
  props: {
    mode: {
      type: String,
      default: "create",
    },
    employeeData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ErrorTooltip,
  },
  validations: {
    ...employeeValidation,
  },
  data() {
    return {
      employee: {},
      activeCorporation: null,
      activeGroup: null,
      isLoading: false,
      accountsLoading: false,
      groupsLoading: false,
      selectedCountryCode: null,
      allCountries: allCountries
        .all()
        .map((country) => {
          return {
            dialCode: `+${country.countryCallingCode}`,
            iso2: country.countryCode.toLowerCase(),
            name: country.countryNameEn,
            flag: country.flag,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
      accountsSearchParams: defaultAccountsSearchParams,
      accountSearch: "",
      accountsList: [],
      selectedAccountGroups: {
        id: null,
        list: [],
      },
    };
  },
  async mounted() {
    await this.$store.dispatch("users/getUserRole");
    await this.loadAccountsList();

    if (this.mode !== "edit") {
      this.prefillCountryCode();
    }
  },
  watch: {
    employeeData: {
      handler() {
        if (this.mode === "edit") {
          this.employee = {
            ...blankState,
            ...this.employeeData,
            phone: this.employeeData.passenger.phoneNumber,
          };

          this.prefillActiveAccount();
          this.loadGroupsByAccountId(this.employeeData.businessAccountId);

          if (this.employee.phone) {
            const googlePhoneNumber = PhoneNumberUtil.parseToGooglePhoneWithoutRegionCode(
              this.employee.phone
            );
            this.selectedCountryCode = "+" + googlePhoneNumber.getCountryCode();
            this.employee.phone = googlePhoneNumber.getNationalNumber().toString();
          }
        } else {
          this.employee = { ...blankState };
        }
      },
      immediate: true,
    },
    accountSearch() {
      this.onLoadAccountsByName();
    },
  },
  computed: {
    ...mapGetters({
      countryCode: "bookingChannel/defaultCountry",
      getUserRole: "users/getUserRole",
    }),
    ...mapGetters("corporations", ["getCorporationsList", "getSelectedCorporationId"]),
    isEditMode() {
      return this.mode === "edit";
    },
    shouldShowInvalidCountryCodeError() {
      if (!this.selectedCountryCode) {
        return false;
      }

      return !this.allCountries.find((country) => country.dialCode === this.selectedCountryCode)
        ?.iso2;
    },
    isPhoneValid() {
      // If the user entered a phone number, then country code is required
      if (this.employee.phone && !this.selectedCountryCode) {
        return false;
      }

      // At this point, we know that the user has entered a phone number and a country code
      return !/[^\d]/.test(this.employee.phone) && this.allCountries
        .filter((country) => country.dialCode === this.selectedCountryCode)
        .map((country) => phoneNumberValidation(this.employee.phone.trim(), country.iso2))
        .some((isValid) => isValid);
    },
    isCountryCodeValid() {
      return !!this.selectedCountryCode;
    },
    formatPhoneNumber() {
      const googlePhoneNumber = PhoneNumberUtil.parseToGooglePhoneWithoutRegionCode(
        `${this.selectedCountryCode}${this.employee.phone}`
      );

      return {
        countryCode: googlePhoneNumber.getCountryCode(),
        nationalNumber: googlePhoneNumber.getNationalNumber().toString(),
        phoneNumber: `+${googlePhoneNumber.getCountryCode()}${googlePhoneNumber.getNationalNumber().toString()}`,
      };
    },
    isFormChanged() {
      if (this.mode === "edit") {
        return isEqual(
          this.getMutableFields(this.employee),
          this.getMutableFields(this.employeeData)
        );
      }

      return isEqual(this.getMutableFields(this.employee), blankState);
    },
  },
  methods: {
    prefillCountryCode() {
      this.selectedCountryCode = this.allCountries.find(
        (country) => country.iso2 === this.countryCode
      )?.dialCode;
    },
    forceClear(submitted) {
      this.employee = { ...blankState };
      this.activeCorporation = null;

      this.isLoading = false;

      this.$v.$reset();
      this.$emit("closeDialog", submitted);
    },
    getMutableFields(val) {
      return {
        givenName: val.givenName,
        familyName: val.familyName,
        email: val.email,
        employeeGroupId: val.employeeGroupId,
      };
    },
    onSelectAccount() {
      this.accountSearch = "";

      if (!this.activeCorporation) {
        return;
      }

      this.loadGroupsByAccountId(this.activeCorporation.id);
    },
    async prefillActiveAccount() {
      if (this.accountsList.length === 0) {
        await this.loadAccountsList();

        this.activeCorporation = this.accountsList.find(
          (account) => account.id === this.employeeData.businessAccountId
        );
      }
    },
    async loadAccountsList() {
      try {
        this.accountsLoading = true;
        const { data } = await this.$store.dispatch("corporations/listCorporations", {
          ...defaultAccountsSearchParams,
        });

        if (Array.isArray(data)) {
          this.accountsList = data;
        }
        this.accountsLoading = false;
      } catch (error) {
        console.error("Error loading accounts list", error);
        this.accountsLoading = false;
      }
    },
    async onLoadAccountsByName() {
      if (!this.accountSearch || this.accountSearch.length < 3) {
        return;
      }

      this.accountsSearchParams = {
        ...this.accountsSearchParams,
        name: this.accountSearch,
      };

      this.accountsLoading = true;
      const { data } = await this.$store.dispatch('corporations/findCorporationByName', { ...this.accountsSearchParams });

      if (Array.isArray(data)) {
        this.accountsList = [...this.accountsList, ...data];
      }
      this.accountsLoading = false;
    },
    async loadGroupsByAccountId(accountId) {
      try {
        this.groupsLoading = true;

        const { data } = await this.$store.dispatch("employee/listEmployeeGroups", {
          maxPerPage: 100, // for now its will be 100
          page: 0,
          sort: "name",
          corporationId: accountId,
        });
  
        this.selectedAccountGroups ={
          id: accountId,
          list: [...data],
        };
        this.groupsLoading = false;
      } catch (error) {
        console.error("Error loading groups list", error);
        this.groupsLoading = false;
      }
    },
    /**
     *
     * @param {String | null | undefined} code
     */
    onDialCodeSelectedFromList(code) {
      if (code && code.dialCode) {
        this.selectedCountryCode = code.dialCode;
        return;
      }

      if (code) {
        this.selectedCountryCode = code.startsWith("+") ? code : `+${code}`;
        return;
      }

      this.selectedCountryCode = code;
      this.employee.dialCode = code;
    },
    async submitDialog() {
      this.$v.employee.$touch();

      if (this.$v.employee.$invalid) {
        return;
      }

      this.isEditMode ? await this.updateBusinessPassenger() : await this.createBusinessPassenger();
    },
    async updateBusinessPassenger() {
      console.info("[updateBusinessPassenger]");

      try {
        this.isLoading = true;
        await this.$store.dispatch("businessPassenger/updateBusinessPassenger", {
          id: this.employee.id,
          givenName: this.employee.givenName,
          familyName: this.employee.familyName,
          email: this.employee.email,
          corporationId: this.employee.businessAccountId,
          employeeGroupId: this.employee.employeeGroupId,
        });
      } catch (error) {
        console.error("Error updating business passenger", error);
      } finally {
        this.forceClear(true);
      }
    },
    async createBusinessPassenger() {
      console.info("[createBusinessPassenger]");
      try {
        this.isLoading = true;
        await this.$store.dispatch("businessPassenger/createBusinessPassenger", {
          givenName: this.employee.givenName,
          familyName: this.employee.familyName,
          email: this.employee.email,
          phone: this.formatPhoneNumber.phoneNumber,
          corporationIds: [this.activeCorporation.id],
          employeeGroupId: this.employee.employeeGroupId,
        });
      } catch (error) {
        console.error("Error creating business passenger", error);
      } finally {
        this.forceClear(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/phoneInput.scss";

.input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #888888;
}

.employee-field-error {
  border: 1px solid #eb5757 !important;
}

.employee-field {
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #eeeeee;
  letter-spacing: -0.005em;
  color: #333333;
}
</style>

<style lang="scss">
.account-search {
  & .v-chip__close {
    color: #333333 !important;
  }
  
}
.employee-field {
  & fieldset {
    background: #f8f8f8;
  }
}
</style>