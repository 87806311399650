import apiInstance, { apiCall } from '@/helpers/apiInstance';
import { business } from '@icabbi/api-gateway-client';

const defaultState = {
  paxCoreBusinessGuestClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  paxCoreBusinessGuestClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.paxCoreBusinessGuestClient) {
      state.paxCoreBusinessGuestClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.PaxCoreConnector });
    }
    return state.paxCoreBusinessGuestClient;
  },
};

const actions = {
  getBusinessGuestList: apiCall((context, payload) => context.getters.paxCoreBusinessGuestClient(context).getGuestList({ payload })),
  deleteGuestPassenger: apiCall((context, id) => context.getters.paxCoreBusinessGuestClient(context).deleteGuestPassenger({ id })),
  updateGuestPassenger: apiCall((context, { id, payload }) => context.getters.paxCoreBusinessGuestClient(context).updateGuestPassenger({ id, payload })),
  createGuestPassenger: apiCall((context, payload) => context.getters.paxCoreBusinessGuestClient(context).createGuestPassenger({ payload })),
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};
