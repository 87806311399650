// eslint-disable-next-line import/prefer-default-export
import { mapGetters } from 'vuex';

export const listUsers = {
  data: () => ({
    users: [],
    usersSearchObject: {
      page: 0,
      maxPerPage: 10,
      sort: 'givenName,familyName',
    },
    usersLoading: true,
    usersListInfo: {
      totalCount: 0,
    },
  }),
  watch: {
    users() {
      this.usersLoading = false;
    },
  },
};

export const getMe = {
  created() {
    if (this.token) {
      this.$store.dispatch('users/getMe');
    }
  },
  watch: {
    token(newValue) {
      if (newValue) {
        this.$store.dispatch('users/getMe');
      }
    },
  },
  computed: {
    ...mapGetters({
      token: 'authentication/token',
      meDisplayName: 'users/meDisplayName',
      me: 'users/me',
    }),
  },
};
