import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  paxCoreBusinessAccountClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  paxCoreBusinessAccountClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.paxCoreBusinessAccountClient) {
      state.paxCoreBusinessAccountClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.PaxCoreConnector });
    }
    return state.paxCoreBusinessAccountClient;
  },
};

const actions = {
  importRecord: apiCall((context, payload) => context.getters.paxCoreBusinessAccountClient(context).importBusinessAccount({ payload })),
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};
