<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-progress-circular v-if="loading" indeterminate />
    </v-layout>
  </v-container>
</template>

<script>
import { Authentication } from '@icabbi/vue-authentication';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';

export default {
  mixins: [bookingChannelComputed],
  data() {
    return {
      loading: false,
      loginUrl: false,
    };
  },
  async created() {
    const { acrValues, audience, locale, requestUrl } = this.$route.query;

    this.loginUrl = await Authentication(this.$store).getLoginRedirectionUrl({
      clientId: this.oidcClientId,
      redirectUri: this.authCallbackUrl,
      requestUrl: requestUrl || audience,
      locale,
      audience,
      acrValues,
    });
  },
  mounted() {
    this.loading = true;

    if (this.loginUrl) window.location = this.loginUrl;
    else this.$router.push('/');
  },
};
</script>
