export class FareApiDtoDelegate {

  /**
   *
   * @param {FareTypes} type
   * @returns {boolean}
   */
  static isEstimationFare(type) {
    return type === 'ESTIMATE';
  }

  /**
   *
   * @param {FareTypes} type
   * @returns {boolean}
   */
  static isFixedFare(type) {
    return type === 'FIXED_FARE';
  }

  /**
   *
   * @param {FareTypes} type
   * @returns {boolean}
   */
  static isRangeFare(type) {
    return type === 'RANGE';
  }
}
