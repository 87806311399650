<template>
  <div>
    <v-card class="mx-auto booking-card">
      <v-progress-linear indeterminate v-if="loading" />

      <v-card-title class="main-title pa-8 pb-6">
        {{
          state === "new"
            ? $t(`components.bookingForm.title.${showAccountFields ? "fields" : "trip"}`)
            : $t(`components.bookingForm.title.edit`)
        }}
      </v-card-title>

      <v-card-text class="px-8" v-show="!showAccountFields">
        <v-row>
          <v-col>
            <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.date") }}</h5>
            <v-menu :close-on-content-click="false" :nudge-bottom="44" v-model="datePickerOpen">
              <template v-slot:activator="{ on }">
                <validated-field
                  v-slot="{ attrs }"
                  v-model="localeDate"
                  :validator="$v.date"
                  :messages="errors"
                >
                  <v-text-field
                    outlined
                    filled
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="localeDate"
                  />
                </validated-field>
              </template>
              <v-date-picker
                no-title
                show-adjacent-months
                :min="datePickerMinDate"
                :locale="$i18n.locale"
                v-model="date"
                @change="datePickerOpen = false"
              />
            </v-menu>
          </v-col>
          <v-col>
            <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.time") }}</h5>
            <validated-field
              v-slot="{ attrs }"
              v-model="timeMask"
              :validator="$v.timeMask"
              :messages="errors"
            >
              <v-text-field
                outlined
                filled
                hide-details
                v-bind="attrs"
                v-model="timeMask"
                @focus="handleTimeFocus"
                @blur="handleTimeBlurEvent"
              />
            </validated-field>
          </v-col>
          <v-col cols="12" v-if="$v.timeMask.$invalid || date < now">
            <v-row>
              <v-col cols="6" class="py-0">
                <span class="error-text" v-if="date < now">{{
                  $t("components.bookingForm.section.date_error")
                }}</span>
              </v-col>
              <v-col cols="6" class="py-0">
                <span class="error-text" v-if="$v.timeMask.$invalid">{{
                  $t("components.bookingForm.section.time_error")
                }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="px-0">
            <address-picker
              :validation="$v.address"
              :validationFlightNumber="$v.flightNumber"
              :waypoints="waypoints"
              :flight="flightNumber"
              :pickup="address.pickup"
              :dropoff="address.dropoff"
              @pickup="address.pickup = $event"
              @dropoff="address.dropoff = $event"
              @waypoints="address.waypoints = $event"
              @updateFlightNumber="flightNumber = $event"
              @setFlightNumber="isShowFlightNumber = $event"
              @removeWaypoint="removeWaypoint"
            />
          </v-col>
        </v-row>
        <template v-if="isAddressViasFeatureEnabled">
          <v-row v-if="(waypoints && waypoints.length) <= 10">
            <v-col>
              <a @click="addEmptyWaypoint">{{ $t("components.bookingForm.addStop") }}</a>
            </v-col>
          </v-row>
        </template>
        <v-divider class="my-6" />
        <v-row>
          <v-col>
            <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.passenger") }}</h5>
            <validated-field
              v-slot="{ attrs }"
              :name="$t('components.bookingForm.field.passenger')"
              v-model="passenger"
              :validator="$v.passenger"
            >
              <user-autocomplete
                v-bind="attrs"
                :initial="passenger"
                :editMode="isEditMode"
                @change="passenger = $event"
              />
            </validated-field>
          </v-col>
          <v-col>
            <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.mobile") }}</h5>
            <vue-tel-input
              filled
              mode="national"
              autocomplete="on"
              :validCharactersOnly="true"
              :autoDefaultCountry="false"
              :defaultCountry="country"
              :dropdownOptions="{
                showDialCodeInList: true,
                showDialCodeInSelection: true,
                showFlags: true,
                width: '240px',
              }"
              :inputOptions="{
                showDialCode: false,
                autofocus: true,
                id: 'tel-input',
                type: 'tel',
              }"
              :disabled="passenger && !!passenger.id"
              v-model="phoneInput"
              @input="phoneChanged"
              @country-changed="handleInput"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <a @click="callGuestForm">{{ $t("components.bookingForm.addGuest") }}</a>
          </v-col>
        </v-row>
        <v-divider class="my-6" />
        <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.payment") }}</h5>
        <v-row>
          <v-col>
            <validated-field
              v-slot="{ attrs }"
              :name="$t('components.bookingForm.field.payment')"
              v-model="payment"
              :validator="$v.payment"
            >
              <v-select
                outlined
                filled
                hide-details
                single-line
                return-object
                height="60"
                v-bind="attrs"
                class="select-high select-payment"
                :item-text="paymentMethodName"
                :label="$t('components.bookingForm.noneAvailable')"
                v-model="payment"
                :loading="paymentMethodsLoading"
                :append-icon="`${paymentMethods.length ? '$dropdown' : ''}`"
                :readonly="!paymentMethods.length"
                :menu-props="{ nudgeBottom: 60 }"
                :disabled="isEditMode"
                :items="paymentMethods"
              >
                <template v-slot:prepend-inner>
                  <component v-bind:is="creditCardIcon(payment ? payment.brand : '')" />
                </template>
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <component v-bind:is="creditCardIcon(item ? item.brand : '')" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ paymentMethodName(item) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-bind="attrs" v-if="attrs.hasErrors" v-slot:append>
                  <error-tooltip-auto :errors="attrs.errorMessages" />
                </template>
              </v-select>
            </validated-field>
          </v-col>
        </v-row>
        <v-divider class="my-6" />

        <vehicle-picker
          :corporationId="corporationId"
          :address="address"
          :bookingOffer="booking ? booking.offer : null"
          @fleetIdChanged="fleetId = $event"
          @vehicleChanged="vehicle = $event"
          @vehicleCountChanged="vehicleCount = $event"
        />

        <v-row>
          <v-col class="pt-0">
            <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.notes") }}</h5>
            <v-textarea outlined filled hide-details rows="2" v-model="notes" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="px-8" v-show="showAccountFields">
        <v-row v-for="(field, i) in accountFields" :key="field.id">
          <v-col>
            <h5 class="section-title pb-2">{{ field.title }}</h5>
            <validated-field
              v-slot="{ attrs }"
              :name="field.title"
              v-model="accountFields[field.id].value"
              :validator="$v.accountFields[field.id] ? $v.accountFields[field.id].value : $v"
            >
              <v-select
                outlined
                filled
                single-line
                hide-no-data
                hide-details
                :disabled="isEditMode"
                v-if="field.type === 'select'"
                v-bind="attrs"
                v-model="accountFields[field.id].value"
                :items="field.values"
                label=""
              >
                <template v-bind="attrs" v-if="attrs.hasErrors" v-slot:append>
                  <error-tooltip-auto :errors="attrs.errorMessages" />
                </template>
              </v-select>
              <v-text-field
                outlined
                filled
                single-line
                clearable
                :disabled="isEditMode"
                hide-details
                v-else
                v-bind="attrs"
                v-model="accountFields[field.id].value"
                label=""
              >
                <template v-bind="attrs" v-if="attrs.hasErrors" v-slot:append>
                  <error-tooltip-auto :errors="attrs.errorMessages" />
                </template>
              </v-text-field>
            </validated-field>
            <v-divider class="my-6 mb-0" v-if="i + 1 < accountFields.length" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="state !== 'edit'" class="px-7 py-0 d-flex align-center">
        <v-checkbox
          v-model="bookReturnBooking"
          :ripple="false"
          dense
          hide-details
          class="mt-0 pt-0"
        ></v-checkbox>
        <span>{{ $t('components.bookingForm.returnBooking.returnQuestion') }}</span>
      </v-card-text>
    </v-card>
    <div v-if="fareEstimation && getFormattedFare" class="d-flex fare-estimate-field px-8 pt-3">
      <p class="mb-0 fare-field">
        {{ `${$t("components.bookingForm.fare")} (${$t(fareEstimation.type)})` }}
      </p>
      <v-spacer></v-spacer>
      <p class="mb-0 pr-2 fare-value">
        {{ `${currencySymbol}` }} {{ getFormattedFare }}

      </p>
    </div>
    <div class="booking-fixed-controls d-flex justify-space-between px-8 pt-2 pb-6">
      <v-btn text plain large class="pa-3 pl-0 cancel" :disabled="creatingBooking" @click="cancel">
        {{
          $t(
            showAccountFields
              ? "components.bookingForm.back"
              : `components.bookingForm.cancel${isEditMode ? "-edit" : ""}`
          )
        }}
      </v-btn>
      <v-spacer />
      <v-btn
        dark
        large
        class="pa-3"
        color="#333333"
        elevation="0"
        :disabled="$v.$invalid || creatingBooking || accountFieldsLoading"
        :loading="creatingBooking"
        @click="createBooking"
      >
        {{
          isEditMode
            ? $t("components.bookingForm.updateBooking")
            : $t("components.bookingForm.book")
        }}
      </v-btn>
    </div>
    <v-dialog v-model="getDuplicateBooking" persistent max-width="400">
      <v-card class="pb-2">
        <v-card-title class="headline">{{
          $t("components.bookingForm.duplicate.title")
        }}</v-card-title>
        <v-card-text>
          <p class="mb-0">{{ $t("components.bookingForm.duplicate.message") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="disable-button"
            color="grey darken-1"
            text
            @click="closeConfirmDuplicateDialog"
          >
            {{ $t("components.bookingForm.duplicate.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="creatingBooking"
            :disabled="creatingBooking"
            depressed
            color="primary"
            @click="createBooking"
          >
            {{ $t("components.bookingForm.duplicate.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ReturnBookingModal
      ref="retunBookingForm"
      :preDate="date"
      :preTime="time"
      @cancelReturnBooking="bookReturnBooking = false"
      @selectedReturnDate="returnPickupTime = $event"
    />
    <v-dialog width="600" :value="showGuestForm">
      <CreateGuestForm
        :guestData="guest"
        @closeDialog="closeGuestForm"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import {
  minLength, maxLength, required, requiredIf,
} from 'vuelidate/lib/validators';
import { iconLoader } from '@/helpers/iconsLoader';
import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';
import { useDefaultPaymentMethods } from '@/mixins/defaultPaymentMethod.mixins';
import { returnBookingMixin } from '@/mixins/booking-form/returnBooking.mixins';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import { getRoles } from '@/mixins/authentication.mixins';
import UserAutocomplete from '@/components/booking/UserAutocomplete.vue';
import { vMaska, Mask } from 'maska';
import AddressPicker from './components/AddressPicker.vue';
import VehiclePicker from './components/VehiclePicker.vue';
import AddressAutocomplete from './components/AddressAutocomplete.vue';
import ReturnBookingModal from './components/ReturnBookingModal.vue';
import CreateGuestForm from '../employee/v2/form/CreateGuestForm.vue';
import { NumberFormatter } from '@icabbi/hi-dash';

const NOW = moment();
const TIME_ASAP = 'ASAP';

// @TODO: move to @/helpers/dateTime.js
const DATE_FORMAT_YMD = 'YYYY-MM-DD';
const DATE_FORMAT_LOCALE = 'LL';
const TIME_FORMAT_HMS = 'HH:mm';
const { generateCardsList } = useDefaultPaymentMethods();

export default {
  name: 'BookingForm',
  directives: { maska: vMaska },
  components: {
    ...iconLoader.booking,
    ...iconLoader.paymentMethod,
    AddressAutocomplete,
    UserAutocomplete,
    AddressPicker,
    ReturnBookingModal,
    CreateGuestForm,
    VehiclePicker,
  },
  mixins: [creditCardIcon, bookingChannelComputed, getRoles, returnBookingMixin],
  props: {
    booking: Object,
    loading: Boolean,
    state: String,
  },
  data: () => ({
    datePickerOpen: false,
    timePickerOpen: false,
    datePickerMinDate: NOW.format(DATE_FORMAT_YMD),
    date: NOW.format(DATE_FORMAT_YMD),
    now: NOW.format(DATE_FORMAT_YMD),
    time: TIME_ASAP,
    timeMask: TIME_ASAP,
    pickupTime: null,
    fareEstimation: null,
    showGuestForm: false,
    guest: null,
    isFareLoading: false,
    address: { pickup: null, waypoints: [], dropoff: null },
    passenger: null,
    phoneInput: '',
    cachedPhoneNumber: null,
    phone: null,
    corporationId: null,
    vehicleOffers: [],
    vehicle: null,
    vehicleCount: 1,
    paymentMethods: [],
    returnPickupTime: null,
    paymentMethodsLoading: false,
    createBookingLoading: false,
    accountFields: [],
    accountFieldsLoading: false,
    payment: null,
    flightNumber: null,
    isShowFlightNumber: false,
    paymentMethodsDefault: {
      employee: [
        {
          type: 'cash',
          title: 'cash',
          brand: 'cash',
        },
        {
          type: 'businessAccount',
          title: 'account',
          brand: 'business',
        },
      ],
      guest: [
        { type: 'businessAccount', title: 'account', brand: 'business' },
        { type: 'cash', title: 'cash', brand: 'cash' },
      ],
    },
    notes: '',
    creatingBooking: false,
    showAccountFields: false,
    errors: {
      address: 'Invalid {attribute}',
      timeMask: 'Time cannot be in the past',
    },
    editMode: false,
    isTimeValid: true,
    validationFields: null,
    waypoints: [],
  }),
  validations() {
    const address = async (val) => val?.detail?.coordinates && val?.detail?.description;
    const date = async (val) => val >= this.now;
    const waypoints = async (val) => {
      let valid = true;
      if (val.length) {
        val.forEach((waypoint) => {
          if (!waypoint?.detail?.coordinates || !waypoint?.detail?.description) valid = false;
        });
      }
      return valid;
    };
    const validations = {
      address: {
        pickup: { address },
        waypoints: { $each: { address: waypoints } },
        dropoff: { address },
      },
      flightNumber: {
        required: requiredIf(() => this.isShowFlightNumber),
        minLength: minLength(3),
        maxLength: maxLength(6),
      },
      vehicle: { required },
      passenger: { required },
      payment: { required },
      timeMask: {
        validateTime: (value) => {
          if (!value) {
            return true;
          }
          if (value.toUpperCase() === TIME_ASAP) {
            return true;
          }
          if (value.length > 4) {
            return NOW.isBefore(this.pickupTime);
          }
          return true;
        },
      },
      date: {
        date,
      },
      accountFields: {},
    };

    if (this.showAccountFields) {
      validations.accountFields = Object.entries(this.accountFields).reduce((acc, [_, field]) => {
        if (field.validation?.required) {
          acc[field.id] = { value: { required } };
        }
        return acc;
      }, {});
    }

    return validations;
  },
  watch: {
    date(val) {
      this.pickupTime = `${val}T${this.time}`;
      this.isTimeValid = !NOW.isBefore(this.pickupTime);
      this.getFareEstimation();
    },
    time(val) {
      if (val.length > 4) {
        this.getFareEstimation();
      }
    },
    async passenger(val) {
      if (val && typeof val === 'object') {
        this.phoneInput = val.phone;
        this.corporationId = val.corporationId;
      } else {
        this.phoneInput = '';
        this.corporationId = null;
      }

      await this.loadPaymentMethods();

      this.getFareEstimation();

      if (this.booking) {
        // eslint-disable-next-line prefer-destructuring
        this.payment = this.paymentMethods.filter((paymentMethod) => {
          const { payment } = this.booking;
          return payment.type === 'creditCard'
            ? paymentMethod.number === payment.paymentMethod.number
            : paymentMethod.type === payment.type;
        })[0];
      }
    },
    async vehicle(val) {
      this.getFareEstimation();

      if (!val) {
        this.accountFields = [];
        return;
      }

      this.accountFieldsLoading = true;

      const acc = (
        await this.$store.dispatch('bookings/getFleetAccounts', {
          fleetId: val.fleetId,
          corporationId: this.passenger.corporationId,
        })
      ).data[0];

      this.accountFields = (
        await this.$store.dispatch('bookings/getAccountFields', {
          accountId: acc.accountId,
          bookingChannelId: this.passenger.bookingChannelId,
          fleetId: val.fleetId,
        })
      ).reduce((result, field) => {
        if (field.active) result[field.id] = field; // eslint-disable-line no-param-reassign
        return result;
      }, {});

      // eslint-disable-next-line no-return-assign
      if (this.validationFields) {
        Object.keys(this.validationFields).forEach(
          (field) => (this.accountFields[field].value = this.validationFields[field]),
        );
      }

      this.accountFieldsLoading = false;
    },
    payment() {
      this.getFareEstimation();
    },
    paymentMethods(val) {
      this.payment = val && val.length ? val[0] : null;
    },
    async booking(bkg) {
      const address = (val) => ({
        description: val.address,
        detail: {
          description: val.address,
          coordinates: { latitude: val.latitude, longitude: val.longitude },
        },
        dispatchAddressId: val.dispatchAddressId || undefined,
        locationTypes: val.locationTypes ? val.locationTypes : [],
      });

      this.address.pickup = address(bkg.origin);
      this.address.dropoff = address(bkg.destination);

      if (bkg.waypoints.length) {
        const waypoints = [];
        bkg.waypoints.forEach((waypt) => {
          waypoints.push(address(waypt));
        });
        this.$nextTick(() => {
          this.waypoints = waypoints;
          this.address.waypoints = waypoints;
        }); // eslint-disable-line no-return-assign
      }

      if (bkg.pickupTime && !moment(bkg.pickupTime).isBefore(moment())) {
        this.date = moment(bkg.pickupTime).format(DATE_FORMAT_YMD);
        const bookingTime = moment(bkg.pickupTime).format(TIME_FORMAT_HMS);
        this.timeMask = bookingTime;
        this.time = bookingTime;
        this.pickupTime = bkg.pickupTime;
      }

      if (bkg.notes) {
        this.notes = bkg.notes;
      }

      if (bkg.bookingProperties) {
        this.flightNumber = bkg.bookingProperties.flightInfo.number;
      }

      if (bkg.fields) {
        this.validationFields = bkg.fields;
      }

      this.passenger = {
        id: bkg.userId,
        corporationId: bkg.corporationId,
        bookingChannelId: bkg.bookingChannelId,
        fullName: `${bkg.userInfo.givenName} ${bkg.userInfo.familyName}`,
        phone: bkg.userInfo.phone,
        type: bkg.passengerAccountType === 'corporationEmployee' ? 'employee' : 'guest',
      };
    },
  },
  computed: {
    ...mapGetters({
      paymentProviderConfig: 'bookingChannel/payment',
      country: 'bookingChannel/defaultCountry',
      isAddressViasFeatureEnabled: 'bookingChannel/isAddressViasFeatureEnabled',
      getDuplicateBooking: 'bookings/getDuplicateBooking',
      getSelectedCorporationId: 'corporations/getSelectedCorporationId',
    }),
    isEditMode() {
      return this.state === 'edit';
    },
    getFormattedFare() {
      if (
        !this.fareEstimation
        || (this.fareEstimation.type !== 'RANGE' && !this.fareEstimation.roundedPrice)
      ) {
        return false;
      }
      if (this.fareEstimation.type === 'RANGE') {
        const minFare = NumberFormatter.stringValueDecimalSeparator(this.fareEstimation.minimumFare.toFixed(2), this.defaultLocale);
        const maxFare = NumberFormatter.stringValueDecimalSeparator(this.fareEstimation.maximumFare.toFixed(2), this.defaultLocale);
        return `${minFare} - ${maxFare}`;
      }

      return NumberFormatter.stringValueDecimalSeparator(this.fareEstimation.roundedPrice.toFixed(2), this.defaultLocale);
    },
    localeDate() {
      return moment(this.date).format(DATE_FORMAT_LOCALE);
    },
    bookingIsToday() {
      return NOW.isSame(this.date, 'day');
    },
    showAddGuest() {
      return (
        typeof this.passenger === 'string' && this.passenger.split(' ').length > 1 && this.phone
      );
    },
  },
  methods: {
    async manageWaypoint(e, index) {
      if (e) {
        this.waypoints[index] = e;
        const newWaypoints = JSON.parse(JSON.stringify(this.waypoints));
        this.address.waypoints = [];
        this.waypoints = [];
        this.waypoints = newWaypoints;
        this.address.waypoints = newWaypoints;
      }
    },
    addEmptyWaypoint() {
      this.waypoints.push('');
      this.address.waypoints = this.waypoints;
      this.addDynamicHeightForTimeline(this.waypoints.length + 1);
    },
    addDynamicHeightForTimeline(index) {
      const element = document.querySelector('.v-timeline');
      const newHeight = 63 * index;
      element.style.setProperty('--before-height', `${newHeight}px`);
    },
    removeWaypoint(index) {
      const waypointIndex = index || 0;
      const currentWaypoints = [];
      this.waypoints.forEach((waypoint, wptIndex) => {
        if (waypointIndex !== wptIndex) currentWaypoints.push(waypoint);
      });
      this.address.waypoints = [];
      this.waypoints = [];
      this.$nextTick(() => {
        this.address.waypoints = currentWaypoints;
        this.waypoints = currentWaypoints;
      });
      this.addDynamicHeightForTimeline(this.waypoints.length + 1);
    },
    async getFareEstimation() {
      if (
        !this.vehicle?.id
        || !this.passenger?.bookingChannelId
        || !this.address?.pickup
        || !this.address?.dropoff
        || !this.time
      ) {
        this.fareEstimation = null;
        return;
      }

      this.isFareLoading = true;
      const address = (val) => ({
        ...val.coordinates,
        address: val.description,
        locationTypes: val.locationTypes || [],
        dispatchAddressId: val.dispatchAddressId || undefined,
      });
      const params = {
        body: {
          offerId: this.vehicle.id,
          bookingChannelId: this.passenger.bookingChannelId,
          pickupTime:
            this.time !== TIME_ASAP
              ? `${this.date}T${this.time}`
              : `${this.date}T${moment(NOW).format('hh:mm:ssZZ')}`,
          origin: address(this.address.pickup.detail),
          destination: address(this.address.dropoff.detail),
          waypoints: [],
        },
        id: this.passenger.corporationId,
        paymentMethodType: this.payment.type,
      };
      if (this.address.waypoints && this.address.waypoints.length > 0) {
        this.address.waypoints.forEach((addr) => {
          params.body.waypoints.push(address(addr.detail));
        });
      }

      this.fareEstimation = await this.$store.dispatch('fareEstimation/getFareEstimation', params);

      if (this.fareEstimation) this.isFareLoading = false;
    },
    async loadVehicleOffers() {
      this.vehicleOffersLoading = true;

      if (
        this.corporationId
        && this.address.pickup?.detail?.coordinates
        && this.address.dropoff?.detail?.coordinates
      ) {
        this.vehicleOffers = await this.$store.dispatch('offers/list', {
          coords: {
            pickup: this.address.pickup.detail.coordinates,
            dropoff: this.address.dropoff.detail.coordinates,
          },
          corporationId: this.corporationId,
          lang: this.$i18n.locale,
        });
      } else this.vehicleOffers = [];

      this.vehicleOffersLoading = false;
    },
    async loadPaymentMethods() {
      this.paymentMethodsLoading = true;

      if (this.passenger?.corporationId) {
        const cards = (await this.$store.dispatch('paymentMethods/getPaymentMethods', {
          corporationId: this.passenger.corporationId,
        })) || [];

        this.paymentMethods = generateCardsList(cards);
      } else this.paymentMethods = [];

      this.payment = this.paymentMethods.find((el) => el.defaultPayment === true);

      // eslint-disable-next-line prefer-destructuring
      if (!this.payment) this.payment = this.paymentMethods[1];

      this.paymentMethodsLoading = false;
    },
    phoneChanged(_, formatted) {
      this.phone = formatted.number || null;
    },
    paymentMethodName(val) {
      if (!val.number) {
        return this.$t(`components.bookingForm.paymentMethod.${val.brand}`);
      }

      return this.$t('components.bookingForm.cardEnding', { number: val.number });
    },
    handleInput() {
      const input = document.getElementById('tel-input');
      input.focus();
    },
    getCurrentTime() {
      return moment().format('HH:mm:ss');
    },
    cancel() {
      if (this.showAccountFields) {
        this.showAccountFields = false;
        return;
      }

      if (this.isEditMode) {
        this.$emit('cancel');
      } else {
        this.$emit('cancelUpdate');
      }
    },
    closeConfirmDuplicateDialog() {
      this.$store.commit('bookings/setDuplicateBooking', false);
    },
    async createBooking() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      if (
        !this.showAccountFields
        && this.payment.type === 'businessAccount'
        && Object.entries(this.accountFields).length
        && this.isEditMode !== true
      ) {
        this.showAccountFields = true;
        return;
      }

      const address = (val) => ({
        ...val.detail.coordinates,
        address: val.description,
        dispatchAddressId: val.dispatchAddressId || undefined,
        locationTypes: val.locationTypes || [],
      });
      const bookingData = {
        requesterType: 'business',
        offerId: this.vehicle.id,
        origin: address(this.address.pickup),
        destination: address(this.address.dropoff),
        waypoints: [],
        numberOfVehicles: Number(this.vehicleCount),
        passengerInfo: {
          passengerId: this.passenger.id,
          passengerType: this.passenger.type,
          corporationId: this.passenger.corporationId,
        },
        payment: { type: this.payment.type },
      };

      if (this.flightNumber) {
        bookingData.bookingProperties = {
          flightInfo: {
            number: this.flightNumber,
          },
        };
      }

      if (this.address.waypoints && this.address.waypoints.length > 0) {
        this.address.waypoints.forEach((addr) => {
          bookingData.waypoints.push(address(addr));
        });
      }

      if (this.notes) bookingData.notes = this.notes;
      if (this.passenger.employeeGroupId) {
        bookingData.passengerInfo.employeeGroupId = this.passenger.employeeGroupId;
      }
      if (this.payment.id) {
        bookingData.payment.paymentMethodId = this.payment.id;
        bookingData.payment.paymentProviderName = this.paymentProviderConfig.name;
      }

      if (this.now !== moment(this.date).format(DATE_FORMAT_YMD)) {
        bookingData.pickupTime = `${this.date}T${
          this.time !== TIME_ASAP ? this.time : this.getCurrentTime()
        }`;
      } else if (this.time !== TIME_ASAP) {
        bookingData.pickupTime = `${this.date}T${this.time}:00`;
      }

      if (this.showAccountFields) {
        bookingData.fields = Object.entries(this.accountFields).reduce((acc, [_, val]) => {
          acc[val.id] = val.value;
          return acc;
        }, {});
      }

      this.creatingBooking = true;

      if (this.state === 'new') {
        const booking = await this.$store.dispatch('bookings/requestBooking', {
          ...bookingData,
          isDuplicateAcknowledged: this.getDuplicateBooking,
        });

        if (
          booking?.requiresConfirmation
          && !(await this.$store.dispatch(
            'bookings/confirmPayment',
            booking.authorizationClientSecret,
          ))
        ) {
          this.creatingBooking = false;
          return;
        }
        if (booking?.requiresBookingConfirmation === false) {
          this.$emit('created', booking);
          return;
        }

        if (
          !booking
          || !(await this.$store.dispatch('bookings/confirmBooking', booking.bookingId))
        ) {
          this.creatingBooking = false;
          return;
        }

        if (this.bookReturnBooking) {
          await this.createReturnBooking(bookingData, this.returnPickupTime);
        }

        this.$emit('created', booking);

        if (this.getDuplicateBooking) {
          this.$store.commit('bookings/setDuplicateBooking', false);
        }
      }

      if (this.isEditMode) {
        bookingData.status = this.booking.status;
        const booking = await this.$store.dispatch('bookings/requestBookingUpdate', {
          bookingId: this.booking.bookingId,
          bookingData,
        });

        if (
          !booking
          || !(await this.$store.dispatch('bookings/confirmBookingUpdate', booking.bookingId))
        ) {
          this.creatingBooking = false;
        }
        this.$emit('updated', booking);
      }
    },
    callGuestForm() {
      this.showGuestForm = true;

      this.guest = {
        phone: this.phone,
        givenName: this.passenger ? this.passenger.split(' ')[0] : null,
        familyName: this.passenger ? this.passenger.split(' ')[1] : null,
      };
    },
    async closeGuestForm(submitted, response) {
      if (submitted) {
        this.passenger = (
          await this.$store.dispatch('employee/findUsers', {
            ids: [response.id],
            page: 0,
          })
        ).data[0] || null;
      }

      this.showGuestForm = false;
    },
    maskInput(ele) {
      if (ele.keyCode === 32) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }
      const val = ele.target.value.toUpperCase().trim();
      if (!val) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }

      const mask = new Mask({ mask: '##:##' });
      if (val !== TIME_ASAP && val.length >= 4) {
        this.time = mask.masked(val);
        this.timeMask = mask.masked(val);
        this.pickupTime = `${this.date}T${this.time}`;
      } else if (val.length === 1) {
        this.time = mask.masked(`0${val}:00`);
        this.timeMask = mask.masked(`0${val}:00`);
        this.pickupTime = `${this.date}T${this.time}`;
      } else if (val.length === 2) {
        this.time = mask.masked(`${val}:00`);
        this.timeMask = mask.masked(`${val}:00`);
        this.pickupTime = `${this.date}T${this.time}`;
      }

      if (!moment(this.time, 'HH:mm', true).isValid()) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
      }
    },
    handleTimeFocus(ele) {
      if (ele.target.value === TIME_ASAP) {
        this.timeMask = '';
        this.time = '';
      } else {
        ele.target.select();
      }
    },
    handleTimeBlurEvent(ele) {
      if (ele.target.value.toUpperCase() === '') {
        this.timeMask = TIME_ASAP;
        this.time = TIME_ASAP;
      }
      this.maskInput(ele);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/phoneInput.scss";
</style>

<style lang="sass" scoped>
.v-btn
  text-transform: initial

.loader-container
  background-color: white !important

.v-btn.cancel
  color: #888888

.v-input.select-high::v-deep
  .v-input__prepend-inner
    margin-right: 12px

  .v-input__append-inner
    margin-top: 18px !important

  label
    font-weight: 600
    font-size: 12px
    letter-spacing: -0.005em
    color: #333333
    margin: 2px 4px 7px 0

.v-input.select-payment::v-deep
  .v-input__prepend-inner
    margin-top: 14px

.vue-tel-input::v-deep
  .vti__input
    font-size: 12px

  .vti__country-code
    font-size: 12px

.v-text-field--filled.error--text::v-deep fieldset
  border-color: #eb5757 !important

.v-btn.theme--dark.v-btn--disabled
  background-color: #272727 !important

.v-list-item__icon
  width: 46px
  height: 32px
  margin-right: 16px !important

.v-list-item--active .v-list-item__icon i
  color: white

.icon-car
  margin-top: 3px

.main-title
  font-weight: 600 !important
  font-size: 24px !important
  letter-spacing: -0.005em !important
  color: #333333

.fare-field
  color: #888888
  font-size: 12px
  margin-bottom: 0px

.fare-value
  color: #333333
  font-size: 18px
  font-weight: 600
  margin-bottom: 0px

.section-title
  text-transform: uppercase
  letter-spacing: 0.1em
  font-size: 10px
  font-weight: 400
  color: #888888

a
  font-weight: 600
  font-size: 12px
  line-height: 15px
  letter-spacing: -0.005em
  text-decoration-line: underline
  color: #888888 !important
.error-text
  color: #eb5757 !important
  font-size: 12px
.booking-card
  max-height: 75vh
  overflow: auto
  box-shadow: none !important
  border-bottom-left-radius: 0px !important
  border-bottom-right-radius: 0px !important
.booking-fixed-controls
  height: 7%
  width: 100%
  background-color: white
  align-items: center
  border-bottom-right-radius: 6px
  border-bottom-left-radius: 6px
.fare-estimate-field
  background-color: white
</style>
