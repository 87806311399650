<template>
  <td :colspan="headers.length" class="pt-3 pb-6">
    <v-row class="custom-padding-expand-details">
      <div class="pa-3 custom-map-width">
        <booking-map
          :waypoints="getMapWaypoints"
          source="trips"
          style="height: 100%; min-height: 23rem;"
          class="expand-map"
          :disable-info-text="true"
          :mapOptions="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false,
          }"
        />
      </div>
      <div class="pa-4 pl-2 pr-0 expand-info-wdth">
        <v-container class="expand-info-container">
          <v-row no-gutters style="height: fit-content">
            <v-col cols="4">
              <div class="d-flex flex-column">
                <span class="detail-info-title text-uppercase my-2">{{
                  $t(`components.tripsHistory.expandInfo.vehicleClass`)
                }}</span>
                <span class="detail-info-value">{{ getVehicleInfo }}</span>
              </div>
            </v-col>

            <v-divider vertical class="expand-divider"></v-divider>

            <v-col cols="4" class="pl-5">
              <div class="d-flex flex-column">
                <span class="detail-info-title text-uppercase my-2">{{
                  $t(`components.tripsHistory.expandInfo.tripId`)
                }}</span>
                <span class="detail-info-value">{{ bookingDetails.dispatchId || `N/A` }}</span>
              </div>
            </v-col>

            <v-divider vertical class="expand-divider"></v-divider>

            <v-col cols="4" class="pl-5">
              <div class="d-flex flex-column">
                <span class="detail-info-title text-uppercase my-2">{{
                  $t(`components.tripsHistory.expandInfo.tripETA`)
                }}</span>
                <span class="detail-info-value">{{
                  calculateMileagues(bookingDetails.distanceMeters)
                }}</span>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-2 expand-divider"></v-divider>

          <v-row no-gutters v-if="bookingDetails.pricingDetails">
            <v-col cols="4" class="d-flex flex-column">
              <span class="detail-info-title text-uppercase mb-2">
                {{ $t("components.tripsHistory.expandInfo.pricing.title") }}
              </span>

              <div class="d-flex justify-space-between pr-3">
                <span class="detail-info-value">
                  {{ $t("components.tripsHistory.expandInfo.pricing.fare") }}:
                </span>

                <span class="detail-info-value">
                  {{ bookingDetails.pricingDetails.price.toFixed(2) }}
                </span>
              </div>

              <div class="d-flex justify-space-between pr-3 my-2">
                <span class="detail-info-value"
                  >{{ $t("components.tripsHistory.expandInfo.pricing.additionalFees") }}:</span
                >

                <span class="detail-info-value">
                  {{ bookingDetails.pricingDetails.additionalFees.toFixed(2) }}
                </span>
              </div>

              <!-- Details of additional fees -->
              <div>
                <div class="d-flex justify-space-between pr-3 mt-1">
                  <span
                    class="price-details"
                    >{{
                      $t("components.tripsHistory.expandInfo.pricing.evaluatedServiceCharge")
                    }}:</span
                  >

                  <span
                    class="price-details"
                    >{{
                      bookingDetails.pricingDetails.additionalFeeLineItems.evaluatedServiceCharge.toFixed(
                        2
                      )
                    }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">{{ $t("components.tripsHistory.expandInfo.pricing.waitingPrice") }}:</span>

                  <span class="price-details">
                    {{
                      bookingDetails.pricingDetails.additionalFeeLineItems.waitingPrice.toFixed(2)
                    }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">{{ $t("components.tripsHistory.expandInfo.pricing.tolls") }}:</span>

                  <span class="price-details">
                    {{ bookingDetails.pricingDetails.additionalFeeLineItems.tolls.toFixed(2) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">{{ $t("components.tripsHistory.expandInfo.pricing.extras") }}:</span>

                  <span class="price-details">
                    {{ bookingDetails.pricingDetails.additionalFeeLineItems.extras.toFixed(2) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">
                    {{ $t("components.tripsHistory.expandInfo.pricing.tip") }}:
                  </span>

                  <span class="price-details">
                    {{ configuredTip.value.toFixed(2) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">
                    {{ $t("components.tripsHistory.expandInfo.pricing.discount") }}:
                  </span>

                  <span class="price-details">
                    {{ bookingDetails.pricingDetails.totalDiscount.toFixed(2) }}
                  </span>
                </div>
              </div>

              <v-divider class="my-2 mr-3 expand-divider"></v-divider>

              <div class="d-flex justify-space-between pr-3 my-2">
                <span class="detail-info-value">
                  {{ $t("components.tripsHistory.expandInfo.pricing.total") }}
                </span>

                <span class="detail-info-value">
                  {{ currencySymbol }}
                  {{ getBookingTotalPrice() }}
                </span>
              </div>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col
              v-if="
                bookingDetails.businessAccountConfig && bookingDetails.businessAccountConfig.fields
              "
              cols="8"
            >
              <v-row no-gutters>
                <v-col
                  v-for="(item, index) in bookingDetails.businessAccountConfig.fields"
                  :key="index"
                  class="d-flex"
                  cols="6"
                >
                  <v-divider v-if="index !== 0" vertical class="expand-divider"></v-divider>

                  <div class="d-flex flex-column pl-4">
                    <span class="detail-info-title text-uppercase my-2">{{ item.title }}</span>
                    <span class="detail-info-value">{{ item.value }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider v-if="bookingDetails.pricingDetails" class="my-2 expand-divider"></v-divider>

          <v-row no-gutters v-if="bookingDetails.notes">
            <v-col class="d-flex flex-column mt-2">
              <span class="detail-info-title text-uppercase mb-2">
                {{ $t(`components.tripsHistory.expandInfo.notes`) }}:
              </span>
              <span class="detail-info-value">{{ bookingDetails.notes }}</span>
            </v-col>
          </v-row>

          <v-divider v-if="bookingDetails.notes" class="my-2 expand-divider"></v-divider>

          <v-row no-gutters class="justify-end align-center">
            <v-btn
              v-if="completedBooking && hasAccess(CORP_ADMIN)"
              outlined
              tile
              class="expand-button expand-black"
              :loading="receiptLoading"
              @click="downloadReceipt(bookingDetails.bookingId)"
              >{{ $t(`button.download-receipt`) }}</v-btn
            >

            <v-btn
              outlined
              tile
              class="expand-button expand-black ml-2"
              @click="copyBooking(bookingDetails.bookingId)"
            >
              {{ $t(`button.reuse`) }}
            </v-btn>
            <v-btn
              v-if="bookingDetails.trackmytaxi"
              :href="generateTMTLink(bookingDetails.trackmytaxi)"
              target="_blank"
              outlined
              tile
              class="expand-button expand-black ml-2"
            >
              {{ $t(`button.track`) }}
            </v-btn>
            <v-btn
              v-if="canCancelTrip"
              class="expand-button expand-white ml-2"
              outlined
              tile
              @click="openDialog('cancel-booking')"
              >{{ $t(`button.cancel`) }}</v-btn
            >
            <v-btn
              outlined
              tile
              v-if="prebookedBooking === bookingDetails.status"
              class="expand-button expand-black mr-2"
              @click="editBooking(bookingDetails.bookingId)"
            >
              {{ $t(`button.edit`) }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </v-row>

    <dialog-wrapper ref="dialogComponent" @closeDialog="closeTripsDialog" />
    <div id="receipt-element" class="d-none" v-html="receipt"></div>
  </td>
</template>

<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import slugify from "slugify";
import { getRoles } from "@/mixins/authentication.mixins";
import { CHANNEL_ADMIN, CORP_ADMIN } from "@/helpers/userRoles";
import Status from "@/helpers/statusTypes";
import DialogWrapper from "@/components/elements/DialogWrapper.vue";
import BookingMap from "@/components/booking/Map.vue";

export default {
  name: "expan-item",
  components: {
    DialogWrapper,
    BookingMap,
  },
  mixins: [getRoles],
  data() {
    return {
      CHANNEL_ADMIN,
      CORP_ADMIN,
      receipt: null,
      receiptLoading: false,
      prebookedBooking: Status.new[4],
      bookingDetails: null,
    };
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    bookingId: {
      type: String,
      required: true,
    },
    tab: {
      type: Array,
      required: true,
    },
  },
  watch: {
    bookingId: {
      handler: async function (newVal) {
        this.bookingDetails = await this.$store.dispatch("tripsHistory/getBookingDetails", newVal);
      },
      immediate: true,
    },
  },
  methods: {
    calculateMileagues(distance) {
      if (!distance) return "N/A";
      return `${(distance * 0.000621371).toFixed(2)} ml`;
    },
    openDialog(type) {
      this.$refs.dialogComponent.openDialog({ id: this.bookingDetails.bookingId, type });
    },
    closeTripsDialog({ type }) {
      if (type === "cancel-booking") {
        this.$emit("refethTrips");
      }
    },
    copyBooking(id) {
      this.$router.push({ name: "booking-copy", params: { id } });
    },
    editBooking(id) {
      this.$router.push({ name: "booking-edit", params: { id } });
    },
    hasAccess(role) {
      return this.userRoles
        ? this.userRoles.filter((userRole) => userRole.name === role).length > 0
        : false;
    },
    generateTMTLink(href) {
      const lngArray = ["en", "fr"];
      const splitLinkArray = href.split("/");

      return `${process.env.VUE_APP_TMT_LINK}/${splitLinkArray[3]}/${splitLinkArray[4]}?lng=${
        lngArray.includes(this.selectedLanguage) ? this.selectedLanguage : "en"
      }`;
    },

    async downloadReceipt(bookingId) {
      this.receiptLoading = true;
      this.receipt = await this.$store.dispatch("tripsHistory/getBookingReceipt", bookingId);
      window.setTimeout(async () => {
        html2pdf(document.getElementById("receipt-element"), {
          margin: 1,
          filename: this.getFileName(),
        });
        this.receiptLoading = false;
      }, 3000);
    },
    getFileName() {
      return `${slugify(`receipt-${this.bookingDetails.createdAt}`)}.pdf`;
    },
    getBookingTotalPrice() {
      return this.bookingDetails.pricingDetails?.total;
    },
  },
  computed: {
    ...mapGetters({
      selectedLanguage: "language/selectedLanguage",
      currencySymbol: "bookingChannel/currencySymbol",
    }),
    getVehicleInfo() {
      if (this.bookingDetails.vehicle) {
        return `${this.bookingDetails.vehicle.make} ${this.bookingDetails.vehicle.model}`;
      }

      return "N/A";
    },
    pastBooking() {
      return [...Status.cancelled, ...Status.completed].includes(this.bookingDetails.status);
    },
    completedBooking() {
      return [...Status.completed].includes(this.bookingDetails.status);
    },
    createdBy() {
      if (this.bookingDetails.bookedByUserInfo) {
        return [
          this.bookingDetails.bookedByUserInfo.givenName,
          this.bookingDetails.bookedByUserInfo.familyName,
        ]
          .join(" ")
          .trim();
      }

      return "N/A";
    },
    canCancelTrip() {
      return ["NEW", "ASSIGN", "ENROUTE", "PREBOOKED"].includes(this.bookingDetails.status);
    },
    getMapWaypoints() {
      if (!this.bookingDetails) return [];
      if (this.bookingDetails.waypoints.length) {
        return [
          {
            address: this.bookingDetails.origin.address,
            lat: this.bookingDetails.origin.latitude,
            lng: this.bookingDetails.origin.longitude,
            type: "pickup",
          },
          ...this.bookingDetails.waypoints.map((waypoint) => ({
            address: waypoint.address,
            lat: waypoint.latitude,
            lng: waypoint.longitude,
            type: "waypoint",
          })),
          {
            address: this.bookingDetails.destination.address,
            lat: this.bookingDetails.destination.latitude,
            lng: this.bookingDetails.destination.longitude,
            type: "dropoff",
          },
        ];
      }
      return [
        {
          address: this.bookingDetails.origin.address,
          lat: this.bookingDetails.origin.latitude,
          lng: this.bookingDetails.origin.longitude,
          type: "pickup",
        },
        {
          address: this.bookingDetails.destination.address,
          lat: this.bookingDetails.destination.latitude,
          lng: this.bookingDetails.destination.longitude,
          type: "dropoff",
        },
      ];
    },
    isTipConfigured() {
      return this.bookingDetails.pricingDetails?.tip?.value > 0;
    },
    configuredTip() {
      return this.bookingDetails.pricingDetails?.tip;
    },
    wasTipAppliedToFinalFare() {
      return this.bookingDetails.pricing?.tip > 0;
    },
    pricingModel() {
      return this.bookingDetails.pricingDetails;
    },
    wasNoShowFeeApplied() {
      return this.bookingDetails.pricingDetails?.passengerNoShowFee > 0;
    },
    wasPrematureCancellationFeeApplied() {
      return this.bookingDetails.pricingDetails?.passengerPrematureCancellationFee > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-padding-expand-details {
  padding-bottom: 10px !important;
}

.custom-map-width {
  max-width: 36.5% !important;
  width: 36.5% !important;
}


.expand-info-wdth {
  max-width: 62.7% !important;
  width: 62.7% !important;
}
.expand-info-container {
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.expand-divider {
  height: 48px;
}
.h-quarter {
  height: 20%;
}
img {
  width: 100%;
  min-height: 115px;
  height: 100%;
}
.map-image {
  background-image: url("../../assets/map.png");
  background-size: auto 100%;
  height: 150px;
}
.detail-info-title {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #888888;
}
.detail-info-value {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #333333;
}

.price-details {
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  letter-spacing: -0.005em;
  text-align: right;
  color: #888888;
}

.expand-links {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  text-decoration-line: underline;
  color: #888888;
  cursor: pointer;
}

.expand-button {
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.005em;
}

.expand-button::before {
  background-color: transparent !important;
}
.expand-white {
  color: #333333;
  background: #ffffff;
}

.expand-black {
  background-color: #333333;
  color: #ffffff;
}

.expand-black:hover {
  background-color: #888888;
  border: 1px solid #888888;
}

.expand-white:hover {
  color: #888888;
  border: 1px solid #888888;
}
</style>
