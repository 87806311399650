import { BookingCreationDtoFactory } from "@/views/bookingForm/bookingCreationDtoFactory";

export class BookingFormInteractor {
  /**
   *
   * @param $store
   * @param {BookingFormModelDto} bookingFormModel
   */
  constructor({ $store, bookingFormModel }) {
    this.$store = $store;
    this.bookingFormModel = bookingFormModel;
  }

  setOffer(offer) {
    this.bookingFormModel.offerId = offer.id;
    this.bookingFormModel.offer = offer;
  }

  /**
   *
   * @param {AddressItemDetailApiDto} pickupAddress
   */
  setPickupAddress(pickupAddress) {
    this.bookingFormModel.origin = pickupAddress;
  }

  /**
   * @param {AddressItemDetailApiDto} destinationAddress
   */
  setDestinationAddress(destinationAddress) {
    this.bookingFormModel.destination = destinationAddress;
  }

  /**
   * @param {AddressItemDetailApiDto[]} waypoints
   */
  setWaypoints(waypoints) {
    this.bookingFormModel.waypoints = waypoints;
  }

  setFlightNumber(flightNumber) {
    this.bookingFormModel.flightNumber = flightNumber;
  }

  setReturnFlightNumber(returnFlightNumber) {
    this.bookingFormModel.returnFlightNumber = returnFlightNumber;
  }

  /**
   *
   * @param {Moment|null} dateTime
   */
  setPickupTime(dateTime) {
    this.bookingFormModel.pickupTime = dateTime;
  }

  /**
   * @param {Moment|null} returnTime
   */
  setReturnBookingTime(returnTime) {
    this.bookingFormModel.returnBookingTime = returnTime;
  }

  /**
   * @param {String} scheduleType
   */
  setScheduleType(scheduleType) {
    this.bookingFormModel.scheduleType = scheduleType;
  }

  setPassenger(passenger) {
    this.bookingFormModel.passenger = passenger;
  }

  /**
   * @param {PaymentMethodDomainModel} paymentMethod
   */
  setPaymentMethod(paymentMethod) {
    this.bookingFormModel.paymentMethod = paymentMethod;
  }

  /**
   * @param {string} fleetId
   */
  setFleetId(fleetId) {
    this.bookingFormModel.fleetId = fleetId;
  }

  /**
   * @param {number} numberOfVehicles
   */
  setNumberOfVehicles(numberOfVehicles) {
    this.bookingFormModel.numberOfVehicles = numberOfVehicles;
  }

  /**
   * @param {boolean} shouldBookReturnBooking
   */
  setShouldBookReturnBooking(shouldBookReturnBooking) {
    this.bookingFormModel.shouldBookReturnBooking = shouldBookReturnBooking;
  }

  /**
   * @param {string} offerId
   */
  setOfferId(offerId) {
    this.bookingFormModel.offerId = offerId;
  }

  setAccountFields(accountFields) {
    this.bookingFormModel.fields = accountFields;
  }

  setBookingNotes(notes) {
    this.bookingFormModel.notes = notes;
  }

  setDuplicateBooking(isDuplicateBooking) {
    this.bookingFormModel.isDuplicateBooking = isDuplicateBooking;
  }

  canFetchOffers() {
    return (
      !!this.bookingFormModel.origin &&
      !!this.bookingFormModel.destination &&
      !!this.bookingFormModel.paymentMethod
    );
  }

  canFetchFareEstimate() {
    return this.bookingFormModel.offer !== null;
  }

  doesAccountRequireCustomFields() {
    return this.bookingFormModel.businessAccountConfig !== null;
  }

  buildCreateBookingApiDto() {
    return this.bookingFormModel.toCreateApiDto();
  }

  /**
   *
   * @param {String} query
   * @return {Promise<Array<BusinessBookingRecipientV1Dto>>}
   */
  async searchBusinessRecipients(query) {
    // @TODO: Get this info from the local Booking Form Model
    const selectedCorporationId = this.$store.getters["corporations/getSelectedCorporationId"];

    console.log("[BookingFormInteractor] fetchPassengersByQuery");
    return this.$store.dispatch("businessBookingRecipient/getBusinessBookingRecipients", {
      dynamicSearch: query,
      page: 0,
      corporationId: selectedCorporationId,
    });
  }

  /**
   * @param {String} businessAccountId
   * @param {Number} page
   * @param {Number} maxPerPage
   * @returns {Promise<FavouritesApiDto[]>}
   */
  async fetchFavouriteAddresses({ businessAccountId, page, maxPerPage }) {
    console.log("[BookingFormInteractor] fetchFavouriteAddresses");
    return this.$store.dispatch("favouritesApiStore/fetchFavouriteAddresses", {
      businessAccountId,
      page,
      maxPerPage,
    });
  }

  /**
   * @params {AddressQueryApiDto} query
   * @returns {Promise<AddressSuggestionApiDto[]>}
   */
  async fetchAddressSuggestions(query) {
    console.log("[BookingFormInteractor] fetchAddressSuggestions");

    return this.$store.dispatch("addressSuggestions/getAddressSuggestions", query);
  }

  /**
   * @param {AddressQueryDetailsApiDto} query
   * @returns {Promise<AddressItemDetailApiDto>}
   */
  async fetchAddressDetails(query) {
    console.log("[BookingFormInteractor] fetchAddressDetails");

    return this.$store.dispatch("addressSuggestions/getAddressDetails", query);
  }

  /**
   * @param {PaymentMethodQuery} query
   * @returns {Promise<PaymentMethodApiV1Dto[]>}
   */
  async fetchPaymentMethods(query) {
    console.log("[BookingFormInteractor] fetchPaymentMethods");

    return this.$store.dispatch("paymentMethodsApiStore/getPaymentMethods", query);
  }

  /**
   *
   * @param {string} corporationId
   * @param {AddressItemDetailApiDto} origin
   * @param {AddressItemDetailApiDto} destination
   * @return {Promise<Array<OfferApiDto>>}
   */
  async fetchOffers({ corporationId, origin, destination }) {
    const locale = this.$store.getters["language/selectedLanguage"];
    return this.$store.dispatch("offersApiStore/getOffers", {
      corporationId,
      lang: locale,
      coords: {
        pickup: {
          latitude: origin.coordinates.latitude,
          longitude: origin.coordinates.longitude,
        },
        dropoff: {
          latitude: destination.coordinates.latitude,
          longitude: destination.coordinates.longitude,
        },
      },
    });
  }

  /**
   * @param {FareEstimateQuery} query
   * @returns {Promise<FareResponceApiDto>}
   */
  async fetchFareEstimate(query) {
    console.log("[BookingFormInteractor] fetchFareEstimate");

    return this.$store.dispatch("fareEstimationApiStore/getFareEstimation", query);
  }

  async fetchFleetAccounts(query) {
    console.log("[BookingFormInteractor] fetchFleetAccounts");
    return this.$store.dispatch("businessBookingRecipient/getFleetAccounts", query);
  }

  /**
   * @param {BookingAccountFieldsQuery} query
   * @returns {Promise<BusinessAccountFieldApiDto[]>}
   */
  async fetchAccountFields(query) {
    console.log("[BookingFormInteractor] fetchAccountFields");

    return this.$store.dispatch("businessBookingRecipient/getAccountFields", query);
  }

  setBusinessRecipient(businessRecipient) {
    this.bookingFormModel.createBookingRecipient = businessRecipient;
  }

  /**
   *
   * @return {Promise<BookingCreationResponseApiDto>}
   */
  async createFirstBooking() {
    const requestBody = BookingCreationDtoFactory.fromBookingFormModel(this.bookingFormModel);

    console.log("[BookingFormInteractor] createFirstBooking", requestBody);
    return this.$store.dispatch("bookingApiStore/requestBooking", requestBody);
  }

  /**
   * @param {string} bookingId
   * @return {Promise<BookingCreationResponseApiDto>}
   */
  async confirmFirstBooking(bookingId) {
    console.log("[BookingFormInteractor] confirmFirstBooking", bookingId);
    return this.$store.dispatch("bookingApiStore/confirmBooking", bookingId);
  }

  /**
   * @param {BookingCreationResponseApiDto} booking
   * @return {Promise<BookingCreationResponseApiDto>}
   */
  async createReturnBooking(booking) {
    const requestBody = BookingCreationDtoFactory.fromBookingFormModelToReturnBooking({
      bookingFormModel: this.bookingFormModel,
      firstBookingConfirmResponseDto: booking,
    });

    console.log("[BookingFormInteractor] createReturnBooking", requestBody);
    return this.$store.dispatch("bookingApiStore/requestBooking", requestBody);
  }

  /**
   * @param {string} bookingId
   * @return {Promise<BookingCreationResponseApiDto>}
   */
  async confirmReturnBooking(bookingId) {
    console.log("[BookingFormInteractor] confirmReturnBooking", bookingId);
    return this.$store.dispatch("bookingApiStore/confirmBooking", bookingId);
  }

  /**
   * @param {string} authorizationClientSecret
   */
  async confirmPaymentBooking(authorizationClientSecret) {
    console.log("[BookingFormInteractor] confirmPaymentBooking", authorizationClientSecret);
    return this.$store.dispatch("bookingApiStore/confirmPayment", authorizationClientSecret);
  }

  /**
   * @param {string} bookingId
   */
  async cancelBooking(bookingId) {
    console.log("[BookingFormInteractor] cancelBooking", bookingId);
    return this.$store.dispatch("bookingApiStore/cancelBooking", bookingId);
  }
}
