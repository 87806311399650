import tripsHistory from '@/assets/icons/sidemenu/tripsHistory.svg';
import Department from '@/assets/icons/sidemenu/department.svg';
import Help from '@/assets/icons/sidemenu/help.svg';
import Team from '@/assets/icons/sidemenu/team.svg';
import Worker from '@/assets/icons/sidemenu/worker.svg';
import travelRestriction from '@/assets/icons/sidemenu/travelRestriction.svg';
import Reporting from '@/assets/icons/sidemenu/reporting.svg';
import paymentMethod from '@/assets/icons/sidemenu/paymentMethod.svg';
import Favourites from '@/assets/icons/favourites.svg';

import ExpandIconClose from '@/assets/icons/expand-icon-close.svg';
import ExpandIconOpen from '@/assets/icons/expand-icon-open.svg';
import LanguageIcon from '@/assets/icons/sidemenu/language.svg';
import TermsAndConditionsIcon from '@/assets/icons/sidemenu/termsAndConditions.svg';
import PrivacyPolicyIcon from '@/assets/icons/sidemenu/privacyPolicy.svg';
import AccountIcon from '@/assets/icons/sidemenu/accountIcon.svg';

import EditIcon from '@/assets/icons/edit-icon.svg';
import DeleteIcon from '@/assets/icons/delete-icon.svg';
import EmailIcon from '@/assets/icons/email.svg';

import BookingMapLocation from '@/assets/icons/booking/map-location.svg';
import BookingCarDefault from '@/assets/icons/booking/car-default.svg';
import AvatarIcon from '@/assets/icons/avatar.svg';
import DriverStateCar from '@/assets/icons/driver-state.svg';

import PayInCar from '@/assets/icons/paymentMethod/iconPayInCar.svg';
import PayBusinessAccount from '@/assets/icons/paymentMethod/iconBusinessAccount.svg';
import ApplePay from '@/assets/icons/paymentMethod/iconApple.svg';
import GooglePay from '@/assets/icons/paymentMethod/iconGoogle.svg';
import VisaCard from '@/assets/icons/paymentMethod/iconVisa.svg';
import MasterCard from '@/assets/icons/paymentMethod/iconMaster.svg';
import AmexCard from '@/assets/icons/paymentMethod/iconAmex.svg';
import DiscoverCard from '@/assets/icons/paymentMethod/iconDiscover.svg';
import UnknownCard from '@/assets/icons/paymentMethod/iconUnknown.svg';
import DefaultCard from '@/assets/icons/paymentMethod/defaultCard.svg';

export const iconLoader = {
  sideMenu: {
    'trip-history-icon': tripsHistory,
    'department-icon': Department,
    'help-icon': Help,
    'team-icon': Team,
    'worker-icon': Worker,
    'travel-restriction-icon': travelRestriction,
    'reporting-icon': Reporting,
    'payment-method-icon': paymentMethod,
    'favourites-icon': Favourites,
  },
  toggleSideMenu: {
    'expand-icon-close': ExpandIconClose,
    'expand-icon-open': ExpandIconOpen,
    'language-icon': LanguageIcon,
    'terms-and-conditions': TermsAndConditionsIcon,
    'privacy-policy': PrivacyPolicyIcon,
    'account-details': AccountIcon,
  },
  elements: {
    'avatar-icon': AvatarIcon,
    'delete-icon': DeleteIcon,
    'edit-icon': EditIcon,
    'email-icon': EmailIcon,
  },
  booking: {
    'driver-state-car': DriverStateCar,
    'map-location': BookingMapLocation,
    'car-default': BookingCarDefault,
    'delete-icon': DeleteIcon,
    'edit-icon': EditIcon,
  },
  paymentMethod: {
    'pay-in-car': PayInCar,
    'pay-business-account': PayBusinessAccount,
    'apple-pay': ApplePay,
    'google-pay': GooglePay,
    'visa-card': VisaCard,
    'master-card': MasterCard,
    'amex-card': AmexCard,
    'discover-card': DiscoverCard,
    'unknown-card': UnknownCard,
    'default-card': DefaultCard,
  },
  favourites: {
    'favourites-icon': Favourites,
  },
};
