import Vue from "vue";
import i18n from "@/plugins/i18n";

const notify = ({ group, title, message, type }) => {
  Vue.notify({
    group,
    title,
    text: message,
    type,
  });
};

export const notifySuccess = (message, title) => {
  notify({ title, message, type: "success" });
};

export const notifyError = ({ title, message }) => {
  notify({ title, message, type: "error" });
};

// eslint-disable-next-line no-unused-vars
export const notifyWarn = ({ title, message, action }) => {
  notify({
    title,
    message,
    type: "warn",
  });
};

export const notifyGeneralError = (error) => {
  const title = i18n.t("notification.error-text");
  const message = error.details.message
    ? error.details.message
    : i18n.t("notification.error", { attribute: error?.httpStatusCode });
  notify({ title, message, type: "error" });
};

export class NotificationByType {
  initNotifications(methodName) {
    const method = this.apiResponse.config.method;
    const notificationsMap = {
      post: {
        title: i18n.t("notification.byMethods.post", {
          store: this.transfromMethodToNotificationName(methodName),
        }),
        message: i18n.t("notification.byMethods.postDescription", { id: this.splitUrl() }),
        type: "success",
      },
      put: {
        title: i18n.t("notification.byMethods.put", {
          store: this.transfromMethodToNotificationName(methodName),
        }),
        message: i18n.t("notification.byMethods.putDescription", { id: this.splitUrl() }),
        type: "success",
      },
      delete: {
        title: i18n.t("notification.byMethods.delete", {
          store: this.transfromMethodToNotificationName(methodName),
        }),
        message: i18n.t("notification.byMethods.deleteDescription", { id: this.splitUrl() }),
        type: "success",
      },
    };

    if (notificationsMap[method]) {
      this.notificationsInstance(notificationsMap[method]);
    }
  }

  initializeNotifications({ group, title, message, type }) {
    return Vue.notify({
      group,
      title,
      text: message,
      type,
    });
  }

  notificationsInstance = ({ title, message, type }) =>
    this.initializeNotifications({
      group: this.notificationsGroups,
      title,
      message,
      type,
    });
}
