<template>
  <v-layout class="d-block booking-form-container">
    <loader v-if="!isBootstrapped" :is-loading="isBootstrapped"></loader>
    <booking-steps-form-container
      v-else
      :booking-form-model="bookingFormModel"
      class="booking-form-container-border"
    >
      <template v-slot:title>
        <span class="booking-form-title">{{ $t(`components.bookingForm.title.trip`) }}</span>
      </template>
    </booking-steps-form-container>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingStepsFormContainer from '@/views/bookingForm/steps/BookingStepsFormContainer';
import { BookingFormModelFactory } from '@/views/bookingForm/BookingFormModelFactory';

export default {
  name: 'BookingFormContainer',
  components: {
    BookingStepsFormContainer,
  },
  data() {
    return {
      isBootstrapped: false,
      bookingFormModel: null,
    };
  },
  computed: {
    ...mapGetters({
      bookingChannelId: 'bookingChannel/id',
      getSelectedCorporationId: 'corporations/getSelectedCorporationId',
    }),
  },
  async mounted() {
    this.bookingFormModel = BookingFormModelFactory.makeForBookingCreation({
      bookingChannelId: this.bookingChannelId,
      paymentProviderName: this.$store.getters['bookingChannel/payment'].name,
      businessAccountId: this.getSelectedCorporationId,
      tip: {
        base: 'percentage',
        value: 0,
      },
    });

    this.isBootstrapped = true;
  },
};
</script>

<style lang="scss">
.booking-form-container {
  position: relative;

  .v-card {
    height: 100%;
  }
}

.booking-form-container {
  .v-text-field--outlined fieldset {
    background-color: #F8F8F8 !important;
    border-color: #EEEEEE !important;
  }
  
  .multiple-address-container .address-select .multiselect__tags {
    background-color: #F8F8F8 !important;
    border: 1px solid #EEEEEE !important;
  }
}
</style>

<style lang="scss" scoped>
.booking-form-title {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.005em;
  text-align: left;
}

.booking-form-container-border {
  border-radius: 8px;
  box-shadow: none;
}
</style>
