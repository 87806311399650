import { CountryLocaleFinder } from '@icabbi/hi-dash';

const defaultState = {
  authCallbackUrl: '',
  companyName: undefined,
  logoURL: undefined,
  oidcClientId: '',
  id: undefined,
  humanReadableId: undefined,
  defaultCountry: '',
  currencySymbol: undefined,
  networkOnly: false,
  defaultLocale: 'en-US',
  payment: {},
  location: {},
  termAndConditionLinks: {},
  privacyPolicyLinks: {},
};

const getters = {
  authCallbackUrl: (state) => state.authCallbackUrl,
  oidcClientId: (state) => state.oidcClientId,
  theme: (state) => state.colorScheme,
  id: (state) => state.id,
  humanReadableId: (state) => state.humanReadableId,
  companyName: (state) => state.companyName,
  logoURL: (state) => state.logoURL,
  defaultCountry: (state) => state.defaultCountry,
  postLogoutUrl: (state) => state.postLogoutUrl,
  currencySymbol: (state) => state.currencySymbol,
  defaultLocale: (state) => state.defaultLocale,
  networkOnly: (state) => state.networkOnly,
  payment: (state) => state.payment,
  defaultLocation: (state) => state.location,
  termAndConditionLinks: (state) => state.termAndConditionLinks,
  privacyPolicyLinks: (state) => state.privacyPolicyLinks,
  isAddressViasFeatureEnabled: (state) => !!state.features.isAddressViasFeatureEnabled,
};

const actions = {};

const mutations = {
  setBookingChannelConfig(state, {
    // eslint-disable-next-line max-len
    authCallbackUrl, features, colorScheme, logo, name, oidcClientId, id, humanReadableId, defaultCountry, postLogoutUrl, currencySymbol, networkOnly, payment, location, termAndConditionLinks, privacyPolicyLinks,
  }) {
    state.authCallbackUrl = authCallbackUrl;
    state.id = id;
    state.humanReadableId = humanReadableId;
    state.colorScheme = colorScheme;
    state.companyName = name;
    state.logoURL = logo;
    state.oidcClientId = oidcClientId;
    state.defaultCountry = defaultCountry;
    state.postLogoutUrl = postLogoutUrl;
    state.currencySymbol = currencySymbol;
    state.networkOnly = networkOnly;
    state.payment = payment;
    state.location = location;
    state.features = features;
    state.termAndConditionLinks = termAndConditionLinks;
    state.privacyPolicyLinks = privacyPolicyLinks;
    state.defaultLocale = CountryLocaleFinder.defaultCountryLocale(defaultCountry);
  },
  setDefaultCountry(state, defaultCountry) {
    state.defaultCountry = defaultCountry;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
