<template>
<div>
  <v-row>
    <v-col cols="12" class="col-without-bottom-padding">
      <v-divider></v-divider>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <div class="d-flex justify-space-between mb-2">
        <h5 class="section-title mb-1 input-label">{{$t('components.linkedAccountForm.form.fleet')}}</h5>
        <delete-icon @click="deleteAccount" v-if="fleetAccounts.length > 1" class="mr-1 icon-control-list"/>
      </div>
      <v-select
        class="language-field"
        :items="configuredFleets"
        v-model="fleetAccount.fleetId"
        :menu-props="{ bottom: true, offsetY: true }"
        :validator="$v.fleetAccount.fleetId"
        item-text="companyName"
        item-value="id"
        v-on:input="value => validate(value)"
        outlined
        hide-details
        background-color="#f8f8f8"
      >
      </v-select>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <h5 class="section-title mb-1 input-label">{{$t('components.linkedAccountForm.form.account_code')}}</h5>
      <v-text-field
        v-model="fleetAccount.accountNumber"
        :append-icon="iconFleetAccount"
        outlined
        filled
        hide-details
        :disabled="!fleetAccount.fleetId"
        :loading="fleetAccount.isLoading"
        :class="{ 'input-field-error': fleetAccount.isValid === false }"
        v-on:input="value => validate(value)"
        :placeholder="$t('components.linkedAccountForm.form.account_code_placeholder')">
      </v-text-field>
      <span class="text-error" v-if="fleetAccount.isValid === false"><small>Dispatch account not valid</small></span>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { iconLoader } from '@/helpers/iconsLoader';

export default {
  name: 'LinkedAccountForm',
  components: { ...iconLoader.elements },
  props: {
    fleets: {
      type: Array,
      required: true,
    },
    fleetAccount: {
      type: Object,
      required: true,
    },
    fleetAccounts: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fleetsGroup: [],
    };
  },
  validations: {
    fleetAccount: {
      fleetId: {
        required,
      },
      accountNumber: {
        required,
      },
    },
  },
  computed: {
    configuredFleets() {
      return this.fleets.filter((fleet) => !this.fleetAccounts.find((fa) => fa.fleetId === fleet.id && fa !== this.fleetAccount));
    },
    iconFleetAccount() {
      if (this.fleetAccount.isValid === true) {
        return 'mdi-checkbox-marked-circle';
      }
      if (typeof this.fleetAccount.isValid === 'boolean' && this.fleetAccount.isValid === false) {
        return 'mdi-alert-circle';
      }
      return '';
    },
  },
  methods: {
    // eslint-disable-next-line vue/return-in-computed-property
    validate() {
      if (!this.$v.fleetAccount.accountNumber.$invalid) this.$emit('validateAccountNumber', this.fleetAccount);
      else { this.$emit('invalidateAccountNumber', this.fleetAccount); }
    },
    deleteAccount() {
      if (this.fleetAccount.id) {
        this.$emit('deleteFleetAccount', { fleetAccount: this.fleetAccount, index: this.index });
      } else {
        this.$emit('deleteFleetAccountLocal', this.index);
      }
    },
  },
};
</script>
<style lang="scss">
.input-field-error .v-icon, .input-field-error .v-icon.primary--text {
  color: #EB5757 !important;
}
.text-error{
  color: #EB5757;
}
</style>
