import { PaymentMethodTypeEnum } from "@/domain/paymentMethod/PaymentMethodTypeEnum";

export class PaymentMethodDomainDelegate {
  static isTypeCreditCard(paymentMethodType) {
    return paymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD;
  }

  static isTypeBusinessDispatchInvoice(paymentMethodType) {
    return paymentMethodType === PaymentMethodTypeEnum.BUSINESS_DISPATCH_INVOICE;
  }

  static isTypeCash(paymentMethodType) {
    return paymentMethodType === PaymentMethodTypeEnum.CASH;
  }
}
