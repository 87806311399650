<template>
  <v-col cols="12" v-if="result" class="pb-0">
    {{  title }}
    <v-icon small color="success" v-if="result.wasSuccess">mdi-checkbox-marked-circle</v-icon>
    <v-icon small color="error" v-if="!result.wasSuccess">mdi-close-circle-outline</v-icon>
    <p v-if="!result.wasSuccess"> Error: {{ result.errorMessage }}</p>
    <p v-if="result.wasSuccess"> <b>id:</b> {{ result.entity.id }}
      <b>isNewRecord</b>: {{ result.isNewRecord }}</p>
  </v-col>
</template>

<script>
export default {
  name: 'ImportResultDetails',
  props: {
    result: {
      type: Object,
    },
    title: {
      type: String,
      default: 'Passenger',
    },
  },
};
</script>

<style scoped>
</style>
