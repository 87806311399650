<template>
  <div class="table-wrapper">
    <payment-methods-table
      :corporationsLoading="methodLoading"
      :totalCards="totalCount"
      :cards="data"
      @callLoadData="loadData"
      @addCard="$refs.addDialog.openDialog()"
      @deleteCard="removeCard"
    ></payment-methods-table>

    <dialog-wrapper ref="addDialog" :width="400">
      <template v-slot:dialogContent>
        <add-card
          :corporationId="corporationId"
          @closeDialog="closeCardDialog"
          @methodAdded="loadData"
          :key="recreate"
        />
      </template>
    </dialog-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { paymentMethod } from '@/mixins/paymentMethod.mixins';
import PaymentMethodsTable from '@/components/paymentMethods/PaymentMethodsTable.vue';
import { getRoles } from '@/mixins/authentication.mixins';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';
import AddCard from '@/components/paymentMethods/AddCard.vue';
import { notifyWarn } from '../../helpers/notification';

export default {
  name: 'cards-list-view',
  mixins: [paymentMethod, getRoles],
  components: {
    PaymentMethodsTable,
    AddCard,
    DialogWrapper,
  },
  data() {
    return {
      corporationId: null,
      recreate: 0,
      settedCorporationIdFromPayments: false,
    };
  },
  mounted() {
    if (this.getSelectedCorporationId) return;

    this.settedCorporationIdFromPayments = true;
  },
  beforeDestroy() {
    if (this.settedCorporationIdFromPayments) { this.$store.commit('corporations/setCorporationId', null); }
  },
  watch: {
    getSelectedCorporationId: {
      handler() {
        this.loadData();
      },
    },
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    ...mapGetters('bookingChannel', ['payment']),
  },
  methods: {
    loadData() {
      if (!this.getSelectedCorporationId) {
        notifyWarn({
          message: this.$t('notification.selectCorporation'),
          title: this.$t('notification.missedCorporation')
        });
        return;
      }

      this.getMethods({ corporationId: this.getSelectedCorporationId });
    },
    async removeAssignedGroups(id, data) {
      const requestParams = {
        removedEmployeeGroupIds: data.map((el) => el.employeeGroupId),
        paymentProviderName: this.payment.name,
        corporationId: this.getSelectedCorporationId,
        paymentMethodId: id,
      };

      await this.$store.dispatch('paymentMethods/assignPaymentToGroup', requestParams);
    },
    async loadGroupToPaymentMethod(data) {
      const params = {
        paymentMethodId: data.id,
        paymentMethodName: this.payment.name,
        corporationId: this.getSelectedCorporationId,
      };

      const result = await this.$store.dispatch('paymentMethods/getEmployeeGroupsForPaymentMethodId', params);

      if (result) {
        await this.removeAssignedGroups(data.id, result);
      }
    },
    async removeCard(data) {
      this.methodLoading = true;

      await this.loadGroupToPaymentMethod(data);
      try {
        const result = await this.deleteCard(
          data.id,
          this.getSelectedCorporationId,
        );
        if (result) {
          this.data = this.data.filter((method) => method.id !== data.id);
        }
      } finally {
        this.methodLoading = false;
      }
    },
    closeCardDialog() {
      this.recreate += 1;
      this.$refs.addDialog.close();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "selectCorporation": "Please select an account",
    "missedCorporation": "Missed account"
  }
}
</i18n>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
}
</style>
