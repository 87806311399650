import { mapGetters } from 'vuex';
import { exportCsv } from 'json2csv-export';
import i18n from '@/plugins/i18n';
import _ from "lodash";

export const businessPassenger = {
  data() {
    return {
      searchObject: {
        page: 0,
        maxPerPage: 10,
        orderBy: 'createdAt',
        bookingChannelId: null,
        passengerId: null,
        businessPassengerGroupId: null,
        businessAccountIds: [],
        dynamicSearch: null,
        relations: 'businessPassengerGroups',
      },
      searchGuestObject: {
        page: 0,
        maxPerPage: 10,
        orderBy: 'createdAt',
        bookingChannelId: null,
        passengerId: null,
        businessPassengerGroupId: null,
        businessAccountIds: [],
        dynamicSearch: null,
      },
      searchPassengerGroupsObject: {
        page: 0,
        maxPerPage: 10,
        sort: '-createdAt',
        dynamicSearch: null,
        ids: null,
        corporationId: null,
        type: null,
        restrictionId: null,
        employeeId: null,
      },
      searchCorporationAdminsObject: {
        page: 0,
        maxPerPage: 10,
        orderBy: 'createdAt',
        dynamicSearch: null,
      },
      businessPassengers: [],
      businessPassengerGroups: [],
      guestPassengers: [],
      corporationAdmins: [],
      totalCorporationAdmins: 0,
      totalBusinessPassengerGroups: 0,
      totalBusinessPassengers: 0,
      totalGuestPassengers: 0,
      corporationAdminsLoading: false,
      businessPassengersLoading: false,
      businessGuestsLoading: false,
      guestPassengersLoading: false,
    };
  },

  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId','selectedCorporation']),
    ...mapGetters('users', ['getUserRole']),
  },
  created() {
    this.debouncedGetBusinessPassengers = _.debounce(this.getBusinessPassengers, 500);
    this.debouncedGetBusinessGuests = _.debounce(this.getBusinessGuests, 500);
    this.debouncedGetBusinessPassengerGroups = _.debounce(this.getBusinessPassengerGroups, 500);
    this.debouncedGetCorporationAdmins = _.debounce(this.getCorporationAdmins, 500);
  },
  watch: {
    searchObject: {
      handler() {
        this.debouncedGetBusinessPassengers();
      },
      deep: true,
    },
    searchGuestObject: {
      handler() {
        this.debouncedGetBusinessGuests();
      },
      deep: true,
    },
    searchPassengerGroupsObject: {
      handler() {
        this.debouncedGetBusinessPassengerGroups();
      },
      deep: true,
    },
    searchCorporationAdminsObject: {
      handler() {
        this.debouncedGetCorporationAdmins()
      },
      deep: true,
    }
  },

  methods: {
    async getBusinessPassengers() {
      if (!this.getSelectedCorporationId && this.getUserRole === 'corporationAdmin') return;
      this.businessPassengersLoading = true;
      const result = await this.$store.dispatch(
        'businessPassenger/getBusinessPassengerList',
        { ...this.searchObject, businessAccountIds: this.getSelectedCorporationId ? [this.getSelectedCorporationId] : [] },
      );

      if (!result) return;

      this.businessPassengers = result.results;
      this.totalBusinessPassengers = result.total;
      this.businessPassengersLoading = false;
    },

    async getCorporationAdmins() {
      if (!this.getSelectedCorporationId) return;

      this.corporationAdminsLoading = true;

      const result = await this.$store.dispatch(
        'users/listUsers',
        {
          ...this.searchCorporationAdminsObject,
          corporationId: this.getSelectedCorporationId,
        }
      )

      if (!result) return;

      this.corporationAdmins = result.data;
      this.totalCorporationAdmins = Number(result.headers['x-total-count']);
      this.corporationAdminsLoading = false;
    },

    async getBusinessGuests() {
      if (!this.getSelectedCorporationId && this.getUserRole === 'corporationAdmin') return;

      this.guestPassengersLoading = true;

      const result = await this.$store.dispatch(
        'businessGuest/getBusinessGuestList',
        { ...this.searchGuestObject, businessAccountIds: this.getSelectedCorporationId ? [this.getSelectedCorporationId] : [] },
      );

      if (!result) return;

      this.guestPassengers = result.results;
      this.totalGuestPassengers = result.total;
      this.guestPassengersLoading = false;
    },

    async getBusinessPassengerGroups() {
      if (this.getUserRole === 'bookingChannelAdmin' && !this.searchPassengerGroupsObject.corporationId) {
        this.searchPassengerGroupsObject.corporationId = this.getSelectedCorporationId;
      }

      this.businessGuestsLoading = true;

      const result = await this.$store.dispatch(
        'employee/listEmployeeGroups',
        { ...this.searchPassengerGroupsObject },
      );

      if (!result) return;

      this.businessPassengerGroups = result.data;
      this.totalBusinessPassengerGroups = Number(result.headers['x-total-count'] || 0);
      this.businessGuestsLoading = false;
    },

    initCallPassengers(tab = null) {
      if (tab === 'employee') {
        this.getBusinessPassengers();
      } else if (tab === 'guest') {
        this.getBusinessGuests();
      } else if (tab === 'groups') {
        this.getBusinessPassengerGroups();
      } else if (tab === "corporationAdmin") {
        this.getCorporationAdmins();
      }
    },

    async loadActiveCorporationGroups(businessAccountId) {
      this.searchPassengerGroupsObject.corporationId = businessAccountId;
      await this.getBusinessPassengerGroups();
    },
  },
};

export const exportPassengers = {
  methods: {
    async exportCorporationAdminsList() {
      const reportDownloadUrl = this.$store.getters['csvReport/getCorporationAdminsReportDownloadUrl']({
        corporationId: this.getSelectedCorporationId,
      });

      window.open(reportDownloadUrl, '_blank');
    },
    exportBusinessBookingRecipientsList() {
      const reportDownloadUrl = this.$store.getters['csvReport/getBusinessBookingRecipientsReportDownloadUrl']({
        corporationId: this.getSelectedCorporationId,
      });

      window.open(reportDownloadUrl, '_blank');
    },
    async exportUsersList() {
      const header = {
        id: 'id',
        givenName: i18n.t('usersExport.headers.givenName'),
        familyName: i18n.t('usersExport.headers.familyName'),
        email: i18n.t('usersExport.headers.email'),
        phoneNumber: i18n.t('usersExport.headers.phoneNumber'),
        userType: i18n.t('usersExport.headers.userType'),
        businessAccountId: i18n.t('usersExport.headers.businessAccountId'),
        businessAccountName: i18n.t('usersExport.headers.businessAccountName'),
        createdAt: i18n.t('usersExport.headers.createdAt'),
      };
      const businessPassengers = await this.getExportBusinessPassengers(0, 50, [this.getSelectedCorporationId], []);
      const businessGuests = await this.getExportBusinessGuests(0, 50, this.getSelectedCorporationId, []);
      const mappedBusinessPassengers = [];
      businessPassengers.forEach((passenger) => {
        const mappedPassenger = {
          id: passenger.id,
          givenName: passenger.passenger.givenName,
          familyName: passenger.passenger.familyName,
          email: passenger.email || passenger.passenger.email,
          phoneNumber: `${passenger.passenger.phoneNumber}`,
          userType: 'BUSINESS_PASSENGER',
          businessAccountId: passenger.businessAccountId,
          businessAccountName: passenger.businessAccount.name,
          createdAt: passenger.createdAt,
        };
        mappedBusinessPassengers.push(mappedPassenger);
      });
      const mappedBusinessGuests = [];
      businessGuests.forEach((guest) => {
        const mappedGuest = {
          id: guest.id,
          givenName: guest.givenName,
          familyName: guest.familyName,
          email: guest.email,
          phoneNumber: guest.phoneNumber,
          userType: 'BUSINESS_GUEST',
          businessAccountId: guest.businessAccountId,
          businessAccountName: guest.businessAccount.name,
          createdAt: guest.createdAt,
        };
        mappedBusinessGuests.push(mappedGuest);
      });
      const data = [
        ...mappedBusinessPassengers,
        ...mappedBusinessGuests,
      ];
      const { businessAccountName } = data[0];
      const fileName = (businessAccountName) ? `${businessAccountName}-users-export` : 'users-export';
      exportCsv({
        header,
        data,
        filename: fileName.replace(/\s+/g, ''), // file name
      });
    },
    async getExportBusinessPassengers(page, maxPerPage, businessAccountIds, allPassengers) {
      const passengers = await this.getBusinessPassengersForPage(page, maxPerPage, businessAccountIds, allPassengers);
      const accumulation = [...allPassengers, ...passengers.results];
      if ((passengers.page + 1) * maxPerPage < passengers.total) {
        // eslint-disable-next-line no-underscore-dangle
        return this.getExportBusinessPassengers(page + 1, maxPerPage, businessAccountIds, accumulation);
      }
      return accumulation;
    },
    async getBusinessPassengersForPage(page, maxPerPage, businessAccountIds) {
      const payload = {
        page,
        maxPerPage,
        businessAccountIds,
      };
      const businessPassengers = await this.$store.dispatch('businessPassenger/getBusinessPassengerList', payload);
      return businessPassengers;
    },
    async getExportBusinessGuests(page, maxPerPage, businessAccountId, allPassengers) {
      const passengers = await this.getBusinessGuestsForPage(page, maxPerPage, businessAccountId, allPassengers);
      const accumulation = [...allPassengers, ...passengers.results];
      if ((passengers.page + 1) * maxPerPage < passengers.total) {
        return this.getExportBusinessGuests(page + 1, maxPerPage, businessAccountId, accumulation);
      }
      return accumulation;
    },
    async getBusinessGuestsForPage(page, maxPerPage, businessAccountId) {
      const payload = {
        page,
        maxPerPage,
        businessAccountId,
      };
      const businessGuests = await this.$store.dispatch('businessGuest/getBusinessGuestList', payload);
      return businessGuests;
    },
  },
};
