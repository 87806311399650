<template>
  <div>
    <slot :attrs="{ errorMessages: activeErrorMessages, label: name, hasErrors: activeErrorMessages.length > 0 }" />
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  extends: singleErrorExtractorMixin,
  props: {
    value: [String, Object],
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (!this.lazy) this.validator.$touch();
    },
  },
};
</script>
