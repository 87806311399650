<template>
  <v-card class="dialog-content">
    <v-card-title class="text-h5 mb-3">
      <span>{{ $t(`components.cancelForm.dialogContainer.${type}.title`) }}</span>
      <v-spacer></v-spacer>
      <v-icon @click="$emit('closeDialog')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text v-if="!loading">
      <p class="dialog-content mb-0">
        {{ $t(`components.cancelForm.dialogContainer.${type}.content.cancel`) }}
      </p>
      <span class="dialog-content-subitem">
        {{ $t(`components.cancelForm.dialogContainer.${type}.content.undo`) }}
      </span>
    </v-card-text>
    <v-card-actions v-if="!loading" class="px-4">
      <v-btn
        class="disable-button"
        color="grey darken-1"
        text
        @click="$emit('closeDialog')"
      >
        {{ $t(`components.cancelForm.dialogContainer.${type}.button.cancel`) }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :class="`cancel-button`"
        class="white--text"
        :color="type === 'resend-email' ? '#333333' : '#EB5757'"
        depressed
        @click="cancelAction"
      >
        {{ $t(`components.cancelForm.dialogContainer.${type}.button.agree`) }}
      </v-btn>
    </v-card-actions>
    <v-layout class="loading-container" v-else justify-center align-center>
      <v-progress-circular
        indeterminate
        color="primary">
      </v-progress-circular>
    </v-layout>
  </v-card>
</template>

<script>
import { initLocale } from '@/mixins/language.mixins';
import { entityLoader } from '@/mixins/entityLoader.mixins';

export default {
  name: 'dialog-wrapper',
  mixins: [initLocale, entityLoader],
  props: {
    type: {
      type: String,
    },
    id: {
      type: String,
    },
    modalData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    isOpen: false,
    loading: false,
    storeAction: null,
  }),
  computed: {
    getStoreAction() {
      let url = null;

      switch (this.type) {
        case 'cancel-booking':
          url = 'bookings/cancelBooking';
          break;
        case 'delete-user':
          url = 'employee/deleteEmployee';
          break;
        case 'delete-guest':
          url = 'businessGuest/deleteGuestPassenger';
          break;
        case 'delete-group':
          url = 'employee/deleteEmployeeGroup';
          break;
        case 'delete-account':
          url = 'corporations/deleteCorporation';
          break;
        case 'delete-payment-method':
          url = 'paymentMethods/deleteCard';
          break;
        case 'delete-fleet-account':
          url = 'fleetAccounts/deleteFleetAccount';
          break;
        case 'delete-business-user':
          url = 'users/deleteUser';
          break;
        case 'resend-email':
          url = 'employee/resendEmailInvitation';
          break;
        default:
          break;
      }

      return url;
    },
  },
  methods: {
    async cancelAction() {
      this.loading = !this.loading;

      try {
        await this.actionPost(this.getStoreAction,
          this.type === 'resend-email'
            ? { id: this.modalData.id, corporationId: this.modalData.corporationId || this.modalData.businessAccountId  }
            : this.modalData.id);
        this.loading = !this.loading;
      } catch (error) {
        this.loading = !this.loading;
      } finally {
        this.$emit('closeDialog', { id: this.id });
      }
    },
  },
};
</script>

<style lang="scss">
.loading-container {
  min-height: 130px;
  background-color: #ffffff;
}
.dialog-content {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #333333;
}

.dialog-content-subitem {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #888888;
}

.disable-button {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-transform: capitalize;
}

.cancel-button {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-transform: capitalize;
}
</style>
