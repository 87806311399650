<template>
  <div class="bs-multiple-address-container">
    <v-timeline dense>
      <v-timeline-item small fill-dot right color="white" class="px-0">
        <template v-slot:icon>
          <map-location class="icon-green" />
        </template>

        <v-col :cols="isShowFlightNumber ? 7 : 12" class="pa-0">
          <multiselect
            class="address-select"
            track-by="id"
            label="description"
            autofocus
            open-direction="bottom"
            :tabindex="0"
            :pre-select-first="true"
            :show-labels="false"
            :placeholder="$t('components.bookingForm.field.pickup')"
            :show-no-options="false"
            :show-no-results="false"
            :close-on-select="true"
            :clear-on-select="false"
            :internal-search="false"
            :searchable="true"
            :loading="addressSuggestionsLoading && type === 'pickup'"
            :options="pickupAddressSuggestions"
            :value="defaultValue.origin"
            height="45px"
            @search-change="$emit('onSearchSuggestions', $event, 'pickup')"
            @select="$emit('onSelectAddress', $event, 'pickup')"
          >
            <span slot="caret"></span>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.isFavourite" class="d-flex align-center">
                <favourites-icon style="width: 24px" />
                <span class="ml-2">{{ props.option.alias }}</span>
              </div>

              <span v-else>
                {{ props.option.description }}
              </span>
            </template>
          </multiselect>
        </v-col>

        <v-col cols="5" v-if="isShowFlightNumber" class="pa-0">
          <v-text-field
            v-model="flightNumberLocal"
            id="flightNumber"
            class="mt-0 ml-2 address-select flightNumberField"
            hide-details
            outlined
            :class="[$v.flightNumberLocal.$invalid ? 'error-border' : '']"
            :placeholder="$t('components.bookingForm.field.flightNumber')"
            @change="$emit('onFlightNumberChange', $event)"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-fade-transition>
                    <v-icon
                      v-if="$v.flightNumberLocal.$invalid"
                      class="validation-icon mt-1"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-alert-circle
                    </v-icon>
                  </v-fade-transition>
                </template>
                <div class="d-flex flex-column">
                  <span v-if="!$v.flightNumberLocal.minLength">
                    {{
                      $t("components.bookingForm.validation.flightNumber.minLength", {
                        attribute: 3,
                      })
                    }}
                  </span>
                </div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-timeline-item>
      <v-timeline-item
        v-for="(waypoint, index) in defaultValue.waypoints"
        :key="index"
        small
        fill-dot
        right
        color="white"
        class="px-0"
      >
        <template v-slot:icon>
          <map-location />
        </template>

        <multiselect
          :ref="`waypoint-${index}`"
          class="address-select"
          track-by="id"
          label="description"
          :close-on-select="true"
          :clear-on-select="false"
          :placeholder="$t('components.bookingForm.field.waypoint')"
          open-direction="bottom"
          :show-labels="false"
          :show-no-results="false"
          :internal-search="false"
          :show-no-options="false"
          :searchable="true"
          :options="waypointAddressSuggestions"
          :value="waypoint"
          :loading="addressSuggestionsLoading && type === 'waypoint'"
          height="45px"
          @search-change="$emit('onSearchSuggestions', $event, 'waypoint')"
          @select="$emit('onSelectAddress', $event, 'waypoint', index)"
        >
          <span slot="caret"></span>
          <template slot="option" slot-scope="props">
            <div v-if="props.option.isFavourite" class="d-flex align-center">
              <favourites-icon style="width: 24px" />
              <span class="ml-2">{{ props.option.alias }}</span>
            </div>

            <span v-else>{{ props.option.description }}</span>
          </template>
        </multiselect>
        <v-icon class="remove-icon cursor-pointer" @click="callRemoveWaypoint(index)"
          >mdi-close</v-icon
        >
      </v-timeline-item>
      <v-timeline-item small fill-dot right color="white" class="px-0">
        <template v-slot:icon>
          <map-location />
        </template>

        <multiselect
          ref="dropoff"
          class="address-select"
          track-by="id"
          label="description"
          :close-on-select="true"
          :clear-on-select="false"
          :placeholder="$t('components.bookingForm.field.dropoff')"
          open-direction="bottom"
          :show-labels="false"
          :show-no-results="false"
          :internal-search="false"
          :show-no-options="false"
          :searchable="true"
          :options="destinationAddressSuggestions"
          :value="defaultValue.destination"
          :loading="addressSuggestionsLoading && type === 'destination'"
          height="45px"
          @search-change="$emit('onSearchSuggestions', $event, 'destination')"
          @select="$emit('onSelectAddress', $event, 'destination')"
        >
          <span slot="caret"></span>
          <template slot="option" slot-scope="props">
            <div v-if="props.option.isFavourite" class="d-flex align-center">
              <favourites-icon style="width: 24px" />
              <span class="ml-2">{{ props.option.alias }}</span>
            </div>

            <span v-else>{{ props.option.description }}</span>
          </template>
        </multiselect>
      </v-timeline-item>
    </v-timeline>

    <span class="add-waypoint" @click="addEmptyWaypoint">{{
      $t("components.bookingForm.addStop")
    }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { minLength } from "vuelidate/lib/validators";
import { iconLoader } from "@/helpers/iconsLoader";

export default {
  name: "MultipleAddressPicker",
  components: {
    ...iconLoader.booking,
    ...iconLoader.favourites,
  },
  props: {
    defaultValue: {
      type: Object,
      required: true,
    },
    flightNumber: {
      type: String,
      required: false,
    },
    favouritePickupSuggestions: {
      type: Array,
      required: true,
    },
    favouriteDropoffSuggestions: {
      type: Array,
      required: true,
    },
    addressSuggestions: {
      type: Array,
      required: true,
    },
    addressSuggestionsLoading: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      flightNumberLocal: this.flightNumber || undefined,
    };
  },
  computed: {
    ...mapGetters({
      selectedLanguage: "language/selectedLanguage",
    }),
    isShowFlightNumber() {
      return !!this.defaultValue?.origin?.locationTypes?.includes("airport");
    },
    pickupAddressSuggestions() {
      if (this.addressSuggestions.length === 0) return this.favouritePickupSuggestions;

      return this.addressSuggestions;
    },
    destinationAddressSuggestions() {
      if (this.addressSuggestions.length === 0) return this.favouriteDropoffSuggestions;

      return this.addressSuggestions;
    },
    waypointAddressSuggestions() {
      if (this.addressSuggestions.length === 0)
        return [...this.favouritePickupSuggestions, ...this.favouriteDropoffSuggestions];

      return this.addressSuggestions;
    },
  },
  methods: {
    callRemoveWaypoint(index) {
      this.$emit("removeWaypoint", index);
      this.addDynamicHeightForTimeline(this.defaultValue.waypoints.length + 1);
    },
    addEmptyWaypoint() {
      this.$emit("addEmptyWaypoint");
      this.addDynamicHeightForTimeline(this.defaultValue.waypoints.length + 1);
    },
    addDynamicHeightForTimeline(index) {
      const element = document.querySelector(".v-timeline");
      const newHeight = 63 * index;
      element.style.setProperty("--before-height", `${newHeight}px`);
    },
  },
  validations() {
    const address = async (val) => val?.detail?.coordinates && val?.detail?.description;
    const waypoints = async (val) => {
      let valid = true;
      if (val.length) {
        val.forEach((waypoint) => {
          if (!waypoint?.detail?.coordinates || !waypoint?.detail?.description) valid = false;
        });
      }
      return valid;
    };

    const validation = {
      address: {
        pickup: { address },
        dropoff: { address },
        waypoints: { $each: { address: waypoints } },
      },
      flightNumberLocal: {
        minLength: minLength(3),
      },
    };

    return validation;
  },
};
</script>

<style lang="scss" scoped>
.bs-multiple-address-container {
  width: 100%;
  min-height: 8vh;

  .icon-green {
    color: #47d061;
  }

  .remove-icon {
    position: absolute;
    right: 10px;
    top: 32%;
    z-index: 1000;

    &:hover {
      color: black;
    }
  }
}
.error-border {
  border: 1.6px solid #FF0000 !important;
  border-radius: 4px;
}

.add-waypoint {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  text-decoration-line: underline;
  color: #888888 !important;
  cursor: pointer;
}

.validation-icon {
  color: #ff0000 !important;
}
</style>

<style lang="scss">
.flightNumberField {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em !important;
  color: #333333 !important;
  background-color: #f0f0f0 !important;

  & fieldset {
    border: 0px !important;
  }
}
.bs-multiple-address-container {
  .address-select {
    .multiselect__input,
    .multiselect__single {
      background: initial !important;

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      color: #333333;

      width: 350px;
      text-overflow: ellipsis !important;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 0px;
    }

    .multiselect__placeholder {
      background: initial !important;

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
    }

    .multiselect__element {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.005em;
      color: #333333;
    }

    .multiselect__option--highlight {
      background: #47d061;
    }

    .multiselect__option {
      white-space: normal !important;
    }

    .multiselect__tags {
      background: rgba(0, 0, 0, 0.06);
      border: none !important;
      border-radius: 4px;
      padding: 8px 8px 0px 8px;

      display: flex;
      align-content: center;
      align-items: center;
      height: 45px;
    }

    .multiselect__content {
      padding-left: 0px;
    }
  }
}
.v-timeline {
  padding-top: 0px;
}

.v-timeline::before {
  top: 40px;
  height: var(--before-height, 63px);
}

.v-timeline-item__body {
  display: flex;
  align-items: center;
  align-content: center;
}

.v-timeline--dense .v-timeline-item {
  height: 64px;
  padding: 0px;
}

.v-timeline-item__divider {
  justify-content: flex-start;
  min-width: auto !important;
}

.bs-multiple-address-container {
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 2.5% !important;
  }
}

.v-timeline--dense .v-timeline-item__body {
  min-width: 375px;
  max-width: 100%;
  margin-left: 10px;
}
</style>
