<template>
  <v-row justify="space-between" align="center" class="px-13 top-nav-wrapper">
  <v-col cols="auto" class="py-1 px-0"></v-col>    
    <v-col xs="12" sm="12" md="auto" class="py-1 px-0">
      <v-img src="../../assets/bb-logo.png" width="180px" />
    </v-col>
    <v-col xs="12" sm="12" md="auto" class="py-1 px-0">
      <corporations-select v-if="getUserRole !== 'bookingChannelAdmin'" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import CorporationsSelect from '@/components/corporations/CorporationsSelect.vue';

export default {
  name: 'TopBarHeader',
  components: {
    CorporationsSelect,
  },
  computed: {
    ...mapGetters('users', ['getUserRole']),
  },
};
</script>

<style lang="sass" scoped>
.top-nav-wrapper
  background-color: #ffffff !important
  margin: 0px
</style>
