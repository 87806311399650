<template>
  <v-row>
    <v-col cols="6">
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.date") }}</h5>
      <v-menu :close-on-content-click="false" :nudge-bottom="44" v-model="datePickerOpen">
        <template v-slot:activator="{ on }">
          <validated-field v-slot="{ attrs }" v-model="localeDate" :validator="$v.date">
            <v-text-field
              outlined
              filled
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
              :value="localeDate"
            />
          </validated-field>
        </template>
        <v-date-picker
          no-title
          show-adjacent-months
          :min="datePickerMinDate"
          :locale="$i18n.locale"
          v-model="date"
          @change="onDateChange"
        />
      </v-menu>
    </v-col>
    <v-col cols="6">
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.time") }}</h5>
      <validated-field v-slot="{ attrs }" v-model="timeMask" :validator="$v.timeMask">
        <v-text-field
          outlined
          filled
          hide-details
          v-bind="attrs"
          v-model="timeMask"
          v-maska
          data-maska="['##:##','@@@@']"
          @focus="handleTimeFocus"
          @input="onTimeChange"
        />
      </validated-field>
      <div class="d-flex flex-column">
        <span v-if="!$v.timeMask.invalidTime" class="error--text text-caption">
          {{ $t("components.bookingForm.section.valid_time") }}
        </span>
        <span v-if="!$v.timeMask.cannotBeInPast" class="error--text text-caption">
          {{ $t("components.bookingForm.section.time_error") }}
        </span>
        <span v-if="!$v.timeMask.returnTimeCannotBeInPast" class="error--text text-caption">
          {{ $t("components.bookingForm.section.return_time_error") }}
        </span>
      </div>
    </v-col>

    <v-col v-if="showResetTime" cols="12" class="pt-0">
      <span class="reset-pickuptime" @click="resetDateTime">
        {{ $t("components.bookingForm.resetPickupTime") }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { vMaska, Mask } from "maska";
import {ScheduleType} from "@/core/api/booking/BookingScheduleType";

const TIME_ASAP = "ASAP";
const DATE_FORMAT_YMD = "YYYY-MM-DD";
// const DATE_FORMAT_LOCALE = 'LL';
// const TIME_FORMAT_HMS = 'HH:mm';

export default {
  name: "DateTimePicker",
  directives: { maska: vMaska },
  data: () => ({
    datePickerOpen: false,
    date: moment().format(DATE_FORMAT_YMD),
    time: TIME_ASAP,
    timeMask: TIME_ASAP,
    defaultTimeValidation: TIME_ASAP,
  }),
  props: {
    defaultDate: {
      type: String,
      default: moment().format(DATE_FORMAT_YMD),
    },
    defaultTime: {
      type: String,
      default: TIME_ASAP,
    },
    showResetTime: {
      type: Boolean,
      default: false,
    },
    returnTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("bookingChannel", ["defaultLocale"]),
    localeDate() {
      return moment(this.date).format("LL");
    },
    datePickerMinDate() {
      return moment().format(DATE_FORMAT_YMD);
    },
    datePickerMaxDate() {
      return moment().add(1, "month").format(DATE_FORMAT_YMD);
    },
    getTimeValidation() {
      return true;
    },
  },
  validations() {
    const date = async (val) => val >= moment().format(DATE_FORMAT_YMD);

    return {
      date: {
        date,
      },
      timeMask: {
        invalidTime: (val) => {
          if (val === TIME_ASAP) return true;
          return moment(val, "HH:mm", true).isValid();
        },
        cannotBeInPast: (val) => {
          if (val === TIME_ASAP) return true;
          if (this.date !== moment().format(DATE_FORMAT_YMD)) return true;
          if (!val || !moment(val, "HH:mm", true).isValid()) return true;
          return moment(val, "HH:mm", true).isSameOrAfter(moment(), "minute");
        },
        returnTimeCannotBeInPast: (val) => {
          if (val === TIME_ASAP) return true;
          if (this.date !== moment(this.defaultDate).format(DATE_FORMAT_YMD)) return true;
          if (this.returnTime) {
            return moment(val, "HH:mm", true).isSameOrAfter(moment(this.defaultTimeValidation, "HH:mm"), "minute");
          }
          return true;
        },
      },
    };
  },
  created() {
    this.date = this.defaultDate || moment().format(DATE_FORMAT_YMD);
    this.time = this.defaultTime || TIME_ASAP;
    this.defaultTimeValidation = this.defaultTime;
    this.timeMask = this.defaultTime === TIME_ASAP ? TIME_ASAP : moment(this.defaultTime, "HH:mm").format("HH:mm");

    this.$emit('pickupTimeChanged', {
      isAsap: this.time === ScheduleType.ASAP,
      date: this.date,
      time: this.time,
      isTimeValid: !this.$v.timeMask.$invalid,
    });
  },
  methods: {
    onDateChange() {
      this.datePickerOpen = false;
      this.timeMask = this.time === 'ASAP' ? moment().format("HH:mm") : this.timeMask;
      this.time = this.time === 'ASAP' ? moment().format("HH:mm") : this.time;

      this.$emit('pickupTimeChanged', {
        isAsap: this.time === ScheduleType.ASAP,
        date: this.date,
        time: this.time,
        isTimeValid: !this.$v.timeMask.$invalid,
      });
    },
    onTimeChange(val) {
      this.time = val === TIME_ASAP ? TIME_ASAP : moment(val, "HH:mm").format("HH:mm");

      this.$emit('pickupTimeChanged', {
        isAsap: this.time === ScheduleType.ASAP,
        date: this.date,
        time: this.time,
        isTimeValid: !this.$v.timeMask.$invalid,
      });
    },
    resetDateTime() {
      this.date = moment().format(DATE_FORMAT_YMD);
      this.time = TIME_ASAP;
      this.timeMask = TIME_ASAP;

      this.$emit('pickupTimeChanged', {
        isAsap: this.time === ScheduleType.ASAP,
        date: this.date,
        time: this.time,
        isTimeValid: !this.$v.timeMask.$invalid,
      });
    },
    maskInput(ele) {
      if (ele.keyCode === 32) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }
      const val = ele.target.value.toUpperCase().trim();
      if (!val) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }

      const mask = new Mask({ mask: "##:##" });
      if (val !== TIME_ASAP && val.length >= 4) {
        this.time = mask.masked(val);
        this.timeMask = mask.masked(val);
      } else if (val.length === 1) {
        this.time = mask.masked(`0${val}:00`);
        this.timeMask = mask.masked(`0${val}:00`);
      } else if (val.length === 2) {
        this.time = mask.masked(`${val}:00`);
        this.timeMask = mask.masked(`${val}:00`);
      }

      this.$emit('pickupTimeChanged', {
        isAsap: this.time === ScheduleType.ASAP,
        date: this.date,
        time: this.time,
        isTimeValid: !this.$v.timeMask.$invalid,
      });
    },
    handleTimeBlurEvent(ele) {
      if (ele.target.value.toUpperCase() === '') {
        this.timeMask = TIME_ASAP;
        this.time = TIME_ASAP;
      }
      this.maskInput(ele);
    },
    handleTimeFocus(ele) {
      if (ele.target.value === TIME_ASAP) {
        this.timeMask = "";
        this.time = "";
      } else {
        ele.target.select();
      }
    },
  },
};
</script>

<style scoped>
.reset-pickuptime {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  text-decoration-line: underline;
  color: #888888 !important;
  cursor: pointer;
}
</style>
