<template>
  <v-row>
    <users-list
      @addUserClick="addUserClick()"
      @editUserClick="editUserClick"
      @deleteUserClick="deleteUserClick($event)"
      @loadData="loadData($event)"
      :users="data"
      :usersLoading="entityLoading"
      :totalUsers="totalCount"
    ></users-list>

    <dialog-wrapper
      ref="userDialog"
      :width="dialogWidth"
      @closeDialog="loadData({
        ...entitySearchObject,
        corporationId: getSelectedCorporationId,
        sort: '-createdAt'
      })"
    >
      <template v-slot:dialogContent>
        <users-form
          :dataInfo="user"
          :mode="mode"
          :corporationsLoading="corporationsLoading"
          :listCorporations="corporations"
          :completeCorporationsList="completeCorporationsList"
          @searchForCorporations= "debouncedListCorporations"
          @closeDialog="closeDialog"
        />
      </template>
    </dialog-wrapper>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import UsersList from '@/components/users/UsersList.vue';
import UsersForm from '@/components/users/UsersForm.vue';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';
import _ from "lodash";

export default {
  name: 'list-users-view',
  components: {
    UsersList,
    DialogWrapper,
    UsersForm,
  },
  mixins: [
    entityLoader,
  ],
  data() {
    return {
      user: null,
      mode: 'add',
      dialogWidth: 600,
      corporations: [],
      corporationsLoading: false,
      completeCorporationsList: [],
      debouncedListCorporations: null,
    };
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    totalCount() {
      return this.entityTotalCount.totalCount;
    },
  },
  async created() {
    // This is needed to have the corporations list ready for the user form to display the corporation's information.
    const { data } = await this.$store.dispatch('corporations/listCorporations', { sort: 'name', maxPerPage: Number.MAX_SAFE_INTEGER });
    this.completeCorporationsList = data;
    this.debouncedListCorporations = _.debounce(this.listCorporations, 500);
  },
  methods: {
    async listCorporations(value) {
      this.corporationsLoading = true;
      const { data } = await this.$store.dispatch('corporations/listCorporations', {sort: 'name', name: value, maxPerPage: 10 });
      this.corporations = data;
      this.corporationsLoading = false;
    },
    addUserClick() {
      this.mode = 'add';
      this.user = null;
      this.dialogWidth = 600;
      this.$refs.userDialog.openDialog();
    },
    editUserClick(user) {
      this.user = user;
      this.mode = 'edit';
      this.dialogWidth = 600;

      this.$refs.userDialog.openDialog();
    },
    closeDialog(submitted) {
      this.$refs.userDialog.close();

      if (submitted) {
        this.loadData({
          ...this.entitySearchObject,
          corporationId: this.getSelectedCorporationId,
          sort: '-createdAt',
        });
      }
    },
    async deleteUserClick(users) {
      this.dialogWidth = 400;
      this.$refs.userDialog.openDialog({ type: 'delete-business-user', id: users.id });
    },
    async loadData({
      sort, page, maxPerPage, dynamicSearch, corporationId,
    }) {
      const params = {
        page,
        maxPerPage,
        sort,
        dynamicSearch,
        corporationId,
      };

      await this.getList('users/listUsers', params);
    },
  },
};
</script>