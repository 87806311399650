<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <h5 class="section-title pb-2">
        {{ $t("components.bookingForm.section.company") }}
      </h5>
      <v-select
        :disabled="isEditMode"
        :menu-props="{ nudgeBottom: 50 }"
        :loading="vehicleOffersLoading"
        :items="fleets"
        hide-details="auto"
        v-model="fleetId"
        color="default"
        outlined
        filled
        single-line
        class="select-high"
        :placeholder="$t('components.bookingForm.selectFleet')"
        append-icon="mdi-chevron-down"
        item-value="fleetId"
      >
        <template slot='selection' slot-scope='{ item }'>
          <span>{{ item.companyName }}</span>
        </template>
        <template slot='item' slot-scope='{ item }'>
          <span class="custom-text">{{ item.companyName }}</span>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" class="py-0">
      <v-divider v-show="isShowDivider" class="my-4" />
    </v-col>

    <v-col>
      <v-row>
        <v-col cols="7" class="pt-0">
          <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.vehicle") }}</h5>
          <validated-field
            v-slot="{ attrs }"
            :name="$t('components.bookingForm.field.vehicle')"
            v-model="vehicle"
            :validator="$v.vehicle"
          >
            <v-select
              outlined
              filled
              hide-details
              single-line
              return-object
              height="60"
              v-bind="attrs"
              class="select-high"
              item-text="name"
              v-model="vehicle"
              :loading="vehicleOffersLoading"
              :append-icon="`${filteredOffers.length ? '$dropdown' : ''}`"
              :readonly="!filteredOffers.length"
              :label="$t('components.bookingForm.noneAvailable')"
              :menu-props="{ nudgeBottom: 60 }"
              :items="filteredOffers"
            >
              <template v-slot:prepend-inner>
                <car-default class="icon-car" />
              </template>
              <template v-bind="attrs" v-if="attrs.hasErrors" v-slot:append>
                <error-tooltip-auto :errors="attrs.errorMessages" />
              </template>
            </v-select>
          </validated-field>
        </v-col>
        <v-col cols="5" class="pt-0">
          <h5 class="section-title pb-2">
            {{ $t("components.bookingForm.section.vehicleCount") }}
          </h5>
          <v-text-field
            outlined
            filled
            height="60"
            hide-details
            v-model="vehicleCount"
            @keypress="(event) => setVehicleCount(event)"
            @change="(event) => handleVehicleCountChange(event)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import { filterNumber } from '@/helpers/filters';
import { required } from 'vuelidate/lib/validators';
import { iconLoader } from '@/helpers/iconsLoader';

export default {
  name: 'vehicle-picker',
  components: {
    ...iconLoader.booking,
  },
  data: () => ({
    offersByFleet: [],
    vehicleOffersLoading: false,
    vehicle: null,
    vehicleCount: 1,
    fleetId: null,
    fleets: [],
  }),
  props: {
    availableFleets: [],
    offersGroupedByFleet: {},


    bookingOffer: {
      type: Object,
    },
    address: {
      type: Object,
      required: true,
    },
    corporationId: {
      type: String,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isShowDivider: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      vehicle: { required },
    };
  },
  watch: {
    corporationId: {
      handler() {
        this.loadVehicleOffers();
      },
      deep: true,
      immediate: true,
    },
    bookingOffer: {
      handler() {
        if (this.bookingOffer) {
          this.fleetId = this.bookingOffer.fleetId;
        }
      },
      immediate: true,
    },
    fleetId(val) {
      this.$emit('fleetIdChanged', val);
    },
    vehicle(val) {
      this.$emit('vehicleChanged', val);
    },
    vehicleCount(val) {
      this.$emit('vehicleCountChanged', val);
    },
  },
  computed: {
    filteredOffers() {
      return this.offersByFleet.filter((offer) => offer.fleetId === this.fleetId) || [];
    },
  },
  methods: {
    async loadVehicleOffers() {
      if (this.offersByFleet.length) return;

      this.vehicleOffersLoading = true;
      let vehicleOffers = [];

      if (
        this.corporationId
        && (this.address.pickup?.detail?.coordinates || this.address.pickup?.coordinates)
        && (this.address.dropoff?.detail?.coordinates || this.address.dropoff?.coordinates)
      ) {
        vehicleOffers = await this.$store.dispatch('offers/list', {
          coords: {
            pickup: this.address.pickup.detail?.coordinates || this.address.pickup.coordinates,
            dropoff: this.address.dropoff.detail?.coordinates || this.address.dropoff.coordinates,
          },
          corporationId: this.corporationId,
          lang: this.$i18n.locale,
        });
      } else vehicleOffers = [];

      this.handleVehicleOffers(vehicleOffers);
      this.vehicleOffersLoading = false;
    },
    handleVehicleOffers(offers) {
      if (offers.length) {
        this.fleets = offers.map((offer) => ({
          fleetId: offer.fleetId,
          companyName: offer.properties.companyName,
        }));

        this.fleets = _.uniqBy(this.fleets, 'fleetId');
        this.offersByFleet = offers;

        this.fleetId = this.bookingOffer?.fleetId || this.fleets[0].fleetId;
        this.vehicle = this.bookingOffer?.id
          ? this.offersByFleet.find((offer) => offer.oldOfferId === this.bookingOffer.id)
          : this.filteredOffers[0];
      }
    },
    setVehicleCount(event) {
      return filterNumber(event);
    },
    handleVehicleCountChange(event) {
      if (event > 10) {
        this.vehicleCount = 10;
        return;
      }

      if (event < 1) {
        this.vehicleCount = 1;
        return;
      }

      this.vehicleCount = event;
    },
  },
};
</script>

<style lang="scss">
.select-high {
  .v-label {
    font-weight: 600 !important;
    font-size: 12px !important;
    letter-spacing: -0.005em !important;
    color: #333333 !important;
    margin: 2px 4px 7px 0px;
  }
}
</style>

<style lang="scss" scoped>
.v-input.select-high::v-deep
  .v-input__prepend-inner {
    margin-right: 12px !important;
  }

  .v-input__append-inner {
    margin-top: 18px !important
  }

  .v-select.v-text-field input {
    font-size: 12px !important;
  }

  .v-icon {
    margin-top: 10px;
  }

.v-text-field--filled.error--text::v-deep fieldset {
  border-color: #eb5757 !important
}

.v-btn.theme--dark.v-btn--disabled {
  background-color: #272727 !important
}

.v-list-item__icon {
  width: 46px;
  height: 32px;
  margin-right: 16px !important;
}

.v-list-item--active .v-list-item__icon i {
  color: white
}

.icon-car {
  margin-top: 3px;
  z-index: 1;
}

.section-title {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 10px;
  font-weight: 400;
  color: #888888;
}

.custom-text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
}

a {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  text-decoration-line: underline;
  color: #888888 !important;
}
.error-text {
  color: #eb5757 !important;
  font-size: 12px;
}
</style>
