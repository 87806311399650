<template>
  <v-container class="loader-container" pa-0 mt-0 fluid :class="{ 'text-xs-center': isLoading, 'fill-height': fullPage || isLoading }">
    <v-layout :justify-center="justifyCenter" class="loader-content" fluid >
      <v-flex class="loader-flex" v-if="isLoading" fill-height>
        <v-container fill-height justify-center pa-0 class="loader-container">
          <v-progress-circular
            :size="size"
            :width="width"
            :color="color"
            indeterminate
          ></v-progress-circular>
        </v-container>
      </v-flex>
      <slot></slot>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    fullPage: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    size: {
      type: Number,
      default: 70,
    },
    width: {
      type: Number,
      default: 7,
    },
    color: {
      type: String,
      default: 'green',
    },
    margin: {
      type: Number,
      default: 10,
    },
    justifyCenter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
  .loader-content{
    position: relative;
    height: 100%;
    background-color: white;
  }
  .loader-container {
    background-color: white;
  }
  .loader-flex {
    position: absolute;
    width: 100%;
    z-index: 2;
  }
</style>
