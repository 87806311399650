// eslint-disable-next-line import/prefer-default-export
export class Env {
  static isAppInDevelopEnvironment() {
    return process.env.VUE_APP_ENV === 'develop';
  }

  static isAppProductionEnvironment() {
    return process.env.VUE_APP_ENV === 'production';
  }

  static getApiGatewayUrl() {
    return process.env.VUE_APP_API_GATEWAY_URL;
  }

  static getEncryptKey() {
    return process.env.VUE_APP_ENCRYPT_KEY;
  }
}
