import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  fleetAccountServiceClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  fleetAccountServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.fleetAccountServiceClient) {
      state.fleetAccountServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.FleetAccountServiceConnector });
    }
    return state.fleetAccountServiceClient;
  },
};

const actions = {
  createFleetAccount: apiCall(async (context, { corporationId, accountNumber, fleetId }) => {
    const fleetAccountServiceClient = context.getters.fleetAccountServiceClient(context);
    const result = await fleetAccountServiceClient.createFleetAccount({ data: { corporationId, accountNumber, fleetId } });
    return result;
  }),

  updateFleetAccount: apiCall(async (context, {
    corporationId, accountNumber, fleetId, id,
  }) => {
    const fleetAccountServiceClient = context.getters.fleetAccountServiceClient(context);
    const result = await fleetAccountServiceClient.updateFleetAccount({
      corporationId, accountNumber, fleetId, id,
    });
    return result;
  }),

  listFleetAccounts: apiCall(async (context, {
    page, maxPerPage, sort, corporationId,
  }) => {
    const fleetAccountServiceClient = context.getters.fleetAccountServiceClient(context);
    return fleetAccountServiceClient.listFleetAccounts({
      sort, page, maxPerPage, corporationId,
    });
  }),

  listFleets: apiCall(async (context) => {
    const fleetAccountServiceClient = context.getters.fleetAccountServiceClient(context);
    return fleetAccountServiceClient.listFleets();
  }),

  validateAccount: apiCall(async (context, { accountNumber, fleetId }) => {
    const fleetAccountServiceClient = context.getters.fleetAccountServiceClient(context);
    const { isValid } = await fleetAccountServiceClient.validateAccount({ fleetId, accountNumber });
    return isValid;
  }),

  deleteFleetAccount: apiCall(async (context, id) => {
    const fleetAccountServiceClient = context.getters.fleetAccountServiceClient(context);
    return fleetAccountServiceClient.deleteFleetAccount({ id });
  }),

};

const mutations = {};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
