import { PaymentMethodTypeEnum } from '@/domain/paymentMethod/PaymentMethodTypeEnum';

export class PaymentMethodDomainTransformer {

  /**
   * @return {PaymentMethodDomainModel}
   */
  static makeCashDomainModel() {
    return {
      paymentMethodType: PaymentMethodTypeEnum.CASH,
      id: "cash",
    };
  }

  /**
   * @return {PaymentMethodDomainModel}
   */
  static makeBusinessDispatchInvoice({ businessAccountId }) {
    return {
      paymentMethodType: PaymentMethodTypeEnum.BUSINESS_DISPATCH_INVOICE,
      id: businessAccountId,
      businessDispatchInvoicePaymentMethod: {
        businessAccountId,
      },
    };
  }

  /**
   *
   * @param {PaymentMethodApiV1Dto} paymentMethodApiV1Dto
   * @return {PaymentMethodDomainModel}
   */
  static fromPaymentMethodApiV1Dto(paymentMethodApiV1Dto) {
    return {
      paymentMethodType: PaymentMethodTypeEnum.CREDIT_CARD,
      id: paymentMethodApiV1Dto.id,
      creditCardPaymentMethod: {
        id: paymentMethodApiV1Dto.id,
        brand: paymentMethodApiV1Dto.brand,
        last4: paymentMethodApiV1Dto.number,
        cardHolderName: paymentMethodApiV1Dto.cardHolderName,
      },
    };
  }
}
