<template>
  <v-autocomplete outlined filled hide-details hide-no-data return-object
    append-icon=""
    hide-selected
    item-text="description"
    no-filter
    clearable
    v-model="selected"
    :error-messages="errorMessages"
    :has-errors="hasErrors"
    :items="items"
    :background-color="backgroundColor"
    :search-input.sync="query"
    @input="getAddressDetails"
    :autofocus="true"
    @click:append="removeAddress(index)"
  >
    <template v-slot:prepend-inner>
      <span v-if="prependText" class="mt-1 prepend-text">{{ prependText }}</span>
      <map-location v-else :class="`icon-${iconColor}`" />
    </template>
    <template v-if="hasErrors" v-slot:append>
      <error-tooltip-auto :errors="errorMessages" />
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from 'lodash';
import { iconLoader } from '@/helpers/iconsLoader';

const SOURCE_GOOGLE = 'google';
const SOURCE_ICABBI = 'icabbi';

export default {
  components: { ...iconLoader.booking },
  props: {
    iconColor: {
      type: String,
      default: 'gray',
    },
    prependText: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    errorMessages: Array,
    hasErrors: Boolean,
    initial: Object,
    index: Number,
  },
  data: () => ({
    selected: null,
    items: [],
    query: '',
    skipFirstQuery: false,
    initialShown: false,
  }),
  created() {
    if (this.initial && this.initial.description) {
      this.$emit('change', this.initial, this.index);
    }
  },
  watch: {
    initial(val) {
      if (!val || this.initialShown) return;
      this.skipFirstQuery = true;
      this.initialShown = true;
      this.items = [val];
      this.selected = val;
    },
    query: debounce(async function (val) { // eslint-disable-line func-names
      if (this.skipFirstQuery) { this.skipFirstQuery = false; return; }
      this.items = await this.getAddressSuggestions(val);
    }, 200),
  },
  methods: {
    getAddressSuggestions(search) {
      if (search) return this.$store.dispatch('geolocation/getSuggestions', { search, lang: this.$i18n.locale });
      return [];
    },
    async getAddressDetails(val) {
      switch (val?.source) {
        case SOURCE_GOOGLE:
          // eslint-disable-next-line no-param-reassign
          val.detail = await this.$store.dispatch('geolocation/getSuggestionDetail', {
            addressId: val.id,
            lang: this.$i18n.locale,
          });
          break;
        case SOURCE_ICABBI:
          // eslint-disable-next-line no-param-reassign
          val.detail = {
            coordinates: val.info.coordinates,
            description: val.description,
          };
          break;
        default:
          break;
      }
      if (val) {
        this.$emit('change', val, this.index);
      }
    },
    removeAddress(index) {
      this.$emit('removeWaypoint', index);
    },
  },
};
</script>

<style lang="sass" scoped>
.icon-gray
  color: #333333

.icon-green
  color: #47d061

.prepend-text
  color: #888888
</style>
