<template>
  <v-card class="pa-8">
    <v-card-title v-if="!presenter.shouldShowConfirmationStep()" class="px-0 pt-0 pb-4">
      <slot name="title" />
    </v-card-title>

    <v-card-text class="pa-0">
      <v-flex>
        <template v-if="presenter.shouldShowPassengerStep()">
          <passenger-step-component :presenter="presenter"></passenger-step-component>
        </template>
        <template v-else-if="presenter.shouldShowAddressStep()">
          <address-step-component :presenter="presenter"></address-step-component>
        </template>
        <template v-else-if="presenter.shouldShowCompanyStep()">
          <company-step-component :presenter="presenter"></company-step-component>
        </template>
        <template v-else-if="presenter.shouldShowAccountStep()">
          <account-fields-step-component :presenter="presenter"></account-fields-step-component>
        </template>
        <template v-else-if="presenter.shouldShowConfirmationStep()">
          <confirmation-step-component :presenter="presenter"></confirmation-step-component>
        </template>
      </v-flex>
    </v-card-text>

    <v-dialog
      :value="showBookingFormDialog"
      max-width="400"
      persistent
      class="dialog_wrapper"
      transition="slide-x-reverse-transition"
    >
      <v-card>
        <v-card-title>
          <span class="booking-form-title">{{ $t(`components.bookingForm.title.businessAccountChanged`) }}</span>
        </v-card-title>

        <v-divider class="mb-2" />

        <v-card-text class="px-5">
          {{ $t(`components.bookingForm.businessAccountChangeDescription`) }}
        </v-card-text>

        <v-card-actions class="px-5 pb-3">
          <v-btn
            color="#333333"
            outlined
            @click="closeDialog"
          >{{ $t('global.cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#333333"
            class="white--text"
            depressed
            @click="submitDialog"
          >{{ $t('global.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { BookingStepsFormPresenter } from "@/views/bookingForm/steps/BookingStepsFormPresenter";
import { BookingFormInteractor } from "../BookingFormInteractor";
import PassengerStepComponent from "@/views/bookingForm/steps/PassengerStepComponent";
import AddressStepComponent from "@/views/bookingForm/steps/AddressStepComponent";
import CompanyStepComponent from "@/views/bookingForm/steps/CompanyStepComponent.vue";
import AccountFieldsStepComponent from "@/views/bookingForm/steps/AccountFieldsStepComponent.vue";
import ConfirmationStepComponent from "@/views/bookingForm/steps/ConfirmationStepComponent.vue";

export default {
  name: "BookingStepsFormContainer",
  components: {
    PassengerStepComponent,
    AddressStepComponent,
    CompanyStepComponent,
    AccountFieldsStepComponent,
    ConfirmationStepComponent,
  },
  props: {
    bookingFormModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      presenter: new BookingStepsFormPresenter({
        interactor: new BookingFormInteractor({
          $store: this.$store,
          bookingFormModel: this.bookingFormModel,
        }),
      }),
      showBookingFormDialog: false,
      cachedBusinessAccuntId: null,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedCorporationId: 'corporations/getSelectedCorporationId',
    }),
  },
  methods: {
    submitDialog() {
      this.presenter.resetPassengerStep();
      this.presenter.goToPassengerStep();
      this.showBookingFormDialog = false;
    },
    closeDialog() {
      this.showBookingFormDialog = false;

      this.$store.commit("corporations/setCorporationId", this.cachedBusinessAccuntId);
      this.presenter.onBusinessAccountIdChange(this.cachedBusinessAccuntId);
    },
  },
  watch: {
    getSelectedCorporationId: {
      handler(newValue, oldValue) {
        if (this.cachedBusinessAccuntId === newValue) return;

        const status = this.presenter.onBusinessAccountIdChange(newValue);
        this.cachedBusinessAccuntId = oldValue;

        if (status) {
          this.showBookingFormDialog = true;
        }
      },
      immediate: true,
    }
  },
};
</script>

<style scoped></style>
