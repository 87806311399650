<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat id="employee-card" class="pa-5">
        <v-card-title id="employee-title" class="form-title">
          <span>{{ $t(`components.employeeForm.corporationAdmin.${mode}.title`) }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="forceClear()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text id="employee-form" class="form-content">
          <v-row class="px-3">
            <v-col cols="6" class="col-without-left-padding">
              <span class="text-uppercase input-label pb-2">{{ $t(`components.employeeForm.default.firstName`) }}</span>

              <v-text-field
                :placeholder="$t(`components.employeeForm.default.givenName`)"
                :class="{ 'employee-field-error': $v.corporationAdmin.givenName.$error }"
                v-model="corporationAdmin.givenName"
                class="employee-field mt-5"
                height="44"
                outlined
                required
                clearable
                hide-details
                @input="$v.corporationAdmin.givenName.$touch()"
                @blur="$v.corporationAdmin.givenName.$touch()"
              >
                <template v-if="$v.corporationAdmin.givenName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.corporationAdmin.givenName"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6" class="col-without-right-padding">
              <span class="text-uppercase input-label pb-2">{{ $t(`components.employeeForm.default.lastName`) }}</span>

              <v-text-field
                v-model="corporationAdmin.familyName"
                :class="{ 'employee-field-error': $v.corporationAdmin.familyName.$error }"
                :placeholder="$t(`components.employeeForm.default.familyName`)"
                class="employee-field mt-5"
                outlined
                clearable
                required
                hide-details
                no-filter
                @input="$v.corporationAdmin.familyName.$touch()"
                @blur="$v.corporationAdmin.familyName.$touch()"
              >
                <template v-if="$v.corporationAdmin.familyName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.corporationAdmin.familyName"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col class="col-without-right-padding col-without-left-padding">
              <span class="text-uppercase input-label">{{ $t(`components.employeeForm.default.email`) }}</span>
            </v-col>

            <v-col cols="12" class="col-without-right-padding col-without-left-padding">
              <v-text-field
                v-model="corporationAdmin.email"
                :placeholder="$t(`components.employeeForm.default.emailPlaceholder`)"
                :class="{ 'employee-field-error': $v.corporationAdmin.email.$error }"
                class="employee-field email-input"
                outlined
                required
                clearable
                hide-details
                no-filter
                @input="$v.corporationAdmin.email.$touch()"
                @blur="$v.corporationAdmin.email.$touch()"
              >
                <template v-if="$v.corporationAdmin.email.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.corporationAdmin.email"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" class="col-without-right-padding col-without-left-padding">
              <span class="text-uppercase input-label">{{ $t('components.employeeForm.default.account') }}</span>
            </v-col>

            <v-col cols="12" class="col-without-right-padding col-without-left-padding">
              <v-autocomplete
                v-model="activeCorporations"
                icon="$icons_product_corporation"
                outlined
                filled
                hide-details
                item-text="name"
                item-value="id"
                return-object
                :multiple="true"
                :items="getCorporationsList"
                :loading="corporationsLoading"
                :search-input.sync="corporationsSearch"
                :placeholder="$t('components.employeeForm.default.accountPlaceholder')"
                @change="corporationsSearch = ''"
              >
              </v-autocomplete>
            </v-col>

            <v-col v-if="activeCorporations.length" cols="12" class="corporation-list-wrapper col-without-right-padding col-without-left-padding">
              <div
                v-for="(corporation, index) in activeCorporations"
                :key="index"
                class="d-flex align-center justify-space-between corporation-item mr-2"
              >
                <div>
                  <p class="mb-1 text-body-1">{{ corporation.name }}</p>
                  <span class="text-subtitle-2">{{ corporation.code }}</span>
                  <span
                    v-if="corporation.contactEmail"
                    class="text-subtitle-2"
                  > - {{ corporation.contactEmail }}</span>
                </div>

                <v-btn
                  @click="activeCorporations.splice(index, 1)"
                  class="corporation-remove-btn"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col v-if="isRoleCorpoAdmin" cols="12" class="col-without-right-padding col-without-left-padding">
              <span class="text-uppercase input-label">{{ $t(`components.employeeForm.default.communicationLanguage`) }}</span>
            </v-col>

            <v-col v-if="isRoleCorpoAdmin" cols="12" class="col-without-right-padding col-without-left-padding">
              <v-select
                :items="languages"
                v-model="corporationAdmin.communicationLanguage"
                class="language-field"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                background-color="#f8f8f8"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-4">
          <v-btn
            class="text-capitalize"
            color="grey darken-1"
            text
            @click="forceClear()"
          >
            {{ $t(`components.employeeForm.${mode}.cancel`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize white--text"
            color="#333333"
            depressed
            :disabled="$v.corporationAdmin.$invalid || isLoading || !(isFormChanged || isCorporationsChanged)"
            @click="submitDialog"
          >
            {{ $t(`components.employeeForm.corporationAdmin.${mode}.agree`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEqual, uniqBy } from 'lodash';

import { getRoles } from '@/mixins/authentication.mixins';
import employeeValidation from '@/helpers/rules/employeeValidation';
import { languageComputed, languageMethods } from '@/mixins/language.mixins';
import { businessPassenger } from '@/mixins/passenger.mixins';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import { CORP_ADMIN } from '@/helpers/userRoles';

import ErrorTooltip from '@/components/elements/ErrorTooltip.vue';

const blankState = {
  businessAccountId: null,
  givenName: '',
  familyName: '',
  phone: '',
  email: '',
  role: 'corporationAdmin',
  corporationId: null,
  communicationLanguage: 'en',
};

const defaultCorporationsSearchParams = {
  page: 0,
  maxPerPage: 10,
  sort: 'name',
};

export default {
  name: 'CreateCorpoAdminForm',
  mixins: [languageComputed, languageMethods, businessPassenger, getRoles, entityLoader],
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    corpoData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ErrorTooltip,
  },
  validations: {
    ...employeeValidation,
  },
  data() {
    return {
      corporationAdmin: {},
      activeCorporations: [],
      corporations: [],
      isLoading: false,
      corporationsLoading: false,
      groupsLoading: false,
      corporationsSearch: "",
      corporationsSearchParams: defaultCorporationsSearchParams,
    };
  },
  async mounted() {
    await this.$store.dispatch('users/getUserRole');
    this.roles.push({ name: this.$t('components.usersForm.default.corpoAdmin'), value: CORP_ADMIN });
  },
  watch: {
    corpoData: {
      handler() {
        if (this.mode === 'edit' && this.corpoData) {
          this.corporationAdmin = { ...this.corpoData };

          const activeCorporationIds = this.corpoData.roles.map((el) => el.corporationId);
          this.activeCorporations = uniqBy(this.getCorporationsList.filter((el) => activeCorporationIds.includes(el.id)), 'id');
        } else {
          this.corporationAdmin = { ...blankState };

          this.activeCorporations = uniqBy(this.getCorporationsList.filter((el) => el.id === this.getSelectedCorporationId), 'id');
        }
      },
      immediate: true,
    },
    selectedLanguage(val) {
      this.corporationAdmin.communicationLanguage = val;
    },
    corporationsSearch: {
      handler(val) {
        if(val && val.length >= 3) {
          this.loadCorporations(val);
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      countryCode: 'bookingChannel/defaultCountry',
      getUserRole: 'users/getUserRole',
    }),
    ...mapGetters('corporations', ['getCorporationsList', 'getSelectedCorporationId']),
    getActionURL() {
      return this.mode === 'edit' ? 'users/updateUser' : 'users/createUser';
    },
    isRoleCorpoAdmin() {
      return this.corporationAdmin.role === 'corporationAdmin';
    },
    isFormChanged() {
      return !isEqual(this.getMutableFields(this.corporationAdmin), this.getMutableFields(this.corpoData));
    },
    isCorporationsChanged() {
      return !isEqual(this.activeCorporations.map((el) => el.id), this.corpoData.roles.map((el) => el.corporationId));
    },
    getActionPayload() {
      return {
          id: this.corporationAdmin.id,
          givenName: this.corporationAdmin.givenName,
          familyName: this.corporationAdmin.familyName,
          email: this.corporationAdmin.email,
          role: this.corporationAdmin.role,
          communicationLanguage: this.corporationAdmin.communicationLanguage,
          corporationId: this.activeCorporations.id,
          corporations: this.activeCorporations.map((el) => el.id),
        };
    },
  },
  methods: {
    forceClear(submitted) {
      this.corporationAdmin = { ...blankState };
      this.activeCorporations = [];

      this.isLoading = false;
      this.formChanged = false;

      this.$v.$reset();
      this.$emit('closeDialog', submitted);
    },
    getMutableFields(val) {
      return {
        givenName: val.givenName,
        familyName: val.familyName,
        email: val.email,
        communicationLanguage: val.communicationLanguage,
      };
    },
    onCountryChanged(country) {
      this.corporationAdmin.dialCode = country.dialCode;
    },
    submitDialog() {
      this.$v.corporationAdmin.$touch();

      if (this.$v.corporationAdmin.$invalid) {
        return;
      }

      this.isLoading = true;

      this.$store.dispatch(this.getActionURL, this.getActionPayload)
        .then(() => {
          this.forceClear(true);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async loadCorporations(val) {
      if (val !== undefined) {
        this.searchParams = { ...defaultCorporationsSearchParams, name: val };
      }
      this.loading = true;
      const { data } = await this.$store.dispatch('corporations/listCorporations', { ...this.searchParams });

      this.$store.commit('corporations/setCorporations', data.filter((el) => el.status === 'active'));
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/phoneInput.scss';

  .input-label {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #888888;
  }

  .employee-field-error {
    border: 1px solid #EB5757 !important;
  }

  .employee-field {
    background: #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    line-height: 15px;
    border: 1px solid #EEEEEE;
    letter-spacing: -0.005em;
    color: #333333;
  }

  .corporation-list-wrapper {
    max-height: 250px;
    overflow-y: auto;
  }

  .corporation-item {
    background: #F8F8F8;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;

    & p {
      color: #333333;
    }
  }
</style>


<style lang="scss">
.employee-field {
  & fieldset {
    background: #f8f8f8;
  }
}
</style>

