<template>
  <loader :is-loading="loading" :fullPage="true" color="primary">
    <v-container fluid class="align-start align-content-start fill-height">
      <div class="bs-table-header">
        <v-row class="mb-1">
          <v-col cols="12" class="col-without-bottom-padding px-8 mt-6 mb-3">
            <h1 class="bs-header-title">
              {{ $t(`components.tripsHistory.title`) }}
            </h1>
          </v-col>
          <v-row justify="space-between" class="align-end mb-3 px-8">
            <v-col
              sm="12"
              md="12"
              lg="7"
              class="col-without-top-padding col-without-bottom-padding v-tabs-container"
            >
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                plain
                class="tabs-text mr-6"
                :class="{ 'tabs-text-active': $route.name === tab.tab }"
                @click="$router.push(`/trips/${tab.tab}`)"
              >
                {{ $t(`components.tripsHistory.tabs.${tab.tab}.title`) }}
              </div>
            </v-col>
            <v-col
              sm="12"
              md="12"
              lg="5"
              class="d-flex align-center col-without-top-padding col-without-bottom-padding"
            >
              <corporations-select v-if="getIsAdmin" />
              <v-text-field
                v-model="dispatchId"
                :label="$t('components.tripsHistory.tripId')"
                color="primary"
                prepend-inner-icon="mdi-magnify"
                clearable
                outlined
                hide-details
                :height="38"
                class="mx-2 bs-search-item"
              >
              </v-text-field>
              <v-btn
                color="white"
                class="ma-2 search-passenger-btn"
                :height="40"
                depressed
                @click="btnSearchPassengerClick"
              >
                <v-icon left> mdi-account-search </v-icon>
                <span v-if="!selectedPassenger">{{ $t("components.tripsHistory.passenger") }}</span>
                <span v-if="selectedPassenger"
                  >{{ $t("components.tripsHistory.passenger") }}: {{ selectedPassenger.givenName }}
                  {{ selectedPassenger.familyName }}
                  <v-icon small>mdi-close</v-icon>
                </span>
              </v-btn>
              <v-btn
                v-if="hasAccess(CORP_ADMIN)"
                to="/create-v2"
                class="add-bs-button"
                :height="40"
                color="#333333"
                depressed
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("components.tripsHistory.add") }}
              </v-btn>
              <v-menu left offset-y nudge-bottom="15">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on" class="icon-button-color">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="showDownloadModal">
                    <v-list-item-title class="flex justify-space-between">
                      <span class="mr-3">{{ $t("components.tripsHistory.download") }}</span>
                      <v-icon>mdi-tray-arrow-down</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <div class="bs-table-table">
        <v-row no-gutters>
          <v-col class="col-without-top-padding table-color">
            <v-data-table
              class="bs-table elevation-0"
              sort-by="pickupTime"
              :items="trips"
              :headers="headers"
              :server-items-length="totalTrips"
              :loading="corporationsLoading"
              :multi-sort="false"
              :must-sort="true"
              :sort-desc="true"
              :single-expand="true"
              :expanded.sync="expanded"
              :page.sync="listRequest.page"
              :options.sync="options"
              :no-data-text="$t(`global.noData`)"
              :no-results-text="$t(`global.noResults`)"
              hide-default-footer
              @update:options="handleUpdateOptions"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <expand-item :headers="headers" :bookingId="item.bookingId" :tab="getTabFilter()" @refethTrips="loadData()" />
              </template>

              <template v-slot:item.createdAt="{ item: { pickupTime } }">
                {{ handlePickupTimeDisplay(pickupTime, true) }}
              </template>

              <template v-slot:item.pickupTime="{ item: { pickupTime } }">
                {{ handlePickupTimeDisplay(pickupTime) }}
              </template>

              <template v-slot:item.name="{ item }">
                {{ getFullName(item) }}
              </template>

              <template v-slot:item.from="{ item }">
                <div class="d-flex flex-column">
                  <span
                    v-if="item.estimatedTimeArrival && item.status === 'ENROUTE'"
                    class="eta-tooltip"
                  >
                    {{
                      $t(`components.tripsHistory.list.eta`, {
                        amount: getArrivalTime(item.estimatedTimeArrival),
                      })
                    }}
                  </span>
                  <span class="address-column">{{ item.origin.address }}</span>
                </div>
              </template>

              <template v-slot:item.to="{ item }">
                <span
                  v-if="item.destinationEtaMinutes && item.status === 'DROPPINGOFF'"
                  class="eta-tooltip"
                >
                  {{
                    $t(`components.tripsHistory.list.eta`, {
                      amount: getArrivalTime(item.destinationEtaMinutes),
                    })
                  }}
                </span>
                <span class="address-column">{{ item.destination.address }}</span>
              </template>

              <template v-slot:item.payment="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <component v-bind:is="creditCardIcon(getPaymentIcon(item.payment))" v-on="on" />
                  </template>
                  <span>
                    {{ getPaymentDetails(item.payment) }}
                  </span>
                </v-tooltip>
              </template>

              <template v-slot:item.pricing="{ item }">
                <span v-if="item.pricing">
                  {{ formattedTotalPrice(item.pricingDetails.total) }}
                </span>
              </template>

              <template v-slot:item.companyName="{ item }">
                {{ item.companyName }}
              </template>

              <template v-slot:item.status="{ item: { status } }">
                <div :class="`status-container py-1 px-2 status-${status}`">
                  {{ status === "DROPPINGOFF" ? "POB" : status }}
                </div>
              </template>
            </v-data-table>
            <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
              <v-row no-gutters class="bs-per-page">
                <span>
                  {{ $t(`components.tripsHistory.perPage`) }}
                </span>
                <span
                  v-for="item in [10, 20, 30]"
                  :key="item"
                  class="px-1"
                  :class="{ 'active-page-count': item === itemsPerPage }"
                  @click="itemsPerPage = item"
                >
                  {{ item }}
                </span>
              </v-row>
              <simple-paginator
                :page="listRequest.page + 1"
                :has-more="listRequest.hasMoreBookings"
                @goToFirst="this.firstPage"
                @previousPage="this.previousPage"
                @nextPage="nextPage"
              />
            </div>
          </v-col>
        </v-row>
      </div>
      <passenger-search-dialog
        v-if="getSelectedCorporationId"
        :showDialog="showDialog"
        :businessAccountId="getSelectedCorporationId"
        @closeClick="showDialog = false"
        @passengerSelected="passengerSelected"
      >
      </passenger-search-dialog>
    </v-container>
  </loader>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import { iconLoader } from "@/helpers/iconsLoader";
import { getRoles } from "@/mixins/authentication.mixins";
import { creditCardIcon } from "@/mixins/creditCardIcons.mixins";
import { bookingChannelComputed } from "@/mixins/bookingChannel.mixins";
import { longDateTimeDay, time } from "@/helpers/dateTime";
import { CHANNEL_ADMIN, CORP_ADMIN } from "@/helpers/userRoles";
import PassengerSearchDialog from "@/components/tripsHistory/dialogs/PassengerSearchDialog.vue";
import CorporationsSelect from "@/components/corporations/CorporationsSelect.vue";
import SimplePaginator from "@/components/elements/SimplePaginator.vue";
import ExpandItem from "./ExpandItem.vue";
import { NumberFormatter } from "@icabbi/hi-dash";
import _ from "lodash";

export default {
  components: {
    ExpandItem,
    PassengerSearchDialog,
    CorporationsSelect,
    SimplePaginator,
    ...iconLoader.paymentMethod,
  },
  mixins: [bookingChannelComputed, getRoles, creditCardIcon],
  props: {
    totalTrips: {
      type: Number,
      required: true,
    },
    trips: {
      type: Array,
      required: true,
    },
    corporationsLoading: {
      type: Boolean,
      required: true,
    },
  },
  filters: { longDateTimeDay, time },
  data() {
    return {
      timer: null,
      CHANNEL_ADMIN,
      CORP_ADMIN,
      tabs: [
        { tab: "all-trips", filter: ["current", "past", "future", "cancelled"] },
        { tab: "in-progress", filter: ["current"] },
        { tab: "pre-bookings", filter: ["future"] },
        { tab: "completed", filter: ["past"] },
        { tab: "cancelled", filter: ["cancelled"] },
      ],
      loading: false,
      tab: 1,
      options: {},
      listRequest: {
        page: 0,
        hasMoreBookings: true,
      },
      itemsPerPage: 10,
      expanded: [],
      dispatchId: null,
      showDialog: false,
      selectedPassenger: null,
    };
  },
  created() {
    this.debouncedLoad = _.debounce(this.loadData, 500);
  },
  mounted() {
    if (this.$route.params.tab) {
      const routeTab = this.$route.params.tab;
      const activeTabl = this.tabs.find((val) => val.tab === routeTab);

      if (activeTabl) {
        this.tab = this.tabs.indexOf(activeTabl);
      } else {
        this.tab = 0;
      }
    }

    this.timer = setInterval(() => {
      this.loadData();
    }, 15000);
  },
  watch: {
    trips() {
      if (this.trips.length < this.itemsPerPage) {
        this.listRequest.hasMoreBookings = false;
      } else {
        this.listRequest.hasMoreBookings = true;
      }
    },
    tab: {
      handler() {
        this.listRequest.page = 0;
        this.loadData();
      },
    },
    itemsPerPage() {
      this.listRequest.page = 0;
      this.debouncedLoad();
    },
    dispatchId: {
      handler() {
        this.listRequest.page = 0;
        this.debouncedLoad();
      },
    },
    getSelectedCorporationId: {
      handler() {
        this.loadData();
      },
      immediate: true,
    },
    $route: {
      handler(val) {
        this.tab = val.meta.tab;
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapGetters("corporations", ["getSelectedCorporationId"]),
    ...mapGetters("users", ["getIsAdmin"]),
    ...mapGetters("bookingChannel", ["defaultLocale"]),
    headers() {
      return [
        {
          text: this.$t("components.tripsHistory.list.date"),
          align: "left",
          cellClass: "typography-table-text",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$t("components.tripsHistory.list.time"),
          align: "left",
          cellClass: "typography-table-text",
          sortable: true,
          value: "pickupTime",
          width: "75px",
        },
        {
          text: this.$t("components.tripsHistory.list.name"),
          align: "left",
          cellClass: "typography-table-text",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("components.tripsHistory.list.fleetName"),
          align: "left",
          cellClass: "typography-table-text",
          sortable: false,
          value: "companyName",
        },
        {
          text: this.$t("components.tripsHistory.list.from"),
          align: "left",
          cellClass: "typography-table-text pr-3",
          sortable: false,
          value: "from",
          width: "150px",
        },
        {
          text: this.$t("components.tripsHistory.list.to"),
          align: "left",
          cellClass: "typography-table-text",
          sortable: false,
          value: "to",
          width: "150px",
        },
        {
          text: this.$t("components.tripsHistory.list.payment"),
          align: "center",
          cellClass: "typography-table-text mr-2",
          sortable: false,
          value: "payment",
        },
        {
          text: this.$t("components.tripsHistory.list.amount"),
          align: "center",
          sortable: false,
          cellClass: "typography-table-text",
          value: "pricing",
        },
        {
          align: "center",
          sortable: false,
          value: "status",
          width: "170px",
        },
      ];
    },
  },
  methods: {
    getArrivalTime(eta) {
      const currentTime = moment().local().format("HH:mm");

      if (eta) {
        return moment(currentTime, "HH:mm").add(eta, "minutes").format("HH:mm");
      }

      return currentTime;
    },
    showDownloadModal() {
      this.$emit("showDownloadModal");
    },
    handlePickupTimeDisplay(pickupTime, showDate) {
      if (showDate) {
        return pickupTime ? longDateTimeDay(pickupTime) : "N/A";
      }
      return pickupTime ? time(pickupTime) : "N/A";
    },
    getFullName(item) {
      return `${item.userInfo.givenName} ${item.userInfo.familyName || ""}`;
    },
    getTabFilter() {
      return this.tabs[this.tab].filter;
    },
    formattedTotalPrice(totalPrice) {
      if (!totalPrice) return "N/A";
      const price = NumberFormatter.stringValueDecimalSeparator(
        totalPrice.toFixed(2),
        this.defaultLocale
      );
      return price ? `${this.currencySymbol} ${price}` : "N/A";
    },
    getPaymentIcon(payment) {
      if (!payment) return "cash";
      return payment.type === "creditCard" ? payment.paymentMethod.brand : payment.type;
    },
    getPaymentDetails(payment) {
      if (!payment) return this.$t("components.bookingForm.paymentMethod.cash");

      if (payment.type === 'cash') {
        return this.$t("components.bookingForm.paymentMethod.cash");
      }
      if (payment.type === "creditCard") {
        return this.$t("components.bookingForm.paymentMethod.creditCard", {
          number: payment.paymentMethod.number,
        });
      }
      if (payment.type === "businessAccount") {
        return this.$t("components.bookingForm.paymentMethod.account");
      }
    },
    handleUpdateOptions(options) {
      this.options = options;
      this.loadData();
    },
    async loadData() {
      if (!this.getSelectedCorporationId) return;

      const { sortBy, sortDesc } = this.options;
      const sort = (sortBy && sortBy[0]) || "createdAt";

      this.$emit("callLoadData", {
        page: this.listRequest.page,
        maxPerPage: this.itemsPerPage,
        dispatchId: this.dispatchId,
        sort: sortDesc && sortDesc[0] ? `-${sort}` : sort,
        filter: this.getTabFilter(),
        corporationId: this.getSelectedCorporationId,
        passengerId:
          this.selectedPassenger && this.selectedPassenger.passengerType === "PASSENGER"
            ? this.selectedPassenger.id
            : null,
        businessGuestId:
          this.selectedPassenger && this.selectedPassenger.passengerType === "GUEST"
            ? this.selectedPassenger.id
            : null,
      });
    },
    passengerSelected(passenger) {
      this.selectedPassenger = passenger;
      this.loadData();
      this.showDialog = false;
    },
    btnSearchPassengerClick() {
      if (this.selectedPassenger) {
        this.selectedPassenger = null;
        this.loadData();
        return;
      }
      this.showDialog = true;
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.loadData();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.loadData();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.loadData();
    },
  },
};
</script>

<style lang="scss">
.tabs-text {
  display: block;
  cursor: pointer;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #aaaaaa;
}

.tabs-text-active {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #333333;
}
</style>

<style scoped>
.address-column {
  display: block;
  width: 17vw;
  min-width: 17vw;
  line-break: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon-button-color {
  color: #b7b7b7 !important;
}
.v-list-item__title {
  font-size: 0.87rem !important;
}

.v-list {
  padding: 0 !important;
}
.v-list-item__title,
.v-list-item__subtitle {
  text-overflow: inherit !important;
}

.eta-tooltip {
  color: #ffa557;
}
</style>
