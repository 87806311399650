<template>
  <v-card>
    <v-card-title class="pa-8 pb-6">
      {{ $t('components.accountsImport.title') }}
    </v-card-title>
    <v-card-text class="px-8" v-if="!showProgress">
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.accountsImport.fleet') }}</div>
          <v-select outlined filled hide-details single-line return-object clearable
                    item-text="companyName"
                    v-model="fleet"
                    :items="fleets"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.accountsImport.file') }}</div>
          <v-file-input outlined filled hide-details show-size
                        prepend-icon=""
                        v-model="file"
                        accept=".csv, text/csv"
                        @change="loadFile"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>{{ $t('components.accountsImport.explanation') }} <i>{{ $t('components.accountsImport.export') }}</i>.</v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="px-8" v-else>
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.accountsImport.progress') }}</div>
          <v-progress-linear height="25"
                             v-model="importProgress"
          >
            <template v-slot:default="{ value }">
              {{ Math.ceil(value) }}%
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.accountsImport.summary.status') }}</div>
          <v-list class="summary">
            <v-list-item v-if="summary.importedCorporations">
              <v-list-item-content>{{ $t('components.accountsImport.summary.imported', { count: summary.importedCorporations }) }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="summary.importedPassengers">
              <v-list-item-content>{{ $t('components.accountsImport.summary.imported-passengers', { count: summary.importedPassengers }) }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="summary.importedUsers">
              <v-list-item-content>{{ $t('components.accountsImport.summary.imported-users', { count: summary.importedUsers }) }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="summary.existing">
              <v-list-item-content >{{ $t('components.accountsImport.summary.existing', { count: summary.existing }) }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="summary.failed">
              <v-list-item-content>{{ $t('components.accountsImport.summary.failed', { count: summary.failed }) }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end pa-8 pt-3">
      <v-btn text plain large class="pa-3 pl-0 cancel" @click="cancel"
             :disabled="importing"
      >
        {{ $t('components.accountsImport.cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn dark large class="pa-3" color="#333333" elevation="0"
             :disabled="!showProgress && !importData.length || importing"
             @click="doImport"
      >
        {{ showProgress
        ? $t('components.accountsImport.done')
        : importData.length
          ? $t('components.accountsImport.add_count', { count: importData.length })
          : $t('components.accountsImport.add') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { listFleetAccounts } from '@/mixins/fleetAccounts.mixins';
import Papa from 'papaparse';

const IMPORT_SUMMARY_DEFAULT = {
  importedCorporations: 0,
  importedUsers: 0,
  importedPassengers: 0,
  failed: 0,
  existing: 0,
};

export default {
  props: {
    fleets: {
      required: true,
      type: Array,
    },
  },
  mixins: [listFleetAccounts],
  data: () => ({
    importing: false,
    showProgress: false,
    file: null,
    importData: [],
    importProgress: 0,
    corporations: [],
    corporation: null,
    summary: { ...IMPORT_SUMMARY_DEFAULT },
    fleet: null,
  }),
  methods: {
    reset() {
      this.importProgress = 0;
      this.file = null;
      this.importData = [];
      this.showProgress = false;
      this.corporation = null;
      this.summary = { ...IMPORT_SUMMARY_DEFAULT };
    },
    cancel() {
      this.$emit('cancel');
      this.reset();
    },
    async loadFile(file) {
      if (file) {
        const { data } = await this.parseCsv(file);
        data.splice(0, 1); // delete first line
        this.importData = data.map((account, row) => (
          this.buildImportData(account, row)
        ));
      } else this.importData = [];
    },
    isEmpty: (str) => typeof str === 'string' && str.length === 0,
    buildImportData(data, row) {
      return {
        accountName: data[0],
        contactEmail: data[1],
        dispatchAccountNumber: this.isEmpty(data[2]) ? null : data[2],
        communicationLanguage: this.isEmpty(data[3]) ? 'en' : data[3],
        givenName: data[4],
        familyName: data[5],
        email: data[1],
        fleetId: this.fleet.id,
        phoneNumber: data[6],
        row: row + 1,
      };
    },
    parseCsv: (file) => new Promise((resolve, reject) => Papa.parse(file, { complete: resolve, error: reject })),
    async doImport() {
      if (this.showProgress) {
        this.cancel();
        return;
      }
      this.$emit('startImport', this.importData);

      this.cancel();
    },
    errorHandler(error) {
      if (error.code === 'USER-SERVICE-01') this.summary.existing++;
      else this.summary.failed++;
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  text-transform: uppercase
  font-size: 12px

.v-file-input::v-deep .v-input__prepend-outer
  margin-top: 12px !important

.v-btn
  text-transform: initial

.v-btn.cancel
  color: #888888

.v-btn.theme--dark.v-btn--disabled
  background-color: #272727 !important

.v-list.summary
  padding: 0

  .v-list-item
    min-height: initial

  .v-list-item__content
    padding: 6px 0
</style>
