import { business, Ic2BookingServiceClient } from '@icabbi/api-gateway-client';
import apiInstance from '@/helpers/apiInstance';

const defaultState = {
  employeeServiceClient: null,
  ic2BookingServiceClient: null,
  fleetAccountServiceClient: null,
};

const getters = {
  employeeServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.employeeServiceClient) {
      state.employeeServiceClient = apiInstance({
        dispatch,
        getters: rootGetters,
        Connector: business.EmployeeServiceConnector
      });
    }
    return state.employeeServiceClient;
  },
  ic2BookingServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.ic2BookingServiceClient) {
      state.ic2BookingServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: Ic2BookingServiceClient });
    }
    return state.ic2BookingServiceClient;
  },
  fleetAccountServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.fleetAccountServiceClient) {
      state.fleetAccountServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.FleetAccountServiceConnector });
    }
    return state.fleetAccountServiceClient;
  },
}

/**
 * @param {BusinessBookingRecipientV1Query} query
 * @returns {BusinessBookingRecipientV1Dto}
 */
function getBusinessBookingRecipients(context, query) {
  return context.getters.employeeServiceClient(context).findUsers(query);
}

/**
 * @param {Object} context
 * @param {Promise<BookingFleetAccountsQuery>} query
 */
function getFleetAccounts(context, query) {
  return context.getters.fleetAccountServiceClient(context).listFleetAccounts({
    corporationId: query.corporationId,
    fleetId: query.fleetId
  });
}

/**
 * 
 * @param {Object} context 
 * @param {BookingAccountFieldsQuery} query 
 * @returns {Promise<BusinessAccountFieldApiDto[]>}
 */
function getAccountFields(context, query) {
  return context.getters.ic2BookingServiceClient(context).getAccountFields(query);
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions: {
    getBusinessBookingRecipients,
    getFleetAccounts,
    getAccountFields,
  },
};
