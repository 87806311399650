<template>
  <v-flex v-if="accountFields">
    <v-row v-for="(field, i) in accountFields" :key="field.id">
      <v-col>
        <h5 class="section-title pb-2">{{ field.title }}</h5>
        <validated-field
          v-slot="{ attrs }"
          :name="field.title"
          v-model="accountFields[field.id].value"
          :validator="$v.accountFields[field.id] ? $v.accountFields[field.id].value : $v"
        >
          <v-select
            outlined
            filled
            single-line
            hide-no-data
            hide-details
            v-if="field.type === 'select'"
            v-bind="attrs"
            v-model="accountFields[field.id].value"
            :items="field.values"
            label=""
          >
            <template v-bind="attrs" v-if="attrs.hasErrors" v-slot:append>
              <error-tooltip-auto :errors="attrs.errorMessages" />
            </template>
          </v-select>
          <v-text-field
            outlined
            filled
            single-line
            clearable
            hide-details
            v-else
            v-bind="attrs"
            v-model="accountFields[field.id].value"
            label=""
          >
            <template v-bind="attrs" v-if="attrs.hasErrors" v-slot:append>
              <error-tooltip-auto :errors="attrs.errorMessages" />
            </template>
          </v-text-field>
        </validated-field>
        <v-divider class="my-6 mb-0" v-if="i + 1 < accountFields.length" />
      </v-col>
    </v-row>

    <v-flex class="mt-4 d-flex justify-space-between">
      <v-btn color="#333333" outlined @click="onBackClick">
        {{ $t("components.bookingForm.back") }}
      </v-btn>

      <v-btn color="#333333" class="white--text" depressed @click="onAccountStepSubmit">
        {{ $t("components.bookingForm.book") }}
      </v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "AccountFieldsStepComponent",
  props: {
    presenter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      account: null,
      accountFields: [],
    };
  },
  async created() {
    this.accountFields = this.presenter.interactor.bookingFormModel.accountFields.reduce(
      (result, field) => {
        if (field.active) result[field.id] = field; // eslint-disable-line no-param-reassign
        return result;
      },
      {}
    );
  },
  methods: {
    onBackClick() {
      this.presenter.onBackClick();
    },
    onAccountStepSubmit() {
      this.presenter.onAccountStepSubmit(this.accountFields);
    },
  },
  validations() {
    return {
      accountFields: Object.entries(this.accountFields).reduce((acc, [_, field]) => {
        if (field.validation?.required) {
          acc[field.id] = { value: { required } };
        }
        return acc;
      }, {}),
    };
  },
};
</script>

<style scoped></style>
