<template>
  <v-autocomplete
    ref="autocomplete"
    outlined
    filled
    hide-details
    hide-no-data
    :items="getCorporationsList"
    :item-text="itemText"
    :placeholder="$t('components.corporationSelect.selectCorporation')"
    :disabled="disabled"
    :clearable="isClearable"
    :loading="loading"
    v-model="selectedCorporation"
    class="bs-search-item"
    background-color="#ffffff"
    return-object
    :search-input.sync="search"
  >
    <template v-slot:prepend-inner>
      <department-icon/>
    </template>
    <template v-slot:append>
      <v-icon>mdi-chevron-down</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import { iconLoader } from '@/helpers/iconsLoader';
import { getRoles } from '@/mixins/authentication.mixins';
import _ from "lodash";

const defaultState = {
  page: 0,
  maxPerPage: 10,
  sort: 'name',
};

export default {
  name: 'corporations-selector',
  mixins: [getRoles],
  components: {
    ...iconLoader.sideMenu,
  },
  props: {
    setSelected: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    prefill: {
      type: Boolean,
      required: false,
      default: true,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    isClearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedCorporation: null,
      searchParams: defaultState,
      loading: false,
      search: '',
    };
  },
  created() {
    this.debouncedLoad = _.debounce(this.loadCorporations, 500);
  },
  async mounted() {
    await this.loadCorporations();
    await this.checkIfHasLocalCorporation();
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId', 'getCorporationsList']),
    ...mapGetters("users", ["getUserRole"]),
  },
  watch: {
    selectedCorporation(val) {
      if (((val || this.getUserRole === 'bookingChannelAdmin') && this.setSelected)) {
        const corporationId = val?.id || val;
        this.$store.commit('corporations/setCorporationId', corporationId);
      }

      // This is used in TripDownloadForm
      this.$emit('onSelectedItem', val);
    },
    search(val) {
      if (!val || this.isValFromExistingList(val)) return;

      this.debouncedLoad(val, true);
    }
  },
  methods: {
    isValFromExistingList(val) {
      return this.getCorporationsList.find((el) => el?.name === val);
    },
    async loadCorporations(val) {
      if (val !== undefined) {
        this.searchParams = { ...defaultState, name: val };
      }

      this.loading = true;
      try {
        const { data } = await this.$store.dispatch('corporations/listCorporations', { ...this.searchParams });
        this.$store.commit('corporations/setCorporations', data.filter((el) => el.status === 'active'));
      } catch (e) {
        this.$store.commit('corporations/setCorporations', []);
      } finally {
        this.loading = false;
      }
    },
    async checkIfHasLocalCorporation() {
      if (this.getCorporationsList.length === 0) return;
      const localCorporation = localStorage.getItem('corporationName');

      if (!localCorporation) {
        this.selectedCorporation = this.getCorporationsList[0];
        this.$store.commit('corporations/setLocalCorporation', this.selectedCorporation);
        return;
      }

      try {
        const { data } = await this.$store.dispatch(
          'corporations/findCorporationByName',
          { ...this.searchParams, name: localCorporation }
        );

        if (data.length) {
          this.selectedCorporation = data.find((el) => el.name === localCorporation);
          this.$store.commit('corporations/setLocalCorporation', this.selectedCorporation);
        } else {
          this.selectedCorporation = this.getCorporationsList[0];

          this.$store.commit('corporations/setLocalCorporation', this.selectedCorporation);
          localStorage.setItem('corporationName', this.selectedCorporation.name);
        }
      } catch (e) {
        this.selectedCorporation = this.getCorporationsList[0];
        this.$store.commit('corporations/setLocalCorporation', this.selectedCorporation);
        return;
      }
    },
  }
};
</script>
