<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat id="employee-card" class="pa-5">
        <v-card-title id="employee-title" class="form-title">
          <span>{{ $t(`components.employeeForm.guest.${mode}.title`) }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="forceClear()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text id="employee-form" class="form-content">
          <v-row>
            <v-col cols="6">
              <span class="text-uppercase input-label mb-2">{{ $t(`components.employeeForm.default.firstName`) }}</span>
              <v-text-field
                :placeholder="$t(`components.employeeForm.default.givenName`)"
                :class="{ 'employee-field-error': $v.guest.givenName.$error }"
                v-model="guest.givenName"
                class="employee-field mt-5"
                height="44"
                outlined
                required
                clearable
                hide-details
                @input="$v.guest.givenName.$touch()"
                @blur="$v.guest.givenName.$touch()"
              >
                <template v-if="$v.guest.givenName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.guest.givenName"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <span class="text-uppercase input-label mb-2">{{ $t(`components.employeeForm.default.lastName`) }}</span>
              <v-text-field
                v-model="guest.familyName"
                :class="{ 'employee-field-error': $v.guest.familyName.$error }"
                :placeholder="$t(`components.employeeForm.default.familyName`)"
                class="employee-field mt-5"
                outlined
                clearable
                required
                hide-details
                no-filter
                @input="$v.guest.familyName.$touch()"
                @blur="$v.guest.familyName.$touch()"
              >
                <template v-if="$v.guest.familyName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.guest.familyName"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col>
              <span class="text-uppercase input-label">{{ $t(`components.employeeForm.default.email`) }}</span>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="guest.email"
                :placeholder="$t(`components.employeeForm.default.emailPlaceholder`)"
                :class="{ 'employee-field-error': $v.guest.email.$error }"
                class="employee-field email-input"
                outlined
                required
                clearable
                hide-details
                no-filter
                @input="$v.guest.email.$touch()"
                @blur="$v.guest.email.$touch()"
              >
                <template v-if="$v.guest.email.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.guest.email"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <span class="input-label">{{ $t(`components.employeeForm.default.phone`) }}</span>
            </v-col>

            <v-col cols="3">
              <v-combobox
                ref="countryCodeInput"
                outlined
                filled
                hide-details
                hide-no-data
                append-icon=""
                class="employee-field"
                :disabled="mode === 'edit'"
                :cache-items="false"
                :item-text="(item) => [item.dialCode, item.name]"
                :value="selectedCountryCode"
                :items="allCountries"
                :placeholder="$t('components.userAutoComplete.placeholderCountryCode')"
                @input="onDialCodeSelectedFromList($event)"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content class="d-flex">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-title>{{ item.dialCode }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="{ item }">
                  {{ item.dialCode || item }}
                </template>

                <template v-slot:append>
                  <v-tooltip
                    v-if="shouldShowInvalidCountryCodeError"
                    bottom
                    color="error"
                    dark
                    nudge-bottom="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <p class="mb-0">
                      {{ $t("components.bookingForm.validation.invalidCountryCode") }}
                    </p>
                  </v-tooltip>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="9">
              <v-text-field
                ref="employeePhoneInput"
                v-model="guest.phone"
                :disabled="mode === 'edit'"
                :placeholder="$t('components.userAutoComplete.placeholderPhone')"
                class="employee-field"
                :rules="[
                  (v) =>
                    /^[+0-9]*$/.test(v.phone || v) ||
                    $t('components.bookingForm.validation.mobile'),
                ]"
                outlined
                clearable
                hide-details
                no-filter
              >
                <template v-slot:append>
                  <v-tooltip
                    v-if="$refs.employeePhoneInput && $refs.employeePhoneInput.hasError"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <p
                      v-for="(error, index) in $refs.employeePhoneInput.errorBucket"
                      :key="index"
                      class="mb-0"
                    >
                      {{ $t(error) }}
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-4">
          <v-btn
            class="text-capitalize"
            color="grey darken-1"
            text
            @click="forceClear()"
          >
            {{ $t(`components.employeeForm.guest.${mode}.cancel`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize white--text"
            color="#333333"
            depressed
            :disabled="$v.guest.$invalid || (!isPhoneValid && !!guest.phone) || isLoading || (!formChanged && mode === 'edit')"
            @click="submitDialog"
          >
            {{ $t(`components.employeeForm.guest.${mode}.agree`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEqual } from 'lodash';
import allCountries from "country-codes-list";
import { phoneNumberValidation } from "@/helpers/rules/phoneNumberValidation";
import { PhoneNumberUtil } from "@/core/util/PhoneNumberUtil";

import employeeValidation from '@/helpers/rules/employeeValidation';

import ErrorTooltip from '@/components/elements/ErrorTooltip.vue';

const blankState = {
  businessAccountId: null,
  givenName: null,
  familyName: null,
  phone: '',
  email: null,
};

export default {
  name: 'CreateGuestForm',
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    guestData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ErrorTooltip,
  },
  validations: {
    ...employeeValidation,
  },
  data() {
    return {
      guest: {},
      isLoading: false,
      formChanged: false,
      selectedCountryCode: null,
      allCountries: allCountries
        .all()
        .map((country) => {
          return {
            dialCode: `+${country.countryCallingCode}`,
            iso2: country.countryCode.toLowerCase(),
            name: country.countryNameEn,
            flag: country.flag,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    };
  },
  mounted() {
    if (this.mode !== "edit") {
      this.prefillCountryCode();
    }
  },
  watch: {
    guestData: {
      handler() {
        if (this.mode === 'edit') {
          this.guest = { ...this.guestData };

          if (this.guest.phoneNumber) {
            const googlePhoneNumber = PhoneNumberUtil.parseToGooglePhoneWithoutRegionCode(
              this.guest.phoneNumber
            );
            this.selectedCountryCode = "+" + googlePhoneNumber.getCountryCode();
            this.guest.phone = googlePhoneNumber.getNationalNumber().toString();
          }
        } else {
          this.guest = { ...blankState, ...this.guestData };
        }
      },
      immediate: true,
    },
    guest: {
      handler(val) {
        if (this.mode === 'edit') {
          this.formChanged = !isEqual(this.getMutableFields(val), this.getMutableFields(this.guestData));
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      countryCode: 'bookingChannel/defaultCountry',
    }),
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    getActionURL() {
      return this.mode === 'edit' ? 'businessGuest/updateGuestPassenger' : 'businessGuest/createGuestPassenger';
    },
    getActionPayload() {
      if (this.mode === 'edit') {
        return {
          id: this.guestData.id,
          payload: {
            email: this.guest.email,
            familyName: this.guest.familyName,
            givenName: this.guest.givenName,
          },
        };
      }
      const phoneNumber = `${this.selectedCountryCode}${this.guest.phone}`.trim();
      return {
        email: this.guest.email,
        familyName: this.guest.familyName,
        givenName: this.guest.givenName,
        phoneNumber: !this.guest.phone ? null : phoneNumber,
        isFakePhoneNumber: !this.guest.phone,
        businessAccountId: this.getSelectedCorporationId,
      };
    },
    shouldShowInvalidCountryCodeError() {
      if (!this.selectedCountryCode) {
        return false;
      }

      return !this.allCountries.find((country) => country.dialCode === this.selectedCountryCode)
        ?.iso2;
    },
    isPhoneValid() {
      if (!this.guest || this.guest.isFakePhoneNumber) {
        return true;
      }
      // If the user entered a phone number, then country code is required
      if (this.guest.phone && !this.selectedCountryCode) {
        return false;
      }

      // At this point, we know that the user has entered a phone number and a country code
      return !/[^\d]/.test(this.guest.phone) && this.allCountries
        .filter((country) => country.dialCode === this.selectedCountryCode)
        .map((country) => phoneNumberValidation(this.guest.phone, country.iso2))
        .some((isValid) => isValid);
    },
    isCountryCodeValid() {
      return !!this.selectedCountryCode;
    },
  },
  methods: {
    forceClear(submitted, res) {
      this.guest = { ...blankState };

      this.isLoading = false;

      this.$v.$reset();
      this.$emit('closeDialog', submitted, res);
    },
    getMutableFields(val) {
      return {
        givenName: val.givenName,
        familyName: val.familyName,
        email: val.email,
      };
    },
    submitDialog() {
      this.$v.guest.$touch();

      if (this.$v.guest.$invalid) {
        return;
      }

      this.isLoading = true;
      this.$store.dispatch(this.getActionURL, this.getActionPayload)
        .then((res) => {
          if (!res) {
            this.$emit('closeDialog', false);
          }

          this.forceClear(true, res);
          this.isLoading = false;
        });
    },
    prefillCountryCode() {
      this.selectedCountryCode = this.allCountries.find(
        (country) => country.iso2 === this.countryCode
      )?.dialCode;
    },
        /**
     *
     * @param {String | null | undefined} code
     */
     onDialCodeSelectedFromList(code) {
      if (code && code.dialCode) {
        this.selectedCountryCode = code.dialCode;
        return;
      }

      if (code) {
        this.selectedCountryCode = code.startsWith("+") ? code : `+${code}`;
        return;
      }

      this.selectedCountryCode = code;
      this.employee.dialCode = code;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/phoneInput.scss';

  .input-label {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #888888;
  }

  .employee-field-error {
    border: 1px solid #EB5757 !important;
  }

  .employee-field {
    background: #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    line-height: 15px;
    border: 1px solid #EEEEEE;
    letter-spacing: -0.005em;
    color: #333333;
  }
</style>

<style lang="scss">
.employee-field {
  & fieldset {
    background: #f8f8f8;
  }
}
</style>
