<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <div class="bs-table-header pt-8">
      <v-row justify="space-between" class="align-end mb-3 px-4">
        <v-col cols="12" class="col-without-top-padding col-without-bottom-padding">
          <h1 class="bs-header-title">
            {{ $t('components.paymentMethods.title') }}
          </h1>
        </v-col>
        <v-col md="8" class="col-without-top-padding col-without-bottom-padding v-tabs-container">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            plain
            class="tabs-text tabs-text-active mr-6"
            :disabled="tabs.length === 1"
          >
            {{ $t(`components.paymentMethods.tabs.${tab.tab}.title`) }}
          </div>
        </v-col>
        <v-col sm="6" md="4" lg="3" class="d-flex align-center col-without-top-padding col-without-bottom-padding justify-end">
          <v-btn class="btn btn-default" @click="addCard" height="36px" depressed>
            <v-icon left>mdi-plus</v-icon>
            {{ $t('components.paymentMethods.search.buttonText') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="bs-table-table">
      <v-row no-gutters>
        <v-col class="col-without-top-padding table-color">
          <v-data-table
            class="bs-table elevation-0"
            :items="cardsToList"
            :headers="headers"
            :server-items-length="totalCards"
            :loading="corporationsLoading"
            :multi-sort="false"
            :must-sort="true"
            :single-expand="true"
            :page.sync="listRequest.page"
            :options.sync="options"
            :no-data-text="$t(`global.noData`)"
            :no-results-text="$t(`global.noResults`)"
            hide-default-footer
            @update:options="handleUpdateOptions"
            @page-count="pageCount = $event"
          >

            <template v-slot:item.brand="{ item: { brand, number, type } }">
              <div v-if="type === 'creditCard'" class="d-flex align-center">
                <component v-bind:is="creditCardIcon(brand)"/>
                <span class="ml-3"> ending with {{number}}</span>
              </div>
              <div v-else class="d-flex align-center">
                <component v-bind:is="creditCardIcon(brand)"/>
                <span class="ml-3">{{ $t(`components.bookingForm.paymentMethod.${brand}`) }}</span>
              </div>
            </template>

            <template v-slot:item.expiration="{ item: { expiration } }">
              <span v-if="expiration">{{formatExpiry({year: expiration.year, month: expiration.month})}}</span>
            </template>

            <template v-slot:item.cardHolderName="{ item: { cardHolderName } }">
              <span>{{cardHolderName}}</span>
            </template>

            <template v-slot:item.defaultPayment="{ item }">
              <v-checkbox
                :ripple="false"
                v-model="item.defaultPayment"
                color="#47D061"
                class="ma-0"
                hide-details
                on-icon="mdi-check-circle-outline"
                off-icon="mdi-checkbox-blank-circle-outline"
                @click="handleDefaultPaymentMethod(item)"
              />
            </template>

            <template v-slot:item.status="{ item: { id, type } }">
              <delete-icon
                v-if="type === 'creditCard'"
                @click="confirmDelete(id)"
                class="icon-control-list"
              />
            </template>
          </v-data-table>
          <div class="d-flex flex-row-reverse align-center py-2 px-4 bs-table-footer">
            <simple-paginator
              :page="listRequest.page + 1"
              :has-more="listRequest.hasMoreBookings"
              @goToFirst="firstPage"
              @previousPage="previousPage"
              @nextPage="nextPage"
            />
          </div>
        </v-col>
      </v-row>
    </div>

    <confirm-dialog
      ref="confirmDialog"
      :data="modalData"
      :okButtonText="$t('components.paymentMethods.delete')"
      @handleCancelButton="cancelModal"
      @handleOkButton="deleteCard"
    >
      <v-card-title class="text-h5">
        {{$t('components.paymentMethods.dialog.title')}}
      </v-card-title>
      <v-card-text>
        <p class="font-weight-bold">{{$t('components.paymentMethods.dialog.message')}}</p>
        <p>{{$t('components.paymentMethods.dialog.sub_message')}}</p>
      </v-card-text>
    </confirm-dialog>
  </v-container>
</template>

<script>
import { longDateTimeDay, time } from '@/helpers/dateTime';
import { iconLoader } from '@/helpers/iconsLoader';
import { getRoles } from '@/mixins/authentication.mixins';
import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';
import { useDefaultPaymentMethods } from '@/mixins/defaultPaymentMethod.mixins';
import ConfirmDialog from '@/components/elements/ConfirmDialog.vue';
import SimplePaginator from "@/components/elements/SimplePaginator.vue";
import moment from 'moment';

const { generateCardsList, setDefaultPayment, removeDefaultPayment } = useDefaultPaymentMethods();
export default {
  props: {
    totalCards: {
      type: Number,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    corporationsLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: { ...iconLoader.elements, ConfirmDialog, SimplePaginator },
  filters: { longDateTimeDay, time },
  mixins: [creditCardIcon, getRoles],
  data() {
    return {
      tabs: [
        { tab: 'cards', filter: [] },
      ],
      cardsToList: [],
      showAddCard: false,
      tab: 0,
      listRequest: {
        page: 0,
        hasMoreBookings: true,
      },
      options: {},
      expanded: [],
      dispatchId: null,
      modalData: null,
    };
  },
  watch: {
    cards: {
      handler(val) {
        if (val.length < 10) {
          this.listRequest.hasMoreBookings = false;
        } else {
          this.listRequest.hasMoreBookings = true;
        }

        this.cardsToList = generateCardsList(val);
      },
      immediate: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('components.paymentMethods.tableHeadings.paymentMethod'),
          align: 'left',
          sortable: true,
          value: 'brand',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.paymentMethods.tableHeadings.expiry'),
          align: 'left',
          sortable: false,
          value: 'expiration',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.paymentMethods.tableHeadings.cardName'),
          align: 'left',
          sortable: false,
          value: 'cardHolderName',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.paymentMethods.tableHeadings.defaultPayment'),
          align: 'left',
          sortable: false,
          value: 'defaultPayment',
          class: 'text-uppercase',
        },
        {
          align: 'right',
          sortable: false,
          value: 'status',
          class: 'text-uppercase',
        },
      ];
    },
  },
  methods: {
    handleUpdateOptions(options) {
      this.options = options;
      this.loadData();
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.loadData();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.loadData();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.loadData();
    },
    handleDefaultPaymentMethod(item) {
      setDefaultPayment(item);
    },
    formatExpiry({ month, year }) {
      return moment(`${year}-${month}-1`).format('YYYY/MM');
    },
    async loadData() {
      this.$emit('callLoadData');
    },
    addCard() {
      this.$emit('addCard');
    },
    deleteCard(data) {
      this.$emit('deleteCard', data);
      removeDefaultPayment();
      this.$refs.confirmDialog.close();
    },
    cancelModal() {
      this.modalData = null;
      this.$refs.confirmDialog.close();
    },
    confirmDelete(id) {
      this.modalData = {
        id,
      };
      this.$refs.confirmDialog.open();
    },
  },
};
</script>
