<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat id="employee-card" class="pa-5">
        <v-card-title id="employee-title" class="form-title">
          <span>{{ $t(`components.usersForm.${mode}.title`) }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="forceClear()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text id="employee-form" class="form-content">
          <v-row>
            <v-col cols="12">
              <span class="text-uppercase input-label">{{ $t(`components.usersForm.default.name`) }}</span>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :placeholder="$t(`components.usersForm.default.givenName`)"
                :class="{ 'employee-field-error': $v.user.givenName.$error }"
                v-model.trim="$v.user.givenName.$model"
                class="employee-field"
                height="44"
                outlined
                clearable
                hide-details
              >
                <template v-if="$v.user.givenName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.user.givenName"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="$v.user.familyName.$model"
                :class="{ 'employee-field-error': $v.user.familyName.$error }"
                :placeholder="$t(`components.usersForm.default.familyName`)"
                class="employee-field"
                outlined
                clearable
                hide-details
                no-filter
              >
                <template v-if="$v.user.familyName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.user.familyName"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col>
              <span class="text-uppercase input-label">{{ $t(`components.usersForm.default.email`) }}</span>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model.trim="$v.user.email.$model"
                :placeholder="$t(`components.usersForm.default.emailPlaceholder`)"
                :class="{ 'employee-field-error': $v.user.email.$error }"
                class="employee-field email-input"
                outlined
                clearable
                hide-details
                no-filter
              >
                <template v-if="$v.user.email.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.user.email"/>
                </template>
              </v-text-field>
            </v-col>

            <v-col>
              <span class="text-uppercase input-label">{{ $t(`components.usersForm.default.commLang`) }}</span>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="languageOptions"
                v-model="$v.user.communicationLanguage.$model"
                class="language-field"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="value"
                outlined
                hide-details
                background-color="#f8f8f8"
                :class="{ 'input-field-error': $v.user.communicationLanguage.$error }"
              >
                <template v-if="$v.user.communicationLanguage.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.user.communicationLanguage"/>
                </template>
              </v-select>
            </v-col>

            <v-col>
              <span class="text-uppercase input-label">{{ $t(`components.usersForm.default.role`) }}</span>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="roles"
                v-model="$v.user.role.$model"
                class="employee-field"
                item-text="name"
                item-value="value"
                :menu-props="{ bottom: true, offsetY: true }"
                :class="{ 'input-field-error': $v.user.role.$error }"
                outlined
                hide-details
              >
                <template v-if="$v.user.role.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.user.role"/>
                </template>
              </v-select>
            </v-col>

            <v-col v-if="isCorporationAdmin">
              <span class="text-uppercase input-label">{{ $t(`components.usersForm.default.corporations`) }}</span>
            </v-col>

            <v-col cols="12" v-if="isCorporationAdmin">
              <v-autocomplete
                outlined
                filled
                icon="$icons_product_corporation"
                item-text="name"
                item-value="id"
                height="48"
                class="employee-field-autocomplete"
                v-model="activeUserCorporations"
                :placeholder="$t('components.usersForm.default.corporations')"
                :loading="corporationsLoading"
                :items="completeCorporationsList"
                :multiple="true"
                :search-input.sync="search"
                hide-details
                return-object
              >
                <template v-if="$v.user.corporations.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.user.corporations"/>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col v-if="user.corporations.length > 0" cols="12" class="corporation-list-wrapper">
              <div
                v-for="(corporation, index) in activeUserCorporations"
                :key="index"
                class="d-flex align-center justify-space-between corporation-item"
              >
                <div>
                  <p v-if="corporation.name" class="mb-1 text-body-1">{{ corporation.name }}</p>
                  <span v-if="corporation.code" class="text-subtitle-2">{{ corporation.code }}</span>
                  <span
                    v-if="corporation.contactEmail"
                    class="text-subtitle-2"
                  > - {{ corporation.contactEmail }}</span>
                </div>

                <v-btn
                  @click="removeCorporation(corporation.id)"
                  class="corporation-remove-btn"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" v-if="mode === 'add' && isCorporationAdmin" class="py-0">
              <v-checkbox
                v-model="user.createPassengerAccount"
                :label="$t(`components.usersForm.default.corporAdminCheckbox`)"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col v-if="user.createPassengerAccount">
              <span class="text-uppercase input-label">{{ $t(`components.usersForm.default.phone`) }}</span>
            </v-col>

            <v-col v-if="user.createPassengerAccount" cols="12">
              <vue-tel-input
                ref="user-phone-input"
                filled
                mode="national"
                :class="{ 'employee-field-error': $v.phoneNumber.$error }"
                :autoDefaultCountry="false"
                :defaultCountry="countryCode"
                :dropdownOptions="{ showDialCodeInSelection: true, showFlags: true, showSearchBox: true }"
                :inputOptions="{ showDialCode: false }"
                v-model.trim="phoneNumber"
              >
                <template v-if="$v.phoneNumber.$error && !$v.phoneNumber.required" v-slot:icon-right>
                  <div class="mr-2 mt-2">
                    <error-tooltip :validator="$v.phoneNumber"/>
                  </div>
                </template>
              </vue-tel-input>
            </v-col>
            <v-col v-if="addPassengerErrors" cols="12">
              <v-alert
                dense
                outlined
                type="error"
                class="mb-0"
              >
                {{addPassengerErrors.messageTitle}}
              </v-alert>
              <v-list dense>
              <v-list-item three-line  v-for="(error, index) in addPassengerErrors.details" :key="index" class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ error.businessAccountName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ error.translatedErrorMessage }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <p v-if="user.givenName && mode !== 'edit'" class="mb-0">
                <span class="font-weight-bold text--black">{{ `${user.givenName}` }}</span>
                {{ $t(`components.usersForm.default.confirmation`, { attribute: companyName }) }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-4">
          <v-btn
            class="text-capitalize"
            color="grey darken-1"
            text
            @click="forceClear()"
          >
            {{ $t(`components.usersForm.${mode}.cancel`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize white--text"
            color="#333333"
            depressed
            :disabled="validateForm"
            @click="submitForm"
          >
            {{ $t(`components.usersForm.${mode}.agree`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </loader>
</template>

<script>
import _ from "lodash";
import { getRoles } from '@/mixins/authentication.mixins';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import { languageMethods, languageList } from '@/mixins/language.mixins';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import ErrorTooltip from '@/components/elements/ErrorTooltip.vue';
import { CHANNEL_ADMIN, CORP_ADMIN } from '@/helpers/userRoles';
import userValidationData from '@/helpers/rules/userValidationData';
import { mapGetters, mapMutations } from 'vuex';

const blankState = {
  givenName: '',
  familyName: '',
  email: '',
  phone: '',
  role: '',
  communicationLanguage: 'en',
  corporations: [],
  createPassengerAccount: false,
};
export default {
  name: 'user-form',
  mixins: [bookingChannelComputed, getRoles, entityLoader, languageMethods, languageList],
  props: {
    mode: {
      type: String,
      default: 'add',
      required: true,
    },
    dataInfo: {
      type: Object,
      required: false,
    },
    listCorporations: {
      type: Array,
      required: true,
    },
    completeCorporationsList: {
      type: Array,
      required: true,
    },
    corporationsLoading: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    ErrorTooltip,
  },
  validations: {
    ...userValidationData,
  },
  data() {
    return {
      error: null,
      isLoading: false,
      user: null,
      search: null,
      saveLoading: false,
      roles: [
        { name: this.$t('components.usersForm.default.corpoAdmin'), value: CORP_ADMIN },
        { name: this.$t('components.usersForm.default.channelAdmin'), value: CHANNEL_ADMIN },
      ],
      pageCorporations: 1,
      corporations: [],
      phone: null,
      phoneNumber: null,
      cachedPhoneNumber: null,
      addPassengerErrors: false,
      listOfCorporationsToRemove: [],
    };
  },
  computed: {
    ...mapGetters({
      countryCode: 'bookingChannel/defaultCountry',
    }),
    countryCode: {
      get() {
        return this.$store.getters['bookingChannel/defaultCountry'];
      },
      set(newCountryCode) {
        this.setCountryCode(newCountryCode);
      },
    },
    activeUserCorporations: {
      get() {
        if (this.listOfCorporationsToRemove.length > 0) {
          return this.user.corporations.filter((corporation) => !this.listOfCorporationsToRemove.includes(corporation.id));
        }

        return this.user.corporations;
      },
      set(corporation) {
        this.user.corporations = corporation;
      }
    },
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    validateForm() {
      return (this.user.createPassengerAccount && this.$v.phoneNumber.$invalid) || this.$v.user.$invalid;
    },
    isCorporationAdmin() {
      return this.user.role === CORP_ADMIN;
    },
  },
  watch: {
    dataInfo: {
      handler(val) {
        if (val) {
          this.user = {
            ...blankState,
            ...val,
            role: val.roles.length ? val.roles[0].name : '',
            corporations: val.roles[0].name === 'corporationAdmin'
              ? _.compact(val.roles.map((role) => this.completeCorporationsList.find((corporation) => corporation.id === role.corporationId)))
              : []
          };
        } else {
          this.user = { ...blankState };
        }
      },
      immediate: true,
    },
    search(val) {
      if (!val) {
        return this.querySelections();
      }
      return val && val.length >= 2 && this.querySelections(val);
    },
    corporations(val) {
      this.user.corporations = val;
    },
    phoneNumber(val) {
      const entryPhoneLength = val.length;

      if (val) this.$v.phoneNumber.$touch();

      if (this.cachedPhoneNumber && entryPhoneLength > this.cachedPhoneNumber.length) {
        this.phoneNumber = this.cachedPhoneNumber;
        return;
      }

      if (this.isPhoneNumberValid()) {
        this.setCountryCode(this.$refs['user-phone-input'].phoneObject.countryCode);
        this.phone = this.$refs['user-phone-input'].phoneObject.number;
        this.cachedPhoneNumber = this.$refs['user-phone-input'].phoneObject.number;
      }
    },
  },
  created() {
    this.populateLanguagesSelectList();
  },
  methods: {
    ...mapMutations('bookingChannel', ['setCountryCode']),
    isPhoneNumberValid() {
      if (this.$refs && this.$refs['user-phone-input'] && this.$refs['user-phone-input'].phoneObject) {
        return this.$refs['user-phone-input'].phoneObject.valid;
      }
      return false;
    },
    querySelections(value) {
      this.$emit('searchForCorporations', value);
    },
    removeCorporation(corporationId) {
      this.listOfCorporationsToRemove.push(corporationId);
    },
    // eslint-disable-next-line consistent-return
    async submitForm() {
      if (this.$v.user.$invalid || this.$v.phoneNumber.$invalid) {
        this.$v.$touch();
        return false;
      }
      this.isLoading = true;
      let result = null;

      const corporationIds = this.user.corporations.map((corporation) => corporation.id);

      if (this.listOfCorporationsToRemove.length > 0) {
        this.listOfCorporationsToRemove.forEach((corporationId) => {
          const index = corporationIds.indexOf(corporationId);
          if (index > -1) {
            corporationIds.splice(index, 1);
          }
        });
      }

      const url = this.mode === 'edit' ? 'users/updateUser' : 'users/createUser';
      result = await this.actionPost(url, {
        ...this.user,
        corporations: corporationIds,
      });

      if (result) {
        if (this.user.createPassengerAccount) {
          const result = await this.$store.dispatch('businessPassenger/createBusinessPassenger', {
            givenName: this.user.givenName,
            familyName: this.user.familyName,
            email: this.user.email,
            phone: this.phone || this.phoneNumber.trim(),
            corporationIds: corporationIds,
            returnErrorDetails: true
          });

          if(result.details) {
            this.addPassengerErrors = result.details;
            this.isLoading = false;
          } else {
            this.forceClear(true);
          }
        }
      } else {
        this.isLoading = false;
      }

      this.isLoading = false;
      this.forceClear(true);
    },

    forceClear(submitted) {
      if (this.mode === 'add') {
        this.user = { ...blankState };
      }

      this.isLoading = false;

      this.user = { ...blankState };
      this.listOfCorporationsToRemove = [];
      this.$v.$reset();
      this.$emit('closeDialog', submitted);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/phoneInput.scss';
  .input-label {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #888888;
  }

  .employee-field {
    background: #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    line-height: 15px;
    border: 1px solid #EEEEEE;
    letter-spacing: -0.005em;
    color: #333333;
  }

  .employee-field:focus-within {
    border-color: #47D061;
  }

  .employee-field-error {
    border: 1px solid #EB5757 !important;
  }

  .form-content {
    padding-bottom: 4px;
  }

  .loader-flex {
    margin: 0% !important;
    height: 528px;
  }

  .corporation-list-wrapper {
    max-height: 250px;
    overflow-y: auto;
  }

  .corporation-item {
    background: #F8F8F8;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;

    & p {
      color: #333333;
    }
  }
</style>

<style lang="scss">
.employee-field-autocomplete {
  .v-input__control {
    border-radius: 6px;
    // border: 1px solid #EEEEEE;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
    color: #333333;
    overflow: auto;
  }

  & fieldset {
    border: 1px solid #EEEEEE !important;
  }

  & fieldset:focus-within {
    border: 1px solid #47D061 !important;
  }

  .v-input__slot {
    background: #F8F8F8 !important;
  }

  .v-input__slot:hover {
    background: #F8F8F8 !important;
  }
}
.employee-field .vti__dropdown {
  outline: none;
}

.employee-field .v-input__control > .v-input__slot fieldset {
  border: none !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 44px;

  .v-input__prepend-inner, .v-input__append-inner {
    margin-top: 11px
  }
}
</style>