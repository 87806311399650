import jwt from 'jsonwebtoken';
import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';
import { CORP_ADMIN } from '@/helpers/userRoles';

const defaultState = {
  businessUserServiceClient: null,
  me: {},
  meDisplayName: '',
  meLoading: false,
  userRole: null,
  isAdmin: false,
};

const getters = {
  me: (state) => state.me,
  getMeLoading: (state) => state.meLoading,
  getUserRole: (state) => state.userRole,
  getIsAdmin: (state) => state.isAdmin,
  meDisplayName: (state) => state.meDisplayName,
  roles: () => [{
    name: 'bookingChannelAdmin',
  },
  {
    name: 'corporationAdmin',
  }],

  // eslint-disable-next-line no-shadow
  businessUserServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.businessUserServiceClient) {
      state.businessUserServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.BusinessUserServiceConnector });
    }
    return state.businessUserServiceClient;
  },

};

// eslint-disable-next-line no-underscore-dangle
const _getRolesWithCorporations = (role, corporations) => corporations.map((corporationId) => ({
  corporationId,
  name: role,
}));

const getUserRoles = (role, corporations) => {
  const roles = role === CORP_ADMIN ? _getRolesWithCorporations(role, corporations) : [{ name: role }];
  return roles;
};

const actions = {
  createUser: apiCall(async (context, {
    communicationLanguage, email, familyName, givenName, role, corporations,
  }) => {
    const roles = getUserRoles(role, corporations);
    return context.getters.businessUserServiceClient(context).createUser({
      data: {
        communicationLanguage, email, familyName, givenName, roles,
      },
    });
  }),
  updateUser: apiCall(async (context, {
    communicationLanguage, email, familyName, givenName, role, corporations, id, guestBookingEmailCorporations
  }) => {
    const roles = getUserRoles(role, corporations);
    return context.getters.businessUserServiceClient(context).updateUser({
      communicationLanguage, email, familyName, givenName, roles, id, guestBookingEmailCorporations
    });
  }),
  deleteUser: apiCall((context, id) => {
    const userServiceClient = context.getters.businessUserServiceClient(context);
    return userServiceClient.deleteUser({ id });
  }),

  async getMe(context) {
    try {
      context.commit('setMeLoading', true);
      const businessUserServiceClient = context.getters.businessUserServiceClient(context);

      const me = await businessUserServiceClient.getMe();

      context.commit('setMe', me);
      context.commit('setMeDisplayName', `${me.givenName} ${me.familyName}`);
      context.commit('setMeLoading', false);
      return me;
    } catch (error) {
      context.commit('setMeLoading', false);

      return { errorCode: error.httpStatusCode ? error.httpStatusCode : '500' };
    }
  },

  listUsers: apiCall(async (context, {
    page, maxPerPage, sort, dynamicSearch, corporationId,
  }) => context.getters.businessUserServiceClient(context).listUsers({
    sort,
    page,
    maxPerPage,
    dynamicSearch,
    corporationId,
  })),

  async getUserRole(context) {
    const idToken = context.rootGetters['authentication/idToken'];

    if (idToken === null) {
      return false;
    }

    const decoded = jwt.decode(idToken);

    if (decoded.roles.length === 0) return false;

    const userRole = decoded.roles[0].name;

    context.commit('setUserRole', userRole);
    return true;
  },
};

const mutations = {
  setMe(state, me) {
    state.me = me;
  },
  setUserRole(state, payload) {
    state.userRole = payload;
    state.isAdmin = payload === 'bookingChannelAdmin';
  },
  setMeDisplayName(state, displayName) {
    state.meDisplayName = displayName;
  },
  setMeLoading(state, meLoading) {
    state.meLoading = meLoading;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
