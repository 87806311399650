var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"py-5 px-8"},[_c('v-card-title',{staticClass:"pa-0 mb-6"},[_vm._v(" "+_vm._s(_vm.$t('components.favourites.form.title'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"height":"36px","icon":"","text":""},on:{"click":function($event){return _vm.closeAction()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-text-field',{staticClass:"address-field mb-5",attrs:{"outlined":"","filled":"","hide-details":"","required":"","placeholder":_vm.$t('components.favourites.form.namePlaceholder'),"height":"45px"},model:{value:(_vm.alias),callback:function ($$v) {_vm.alias=$$v},expression:"alias"}}),_c('multiselect',{staticClass:"address-select mb-5",attrs:{"track-by":"id","label":"description","open-direction":"bottom","show-labels":false,"placeholder":_vm.$t('components.favourites.form.addressPlaceholder'),"show-no-options":false,"close-on-select":true,"clear-on-select":false,"options":_vm.suggestions,"internal-search":false,"searchable":true,"height":"45px"},on:{"search-change":_vm.getAddressSuggestions,"select":function($event){return _vm.getAddressDetails($event)}},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}},[_c('span',{attrs:{"slot":"caret"},slot:"caret"})]),_c('booking-map',{directives:[{name:"show",rawName:"v-show",value:(_vm.formattedAddress),expression:"formattedAddress"}],ref:"map",staticStyle:{"height":"300px"},attrs:{"disableInfoWindow":true,"waypoints":_vm.formattedAddress,"mapOptions":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
      keyboardShortcuts: false,
      gestureHandling: 'none',
      clickableIcons: false,
    }}}),_c('v-card-actions',{staticClass:"px-0",class:_vm.suggestions.length && !_vm.formattedAddress ? 'dynamic-height': 'mt-5'},[_c('v-btn',{staticClass:"btn btn-flat",attrs:{"text":""},on:{"click":function($event){return _vm.closeAction()}}},[_vm._v(" "+_vm._s(_vm.$t('components.favourites.form.cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"btn btn-default",attrs:{"depressed":""},on:{"click":_vm.createFavouriteAddress}},[_vm._v(" "+_vm._s(_vm.$t('components.favourites.form.continue'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }