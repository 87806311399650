<template>
  <div class="d-flex flex-column">
    <v-flex>
      <MultipleAddressPicker
        :default-value="defaultAddressInput"
        :address-suggestions="addressSuggestions"
        :favourite-pickup-suggestions="favouritePickupSuggestions"
        :favourite-dropoff-suggestions="favouriteDropoffSuggestions"
        :address-suggestions-loading="addressSuggestionsLoading"
        :flight-number="flightNumber"
        :type="searchType"
        @onSearchSuggestions="debounceSearchSuggestions"
        @onSelectAddress="onSelectAddress"
        @addEmptyWaypoint="() => waypoints.push(null)"
        @removeWaypoint="(index) => waypoints.splice(index, 1)"
        @onFlightNumberChange="flightNumber = $event"
      />
    </v-flex>

    <v-divider class="my-4" />

    <v-flex>
      <DateTimePicker
        :default-date="date"
        :default-time="time"
        :showResetTime="shouldDatePickerAllowResetTime"
        @pickupTimeChanged="onPickupTimeChanged"
      />
    </v-flex>

    <v-flex class="mt-4 d-flex justify-space-between">
      <v-btn color="#333333" outlined @click="onBackClick">
        {{ $t("components.bookingForm.back") }}
      </v-btn>

      <v-btn
        color="#333333"
        class="white--text"
        depressed
        :disabled="!origin || !destination || isTimeInvalid"
        @click="onAddressStepSubmit"
      >
        {{ $t("components.bookingForm.next") }}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import debounce from "debounce";

import { HiDash } from "@/core/util/HiDash";
import DateTimePicker from "@/components/booking/components/DateTimePicker.vue";
import MultipleAddressPicker from "@/components/booking/components/MultipleAddressPicker.vue";
import { AddressApiDtoDelegate } from "@/core/api/address/AddressApiDtoDelegate";
import { SafeAwait } from "@/core/util/SafeAwait";
import { ScheduleType } from "@/core/api/booking/BookingScheduleType";

export default {
  name: "AddressStepComponent",
  components: {
    MultipleAddressPicker,
    DateTimePicker,
  },
  props: {
    presenter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addressSuggestionsLoading: false,
      debounceSearchSuggestions: null,
      addressSuggestions: [],
      favouritePickupSuggestions: [],
      favouriteDropoffSuggestions: [],
      scheduleType: "ASAP",

      /**
       * @type {AddressItemDetailApiDto | null}
       */
      origin: null,
      /**
       * @type {Array<AddressItemDetailApiDto>}
       */
      waypoints: [],

      /**
       * @type {AddressItemDetailApiDto | null}
       */
      destination: null,
      flightNumber: null,
      time: null,
      date: null,
      /**
       * @type {moment.Moment | null}
       */
      pickupTime: null,
      searchType: "pickup",
      isTimeInvalid: false,

      /**
       * @type {'google' | 'icabbi' | null}
       */
      latestAddressSuggestionSource: null,
    };
  },
  created() {
    this.debounceSearchSuggestions = debounce(this.onSearchSuggestions, 300);
    this.fetchFavouriteAddresses();
    this.prefillAddressesIfAvailable();
    this.prefillPickupTimeIfAvailable();
  },
  computed: {
    ...mapGetters({
      selectedLanguage: "language/selectedLanguage",
    }),
    defaultAddressInput() {
      return {
        origin: this.origin,
        waypoints: this.waypoints,
        destination: this.destination,
      };
    },
    shouldDatePickerAllowResetTime() {
      return this.scheduleType === ScheduleType.PREBOOKING;
    },
  },
  methods: {
    prefillPickupTimeIfAvailable() {
      const { pickupTime, scheduleType } = this.presenter.getPickupTime();

      if (scheduleType === "PREBOOKING") {
        this.scheduleType = scheduleType;
        this.date = moment(pickupTime).format("YYYY-MM-DD");
        this.time = moment(pickupTime).format("HH:mm");
      } else {
        this.scheduleType = "ASAP";
        this.time = "ASAP";
      }
    },
    prefillAddressesIfAvailable() {
      const { origin, waypoints, destination, flightNumber } = this.presenter.getAddresses();

      this.setAddressFields(origin, "pickup");
      this.setAddressFields(destination, "destination");

      if (waypoints && waypoints.length) {
        waypoints.forEach((waypoint, index) => {
          this.setAddressFields(waypoint, "waypoint", index);
        });
      }

      this.flightNumber = flightNumber;
    },
    async fetchFavouriteAddresses() {
      const { error, result: favourites } = await SafeAwait.execute(async () => {
        return this.presenter.fetchFavouriteAddresses();
      });

      if (error) {
        this.$notify({
          type: "error",
          title: "Error",
          text: this.$t("components.bookingForm.requestErrors.fetchFavouriteAddresses"),
        });
        return;
      }

      favourites.results.forEach((favourite) => {
        if (favourite.suggestForPickupAddress) {
          this.favouritePickupSuggestions.push({
            id: favourite.id,
            description: favourite.fullAddressText,
            alias: favourite.alias,
            info: {
              coordinates: {
                latitude: favourite.latitude,
                longitude: favourite.longitude,
              },
            },
            locationTypes: favourite.locationTypes,
            isFavourite: true,
          });
        }

        if (favourite.suggestForDestinationAddress) {
          this.favouriteDropoffSuggestions.push({
            id: favourite.id,
            description: favourite.fullAddressText,
            alias: favourite.alias,
            info: {
              coordinates: {
                latitude: favourite.latitude,
                longitude: favourite.longitude,
              },
            },
            locationTypes: favourite.locationTypes,
            isFavourite: true,
          });
        }
      });
    },
    async onSearchSuggestions(search, type) {
      if (search === '') {
        this.resetSuggestions();
        return;
      }

      this.searchType = type;
      if (HiDash.isString(search) && search.length < 3) return;
      this.addressSuggestionsLoading = true;

      const { error, result } = await SafeAwait.execute(async () => {
        return this.presenter.fetchAddressSuggestions({
          search,
          lang: this.selectedLanguage,
        });
      });

      this.addressSuggestionsLoading = false;

      if (error) {
        this.$notify({
          type: "error",
          title: "Error",
          text: this.$t("components.bookingForm.requestErrors.fetchAddressSuggestion"),
        });
        return;
      }

      this.addressSuggestions = result.suggestions;
      this.latestAddressSuggestionSource = result.source;
    },
    /**
     *
     * @param {AddressSuggestionApiDto} address
     * @param type
     * @param index
     * @return {Promise<void>}
     */
    async onSelectAddress(address, type, index) {
      /**
       *
       * @type {AddressItemDetailApiDto | null}
       */
      let addressDetails = null;

      if (address.isFavourite) {
        this.latestAddressSuggestionSource = "icabbi";
      }

      if (AddressApiDtoDelegate.isSourceFromGoogle(this.latestAddressSuggestionSource)) {
        const { error, result } = await SafeAwait.execute(async () => {
          return this.presenter.fetchAddressDetails({
            addressId: address.id,
            lang: this.selectedLanguage,
          });
        });

        if (error) {
          this.$notify({
            type: "error",
            title: "Error",
            text: this.$t("components.bookingForm.requestErrors.fetchAddressDetails"),
          });
          return;
        }

        addressDetails = result;
      } else if (AddressApiDtoDelegate.isSourceFromICabbi(this.latestAddressSuggestionSource)) {
        addressDetails = {
          id: address.id,
          source: this.latestAddressSuggestionSource,
          description: address.description,
          locationTypes: address.locationTypes,
          coordinates: {
            latitude: address.info.coordinates.latitude,
            longitude: address.info.coordinates.longitude,
          },
        };
      } else {
        alert("Unknown address source");
      }

      if (type === 'pickup') {
        console.log("[AddressStepComponent] Fetching drivers");

        this.$store.dispatch('driverStates/getDrivers', {
          latitude: addressDetails.coordinates.latitude,
          longitude: addressDetails.coordinates.longitude,
          limit: 10,
          status: 'available',
        });
      }

      this.setAddressFields(addressDetails, type, index);
      this.resetSuggestions();
    },
    /**
     *
     * @param {AddressItemDetailApiDto} addressDetails
     * @param type
     * @param index
     */
    setAddressFields(addressDetails, type, index) {
      switch (type) {
        case "pickup":
          this.origin = addressDetails;

          this.$store.commit("bookings/setAddress", {
            type: "pickup",
            address: addressDetails,
          });
          break;
        case "waypoint":
          this.$set(this.waypoints, index, addressDetails);

          this.$store.commit("bookings/setAddress", {
            type: "waypoints",
            address: this.waypoints,
          });
          break;
        case "destination":
          this.destination = addressDetails;

          this.$store.commit("bookings/setAddress", {
            type: "dropoff",
            address: addressDetails,
          });
          break;
      }
    },
    handleTimeChange(time, valid = true) {
      this.scheduleType = time !== "ASAP" ? "PREBOOKING" : "ASAP";
      this.time = time;
      this.isTimeInvalid = valid;
    },
    handleDateChange(date) {
      this.scheduleType = date !== moment().format("YYYY-MM-DD") ? "PREBOOKING" : "ASAP";

      this.date = date;
    },
    resetSuggestions() {
      this.addressSuggestions = [];
      this.latestAddressSuggestionSource = null;
    },
    onBackClick() {
      this.presenter.onBackClick();
    },
    /**
     *
     * @return {moment.Moment|null}
     */
    onAddressStepSubmit() {
      this.presenter.onAddressStepSubmit({
        origin: this.origin,
        waypoints: this.waypoints,
        destination: this.destination,
        flightNumber: this.flightNumber,
        scheduleType: this.scheduleType,
        pickupTime: this.pickupTime,
      });
    },
    onPickupTimeChanged(payload) {
      console.log("[AddressStepComponent] onPickupTimeChanged", payload);

      if (payload.isAsap) {
        this.scheduleType = ScheduleType.ASAP;
        this.pickupTime = null;
        this.isTimeInvalid = false;
      } else {
        this.scheduleType = ScheduleType.PREBOOKING;

        if (!payload.isTimeValid) {
          this.isTimeInvalid = true;
        } else {
          this.isTimeInvalid = false;
          const mt = moment.tz(`${payload.date} ${payload.time}`, "YYYY-MM-DD HH:mm", "UTC");

          if (moment(mt).isValid()) {
            this.pickupTime = mt;
          } else {
            // Sanity check
            alert("Invalid date or time");
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
