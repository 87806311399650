import { business, passenger } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  bookingHistoryServiceConnector: null,
  receiptServiceConnector: null,
};

const apiInstances = {
  bookingHistoryServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (state.bookingHistoryServiceConnector === null) {
      state.bookingHistoryServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.BookingHistoryServiceConnector });
    }
    return state.bookingHistoryServiceConnector;
  },
  receiptServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (state.receiptServiceConnector === null) {
      state.receiptServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: passenger.ReceiptServiceConnector });
    }
    return state.receiptServiceConnector;
  },
};

const getters = {
  ...apiInstances,
};

const actions = {
  getBookingDetails: apiCall((context, bookingId) => context.getters.bookingHistoryServiceConnector(context).getHistoryDetails({ id: bookingId })),
  getBookingReceipt: apiCall((context, bookingId) => context.getters.receiptServiceConnector(context).getReceiptByBookingId({ bookingId })),
  async getCorporationsHistory(context, {
    filter,
    page,
    maxPerPage,
    startDate,
    corporationId,
    dispatchId,
    sort,
    passengerId,
    businessGuestId,
  }) {
    try {
      const bookingHistoryServiceConnector = context.getters.bookingHistoryServiceConnector(context);
      const bookingHistory = await bookingHistoryServiceConnector.getHistoryList({
        filter,
        page,
        maxPerPage,
        startDate,
        corporationId,
        dispatchId,
        sort,
        passengerId,
        businessGuestId,
      });

      context.commit('SET_TRIPS_HISTORY', bookingHistory);
      return bookingHistory;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return false;
    }
  },
  async getFleetHistory(context, {
    filter,
    page,
    maxPerPage,
    startDate,
    corporationId,
    dispatchId,
    sort,
    passengerId,
    businessGuestId,
  }) {
    try {
      const bookingHistoryServiceConnector = context.getters.bookingHistoryServiceConnector(context);
      const bookingHistory = await bookingHistoryServiceConnector.getFleetHistoryList({
        filter,
        page,
        maxPerPage,
        startDate,
        corporationId,
        dispatchId,
        sort,
        passengerId,
        businessGuestId,
      });

      context.commit('SET_TRIPS_HISTORY', bookingHistory);
      return bookingHistory;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return false;
    }
  },
};

const mutations = {
  SET_TRIPS_HISTORY(state, payload) {
    state.tripsHistory = payload.data.sort((a, b) => {
      if (a.pickupTime < b.pickupTime) return 1;
      if (a.pickupTime > b.pickupTime) return -1;
      return 0;
    });

    state.totalTrips = Number(payload.headers['x-total-count'] || 0);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
