<template>
    <div class="user-profile-navigation-footer mb-3">
      <v-menu offset-x nudge-right="20" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-layout v-on="on" v-bind="attrs" row wrap justify-center align-center class="mr-4 ml-3 user-profile-container mb-2">
            <v-hover v-slot="{ hover }">
              <v-flex class="justify-center">
                <v-avatar
                  size="36"
                  class="avatar-icon"
                  color="primary"
                >
                  <span :class="{ 'on-hover': hover }">{{ getInitials }}</span>

                  <div class="d-none justify-center align-center" :class="{ 'show-edit-icon': hover }">
                    <v-icon size="16" color="white">mdi mdi-cog</v-icon>
                  </div>
                </v-avatar>
              </v-flex>
            </v-hover>
            <v-flex v-if="toggleMenu" xs9>
              <span class="user-profile-navigation-username">{{ displayName }}</span>
            </v-flex>
          </v-layout>
        </template>

        <v-list class="py-0">
          <v-list-item class="d-flex" v-if="isCorporationAdmin">
            <v-list-item-title class="user-profile-navigation-username">
              <account-details class="mr-2" />
              <router-link to="/user-profile">{{ $t('components.userProfileNavigation.accountDetails') }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="d-flex">
            <v-list-item-title class="user-profile-navigation-username">
              <terms-and-conditions class="mr-2" />
              <a target="_blank" :href="termAndConditionLink">{{ $t('components.userProfileNavigation.termsAndConditions')}}</a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="d-flex">
            <v-list-item-title class="user-profile-navigation-username">
              <privacy-policy class="mr-2" />
              <a target="_blank" :href="termAndConditionLink">{{ $t('components.userProfileNavigation.privacyPolicy')}}</a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="d-flex">
            <language-icon class="mr-2" />
            <v-select
              class="mx-auto get-started-language-selector"
              :items="languages"
              :label="$t('language')"
              item-text="name"
              item-value="id"
              :menu-props="{
                offsetX: true,
                nudgeRight: 20,
              }"
              v-model="language"
              hide-details
              @change="changeLanguage"
            ></v-select>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="user-profile-navigation-username">
              <v-icon class="mr-2" @click="$router.push('/logout')" color="black">mdi-logout</v-icon>
              {{ $t('components.userProfileNavigation.logout')}}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-layout v-if="showBookTrip" row wrap justify-center align-center class="my-2 mr-4 ml-3 user-profile-container">
        <v-flex>
          <v-btn v-if="toggleMenu" to="/create-v2" class="user-profile-booking-creation-button"> {{ $t('components.userProfileNavigation.bookTrip')}} </v-btn>
          <v-btn v-else to="/create-v2" fab dark elevation="0" class="expand_book_btn">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center align-center class="mx-3 mb-0 mt-3">
        <expand-icon-close v-if="toggleMenu" @click="callToggleMenu" />
        <expand-icon-open v-else @click="callToggleMenu" />
      </v-layout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { languageMethods, languageComputed } from '@/mixins/language.mixins';
import { iconLoader } from '@/helpers/iconsLoader';

export default {
  name: 'UserProfileNavigation',
  mixins: [languageMethods, languageComputed],
  props: {
    displayName: String,
    showBookTrip: Boolean,
  },
  components: {
    ...iconLoader.toggleSideMenu,
    ...iconLoader.elements,
  },
  data() {
    return {
      open: false,
      toggleMenu: false,
      language: null,
      isCorporationAdmin: false,
    };
  },
  async created() {
    this.language = this.selectedLanguage;
  },
  watch: {
    locale() {
      if (this.open) {
        this.open = false;
        this.$nextTick(() => {
          this.open = true;
        });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('users/getUserRole');
    this.isCorporationAdmin = this.userRole === 'corporationAdmin';
  },
  methods: {
    async changeLanguage(language) {
      await this.selectLanguage(language);
    },
    callToggleMenu() {
      this.toggleMenu = !this.toggleMenu;
      this.$emit('expandMenu');
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'users/getUserRole',
      termAndConditionLinks: 'bookingChannel/termAndConditionLinks',
      privacyPolicyLinks: 'bookingChannel/privacyPolicyLinks',
    }),
    locale() {
      return this.$i18n.locale;
    },
    getInitials() {
      if (this.displayName) {
        const arrSplitName = this.displayName.split(' ');

        return `${arrSplitName[0].substring(0, 1)}${arrSplitName[arrSplitName.length - 1].substring(0, 1)}`;
      }

      return '';
    },
    privacyPolicyLink() {
      if (!this.privacyPolicyLinks) return '#';
      return (
        this.privacyPolicyLinks[this.$i18n.locale] || this.privacyPolicyLinks.en
      );
    },
    termAndConditionLink() {
      if (!this.termAndConditionLinks) return '#';
      return (
        this.termAndConditionLinks[this.$i18n.locale]
        || this.termAndConditionLinks.en
      );
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer--mini-variant {
  .user-profile-container {
    background: inherit;
    border-radius: 16px;
  }
}
</style>
<style lang="scss" scoped>
  .user-profile-navigation-footer {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent !important;

    button {
      border: 0;
      border-top: 1px solid #d4d4d4;
    }
  }

  .user-layout {
    height: 34px;
  }

  svg {
    cursor: pointer;
  }

  .user-profile-container {
    background: #F2F2F2;
    border-radius: 16px;
    height: 36px;
    min-width: 36px;
  }

  .expand_book_btn {
    font-size: 25px !important;
    font-weight: 200;
    padding: 0px !important;
    width: 35px !important;
    height: 35px !important;
    border: none !important;
    background: #333333;
    color: #ffffff !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 50%;
  }

  .user-profile-booking-creation-button {
    width: 100%;
    border-radius: 16px;
    background-color: #333333 !important;
    text-transform: capitalize;
    color: #ffffff !important;
  }

  .user-profile-navigation-username {
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
    color: #333333;

    svg {
      width: 24px;
      height: 24px;
    }

    a {
      color: #333333;
      text-decoration: none;
    }
  }

  .avatar-icon {
    width: 35px;
    height: 35px;
    color: white;
    background-color: #333333;
    cursor: pointer;
  }

  .avatar-icon span:is(.on-hover) {
    display: none;
    transition: all 0.3s ease-in-out;
  }

  .show-edit-icon {
    display: flex !important;
    transition: all 0.3s ease-in-out;
  }
</style>
