import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';

export default {
  endDateInput: {
    minLength: minLength(2),
    maxLength: maxLength(255),
    required,
  },
  startDateInput: {
    minLength: minLength(2),
    maxLength: maxLength(255),
    required,
  },
  corporation: {
    minLength: minLength(2),
    required,
  },
};
