const defaultState = {
  drawerOpen: false,
};

const getters = {
  drawerOpen: (state) => state.drawerOpen,
};

const mutations = {
  setDrawerOpen(state, isOpen) {
    state.drawerOpen = isOpen;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
};
