import {
  minLength, maxLength, required, email,
} from 'vuelidate/lib/validators';
import isValidString from './validationMethods';
import { CORP_ADMIN } from '../userRoles';

const requiredIfCorporationAdmin = (value, user) => {
  if (user.role !== CORP_ADMIN) return true;
  return required(value);
};

const requiredIfCreatePassengerIsChecked = (value, context) => {
  if (context.user.createPassengerAccount) {
    return required(value);
  }
  return true;
};

export default {
  user: {
    givenName: {
      minLength: minLength(2),
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    familyName: {
      minLength: minLength(2),
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    role: {
      minLength: minLength(2),
      required,
      isValidString,
    },
    email: {
      minLength: minLength(2),
      required,
      email,
    },
    communicationLanguage: {
      required,
    },
    corporations: {
      requiredIfCorporationAdmin,
      $each: {
        required,
      },
    },
  },
  userProfile: {
    givenName: {
      minLength: minLength(2),
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    familyName: {
      minLength: minLength(2),
      maxLength: maxLength(255),
      required,
      isValidString,
    },
    email: {
      minLength: minLength(2),
      required,
      email,
    },
  },
  phoneNumber: {
    requiredIfCreatePassengerIsChecked,
    minLength: minLength(10),
  },
};
