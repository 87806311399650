import en from './en.json';
import fr from './fr.json';
import fi from './fi.json';
import sp from './sp.json';
import sv from './sv.json';
import nl from './nl.json';

export default {
  en,
  fr,
  fi,
  sp,
  sv,
  nl,
};
