import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vuelidateErrorExtractor from 'vuelidate-error-extractor';

Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  messages: {
    required: 'The {attribute} is required',
  },
});
