<template>
  <v-flex>
    <p class="booking-creation-title d-flex align-center">
      {{ $t("components.bookingForm.confirmation.title") }}
    </p>
    <v-alert dense outlined :color="firstBookingCreationAlert.error ? 'red' : 'primary'">
      <div class="d-flex justify-space-between align-center w-full">
        <h5 v-if="firstBookingCreationAlert.error" class="red--text booking-status-title">
          {{ $t("components.bookingForm.confirmation.bookingErrorStatus") }}
        </h5>
        <h5
          v-else-if="firstBookingCreationAlert.isLoading"
          class="booking-status-title black--text"
        >
          {{ $t("components.bookingForm.confirmation.bookingCreationStatus") }}
        </h5>
        <h5 v-else-if="!firstBooking" class="booking-status-title black--text">
          {{ $t("components.bookingForm.confirmation.bookingConfirmationCancelled") }}
        </h5>
        <h5 v-else class="booking-status-title black--text">
          {{ $t("components.bookingForm.confirmation.bookingConfirmationStatus") }}
        </h5>
        <div>
          <v-progress-circular
            v-if="firstBookingCreationAlert.isLoading"
            indeterminate
            color="primary"
            size="20"
          ></v-progress-circular>
          <v-icon :color="firstBookingCreationAlert.error ? 'red' : 'primary'" v-else>{{
            firstBookingCreationAlert.error ? "mdi-alert-circle" : "mdi-check-circle"
          }}</v-icon>
        </div>
      </div>
      <BookingDetails v-if="firstBooking" class="mt-2" :details="firstBooking" />
      <p
        v-if="firstBooking"
        class="booking-cancel-button mt-4 mb-1"
        @click="cancelBooking(firstBooking.id, 'firstBooking')"
      >
        {{ $t("components.bookingForm.cancel-booking") }}
      </p>
    </v-alert>

    <v-alert
      v-if="shouldCreateReturnBooking"
      dense
      outlined
      :color="returnBookingCreationAlert.error ? 'red' : 'primary'"
      class="mt-2"
    >
      <div class="d-flex justify-space-between align-center w-full">
        <h5 v-if="returnBookingCreationAlert.error" class="red--text booking-status-title">
          {{ $t("components.bookingForm.confirmation.returnBookingErrorStatus") }}
        </h5>
        <h5
          v-else-if="returnBookingCreationAlert.isLoading"
          class="booking-status-title black--text"
        >
          {{ $t("components.bookingForm.confirmation.returnBookingCreationStatus") }}
        </h5>
        <h5
          v-else-if="
            ![taskEnum.CREATE_RETURN_BOOKING, taskEnum.CONFIRM_RETURN_BOOKING].includes(currentTask)
          "
          class="booking-status-title black--text"
        >
          {{ $t("components.bookingForm.confirmation.returnBookingWaitingStatus") }}
        </h5>
        <h5 v-else-if="!returnBooking" class="booking-status-title black--text">
          {{ $t("components.bookingForm.confirmation.returnBookingConfirmationCancelled") }}
        </h5>
        <h5 v-else class="booking-status-title black--text">
          {{ $t("components.bookingForm.confirmation.returnBookingConfirmationStatus") }}
        </h5>
        <div>
          <v-icon
            v-if="
              ![taskEnum.CREATE_RETURN_BOOKING, taskEnum.CONFIRM_RETURN_BOOKING].includes(
                currentTask
              )
            "
            >mdi-pause</v-icon
          >
          <v-progress-circular
            v-else-if="returnBookingCreationAlert.isLoading"
            indeterminate
            color="primary"
            size="20"
          ></v-progress-circular>
          <v-icon :color="returnBookingCreationAlert.error ? 'red' : 'primary'" v-else
            >mdi-check-bold</v-icon
          >
        </div>
      </div>
      <BookingDetails v-if="returnBooking" class="mt-2" :details="returnBooking" />
      <span
        v-if="returnBooking"
        class="booking-cancel-button mt-3"
        @click="cancelBooking(returnBooking.id, 'returnBooking')"
        >{{ $t("components.bookingForm.cancel-booking") }}</span
      >
    </v-alert>

    <div class="d-flex justify-space-between">
      <v-btn
        v-if="firstBooking || returnBooking"
        color="#333333"
        outlined
        @click="onMoveToFirstStep"
      >
        {{ $t("components.bookingForm.createAnotherBooking") }}
      </v-btn>

      <v-btn v-else color="#333333" outlined @click="onBackClick">
        {{ $t("components.bookingForm.back") }}
      </v-btn>
    </div>

    <v-dialog v-model="getDuplicateBooking" persistent max-width="400">
      <v-card class="pb-2">
        <v-card-title class="headline">{{
          $t("components.bookingForm.duplicate.title")
        }}</v-card-title>
        <v-card-text>
          <p class="mb-0">{{ $t("components.bookingForm.duplicate.message") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="disable-button"
            color="grey darken-1"
            text
            @click="closeConfirmDuplicateDialog"
          >
            {{ $t("components.bookingForm.duplicate.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="createFirstBooking.loading"
            :disabled="createFirstBooking.loading"
            depressed
            color="primary"
            @click="createFirstBooking"
          >
            {{ $t("components.bookingForm.duplicate.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import { SafeAwait } from "@/core/util/SafeAwait";
import BookingDetails from "@/views/bookingForm/BookingDetails";

const TASK_ENUM = {
  CREATE_FIRST_BOOKING: "CREATE_FIRST_BOOKING",
  CONFIRM_FIRST_BOOKING: "CONFIRM_FIRST_BOOKING",
  CREATE_RETURN_BOOKING: "CREATE_RETURN_BOOKING",
  CONFIRM_RETURN_BOOKING: "CONFIRM_RETURN_BOOKING",
};

export default {
  name: "ConfirmationStepComponent",
  components: {
    BookingDetails,
  },
  props: {
    /**
     * @type {BookingFormPresenter}
     */
    presenter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTask: TASK_ENUM.CREATE_FIRST_BOOKING,
      firstBookingCreationAlert: {
        isLoading: true,
        error: null,
      },
      returnBookingCreationAlert: {
        isLoading: false,
        error: null,
      },
      firstBooking: null,
      returnBooking: null,
      tasks: {
        [TASK_ENUM.CREATE_FIRST_BOOKING]: {
          isLoading: true,
          error: null,
        },
        [TASK_ENUM.CONFIRM_FIRST_BOOKING]: {
          isLoading: false,
          error: null,
        },
        [TASK_ENUM.CREATE_RETURN_BOOKING]: {
          isLoading: false,
          error: null,
        },
        [TASK_ENUM.CONFIRM_RETURN_BOOKING]: {
          isLoading: false,
          error: null,
        },
      },
    };
  },
  async created() {
    this.currentTask = TASK_ENUM.CREATE_FIRST_BOOKING;
    await this.createFirstBooking();
  },
  computed: {
    ...mapGetters({
      getDuplicateBooking: "bookings/getDuplicateBooking",
    }),
    shouldCreateReturnBooking() {
      return this.presenter.interactor.bookingFormModel.shouldBookReturnBooking;
    },
    taskEnum() {
      return TASK_ENUM;
    },
  },
  methods: {
    async cancelBooking(bookingId, type) {
      if (type === "firstBooking") {
        this.firstBookingCreationAlert.isLoading = true;
      } else if (type === "returnBooking") {
        this.returnBookingCreationAlert.isLoading = true;
      }

      const { error } = await SafeAwait.execute(async () => {
        return this.presenter.interactor.cancelBooking(bookingId);
      });

      if (type === "firstBooking") {
        this.firstBookingCreationAlert.isLoading = false;
      } else if (type === "returnBooking") {
        this.returnBookingCreationAlert.isLoading = false;
      }

      if (error) {
        this.$notify({
          type: "error",
          title: "Error",
          text: error.message,
        });
      } else {
        this.$notify({
          type: "success",
          title: "Success",
          text: "Booking cancelled successfully",
        });

        if (type === "firstBooking") {
          this.firstBooking = null;
          this.resetFirstBookingCreationAlert();
        } else {
          this.returnBooking = null;
          this.resetReturnBookingCreationAlert();
        }
      }
    },
    setTaskToIsLoading(task) {
      this.tasks[task].isLoading = true;
    },
    setTaskToIsNotLoading(task) {
      this.tasks[task].isLoading = false;
    },
    resetFirstBookingCreationAlert({ isLoading = false } = {}) {
      this.firstBookingCreationAlert = {
        isLoading: isLoading,
        error: null,
      };
    },
    resetReturnBookingCreationAlert({ isLoading = false } = {}) {
      this.returnBookingCreationAlert = {
        isLoading: isLoading,
        error: null,
      };
    },
    async createFirstBooking() {
      if (this.getDuplicateBooking) {
        this.$store.commit("bookings/setDuplicateBooking", false);
      }

      this.setTaskToIsLoading(TASK_ENUM.CREATE_FIRST_BOOKING);
      this.resetFirstBookingCreationAlert({ isLoading: true });

      const { error, result } = await SafeAwait.execute(async () => {
        return this.presenter.interactor.createFirstBooking();
      });

      // this.firstBookingCreationAlert.isLoading = false;
      this.setTaskToIsNotLoading(TASK_ENUM.CREATE_FIRST_BOOKING);

      if (error) {
        this.firstBookingCreationAlert.isLoading = false;
        this.checkIsDuplicateBooking(error.code);

        this.$notify({
          group: "actions-notification",
          type: "error",
          title: "Error",
          text: error.message,
          data: {
            component: "ConfirmationStepComponent",
            type: "createFirstBooking",
            callback: this.createFirstBooking,
          },
        });

        // Allow the user to retry
        // Show the error error to the user in the alert
        this.tasks[this.currentTask].error = error;
        this.firstBookingCreationAlert.error = error;
        return;
      } else {
        if (result.requiresConfirmation) {
          const paymentConfirmResult = await this.presenter.interactor.confirmPaymentBooking(
            result.authorizationClientSecret
          );
          console.log(
            "[ConfirmationStepComponent] - after confirmPaymentBooking",
            paymentConfirmResult
          );

          if (paymentConfirmResult.error) {
            this.firstBookingCreationAlert.isLoading = false;

            this.$notify({
              group: "actions-notification",
              type: "error",
              title: "Error",
              text: paymentConfirmResult.error.message,
              data: {
                component: "ConfirmationStepComponent",
                type: "confirmPaymentBooking",
                callback: this.createFirstBooking,
              },
            });

            this.tasks[this.currentTask].error = paymentConfirmResult.error;
            this.firstBookingCreationAlert.error = paymentConfirmResult.error;
          }
        }

        if (result.requiresBookingConfirmation !== false) {
          await this.confirmFirstBooking(result.bookingId);
        } else {
          this.firstBookingCreationAlert.isLoading = false;
          this.setTaskToIsNotLoading(TASK_ENUM.CONFIRM_FIRST_BOOKING);

          this.firstBooking = result;
        }
      }
    },
    async confirmFirstBooking(bookingId) {
      this.currentTask = TASK_ENUM.CONFIRM_FIRST_BOOKING;
      this.firstBookingCreationAlert.isLoading = true;
      this.setTaskToIsLoading(TASK_ENUM.CONFIRM_FIRST_BOOKING);

      const { error, result } = await SafeAwait.execute(async () => {
        return this.presenter.interactor.confirmFirstBooking(bookingId);
      });

      this.firstBookingCreationAlert.isLoading = false;
      this.setTaskToIsNotLoading(TASK_ENUM.CONFIRM_FIRST_BOOKING);

      if (error) {
        this.$notify({
          group: "actions-notification",
          type: "error",
          title: "Error",
          text: error.message,
          data: {
            component: "ConfirmationStepComponent",
            type: "confirmFirstBooking",
            callback: this.createFirstBooking,
          },
        });

        // Allow the user to retry
        // Show the error error to the user in the alert

        this.tasks[this.currentTask].error = error;
        this.firstBookingCreationAlert.error = error;
        return;
      } else {
        this.firstBooking = result;
        if (this.shouldCreateReturnBooking) {
          await this.createReturnBooking(this.firstBooking);
        }
      }
    },
    async createReturnBooking(booking) {
      this.currentTask = TASK_ENUM.CREATE_RETURN_BOOKING;
      this.setTaskToIsLoading(TASK_ENUM.CREATE_RETURN_BOOKING);
      this.resetReturnBookingCreationAlert({ isLoading: true });

      const { error, result } = await SafeAwait.execute(async () => {
        return this.presenter.interactor.createReturnBooking(booking);
      });

      this.returnBookingCreationAlert.isLoading = false;
      this.setTaskToIsNotLoading(TASK_ENUM.CREATE_RETURN_BOOKING);

      if (error) {
        this.$notify({
          group: "actions-notification",
          type: "error",
          title: "Error",
          text: error.message,
          data: {
            component: "ConfirmationStepComponent",
            type: "createReturnBooking",
            callback: this.createReturnBooking,
          },
        });

        // Allow the user to retry
        // Show the error error to the user in the alert

        this.tasks[this.currentTask].error = error;
        this.returnBookingCreationAlert.error = error;
        return;
      } else {
        if (result.requiresConfirmation) {
          await this.presenter.interactor.confirmPaymentBooking(result.authorizationClientSecret);
        }

        await this.confirmReturnBooking(result.bookingId);
      }
    },
    async confirmReturnBooking(bookingId) {
      this.currentTask = TASK_ENUM.CONFIRM_RETURN_BOOKING;
      this.setTaskToIsLoading(TASK_ENUM.CONFIRM_RETURN_BOOKING);
      this.resetReturnBookingCreationAlert({ isLoading: true });

      const { error, result } = await SafeAwait.execute(async () => {
        return this.presenter.interactor.confirmReturnBooking(bookingId);
      });

      this.returnBookingCreationAlert.isLoading = false;
      this.setTaskToIsNotLoading(TASK_ENUM.CONFIRM_RETURN_BOOKING);

      if (error) {
        this.$notify({
          group: "actions-notification",
          type: "error",
          title: "Error",
          text: error.message,
          data: {
            component: "ConfirmationStepComponent",
            type: "confirmReturnBooking",
            callback: this.createReturnBooking,
          },
        });

        // Allow the user to retry
        // Show the error error to the user in the alert

        this.tasks[this.currentTask].error = error;
        this.returnBookingCreationAlert.error = error;
      } else {
        this.returnBooking = result;
        console.log(result, "bookingCreated");
      }
    },
    checkIsDuplicateBooking(code) {
      if (code === "DUPLICATE_BOOKING_NOT_ACKNOWLEDGED") {
        this.$store.commit("bookings/setDuplicateBooking", true);
        this.presenter.setDuplicateBooking(true);
      }
    },
    closeConfirmDuplicateDialog() {
      this.$store.commit("bookings/setDuplicateBooking", false);
    },
    onBackClick() {
      this.presenter.onBackClick();
    },
    onMoveToFirstStep() {
      this.presenter.onMoveToFirstStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-creation-title {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.005em;
  text-align: left;
  color: #333333;
}

.booking-status-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: capitalize;
  color: #000000;
}

.booking-cancel-button {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: initial;
  text-decoration: underline;
  cursor: pointer;
}
</style>
