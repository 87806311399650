<template>
  <travel-restrictions-list
    :travelRestrictions="data"
    :travelRestrictionsLoading="entityLoading"
    :totalTravelRestictions="totalCount"
    @loadData="loadData($event)"
  />
</template>

<script>
import { entityLoader } from '@/mixins/entityLoader.mixins';
import TravelRestrictionsList from '@/components/travelRestrictions/TravelRestrictionList.vue';

export default {
  name: 'travel-restrictions-view',
  mixins: [entityLoader],
  components: {
    TravelRestrictionsList,
  },
  computed: {
    totalCount() {
      return this.entityTotalCount.totalCount;
    },
  },
  methods: {
    async loadData({
      sort, page, maxPerPage, dynamicSearch, corporationId,
    }) {
      const params = {
        page,
        maxPerPage,
        sort,
        dynamicSearch,
      };
      if (corporationId) params.corporationId = corporationId;

      await this.getList('travelRestriction/listTravelRestrictions', params);
    },
  },
};
</script>
