import { iconLoader } from '@/helpers/iconsLoader';

export const creditCardIcon = {
  mixins: [iconLoader],
  components: { ...iconLoader.paymentMethod, ...iconLoader.sideMenu },
  methods: {
    creditCardIcon(brand) {
      const brandLower = brand ? brand.toLowerCase() : '';
      switch (brandLower) {
        case 'cash':
          return 'pay-in-car';
        case 'account':
          return 'pay-business-account';
        case 'businessaccount':
          return 'pay-business-account';
        case 'visa':
          return 'visa-card';
        case 'mastercard':
          return 'master-card';
        case 'amex':
          return 'amex-card';
        case 'discover':
          return 'discover-card';
        case 'applepay':
          return 'apple-pay';
        case 'googlepay':
          return 'google-pay';
        default:
          return 'unknown-card';
      }
    },
  },
};
