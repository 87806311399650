export default function isValidString(value) {
  if (!value) {
    return true;
  }
  return /^[A-zÀ-ÿ0-9\- ']*$/.test(value);
}
export function isValidUUID(value) {
  if (!value) {
    return true;
  }

  return /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(value);
}
