<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <div class="bs-table-header pt-8">
      <v-row class="align-center mb-3 px-4" justify="space-between">
        <v-col cols="auto" class="col-without-top-padding col-without-bottom-padding">
          <h1 class="bs-header-title">
            {{ $t('components.travelRestrictionList.travelRestrictionTitle') }}
          </h1>
        </v-col>
        <v-col sm="6" md="5" lg="5" class="d-flex align-center col-without-top-padding col-without-bottom-padding">
          <v-text-field
            v-model="dynamicSearch"
            :label="$t('components.travelRestrictionList.search')"
            color="primary"
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            hide-details
            :height="38"
            class="mr-3 bs-search-item"
          >
          </v-text-field>
          <v-btn to="/create-v2" class="add-bs-button" :height="40" color="#333333" depressed>
            <v-icon left>mdi-plus</v-icon>
            {{ $t('components.travelRestrictionList.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="bs-table-table">
      <v-row no-gutters>
        <v-col class="col-without-top-padding table-color">
          <v-data-table
            class="bs-table elevation-0"
            calculate-widths
            :items="travelRestrictions"
            :headers="headers"
            :server-items-length="totalTravelRestictions"
            :loading="travelRestrictionsLoading"
            :multi-sort="false"
            :must-sort="true"
            :page.sync="page"
            :options.sync="options"
            :items-per-page="itemsPerPage"
            :no-data-text="$t(`global.noData`)"
            :no-results-text="$t(`global.noResults`)"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:item.createdAt="{ item: { createdAt } }">
              {{ createdAt | longDateTimeDay }}
            </template>

            <template v-slot:item.status="{ item: { status } }">
              <span class="text-capitalize">{{ status }}</span>
            </template>

            <template v-slot:item.action="{ item }">
                <edit-icon class="mr-1 icon-control-list"/>
                <delete-icon class="mr-1 icon-control-list"/>
              </template>
          </v-data-table>
          <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
            <v-row no-gutters class="bs-per-page">
              <span>
                {{ $t(`components.travelRestrictionList.travelRestriction.restrictionsPerPage`) }}
              </span>
              <span
                v-for="item  in [10, 20, 30]"
                :key="item"
                class="px-1"
                :class="{ 'active-page-count': item === options.itemsPerPage }"
                @click="options.itemsPerPage = item"
              >
                {{ item }}
              </span>
            </v-row>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
              color="#f2f2f2"
              class="bs-pagination"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { iconLoader } from '@/helpers/iconsLoader';
import { longDateTimeDay } from '@/helpers/dateTime';

export default {
  name: 'travel-restrioctions-list',
  components: {
    ...iconLoader.elements,
  },
  props: {
    travelRestrictionsLoading: {
      type: Boolean,
      required: true,
    },
    travelRestrictions: {
      type: Array,
      required: true,
    },
    totalTravelRestictions: {
      type: Number,
      required: true,
    },
  },
  filters: { longDateTimeDay },
  created() {
    this.debouncedLoad = _.debounce(this.loadData, 500);
  },
  data() {
    return {
      dynamicSearch: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    totalEmployees() {
      this.options.pageCount = Math.round(this.totalEmployees / this.itemsPerPage) + 1;
    },
    dynamicSearch(val) {
      if (val === '') this.dynamicSearch = null;

      this.page = 1;
      this.debouncedLoad();
    },
    getSelectedCorporationId: {
      handler() {
        this.loadData();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    headers() {
      return [
        {
          text: this.$t('components.travelRestrictionList.travelRestriction.date'),
          align: 'left',
          sortable: true,
          value: 'createdAt',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.travelRestrictionList.travelRestriction.name'),
          align: 'left',
          sortable: true,
          value: 'name',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.travelRestrictionList.travelRestriction.status'),
          align: 'left',
          sortable: true,
          value: 'status',
          class: 'text-uppercase',
        },
        {
          value: 'action',
          width: '10%',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
  methods: {
    async loadData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.options;
      const sort = sortBy[0] || '-createdAt';
      this.$emit('loadData', {
        page: page - 1,
        dynamicSearch: this.dynamicSearch,
        maxPerPage: itemsPerPage,
        sort: sortDesc[0] ? `-${sort}` : sort,
        corporationId: this.getSelectedCorporationId,
      });
    },
  },
};
</script>
