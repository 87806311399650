import { business, Ic2BookingServiceClient } from "@icabbi/api-gateway-client";
import apiInstance from "@/helpers/apiInstance";

const defaultState = {
  bookingCommandConnector: null,
  ic2BookingServiceClient: null,
  fleetAccountServiceClient: null,
};

const getters = {
  bookingCommandConnector:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.bookingCommandConnector) {
        state.bookingCommandConnector = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: business.BookingCommandConnector,
        });
      }
      return state.bookingCommandConnector;
    },
  ic2BookingServiceClient:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.ic2BookingServiceClient) {
        state.ic2BookingServiceClient = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: Ic2BookingServiceClient,
        });
      }
      return state.ic2BookingServiceClient;
    },
  fleetAccountServiceClient:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.fleetAccountServiceClient) {
        state.fleetAccountServiceClient = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: business.FleetAccountServiceConnector,
        });
      }
      return state.fleetAccountServiceClient;
    },
};

/**
 * @param {Object} context
 * @param {string} bookingId
 */
function cancelBooking(context, bookingId) {
  return context.getters.bookingCommandConnector(context).cancelBooking({ bookingId });
}

/**
 * @param {Object} context
 * @param {CreateBookingApiDto} payload
 * @returns {Promise<BookingApiDto>}
 */
function requestBooking(context, payload) {
  return context.getters.bookingCommandConnector(context).requestBooking({ data: payload });
}

/**
 * @param {Object} context
 * @param {string} bookingId
 * @returns {Promise<BookingApiDto>}
 */
function confirmBooking(context, bookingId) {
  return context.getters.bookingCommandConnector(context).confirmBooking({ bookingId });
}

async function confirmPayment(context, authorizationClientSecret) {
  const stripe = await context.rootGetters['paymentMethods/stripeClient'];
  console.log('[BookingApiStore.confirmPayment] - before invoke', authorizationClientSecret);
  return stripe.confirmCardPayment(authorizationClientSecret);
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions: {
    cancelBooking,
    requestBooking,
    confirmBooking,
    confirmPayment,
  },
};
