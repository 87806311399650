import queryString from 'query-string';
import i18n from '@/plugins/i18n';

const SELECTED_LANGUAGE_KEY = 'selected_language';

const defaultState = {
  selectedLanguage: null,
};

const getters = {
  selectedLanguage: (state) => {
    const queryParams = queryString.parse(location.search); // eslint-disable-line no-restricted-globals

    const { locale: queryLocale } = queryParams;

    const navigatorLanguage = navigator.language ? navigator.language.toLowerCase() : 'en-uk';
    const storedLanguage = localStorage.getItem(SELECTED_LANGUAGE_KEY);
    return state.selectedLanguage
      || queryLocale
      || storedLanguage
      || navigatorLanguage;
  },
};

const actions = {
  setSelectedLanguage(context, selectedLanguage) {
    localStorage.setItem(SELECTED_LANGUAGE_KEY, selectedLanguage);
    context.commit('setSelectedLanguage', selectedLanguage);
    i18n.locale = selectedLanguage;
  },
};
const mutations = {
  setSelectedLanguage(state, selectedLanguage) {
    state.selectedLanguage = selectedLanguage;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
