import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  corporationServiceClient: null,
  corporationId: null,
  corporations: [],
};

const getters = {
  // eslint-disable-next-line no-shadow
  corporationServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.corporationServiceClient) {
      state.corporationServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.CorporationServiceConnector });
    }
    return state.corporationServiceClient;
  },
  getSelectedCorporationId: (state) => state.corporationId,
  getCorporationsList: (state) => state.corporations,
  selectedCorporation: (state) => state.corporations.find((corporation) => corporation.id === state.corporationId),
};

const actions = {
  listCorporations: apiCall(async (context, {
    page, maxPerPage, sort, name,
  }) => {
    const corporationServiceClient = context.getters.corporationServiceClient(context);
    const result = await corporationServiceClient.listCorporations({
      sort, page, maxPerPage, name,
    });

    return result;
  }),
  findCorporationByName: apiCall(async (context, {
    page, maxPerPage, sort, name,
  }) => {
    const corporationServiceClient = context.getters.corporationServiceClient(context);

    const result = await corporationServiceClient.findCorporationByName({
      page, maxPerPage, sort, name,
    })

    return result;
  }),
  createCorporation: apiCall(async (context, { name, communicationLanguage, contactEmail }) => {
    const corporationServiceClient = context.getters.corporationServiceClient(context);
    return corporationServiceClient.createCorporation({ data: { name, communicationLanguage, contactEmail } });
  }),
  updateCorporation: apiCall(async (context, {
    id, name, communicationLanguage, contactEmail,
  }) => {
    const corporationServiceClient = context.getters.corporationServiceClient(context);
    return corporationServiceClient.updateCorporation({
      id, name, communicationLanguage, contactEmail,
    });
  }),
  deleteCorporation: apiCall(async (context, id) => {
    const corporationServiceClient = context.getters.corporationServiceClient(context);
    return corporationServiceClient.deleteCorporation({ id });
  }),
};

const mutations = {
  setCorporationId(state, payload) {
    state.corporationId = payload;
    localStorage.setItem('corporationName', state.corporations.find((corporation) => corporation?.id === payload).name);
  },
  setCorporations(state, payload) {
    state.corporations = [
      ...state.corporations,
      ...payload
    ];
  },
  setLocalCorporation(state, corporation) {
    if (!corporation) {
      return;
    }

    state.corporations.unshift(corporation);
  }
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
