import { business } from "@icabbi/api-gateway-client";
import apiInstance from "@/helpers/apiInstance";

const defaultState = {
  favouriteAddressServiceConnector: null,
};

const getters = {
  favouriteAddressServiceConnector:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.favouriteAddressServiceConnector) {
        state.favouriteAddressServiceConnector = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: business.FavouriteAddressConnector,
        });
      }
      return state.favouriteAddressServiceConnector;
    },
};

const actions = {
  fetchFavouriteAddresses: async (context, { businessAccountId, page, maxPerPage }) => {
    const favouriteAddressServiceConnector =
      context.getters.favouriteAddressServiceConnector(context);
    const result = await favouriteAddressServiceConnector.listBusinessAccountFavouriteAddress({
      id: businessAccountId,
      payload: { page, maxPerPage },
    });

    return result;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};
