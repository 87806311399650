<template>
  <v-dialog
    :value="isOpen"
    :max-width="width"
    persistent
    class="dialog_wrapper"
    transition="slide-x-reverse-transition"
  >
    <cancel-form
      v-if="type"
      :type="type"
      :modalData="payload"
      @closeDialog="close"
    />
    <slot v-else name="dialogContent" />
  </v-dialog>
</template>

<script>
import { initLocale } from '@/mixins/language.mixins';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import { notifySuccess } from '@/helpers/notification';
import CancelForm from './CancelForm.vue';

export default {
  name: 'dialog-wrapper',
  mixins: [initLocale, entityLoader],
  components: { CancelForm },
  props: {
    width: {
      type: Number,
      default: 400,
    },
  },
  data: () => ({
    isOpen: false,
    type: null,
    loading: false,
    payload: null,
  }),
  methods: {
    openDialog(data) {
      this.type = null;

      if (data) {
        this.type = data.type;
        this.payload = data;
      }

      this.isOpen = true;
    },
    close(data) {
      this.isOpen = false;

      if (data) {
        this.$emit('closeDialog', { id: this.id, type: this.type });
        if (this.type === 'resend-email') notifySuccess(this.$t('notification.emailSent'));
      }
    },
  },
  beforeDestroy() {
    this.isOpen = false;
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  min-height: 130px;
  background-color: #ffffff;
}
.dialog-content {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #333333;
}

.dialog-content-subitem {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #888888;
}

.disable-button {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-transform: capitalize;
}

.cancel-button {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-transform: capitalize;
}
</style>
