// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

export class PhoneNumberUtil {

  /**
   * Wil throw an error if the phone number is invalid.
   * @param phoneNumber
   * @param countryCode
   */
  static parseToGooglePhoneNumber({ phoneNumber, countryCode }) {
    return phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
  }

  static fromGooglePhoneNumberToE164(googlePhoneNumber) {
    return phoneUtil.format(googlePhoneNumber, PNF.E164);
  }

  static parseToGooglePhoneWithoutRegionCode(phoneNumber) {
    return phoneUtil.parse(phoneNumber);
  }
}
