<template>
  <!-- <v-container fluid class="align-start align-content-start fill-height"> -->
    <div class="bs-table-header pt-8">
      <v-row justify="space-between" class="align-end mb-3 px-4">
        <v-col cols="12" class="col-without-top-padding col-without-bottom-padding">
          <h1 class="bs-header-title">
            {{ $t('components.employeeList.title') }}
          </h1>
        </v-col>
        <v-col md="7" class="col-without-top-padding col-without-bottom-padding v-tabs-container">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            plain
            class="tabs-text mr-6"
            :class="{ 'tabs-text-active': activeTab === index }"
            @click="updateRouterTab(item.tab, index)"
          >
            {{ $t(`components.employeeList.tabs.${item.tab}`) }}
          </div>
        </v-col>
        <v-col :cols="isGroupCreation ? 2 : 5" class="d-flex align-center col-without-top-padding col-without-bottom-padding">
          <v-text-field
            v-if="!isGroupCreation"
            v-model="dynamicSearch"
            :label="$t('components.employeeList.search')"
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            hide-details
            :height="38"
            class="mr-3 bs-search-item"
          ></v-text-field>
          <v-btn
            v-if="!isGroupCreation"
            class="add-bs-button"
            color="#333333"
            :height="40"
            depressed
            @click="addEmployeeClick()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t(`components.employeeList.${getActiveTab.tab}.add`) }}
          </v-btn>
          <v-menu left offset-y nudge-bottom="15" v-if="getActiveTab">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-3">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item link @click="showImportDialog">
                <v-list-item-content class="mr-6">
                  <v-list-item-title>{{ $t('global.importCsv') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon size="20" color="#ccc">mdi-tray-arrow-up</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item link target="_blank" href="/templates/user_import_template.csv">
                <v-list-item-content class="mr-6">
                  <v-list-item-title>{{ $t('global.csvTemplate') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon size="20" color="#ccc">mdi-tray-arrow-down</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item link @click="exportBusinessBookingRecipientsList">
                <v-list-item-content class="mr-6">
                  <v-list-item-title>{{ $t('global.exportBusinessBookingRecipientsList') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon size="20" color="#ccc">mdi-tray-arrow-down</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item link @click="exportCorporationAdminsList">
                <v-list-item-content class="mr-6">
                  <v-list-item-title>{{ $t('global.exportCorporationAdminsList') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon size="20" color="#ccc">mdi-tray-arrow-down</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
  <!-- </v-container> -->
</template>

<script>
export default {
  name: 'EmployeeListHeader',
  data() {
    return {
      activeTab: 0,
      tabs: [
        { tab: 'employee', action: 'businessPassenger/getBusinessPassengerList' },
        { tab: 'guest', action: 'businessGuest/getBusinessGuestList' },
        { tab: 'groups', action: 'employee/listEmployeeGroups' },
        { tab: 'corporationAdmin', action: '' },
      ],
      isGroupCreation: false,
      dynamicSearch: null,
    };
  },
  computed: {
    getActiveTab() {
      return this.tabs[this.activeTab];
    },
  },
  watch: {
    activeTab: {
      immediate: true,
      handler() {
        if (this.$route.query.tab !== this.getActiveTab.tab) {
          this.$router.replace({ query: { tab: this.getActiveTab.tab } });
        }

        this.$emit('activeTab', this.getActiveTab);
      },
    },
    dynamicSearch(val) {
      if (val === null) {
        this.$emit('search', '');
        return;
      }

      if (val.length >= 3) {
        this.$emit('search', val);
      } else if (!val) {
        this.$emit('search', '');
      }
    },
  },
  methods: {
    showImportDialog() {
      this.$emit('import');
    },
    exportCorporationAdminsList() {
      this.$emit('exportCorporationAdminsList');
    },
    exportBusinessBookingRecipientsList() {
      this.$emit('exportBusinessBookingRecipientsList');
    },
    updateRouterTab(tab, index) {
      this.activeTab = index;
      if (this.$route.query.tab === tab) return;

      this.$router.push({ query: { tab } });
    },
    addEmployeeClick() {
      this.$emit(this.getActiveTab.tab === 'groups' ? 'addEmployeeGroupClick' : 'addEmployeeClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
}
</style>
