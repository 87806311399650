<template>
  <div class="d-flex flex-row align-center mr-1">
    <v-btn class="mr-1" outlined text small color="#333333" :disabled="page <= 1" @click="goToFirst">
      <v-icon>
        mdi-chevron-double-left
      </v-icon>
    </v-btn>
    <v-btn class="mr-1" outlined text small color="#333333" :disabled="page <= 1" @click="previousPage">
      <v-icon>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn outlined text small color="#333333">
      {{ page }}
    </v-btn>
    <v-btn class="ml-1" outlined text small color="#333333" :disabled="!hasMore" @click="nextPage">
      <v-icon>
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SimplePaginator',
  props: {
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    hasMore: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    nextPage() {
      this.$emit('nextPage');
    },
    previousPage() {
      this.$emit('previousPage');
    },
    goToFirst() {
      this.$emit('goToFirst');
    },
  },
};
</script>

<style scoped>

</style>
