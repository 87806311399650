<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <div class="bs-table-header">
      <v-row class="mb-3">
        <v-col cols="12" class="col-without-bottom-padding px-8 mt-6 mb-3">
          <h1 class="bs-header-title">
            {{ $t("components.corporationsListTable.page-title") }}
          </h1>
        </v-col>
        <v-row  class="d-flex justify-end mb-3 px-8">
          <v-col sm="4" md="5" lg="5" class="d-flex align-center col-without-top-padding col-without-bottom-padding justify-end">
            <v-text-field
              :label="$t('components.corporationsListTable.search')"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              outlined
              hide-details
              clearable
              :height="38"
              class="mr-2 bs-search-item"
            ></v-text-field>
            <v-btn
              class="btn btn-default"
              depressed
              :height="40"
              @click="addCorporation"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('components.corporationsListTable.new-account') }}
            </v-btn>
            <v-menu left offset-y nudge-bottom="15" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="ml-3">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link @click="showImportDialog">
                  <v-list-item-content class="mr-6">
                    <v-list-item-title>{{ $t('global.importCsv') }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon size="20" color="#ccc">mdi-tray-arrow-up</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item link target="_blank" href="/templates/account_import_template.csv">
                  <v-list-item-content class="mr-6">
                    <v-list-item-title>{{ $t('global.csvTemplate') }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon size="20" color="#ccc">mdi-tray-arrow-down</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-row>
    </div>
    <div class="bs-table-table">
      <v-row no-gutters>
        <v-col class="col-without-top-padding table-color">
          <v-data-table
            class="bs-table elevation-0"
            calculate-widths
            :items="corporations"
            :headers="headers"
            :server-items-length="totalCorporations"
            :loading="corporationsLoading"
            :multi-sort="false"
            :must-sort="true"
            :single-expand="true"
            :page.sync="listRequest.page"
            :options.sync="options"
            :no-data-text="$t(`global.noData`)"
            :no-results-text="$t(`global.noResults`)"
            hide-default-footer
            @update:options="handleUpdateOptions"
          >
            <template v-slot:item.status="{ item }">
              <edit-icon @click="editCorporation(item)" class="mr-1 icon-control-list"/>
              <delete-icon @click="deleteCorporation(item)" class="mr-1 icon-control-list"/>
            </template>
            <template v-slot:item.communicationLanguage="{ item: { communicationLanguage } }">
              {{ languages[communicationLanguage] }}
            </template>
            <template v-slot:item.createdAt="{ item: { createdAt } }">
              {{ formatDate(createdAt) }}
            </template>
          </v-data-table>
          <div class="d-flex justify-space-between align-center py-2 px-4 bs-table-footer">
            <v-row no-gutters class="bs-per-page">
              <span>
                {{ $t(`global.perPage`) }}
              </span>
              <span
                v-for="item  in [10, 20, 30]"
                :key="item"
                class="px-1"
                :class="{ 'active-page-count': item === itemsPerPage }"
                @click="itemsPerPage = item"
              >
                {{ item }}
              </span>
            </v-row>
            <simple-paginator
              :page="listRequest.page + 1"
              :has-more="listRequest.hasMoreBookings"
              @goToFirst="this.firstPage"
              @previousPage="this.previousPage"
              @nextPage="nextPage"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';
import { longDateTime } from '@/helpers/dateTime';
import { languageList } from '@/mixins/language.mixins';
import SimplePaginator from "@/components/elements/SimplePaginator.vue";
import _ from "lodash";

export default {
  props: {
    totalCorporations: {
      type: Number,
      required: true,
    },
    corporations: {
      type: Array,
      required: true,
    },
    corporationsLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ...iconLoader.elements,
    SimplePaginator,
  },
  mixins: [languageList],
  data() {
    return {
      listRequest: {
        page: 0,
        hasMoreBookings: true,
      },
      itemsPerPage: 10,
      options: {},
      expanded: [],
      search: null,
      modalData: null,
      isProd: false,
    };
  },
  created() {
    this.debouncedLoad = _.debounce(this.loadData, 500);
  },
  watch: {
    corporations() {
      if (this.corporations.length < this.itemsPerPage) {
        this.listRequest.hasMoreBookings = false;
      } else {
        this.listRequest.hasMoreBookings = true;
      }
    },
    itemsPerPage() {
      this.listRequest.page = 0;
      this.loadData();
    },
    options: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    totalCorporations() {
      this.options.pageCount = Math.round(this.totalCorporations / this.itemsPerPage) + 1;
    },
    search(val) {
      if (!val) this.search = '';
      if (this.search.length >= 2 || this.search.length === 0) {
        this.debouncedLoad();
      }
      this.page = 1;
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('components.corporationsListTable.table-headers.accountName'),
          align: 'left',
          sortable: true,
          value: 'name',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.communication-lang'),
          align: 'left',
          sortable: true,
          value: 'communicationLanguage',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.main-contact'),
          align: 'left',
          sortable: true,
          value: 'contactEmail',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.createdAt'),
          align: 'left',
          sortable: true,
          value: 'createdAt',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.corporationsListTable.table-headers.code'),
          align: 'left',
          sortable: true,
          value: 'code',
          class: 'text-uppercase',
        },
        {
          align: 'right',
          sortable: false,
          value: 'status',
          class: 'text-uppercase',
        },
      ];
    },
  },
  methods: {
    showImportDialog() {
      this.$emit('import');
    },
    handleUpdateOptions(options) {
      this.options = options;
      this.loadData();
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.loadData();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.loadData();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.loadData();
    },
    async loadData() {
      const { sortBy, sortDesc } = this.options;
      const sort = sortBy[0] || 'name';
      this.$emit('callLoadData', {
        page: this.listRequest.page,
        maxPerPage: this.itemsPerPage,
        sort: sortDesc[0] ? `-${sort}` : sort,
        name: this.search,
      });
    },
    addCorporation() {
      this.$emit('addCorporation');
    },
    editCorporation(data) {
      this.$emit('editCorporation', data);
    },
    deleteCorporation(data) {
      this.$emit('deleteCorporation', data);
    },
    formatDate(date) {
      return longDateTime(date);
    },
  },
};
</script>
