import { PaymentMethodDomainTransformer } from "@/domain/paymentMethod/PaymentMethodDomainTransformer";
import { PaymentMethodDomainDelegate } from "@/domain/paymentMethod/PaymentMethodDomainDelegate";

const FORM_STEP_CODES = {
  PASSENGER_STEP: "PASSENGER_STEP",
  ADDRESS_STEP: "ADDRESS_STEP",
  COMPANY_STEP: "COMPANY_STEP",
  ACCOUNTS_STEP: "ACCOUNTS_STEP",
  CONFIRMATION_STEP: "CONFIRMATION_STEP",
};

// TODO - define operation types for booking form
// const OPERATION_TYPE = {
//   BOOKING_CREATE: 'BOOKING_CREATE',
//   BOOKING_UPDATE: 'BOOKING_UPDATE',
// };

export class BookingStepsFormPresenter {
  constructor({ interactor }) {
    console.log("[BookingStepsFormPresenter] constructor");

    /**
     * @type {BookingFormInteractor}
     */
    this.interactor = interactor;

    this.currentFormStepCode = FORM_STEP_CODES.PASSENGER_STEP;

    /**
     *
     * @type {BusinessBookingRecipientV1Dto | null}
     */
    this.selectedBusinessBookingRecipient = null;

    this.bookingRequestResponseDto = null;
    this.bookingConfirmResponseDto = null;

    this.offers = [];

    this.fleetAccountsViewModel = {
      isLoading: false,
      error: null,
      data: [],
    };

    this.accountFieldsViewModel = {
      isLoading: false,
      error: null,
      data: [],
    };
  }

  onBackClick() {
    if (this.currentFormStepCode === FORM_STEP_CODES.ADDRESS_STEP) {
      this.currentFormStepCode = FORM_STEP_CODES.PASSENGER_STEP;
    } else if (this.currentFormStepCode === FORM_STEP_CODES.COMPANY_STEP) {
      this.currentFormStepCode = FORM_STEP_CODES.ADDRESS_STEP;
    } else if (this.currentFormStepCode === FORM_STEP_CODES.ACCOUNTS_STEP) {
      this.currentFormStepCode = FORM_STEP_CODES.COMPANY_STEP;
    } else if (this.currentFormStepCode === FORM_STEP_CODES.CONFIRMATION_STEP) {
      this.currentFormStepCode = FORM_STEP_CODES.COMPANY_STEP;
    }
  }

  onMoveToFirstStep() {
    window.location.reload(); // reload page to reset bookingFormModel
  }

  goToPassengerStep() {
    this.currentFormStepCode = FORM_STEP_CODES.PASSENGER_STEP;
  }

  resetPassengerStep() {
    this.interactor.setBusinessRecipient({
      employeeId: null,
      guestId: null,
      givenName: null,
      familyName: null,
      phoneNumber: null,
      email: null,
      isAdhocGuest: false,
    });
  }

  getFormStepCode() {
    return this.currentFormStepCode;
  }

  /**
   *
   * @param {CreateBookingRecipient} businessRecipient
   */
  onPassengerStepSubmit(businessRecipient) {
    this.interactor.setBusinessRecipient(businessRecipient);
    this.currentFormStepCode = FORM_STEP_CODES.ADDRESS_STEP;

    // if (this.interactor.canFetchPaymentMethods()) {
    // I must then UPDATE the payment methods
    // }
  }

  /**
   *
   * @param {AddressItemDetailApiDto} origin
   * @param {AddressItemDetailApiDto} destination
   * @param { Array<AddressItemDetailApiDto>  }waypoints
   * @param {string} flightNumber
   * @param {string} scheduleType
   * @param {Moment|null} pickupTime
   */
  onAddressStepSubmit({ origin, destination, waypoints, flightNumber, scheduleType, pickupTime }) {
    this.interactor.setPickupAddress(origin);
    this.interactor.setDestinationAddress(destination);
    this.interactor.setWaypoints(waypoints);
    this.interactor.setFlightNumber(flightNumber);
    this.interactor.setPickupTime(pickupTime);
    this.interactor.setScheduleType(scheduleType);

    this.currentFormStepCode = FORM_STEP_CODES.COMPANY_STEP;

    if (this.interactor.canFetchOffers()) {
      // I must then UPDATE the offers
      // Make sure the first offer is pre-selected
      // Fetch the fare estimate
    }
  }

  /**
   * @param {string} fleetId
   * @param {string} offerId
   * @param {PaymentMethodDomainModel} paymentMethod
   * @param {number} numberOfVehicles
   * @param {boolean} shouldBookReturnBooking
   * @param {Moment|null} returnTime
   */
  async onCompanyStepSubmit({ fleetId, offerId, paymentMethod, numberOfVehicles, shouldBookReturnBooking, returnFlightNumber, returnTime, bookingNotes }) {
    this.interactor.setFleetId(fleetId);
    this.interactor.setOfferId(offerId);
    this.interactor.setPaymentMethod(paymentMethod);
    this.interactor.setNumberOfVehicles(numberOfVehicles);
    this.interactor.setShouldBookReturnBooking(shouldBookReturnBooking);
    this.interactor.setReturnFlightNumber(returnFlightNumber);
    this.interactor.setReturnBookingTime(returnTime);
    this.interactor.setBookingNotes(bookingNotes);
  }

  setDuplicateBooking(duplicateBooking) {
    this.interactor.setDuplicateBooking(duplicateBooking);
  }

  async startBookingCreationFlow() {
    // Create booking
    // Confirm booking

    // && if return booking
    // Create return booking
    // Confirm return booking
    this.currentFormStepCode = FORM_STEP_CODES.CONFIRMATION_STEP;
  }

  onAccountStepSubmit(accountFields) {
    this.interactor.setAccountFields(accountFields);
    this.currentFormStepCode = FORM_STEP_CODES.CONFIRMATION_STEP;
  }

  switchToAccountFieldsStep() {
    this.currentFormStepCode = FORM_STEP_CODES.ACCOUNTS_STEP;
  }

  shouldShowPassengerStep() {
    console.log("[BookingStepsFormPresenter] shouldShowPassengerStep", this.currentFormStepCode);
    return this.currentFormStepCode === FORM_STEP_CODES.PASSENGER_STEP;
  }

  shouldShowAddressStep() {
    console.log("[BookingStepsFormPresenter] shouldShowAddressStep", this.currentFormStepCode);
    return this.currentFormStepCode === FORM_STEP_CODES.ADDRESS_STEP;
  }

  shouldShowCompanyStep() {
    console.log("[BookingStepsFormPresenter] shouldShowCompanyStep", this.currentFormStepCode);
    return this.currentFormStepCode === FORM_STEP_CODES.COMPANY_STEP;
  }

  shouldShowAccountStep() {
    console.log("[BookingStepsFormPresenter] shouldShowAccountStep", this.currentFormStepCode);
    return this.currentFormStepCode === FORM_STEP_CODES.ACCOUNTS_STEP;
  }

  shouldShowConfirmationStep() {
    console.log("[BookingStepsFormPresenter] shouldShowConfirmationStep", this.currentFormStepCode);
    return this.currentFormStepCode === FORM_STEP_CODES.CONFIRMATION_STEP;
  }

  shouldShowBusinessAccountConfigModal() {
    return this.interactor.doesAccountRequireCustomFields();
  }

  shouldInitCreditCardConfirmationFlow() {}

  canFetchOffers() {
    const bookingFormModel = this.interactor.bookingFormModel;

    return (
      !!bookingFormModel.origin &&
      !!bookingFormModel.destination &&
      !!bookingFormModel.businessAccountId
    );
  }

  onBusinessAccountIdChange(businessAccountId) {
    this.interactor.bookingFormModel.businessAccountId = businessAccountId;

    if (this.currentFormStepCode === FORM_STEP_CODES.PASSENGER_STEP) return false;
    return true;
  }

  buildFareEstimationQuery() {
    return {
      pickupTime: this.interactor.bookingFormModel.pickupTime || null,
      origin: {
        latitude: this.interactor.bookingFormModel.origin.coordinates.latitude,
        longitude: this.interactor.bookingFormModel.origin.coordinates.longitude,
      },
      destination: {
        latitude: this.interactor.bookingFormModel.destination.coordinates.latitude,
        longitude: this.interactor.bookingFormModel.destination.coordinates.longitude,
      },
      waypoints: this.interactor.bookingFormModel.waypoints || [],
      bookingChannelId: this.interactor.bookingFormModel.bookingChannelId,
      corporationId: this.interactor.bookingFormModel.businessAccountId,
    };
  }

  /**
   *
   * @param {String} query
   * @return {Array<BusinessBookingRecipientV1Dto>}
   */
  searchBusinessRecipients(query) {
    return this.interactor.searchBusinessRecipients(query);
  }

  /**
   * @params {AddressQueryApiDto} query
   * @returns {AddressSuggestionApiDto[]}
   */
  async fetchAddressSuggestions(query) {
    return await this.interactor.fetchAddressSuggestions(query);
  }

  /**
   * @param {AddressQueryDetailsApiDto} query
   * @returns {AddressItemDetailApiDto}
   */
  async fetchAddressDetails(query) {
    return await this.interactor.fetchAddressDetails(query);
  }

  /**
   *
   * @return {Promise<PaymentMethodDomainModel[]>}
   */
  async fetchPaymentMethods() {
    const apiPaymentMethods = await this.interactor.fetchPaymentMethods({
      corporationId: this.interactor.bookingFormModel.businessAccountId,
      paymentProviderName: this.interactor.bookingFormModel.paymentProviderName,
    });

    return [
      PaymentMethodDomainTransformer.makeBusinessDispatchInvoice({
        businessAccountId: this.interactor.bookingFormModel.businessAccountId,
      }),
      PaymentMethodDomainTransformer.makeCashDomainModel(),
      ...apiPaymentMethods.map((apiPaymentMethod) => {
        return PaymentMethodDomainTransformer.fromPaymentMethodApiV1Dto(apiPaymentMethod);
      }),
    ];
  }

  /**
   * @param {FareEstimateQuery} query
   * @returns {FareResponceApiDto}
   */
  async fetchFareEstimation({ offerId, paymentMethodType }) {
    return await this.interactor.fetchFareEstimate({
      bookingChannelId: this.interactor.bookingFormModel.bookingChannelId,
      corporationId: this.interactor.bookingFormModel.businessAccountId,
      offerId,
      paymentMethodType: PaymentMethodDomainDelegate.isTypeCreditCard(paymentMethodType)
        ? "personalCard"
        : paymentMethodType,
      pickupTime:
        this.interactor.bookingFormModel.scheduleType === "PREBOOKING"
          ? this.interactor.bookingFormModel.pickupTime
          : undefined,
      origin: {
        latitude: this.interactor.bookingFormModel.origin.coordinates.latitude,
        longitude: this.interactor.bookingFormModel.origin.coordinates.longitude,
      },
      destination: {
        latitude: this.interactor.bookingFormModel.destination.coordinates.latitude,
        longitude: this.interactor.bookingFormModel.destination.coordinates.longitude,
      },
      waypoints: this.interactor.bookingFormModel.waypoints.map((waypoint) => {
        return {
          latitude: waypoint.coordinates.latitude,
          longitude: waypoint.coordinates.longitude,
        };
      }) || [],
    });
  }

  async fetchFleetAccounts() {
    return await this.interactor.fetchFleetAccounts({
      corporationId: this.interactor.bookingFormModel.businessAccountId,
      fleetId: this.interactor.bookingFormModel.fleetId,
    });
  }

  /**
   * @param {string} accountId
   * @returns {Promise<BusinessAccountFieldApiDto[]>}
   */
  async fetchAccountFields(accountId) {
    return await this.interactor.fetchAccountFields({
      accountId: accountId,
      bookingChannelId: this.interactor.bookingFormModel.bookingChannelId,
      fleetId: this.interactor.bookingFormModel.fleetId,
    });
  }

  /**
   * @returns {Promise<BusinessAccountFieldApiDto[]>}
   */
  async fetchFavouriteAddresses() {
    return await this.interactor.fetchFavouriteAddresses({
      businessAccountId: this.interactor.bookingFormModel.businessAccountId,
      page: 0,
      maxPerPage: 10,
    });
  }

  /**
   *
   * @return {CreateBookingRecipient}
   */
  getRecipient() {
    return this.interactor.bookingFormModel.createBookingRecipient;
  }

  getAddresses() {
    return {
      origin: this.interactor.bookingFormModel.origin,
      destination: this.interactor.bookingFormModel.destination,
      waypoints: this.interactor.bookingFormModel.waypoints,
      flightNumber: this.interactor.bookingFormModel.flightNumber,
      scheduleType: this.interactor.bookingFormModel.scheduleType,
    };
  }

  getPickupTime() {
    return {
      pickupTime: this.interactor.bookingFormModel.pickupTime,
      scheduleType: this.interactor.bookingFormModel.scheduleType,
    };
  }

  getReturnBooking() {
    return {
      shouldBookReturnBooking: this.interactor.bookingFormModel.shouldBookReturnBooking,
      returnBookingTime: this.interactor.bookingFormModel.returnBookingTime,
    };
  }

  /**
   *
   * @param {BusinessBookingRecipientV1Dto | null} businessBookingRecipient
   */
  setSelectedBusinessBookingRecipient(businessBookingRecipient) {
    this.selectedBusinessBookingRecipient = businessBookingRecipient;
  }

  getOffers() {
    return this.offers;
  }

  async fetchOffers() {
    const { offers } = await this.interactor.fetchOffers({
      corporationId: this.interactor.bookingFormModel.businessAccountId,
      origin: this.interactor.bookingFormModel.origin,
      destination: this.interactor.bookingFormModel.destination,
    });

    this.offers = offers;
    return this.offers;
  }
}
