import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';
import _ from 'lodash';
import { Env } from '@/env';
import { AuthUtil } from '@/core/util/AuthUtil';
import momentTz from 'moment-timezone';

const defaultState = {
  businessReportingServiceConnector: null,
  businessUserServiceConnector: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  businessReportingServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.businessReportingServiceConnector) {
      state.businessReportingServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.BusinessReportingServiceConnector });
    }
    return state.businessReportingServiceConnector;
  },
  getCorporationAdminsReportDownloadUrl: (state, getters, rootState, rootGetters) => ({ corporationId }) => {
    const lang = rootGetters['language/selectedLanguage'];
    const token = rootGetters['authentication/token'];
    const bearerToken = `Bearer ${token}`;
    const queryParams = _.omitBy({
      corporationId,
      lang,
      peat: AuthUtil.encryptBearerToken(bearerToken),
    }, _.isNil);
    const queryString = _.map(queryParams, (value, key) => `${key}=${value}`).join('&');
    const apiGatewayUrl = Env.getApiGatewayUrl();
    return `${apiGatewayUrl}/v1/business/reports/corporation-admins?${queryString}`;
  },
  getBusinessBookingRecipientsReportDownloadUrl: (state, getters, rootState, rootGetters) => ({ corporationId }) => {
    const lang = rootGetters['language/selectedLanguage'];
    const token = rootGetters['authentication/token'];
    const bearerToken = `Bearer ${token}`;
    const queryParams = _.omitBy({
      businessAccountId: corporationId,
      lang,
      peat: AuthUtil.encryptBearerToken(bearerToken),
    }, _.isNil);
    const queryString = _.map(queryParams, (value, key) => `${key}=${value}`).join('&');
    const apiGatewayUrl = Env.getApiGatewayUrl();
    return `${apiGatewayUrl}/v1/business-booking-recipient/generate-report?${queryString}`;
  },
  // eslint-disable-next-line no-shadow
  getReportDownloadUrl: (state, getters, rootState, rootGetters) => ({
                                                                       filename, corporationId, startDate, endDate,
                                                                     }) => {
    const lang = rootGetters['language/selectedLanguage'];
    const bookingChannelId = rootGetters['bookingChannel/id'];
    const token = rootGetters['authentication/token'];
    const bearerToken = `Bearer ${token}`;
    const queryParams = _.omitBy({
      bookingChannelId,
      fileName: filename,
      businessAccountId: corporationId,
      pickupTimeFrom: momentTz.tz(startDate, 'UTC').toISOString(),
      pickupTimeTo: momentTz.tz(endDate, 'UTC').toISOString(),
      lang,
      peat: AuthUtil.encryptBearerToken(bearerToken),
    }, _.isNil);
    const queryString = _.map(queryParams, (value, key) => `${key}=${value}`).join('&');
    const apiGatewayUrl = Env.getApiGatewayUrl();
    return `${apiGatewayUrl}/v1/booking-report/trip-history?${queryString}`;
  },
};

const actions = {
  generateReport: apiCall(async (context, {
    corporationId, startDate, endDate, filename,
  }) => {
    const lang = context.rootGetters['language/selectedLanguage'];
    return context.getters.businessReportingServiceConnector(context).generateReport({
      corporationId, startDate, endDate, filename, lang,
    });
  }),

  generateBookingReport: apiCall(async (context, {
    filter, page, maxPerPage, corporationId, startDate, endDate, sort,
  }) => context.getters.businessReportingServiceConnector(context).getReportBookings({
    filter, page, maxPerPage, corporationId, startDate, endDate, sort,
  })),
};

const mutations = {};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
