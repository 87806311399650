<template>
  <v-card>
    <v-card-title class="pa-8 pb-6">
      {{ $t('components.employeeFormImport.title') }}
    </v-card-title>
    <v-card-text class="px-8" v-if="!showProgress">
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.employeeFormImport.account') }}</div>
          <corporations-select @onSelectedItem="corporation" :setSelected="false" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.employeeFormImport.file') }}</div>
          <v-file-input outlined filled hide-details show-size
                        prepend-icon=""
                        v-model="file"
                        accept=".csv, text/csv"
                        @change="loadFile"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>{{ $t('components.employeeFormImport.explanation') }} <i>{{ $t('components.employeeFormImport.export') }}</i>.</v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="px-8" v-else>
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.employeeFormImport.progress') }}</div>
          <v-progress-linear height="25"
                             v-model="importProgress"
          >
            <template v-slot:default="{ value }">
              {{ Math.ceil(value) }}%
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="label mb-1">{{ $t('components.employeeFormImport.summary.status') }}</div>
          <v-list class="summary">
            <v-list-item v-if="summary.imported">
              <v-list-item-content>{{ $t('components.employeeFormImport.summary.imported', { count: summary.imported }) }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="summary.existing">
              <v-list-item-content >{{ $t('components.employeeFormImport.summary.existing', { count: summary.existing }) }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="summary.failed">
              <v-list-item-content>{{ $t('components.employeeFormImport.summary.failed', { count: summary.failed }) }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end pa-8 pt-3">
      <v-btn text plain large class="pa-3 pl-0 cancel" @click="cancel"
             :disabled="importing"
      >
        {{ $t('components.employeeFormImport.cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn dark large class="pa-3" color="#333333" elevation="0"
             :disabled="!corporation || !showProgress && !importData.length || importing"
             @click="doImport"
      >
        {{ showProgress
        ? $t('components.employeeFormImport.done')
        : importData.length
          ? $t('components.employeeFormImport.add_count', { count: importData.length })
          : $t('components.employeeFormImport.add') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { find } from 'lodash';
import Papa from 'papaparse';
import PQueue from 'p-queue';
import CorporationsSelect from '@/components/corporations/CorporationsSelect.vue';

const IMPORT_SUMMARY_DEFAULT = {
  imported: 0,
  failed: 0,
  existing: 0,
};

export default {
  components: {
    CorporationsSelect,
  },
  data: () => ({
    importing: false,
    showProgress: false,
    file: null,
    importData: [],
    importProgress: 0,
    corporations: [],
    corporation: null,
    corporationsLoading: false,
    summary: { ...IMPORT_SUMMARY_DEFAULT },
  }),
  async mounted() {
    this.corporationsLoading = true;
    const { data } = await this.$store.dispatch('corporations/listCorporations', { maxPerPage: 100, sort: 'name' });
    this.corporations = data ?? [];
    this.resetCorporation();
    this.corporationsLoading = false;
  },
  computed: {
    ...mapGetters({
      preselectedCorporation: 'corporations/getSelectedCorporationId',
    }),
  },
  watch: {
    preselectedCorporation() {
      this.resetCorporation();
    },
  },
  methods: {
    reset() {
      this.importProgress = 0;
      this.file = null;
      this.importData = [];
      this.showProgress = false;
      this.corporation = null;
      this.summary = { ...IMPORT_SUMMARY_DEFAULT };
    },
    cancel() {
      this.$emit('cancel');
      this.reset();
    },
    resetCorporation() {
      if (this.preselectedCorporation) this.corporation = find(this.corporations, { id: this.preselectedCorporation });
      else this.corporation = null;
    },
    async loadFile(file) {
      const phone = (countryCode, number) => {
        const phoneCountryCode = (countryCode && !countryCode.startsWith('+') && !countryCode.startsWith('0') ? `+${countryCode}` : countryCode);
        return phoneCountryCode + number;
      };
      if (file) {
        this.importData = (await this.parseCsv(file)).data.map((user) => ({
          givenName: user[0],
          familyName: user[1],
          email: user[2],
          phone: phone(user[3], user[4]),
        }));
      } else this.importData = [];
      if (this.importData && this.importData[0].givenName === 'First Name') this.importData.splice(0, 1);
    },
    parseCsv: (file) => new Promise((resolve, reject) => Papa.parse(file, { complete: resolve, error: reject })),
    async doImport() {
      if (this.showProgress) {
        this.cancel();
        return;
      }

      const numUsers = this.importData.length;
      this.importing = true;
      this.showProgress = true;

      const queue = new PQueue({ concurrency: 5 });
      let count = 0;

      const progress = () => {
        this.importProgress = ++count / numUsers * 100;
        this.summary.imported = count - this.summary.existing - this.summary.failed;
      };
      const errorHandler = (error) => {
        if (error.code === 'EMPLOYEE-SERVICE-03') this.summary.existing++;
        else this.summary.failed++;
      };
      const mapper = (user) => {
        const data = { ...user, corporationId: this.corporation.id, errorHandler };
        const store = this.$store;
        return () => store.dispatch('employee/createEmployee', data);
      };

      queue.on('next', progress.bind(this));
      await queue.addAll(this.importData.map(mapper.bind(this)));

      this.importing = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  text-transform: uppercase
  font-size: 12px

.v-file-input::v-deep .v-input__prepend-outer
  margin-top: 12px !important

.v-btn
  text-transform: initial

.v-btn.cancel
  color: #888888

.v-btn.theme--dark.v-btn--disabled
  background-color: #272727 !important

.v-list.summary
  padding: 0

  .v-list-item
    min-height: initial

  .v-list-item__content
    padding: 6px 0
</style>
