/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';

function initialState() {
  return {
    locationFrom: {
      description: '',
      detail: {
        description: '',
        coordinates: null,
      },
    },
    locationTo: {
      description: '',
      detail: {
        description: '',
        coordinates: null,
      },
    },
    location: null,
    maxAmount: {
      amount: 0,
      maxAmountRuleType: 'day',
      type: 'maxAmount',
    },
    frequency: {
      maxNumberOfBookings: 0,
      frequencyRuleType: 'day',
      type: 'frequency',
    },
    dateTime: [],
  };
}

export const initTravelRestrictionEntity = {
  data: () => ({
    restrictionListParams: {
      page: 0,
      maxPerPage: 10,
      sort: '-createdAt',
    },
    isSavedEntities: {
      location: false,
      maxAmount: false,
      frequency: false,
    },
    restrictionRulesParams: {
      page: 0,
      maxPerPage: 10,
      sort: '-createdAt',
      type: null,
    },
    restrictionList: [],
    intersection: false,
    restrictionTotalCount: {
      totalCount: 0,
    },
    ruleTypes: ['location', 'maxAmount', 'frequency', 'dateTime'],
    groupRules: {
      locationFrom: {
        description: '',
        detail: {
          description: '',
          coordinates: null,
        },
      },
      locationTo: {
        description: '',
        detail: {
          description: '',
          coordinates: null,
        },
      },
      location: null,
      maxAmount: {
        amount: 0,
        maxAmountRuleType: 'day',
        type: 'maxAmount',
      },
      frequency: {
        maxNumberOfBookings: 0,
        frequencyRuleType: 'day',
        type: 'frequency',
      },
      dateTime: [],
    },
  }),
  watch: {
    restrictionListParams: {
      handler() {
        this.getTravelRestrictionList();
      },
      deep: true,
    },
    getSelectedCorporationId: {
      handler() {
        this.intersection = false;
        this.restrictionListParams.page = 0;
        this.getTravelRestrictionList();
      },
    },
  },
  mounted() {
    this.getTravelRestrictionList();
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    ...mapGetters('employee', ['getCreatedGroupId', 'getCreatedGroupName']),
    ...mapGetters('travelRestriction', ['getTravelRestrictionId', 'getTravelRestrictionRuleId']),
  },
  methods: {
    async resetTravelRestrictions() {
      const promises = [];
      this.ruleTypes.forEach((type) => {
        const rule = this.getTravelRestrictionRuleId(type);

        if (rule && !Array.isArray(rule)) {
          const promise = this.deleteTravelRestrictionRule({
            ids: {
              ruleId: rule,
              travelRestrictionId: this.getTravelRestrictionId,
            },
            type,
          });

          promises.push(promise);
        } else if (rule && Array.isArray(rule)) {
          rule.forEach(({ id }) => {
            const promise = this.deleteTravelRestrictionRule({
              ids: {
                ruleId: id,
                travelRestrictionId: this.getTravelRestrictionId,
              },
              type,
            });

            promises.push(promise);
          });
        }
      });

      const result = await Promise.all(promises);

      return result;
    },
    resetGroupRules() {
      Object.assign(this.$data.groupRules, initialState());
    },
    setLocation(location) {
      this.groupRules.locationFrom = {
        description: location?.originAddress,
        detail: {
          description: location?.originAddress,
          coordinates: {
            longitude: location?.originCoordinates.coordinates[0],
            latitude: location?.originCoordinates.coordinates[0],
          },
        },
      };

      this.groupRules.locationTo = {
        description: location?.destinationAddress,
        detail: {
          description: location?.destinationAddress,
          coordinates: {
            longitude: location?.destinationCoordinates.coordinates[0],
            latitude: location?.destinationCoordinates.coordinates[0],
          },
        },
      };
    },
    async setRules(restrictionId) {
      this.$store.commit('travelRestriction/setCreatedRestrictionId', restrictionId);
      await this.storeGroupRules();
    },
    storeGroupRules() {
      this.ruleTypes.forEach(async (el) => {
        this.restrictionRulesParams.type = el;
        const result = await this.getRestrictionRulesByType(this.getTravelRestrictionId);

        if (result.length === 0) {
          this.$store.commit('travelRestriction/resetRuleId', el);
          return;
        }

        if (el === 'location') {
          this.setLocation(result[0]);
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.groupRules[el] = el === 'dateTime' ? result : result[0];
        }

        this.$store.commit('travelRestriction/setRuleId', { type: el, value: result });
      });
    },
    async getRestrictionRules(id) {
      const promises = [];
      this.ruleTypes.forEach((el) => {
        this.restrictionRulesParams.type = el;
        promises.push(this.getRestrictionRulesByType(id));
      });

      return (await Promise.all(promises)).filter((el) => el.length);
    },
    async initRestriction() {
      await this.createTravelRestriction({ name: this.getCreatedGroupName, corporationId: this.getSelectedCorporationId });
    },
    async manageRules(type) {
      if (!this.getTravelRestrictionId && this.getCreatedGroupId) await this.initRestriction();

      if (type === 'location') {
        this.formatLocationRule();
        this.isSavedEntities[type] = true;
        return true;
      }

      delete this.groupRules[type].id;
      delete this.groupRules[type].travelRestrictionId;

      const params = { data: { ...this.groupRules[type] }, id: this.getTravelRestrictionId };

      this.actionRestrictionRule(params, type);
      this.isSavedEntities[type] = true;
      return true;
    },
    async actionRestrictionRule(params, type) {
      const existingRule = this.getTravelRestrictionRuleId(type);

      if (existingRule || existingRule?.id) {
        params.ruleId = existingRule?.id || existingRule;
        params.travelRestrictionId = this.getTravelRestrictionId;

        // eslint-disable-next-line no-unused-expressions
        if (type === 'dateTime') {
          await this.updateTravelRestrictionRule(params);
        } else {
          await this.deleteTravelRestrictionRule({
            ids: {
              ruleId: existingRule?.id || existingRule,
              travelRestrictionId: this.getTravelRestrictionId,
            },
            type,
          });
          delete params.ruleId;
          delete params.travelRestrictionId;

          await this.actionRestrictionRule(params);
        }
      } else {
        params.id = this.getTravelRestrictionId;

        if (params.data.type !== 'maxAmount' && params.data.type !== 'frequency') {
          await this.createTravelRestrictionRule(params);
        } else {
          const amount = params.data.type === 'maxAmount' ? this.groupRules.maxAmount.amount : this.groupRules.frequency.maxNumberOfBookings;

          if (amount > 0) {
            await this.createTravelRestrictionRule(params);
          }
        }
      }
    },
    async copyExistingRestriction(id, params) {
      if (this.getCreatedGroupId) {
        this.setRestrictionRules(this.getRestrictionRules(id), params);
      }
    },
    async setRestrictionRules(rules, params) {
      await this.createTravelRestriction(params);

      rules.forEach((el) => {
        el.forEach(async (item) => {
          const createParams = { data: { ...item }, id: this.getTravelRestrictionId };
          delete createParams.data.id;

          await this.createTravelRestrictionRule(createParams);
        });
      });

      await this.storeGroupRules();
    },
    async getRestrictionRulesByType(id) {
      const result = await this.$store.dispatch('travelRestriction/getTravelRestrictionRulesById', { id, ...this.restrictionRulesParams });
      return result.data;
    },
    async associateGroupToRestriction(groupId, restrictionId) {
      const params = {
        associatedIds: [`${groupId}`],
        type: 'employeeGroups',
        restrictionId,
      };

      const result = await this.$store.dispatch('travelRestriction/associateGroupToRestriction', params);
      return result;
    },
    async createTravelRestriction(params) {
      if (params) {
        const result = await this.$store.dispatch('travelRestriction/createTravelRestriction', params);
        await this.$store.dispatch('employee/updateEmployeeGroup', {
          restrictionId: result.id,
          id: this.getCreatedGroupId,
          name: params.name,
          description: params.name,
        });

        this.$store.commit('travelRestriction/setCreatedRestrictionId', result.id);
      }
    },
    async createTravelRestrictionRule(params) {
      const result = await this.$store.dispatch('travelRestriction/createTravelRestrictionRule', params);

      this.$store.commit('travelRestriction/setRuleId', { type: params.data.type, value: result });
    },
    async updateTravelRestrictionRule(params) {
      await this.$store.dispatch('travelRestriction/updateTravelRestrictionRule', params);
    },
    async deleteTravelRestrictionRule(params) {
      const result = await this.$store.dispatch('travelRestriction/deleteTravelRestrictionRule', params);
      return result;
    },
    async getTravelRestrictionList() {
      this.restrictionListParams.corporationId = this.getSelectedCorporationId ? this.getSelectedCorporationId : null;

      const result = await this.$store.dispatch('travelRestriction/listTravelRestrictions', this.restrictionListParams);
      this.restrictionTotalCount = this.formatHeaders(result.headers);

      if (this.intersection) {
        this.restrictionList.push(...result.data);
      } else {
        this.restrictionList = result.data;
      }
    },
    formatHeaders(headers) {
      return {
        totalCount: Number(headers['x-total-count'] || 0),
      };
    },
    formatLocationRule() {
      if (this.groupRules.locationFrom.description && this.groupRules.locationTo.description) {
        const params = {
          data: {
            locationRuleType: 'between',
            type: 'location',
            originAddress: this.groupRules.locationFrom.description,
            originCoordinates: this.groupRules.locationFrom.detail.coordinates,
            destinationAddress: this.groupRules.locationTo.description,
            destinationCoordinates: this.groupRules.locationTo.detail.coordinates,
          },
          id: this.getTravelRestrictionId,
        };

        this.actionRestrictionRule(params, 'location');
      } else if (!this.groupRules.locationFrom.description) {
        const params = {
          data: {
            type: 'location',
            locationRuleType: 'to',
            originAddress: this.groupRules.locationTo.description,
            originCoordinates: this.groupRules.locationTo.detail.coordinates,
          },
          id: this.getTravelRestrictionId,
        };

        this.actionRestrictionRule(params, 'location');
      } else {
        const params = {
          data: {
            type: 'location',
            locationRuleType: 'from',
            originAddress: this.groupRules.locationFrom.description,
            originCoordinates: this.groupRules.locationFrom.detail.coordinates,
          },
          id: this.getTravelRestrictionId,
        };

        this.actionRestrictionRule(params, 'location');
      }
    },
    async setDateTime(data) {
      if (!this.getTravelRestrictionId && this.getCreatedGroupId) await this.initRestriction();

      // find existing dateTime rules
      const existingDateTimeRule = this.getTravelRestrictionRuleId('dateTime')
        .find((el) => el.day === data.dateTimeRuleType);

      if (existingDateTimeRule) {
        const params = {
          data: { ...data },
          ruleId: existingDateTimeRule.id,
          travelRestrictionId: this.getTravelRestrictionId,
        };
        await this.updateTravelRestrictionRule(params);
        return 'updated';
      }

      const params = {
        data: { ...data },
        id: this.getTravelRestrictionId,
      };
      await this.createTravelRestrictionRule(params);
      return 'created';
    },
  },
};
