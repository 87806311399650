<template>
  <v-container class="pa-0 full-view">
    <booking-map :waypoints="waypoints" />
    <v-dialog
      contained
      hide-overlay
      no-click-animation
      persistent
      :content-class="`${
        navDrawerOpen ? 'modal-panel-left-nav' : 'modal-panel-left, booking-dialog'
      }`"
      origin="top center"
      max-width="488"
      value="true"
    >
      <BookingFormContainer v-if="$route.name === 'book-v2'" />
      <booking-form
        v-else
        :booking="booking"
        :loading="loading"
        :state="bookingState"
        @created="$router.push({ name: 'trip-list' })"
        @updated="returnToPrebookingTab"
        @cancel="$router.go(-1)"
        @cancelUpdate="$router.go(-1)"
        @address="addressChanged"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import BookingMap from "@/components/booking/Map.vue";
import BookingForm from "@/components/booking/BookingForm.vue";
import BookingFormContainer from "../bookingForm/BookingFormContainer.vue";

export default {
  components: { BookingMap, BookingForm, BookingFormContainer },
  computed: {
    ...mapGetters({
      navDrawerOpen: "navigation/drawerOpen",
      getAddresses: "bookings/getAddresses",
    }),
    bookingState() {
      if (!this.$route.meta.state) {
        return this.state;
      }
      // eslint-disable-next-line consistent-return
      return this.$route.meta.state;
    },
  },
  data: () => ({
    waypoints: [],
    booking: null,
    loading: false,
    state: "new",
  }),
  async mounted() {
    await this.loadBooking(this.$route.params.id);
  },
  watch: {
    getAddresses: {
      handler(val) {
        this.addressChanged(val);
      },
      deep: true,
    },
  },
  methods: {
    addressChanged(val) {
      const waypoints = [];
      const address = (addr, type) => ({
        lat: addr.detail ? addr.detail.coordinates.latitude : addr.coordinates.latitude,
        lng: addr.detail ? addr.detail.coordinates.longitude : addr.coordinates.longitude,
        address: addr.description,
        type,
      });

      if (val.pickup) waypoints.push(address(val.pickup, "pickup"));
      if (val.waypoints && val.waypoints.length) {
        val.waypoints.forEach((waypoint) => {
          if (waypoint) waypoints.push(address(waypoint, "waypoint"));
        });
      }

      if (val.dropoff) waypoints.push(address(val.dropoff, "dropoff"));

      this.waypoints = waypoints;
    },
    async loadBooking(id) {
      if (!id) return;

      this.loading = true;
      this.booking = await this.$store.dispatch("tripsHistory/getBookingDetails", id);
      this.loading = false;
    },
    returnToPrebookingTab() {
      this.$router.push({ name: "trip-list", params: { tab: "pre-bookings" } });
    },
  },
};
</script>

<style lang="sass">
.booking-dialog
  position: absolute
  z-index: 1000
  top: 50px
  left: 75px

.v-dialog__content
  top: 15px
.modal-panel-left
  margin-left: calc(-1 * calc(100% - 720px))
  overflow-x: clip !important
  overflow-y: auto !important

.modal-panel-left-nav
  margin-left: calc(-1 * calc(100% - 1010px))
</style>
