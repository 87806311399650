<template>
  <v-app style="background-color: #dbdbdb">
    <notifications position="top center" />
    <!-- <notifications group="request-notifications" position="top right" /> -->
    <notifications group="actions-notification" position="top center">
      <template v-slot:body="{ item, close }">
        <div class="red white--text pa-2">
          <p class="body-1 mb-0">{{ item.title }}</p>
          <span class="body-2 font-weight-light">{{ item.text }}</span>
          <div class="d-flex justify-end">
            <v-btn x-small text @click="close">
              Close
            </v-btn>
            <v-btn class="white--text ml-1" outlined x-small @click="item.data.callback()">
              Retry
            </v-btn>
          </div>
        </div>
      </template>
    </notifications>
    <vue-headful :title="$t('app.title', { companyName: this.companyName })" />
    <v-main id="main-view">
      <top-bar-header v-if="showSidebar" />
      <v-container fluid fill-height :class="full ? 'pa-0' : 'px-10'">
        <navigation v-if="showSidebar && hasAccess(CORP_ADMIN)" :displayName="meDisplayName" />
        <fm-navigation v-if="showSidebar && hasAccess(CHANNEL_ADMIN)" :displayName="meDisplayName" />
        <router-view id="main-router-view" :class="full ? 'ma-0 full' : ''" />
      </v-container>
      <version />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { initLocale } from "@/mixins/language.mixins";
import { initBookingChannel, bookingChannelComputed } from "@/mixins/bookingChannel.mixins";
import Navigation from "@/components/navigation/Navigation.vue";
import TopBarHeader from "@/components/navigation/TopBarHeader.vue";
import { getMe } from "@/mixins/users.mixins";
import Version from "./components/Version.vue";
import { getRoles } from "@/mixins/authentication.mixins";
import { CORP_ADMIN, CHANNEL_ADMIN } from '@/helpers/userRoles';
import FmNavigation from "@/components/navigation/FMNavigation.vue";

export default {
  data() {
    return {
      CORP_ADMIN,
      CHANNEL_ADMIN,
    };
  },
  components: {
    FmNavigation,
    Navigation,
    TopBarHeader,
    Version,
  },
  mixins: [initLocale, initBookingChannel, bookingChannelComputed, getMe, getRoles],
  async mounted() {
    await this.$store.dispatch("users/getUserRole");
  },
  computed: {
    ...mapGetters("authentication", ["idToken"]),
    showSidebar() {
      return this.$route.meta?.sidebar;
    },
    full() {
      return this.$route.meta?.full;
    },
  },
  watch: {
    idToken() {
      this.$store.dispatch("users/getUserRole");
    },
  },
};
</script>

<style lang="sass" scoped>
#main-view
  overflow-y: hidden

#main-router-view
  height: 100%
  width: 100%

#main-router-view.full
  max-width: initial
</style>
