<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <div class="bs-table-header pt-8">
      <v-row justify="space-between" class="align-end mb-3 px-4">
        <v-col cols="4">
          <h1 class="bs-header-title">
            {{ $t("components.favourites.title") }}
          </h1>
        </v-col>
        <v-spacer />
        <v-col class="d-flex align-center justify-end">
          <v-btn class="btn btn-default" @click="$emit('addFavourite')" height="36px" depressed>
            <v-icon left>mdi-plus</v-icon>
            {{ $t("components.favourites.add") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="bs-table-table">
      <v-row v-if="results.length > 0" no-gutters>
        <v-col class="col-withou-top-padding table-color">
          <v-data-table
            class="bs-table elevation-0"
            :headers="headers"
            :items="results"
            :server-items-length="totalFavourites"
            calculate-widths
            :multi-sort="false"
            :must-sort="true"
            :single-expand="true"
            :page.sync="listRequest.page"
            :options.sync="options"
            :no-data-text="$t(`global.noData`)"
            :no-results-text="$t(`global.noResults`)"
            hide-default-footer
            @update:options="handleUpdateOptions"
          >
            <template v-slot:item.alias="{ item }">
              <div class="d-flex align-center">
                <favourites-icon style="color: #47d061; width: 24px" />
                <span class="ml-2">{{ item.alias }}</span>
              </div>
            </template>
            <template v-slot:item.suggestForPickupAddress="{ item }">
              <v-checkbox
                v-model="item.suggestForPickupAddress"
                hide-details
                :ripple="false"
                class="ma-0"
                color="#47D061"
                on-icon="mdi-check-circle-outline"
                off-icon="mdi-checkbox-blank-circle-outline"
                @click="handleFavouriteAddressSuggestion(item)"
              />
            </template>

            <template v-slot:item.suggestForDestinationAddress="{ item }">
              <v-checkbox
                v-model="item.suggestForDestinationAddress"
                color="#47D061"
                hide-details
                :ripple="false"
                class="ma-0"
                on-icon="mdi-check-circle-outline"
                off-icon="mdi-checkbox-blank-circle-outline"
                @click="handleFavouriteAddressSuggestion(item)"
              />
            </template>

            <template v-slot:item.actions="{ item }">
              <delete-icon @click="removeFavouriteAddress(item.id)" class="icon-control-list" />
            </template>
          </v-data-table>
          <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
            <v-row no-gutters class="bs-per-page">
              <span>
                {{ $t(`components.tripsHistory.perPage`) }}
              </span>
              <span
                v-for="item in [10, 20, 30]"
                :key="item"
                class="px-1"
                :class="{ 'active-page-count': item === itemsPerPage }"
                @click="itemsPerPage = item"
              >
                {{ item }}
              </span>
            </v-row>
            <simple-paginator
              :page="listRequest.page + 1"
              :has-more="listRequest.hasMoreBookings"
              @goToFirst="this.firstPage"
              @previousPage="this.previousPage"
              @nextPage="nextPage"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="empty-state">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <favourites-icon style="color: #47d061; width: 50px" />
          <span class="empty-state-text mt-5">
            {{ $t("components.favourites.table.empty-state") }}
          </span>
          <span class="empty-state-description">
            {{ $t("components.favourites.table.empty-state-description") }}
          </span>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { iconLoader } from "@/helpers/iconsLoader";
import { mapGetters } from "vuex";

import SimplePaginator from "@/components/elements/SimplePaginator.vue";

export default {
  name: "favourite-table",
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    totalFavourites: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ...iconLoader.favourites,
    ...iconLoader.elements,
    SimplePaginator,
  },
  data: () => ({
    listRequest: {
      page: 0,
      hasMoreBookings: true,
    },
    itemsPerPage: 10,
    options: {},
  }),
  watch: {
    getSelectedCorporationId: {
      handler() {
        this.listRequest.page = 0;
        this.loadData();
      },
      immediate: true,
    },
    itemsPerPage() {
      this.listRequest.page = 0;
      this.loadData();
    },
    results() {
      if (this.results.length < this.itemsPerPage) {
        this.listRequest.hasMoreBookings = false;
      } else {
        this.listRequest.hasMoreBookings = true;
      }
    }
  },
  computed: {
    ...mapGetters("corporations", ["getSelectedCorporationId"]),
    headers() {
      return [
        {
          text: this.$t("components.favourites.table.name"),
          align: "left",
          sortable: false,
          value: "alias",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.favourites.table.address"),
          align: "left",
          sortable: false,
          value: "fullAddressText",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.favourites.table.favourite_pickup"),
          align: "left",
          sortable: false,
          value: "suggestForPickupAddress",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.favourites.table.favourite_dropoff"),
          align: "left",
          sortable: false,
          value: "suggestForDestinationAddress",
          class: "text-uppercase",
        },
        {
          align: "right",
          sortable: false,
          value: "actions",
          class: "text-uppercase",
        },
      ];
    },
  },
  methods: {
    handleUpdateOptions(options) {
      this.options = options;
      this.loadData();
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.loadData();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.loadData();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.loadData();
    },
    async loadData() {
      if (!this.getSelectedCorporationId) return;
      this.$emit("callLoadData", {
        payload: {
          page: this.listRequest.page,
          maxPerPage: this.itemsPerPage,
        },
        businessAccountId: this.getSelectedCorporationId,
      });
    },
    async removeFavouriteAddress(id) {
      const response = await this.$store.dispatch("favourites/deleteFavouriteAddress", { id });

      if (response) {
        this.results = this.results.filter((el) => el.id !== id);
      }
    },
    handleFavouriteAddressSuggestion(item) {
      this.$store.dispatch("favourites/updateFavouriteAddress", {
        id: item.id,
        payload: {
          suggestForPickupAddress: item.suggestForPickupAddress,
          suggestForDestinationAddress: item.suggestForDestinationAddress,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.empty-state {
  height: 70vh;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  /* identical to box height, or 139% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.005em;

  /* Day/Grey0 */
  color: #333333;
}

.empty-state-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.005em;

  /* Day/Grey1 */
  color: #888888;
}
</style>
