import Vue from 'vue';
import VueHeadful from 'vue-headful';
import * as GmapVue from 'gmap-vue';
import VueTelInput from 'vue-tel-input';
import Notifications from 'vue-notification';
import VueGeolocation from 'vue-browser-geolocation';

import App from '@/App.vue';

import Loader from '@/components/elements/Loader.vue';
import ValidatedField from '@/components/elements/ValidatedField.vue';
import Multiselect from 'vue-multiselect';
import ErrorTooltipAuto from '@/components/elements/ErrorTooltipAuto.vue';
import '@/plugins/vuelidate';
import router from '@/plugins/router';
import store from '@/plugins/vuex';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';

import '@/styles/global.scss';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.config.productionTip = false;

Vue.use(GmapVue, { load: { key: process.env.VUE_APP_GOOGLE_MAP_KEY } });
Vue.use(VueTelInput);
Vue.use(Notifications);

Vue.component('vue-headful', VueHeadful);
Vue.component('loader', Loader);
Vue.component('multiselect', Multiselect);
Vue.component('validated-field', ValidatedField);
Vue.component('error-tooltip-auto', ErrorTooltipAuto);
Vue.use(VueGeolocation);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
