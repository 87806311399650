import { business, ReverseGeocoderServiceClient } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  suggestionServiceClient: null,
  reverseGeocoderClient: null,
  suggestions: [],
  searchSource: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  suggestionServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.suggestionServiceClient) {
      state.suggestionServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.SuggestionServiceConnector });
    }
    return state.suggestionServiceClient;
  },
  reverseGeocoderClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.reverseGeocoderClient) {
      state.reverseGeocoderClient = apiInstance({ dispatch, getters: rootGetters, Connector: ReverseGeocoderServiceClient });
    }
    return state.reverseGeocoderClient;
  },
  addressSuggestions: (state) => state.suggestions,
  searchSource: (state) => state.searchSource,
};

const actions = {
  getSuggestions: apiCall(async (context, { search, lang }) => {
    const suggestionServiceClient = context.getters.suggestionServiceClient(context);
    const { suggestions, source } = await suggestionServiceClient.getSuggestions({ params: { search, lang, limit: 5 } });

    context.commit('setSuggestions', suggestions);
    context.commit('setSearchSource', source);

    // TODO return { suggestions, source }
    // return { suggestions, source };
    return suggestions.map((suggestion) => ({ ...suggestion, source }));
  }),
  getSuggestionDetail: apiCall(async (context, { addressId, lang }) => {
    const suggestionServiceClient = context.getters.suggestionServiceClient(context);
    const result = await suggestionServiceClient.getSuggestionDetails(addressId, { params: { lang } });
    return result;
  }),
  geocode: apiCall(async (context, { location, lang }) => {
    const { lat, lng } = location;
    const options = {
      params: {
        lang,
        latitude: lat,
        longitude: lng,
      },
    };
    const response = await context.getters.reverseGeocoderClient(context).getReverseGeocode(options);

    if (!response.results.length) throw new Error('Please enable geolocation in your browser');

    const { description, locationTypes } = response.results[0];
    return { address: { description, locationTypes, coordinates: { latitude: lat, longitude: lng } } };
  }),
};

const mutations = {
  setSuggestions(state, suggestions) {
    state.suggestions = suggestions;
  },
  setSearchSource(state, source) {
    state.searchSource = source;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  getters,
  actions,
};
