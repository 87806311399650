import _ from "lodash";

export const entityLoader = {
  data: () => ({
    data: [],
    entitySearchObject: {
      page: 0,
      maxPerPage: 10,
      sort: 'givenName',
      dynamicSearch: null,
    },
    entityLoading: false,
    entityTotalCount: {
      totalCount: 0,
    },
    intersection: false,
  }),
  created() {
    this.debouncedGetEntity = _.debounce(this.getCorporationAdmins, 500);
  },
  watch: {
    data() {
      this.entityLoading = false;
    },
    entitySearchObject: {
      handler() {
        this.debouncedGetEntity();
      },
      deep: true,
    }
  },
  methods: {
    async getList(url, options) {
      this.entityLoading = true;
      const result = await this.$store.dispatch(url, options);
      if (result.data && this.intersection) {
        this.data.push(...result.data);
      } else {
        this.data = result.data;
      }

      if (result.headers) {
        this.entityTotalCount = this.formatHeaders(result.headers);
      }
      return true;
    },
    async getListFromPagination(url, options) {
      this.entityLoading = true;
      const result = await this.$store.dispatch(url, options);
      this.entityLoading = false;
      if (result.data && this.intersection) {
        this.data.push(...(result.data?.results || []));
      } else {
        this.data = result.data?.results || [];
      }

      this.entityTotalCount.totalCount = result.data?.total || 0;
      return true;
    },
    async actionPost(url, options) {
      const result = await this.$store.dispatch(url, options);

      if (result) return true;

      return false;
    },
    formatHeaders(headers) {
      return {
        totalCount: Number(headers['x-total-count'] || 0),
      };
    },
    async getCorporationAdmins(
    ) {
      return await this.getList('users/listUsers', this.entitySearchObject);
    },
  },
};
