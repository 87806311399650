<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="error-icon mt-1" small v-on="on" v-bind="attrs">mdi-help-circle-outline</v-icon>
    </template>
    <div v-for="error in errors">{{ error }}</div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    errors: Array,
  },
};
</script>

<style lang="sass" scoped>
.error-icon
  color: #eb5757
  cursor: pointer
</style>
