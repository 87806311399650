/* eslint-disable no-unused-vars */
<template>
  <v-container class="grey lighten-5 pl-0 pr-0">
    <v-row v-for="(item, index) in weekList" :key="index" class="align-start weeklist-row mt-0">
        <v-col cols="1" sm="2" class="d-flex align-start pt-0 pb-0">
          <span class="text-capitalize week-day-title mt-3">{{ $t(`components.travelRestrictionDateTimePicker.${item.day}`) }}</span>
        </v-col>
        <v-col cols="11" lg="8" xl="8" md="11" sm="10" class="d-flex align-center justify-end" >
          <v-row>
            <travel-restriction-date-time-rule
              v-for="(restriction, indexRes) in item.restriction"
              :key="indexRes"
              :restriction = "restriction"
              :indexRes = "indexRes"
              :item = "item"
              :index = "index"
              @removeRule="removeRule"
              @deleteRule="deleteRule"
              @updateRestriction="updateDateTimeGroup"
            ></travel-restriction-date-time-rule>
<!--            <v-col cols="12" v-for="(restriction, indexRes) in item.restriction" :key="indexRes" class="d-flex justify-start pt-0 pb-0"-->
<!--                   :class="{ 'pr-12': indexRes > 0, 'rules-col': (item.restriction.length > 1 && indexRes === 0) }">-->
<!--              <v-radio-group-->
<!--                class="radio-week"-->
<!--                v-model="restriction.isRestriction"-->
<!--                row-->
<!--                hide-details-->
<!--              >-->
<!--                <v-radio-->
<!--                  :label="$t('components.travelRestrictionDateTimePicker.noRestriction')"-->
<!--                  class="no-restriction mt-3"-->
<!--                  :value="false"-->
<!--                  @click="removeRule(item, index, indexRes)"-->
<!--                ></v-radio>-->
<!--                <v-radio-->
<!--                  :label="$t('components.travelRestrictionDateTimePicker.fullDay')"-->
<!--                  class="no-restriction mt-3"-->
<!--                  value="fullDay"-->
<!--                  @click="$forceUpdate()"-->
<!--                  :disabled="hasFullDay(item)"-->
<!--                ></v-radio>-->
<!--                <v-radio-->
<!--                  :disabled="restriction.isDisabled"-->
<!--                  :value="true"-->
<!--                  class="time-selector"-->
<!--                  @click="$forceUpdate()"-->
<!--                >-->
<!--                  <template v-slot:label>-->
<!--                    <div class="d-flex flex-column" :class="{ 'pb-n2': item.restriction.length >= 2, 'radio-disabled': restriction.isDisabled }">-->
<!--                      <div  class="d-flex initial-time-selector mr-0" >-->
<!--                        <v-text-field-->
<!--                          outlined-->
<!--                          placeholder="00:00"-->
<!--                          class="time-radio mr-2"-->
<!--                          v-model="restriction.startTime"-->
<!--                          background-color="#ffffff"-->
<!--                          hide-details-->
<!--                          min="00:00"-->
<!--                          max="23:59"-->
<!--                          type="time"-->
<!--                        />-->
<!--                        <v-text-field-->
<!--                          outlined-->
<!--                          placeholder="00:00"-->
<!--                          class="time-radio"-->
<!--                          :class="{ 'invalid-from': !restriction.isValid }"-->
<!--                          :error-messages="restriction.isValid ? $t('components.travelRestrictionDateTimePicker.validFromTime') : 'test'"-->
<!--                          background-color="#ffffff"-->
<!--                          hide-details-->
<!--                          type="time"-->
<!--                          v-model="restriction.endTime"-->
<!--                          min="00:00"-->
<!--                          max="23:59"-->
<!--                          @input="(val) => validateField(val, restriction.startTime, restriction)"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-radio>-->
<!--              </v-radio-group>-->
<!--              <v-btn small-->
<!--                     text-->
<!--                     color="success"-->
<!--                     plain-->
<!--                     class="mt-1 save-restriction"-->
<!--                     :class="{ 'hidden': !hasChanges(item) }"-->
<!--                     @click="saveRules(item, index)">-->
<!--                Save-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="success"-->
<!--                size="x-small"-->
<!--                icon-->
<!--                plain-->
<!--                v-if="indexRes > 0"-->
<!--                @click="deleteRule(item, index, indexRes)"-->
<!--              >-->
<!--                <v-icon>mdi-minus-circle-outline</v-icon>-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="success"-->
<!--                icon-->
<!--                plain-->
<!--                @click="addNewTimeSlot(item)"-->
<!--                :class="{ 'hidden': !canAddRule(item, indexRes)  }"-->
<!--                v-if="indexRes === 0  && item.restriction.length < 2"-->
<!--              >-->
<!--                <v-icon >mdi-plus-circle-outline</v-icon>-->
<!--              </v-btn>-->
<!--            </v-col>-->
          </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { initTravelRestrictionEntity } from '@/mixins/travelRestriction.mixins';
import TravelRestrictionDateTimeRule from '@/components/travelRestrictions/TravelRestrictionDateTimeRule.vue';
import { weekRestriction } from './helpers/weekRestriction';

export default {
  name: 'travel-restriction-date-time-picker',
  mixins: [initTravelRestrictionEntity, weekRestriction],
  components: {
    TravelRestrictionDateTimeRule,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      timeArray: [],
      wholeDayDefaultValue: {
        startTime: { hours: 0, minutes: 0 },
        endTime: { hours: 0, minutes: 0 },
      },
    };
  },
  watch: {
    getTravelRestrictionId: {
      handler(val) {
        if (val) {
          this.createdRestrictionId = val;
        }
      },
    },
    data: {
      handler(val) {
        val.forEach((el) => {
          const selectedWeekDay = _.find(this.weekList, ['day', el.dateTimeRuleType]);
          if (!selectedWeekDay) return;

          el.dateTimeSlots.forEach((item, index) => {
            selectedWeekDay.restriction[index] = { startTime: this.formatNumber(item.startTime), endTime: this.formatNumber(item.endTime) };
            selectedWeekDay.restriction[index].isDisabled = false;
            selectedWeekDay.restriction[index].isValid = true;
            if (item.isRestriction === 'fullDay' || _.isEqual(item, this.wholeDayDefaultValue)) {
              selectedWeekDay.restriction[index].isRestriction = 'fullDay';
              return;
            }
            selectedWeekDay.restriction[index].isRestriction = true;
          });
          if (selectedWeekDay.restriction.length === 0) {
            selectedWeekDay.restriction.push({
              startTime: '00:00', endTime: '00:01', isRestriction: false, isDisabled: false, isValid: false,
            });
          }
          this.$emit('updateOriginalValue', el.dateTimeRuleType, this.formatOriginalValues(selectedWeekDay.restriction));
        });
        this.$forceUpdate();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('travelRestriction', ['getTravelRestrictionId', 'getTravelRestrictionRuleId']),
  },
  methods: {
    formatOriginalValues(restrictions) {
      const formatted = [];
      restrictions.forEach((restriction) => {
        formatted.push(
          {
            startTime: {
              hours: Number(restriction.startTime.split(':')[0]),
              minutes: Number(restriction.startTime.split(':')[1]),
            },
            endTime: {
              hours: Number(restriction.endTime.split(':')[0]),
              minutes: Number(restriction.endTime.split(':')[1]),
            },
          },
        );
      });
      return formatted;
    },
    resetWeeks(params) {
      const newState = (params) || this.initialState;
      this.weekList = newState;
      this.$forceUpdate();
    },
    validateField(toTime, fromTime, item) {
      if (toTime < fromTime) {
        // eslint-disable-next-line no-param-reassign
        item.isValid = false;
        this.$forceUpdate();
        return;
      }
      // eslint-disable-next-line no-param-reassign
      item.isValid = true;
      this.$forceUpdate();
    },
    async removeRule(item, index, indexRes, removeRes = false) {
      this.weekList[index].restriction[indexRes] = {
        startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
      };
      if (removeRes) this.weekList[index].restriction.splice(indexRes, 1);
      this.$forceUpdate();
    },
    async deleteRule(item, index, indexRes) {
      if (indexRes) await this.removeRule(item, index, indexRes, true);
      await this.deleteTravelRule(item);
      this.originalValues[item.day] = [{
        startTime: '00:00',
        endTime: '00:00',
        isRestriction: false,
        isDisabled: false,
        isValid: true,
      }];

      this.$forceUpdate();
    },
    formatNumber(date) {
      return `${date.hours === 0 ? '00' : String(date.hours).padStart(2, 0)}:${date.minutes === 0 ? '00' : String(date.minutes).padStart(2, 0)}`;
    },
    async deleteTravelRule(item) {
      const rules = this.getTravelRestrictionRuleId('dateTime');
      const rule = _.find(rules, ['day', item.day]);
      if (rule && rule.id) {
        item.restriction.forEach((slot) => {
          // eslint-disable-next-line no-param-reassign
          delete slot.isRestriction;
        });
        await this.setDateTime({
          dateTimeRuleType: rule.day,
          dateTimeSlots: item.restriction,
          type: 'dateTime',
        });
      }
    },
    async saveRules(item, index) {
      const restrictions = item.restriction;
      const params = {
        dateTimeRuleType: item.day,
        dateTimeSlots: [],
        type: 'dateTime',
      };
      const updatedRestrictions = [];
      if (this.hasFullDay(item)) {
        updatedRestrictions.push({
          startTime: '00:00',
          endTime: '00:00',
          isRestriction: 'fullDay',
          isDisabled: false,
          isValid: true,
        });
        params.dateTimeSlots.push(
          {
            startTime: {
              hours: 0,
              minutes: 0,
            },
            endTime: {
              hours: 0,
              minutes: 0,
            },
          },
        );
      } else {
        restrictions.forEach((restriction) => {
          if (restriction.isRestriction) {
            updatedRestrictions.push(restriction);
            params.dateTimeSlots.push(
              {
                startTime: {
                  hours: Number(restriction.startTime.split(':')[0]),
                  minutes: Number(restriction.startTime.split(':')[1]),
                },
                endTime: {
                  hours: Number(restriction.endTime.split(':')[0]),
                  minutes: Number(restriction.endTime.split(':')[1]),
                },
              },
            );
          }
        });
      }
      if (params.dateTimeSlots.length) {
        await this.setDateTime(params);
      } else {
        this.deleteRule(item, index, 0);
        updatedRestrictions.push({
          startTime: '00:00',
          endTime: '00:00',
          isRestriction: false,
          isDisabled: false,
          isValid: true,
        });
      }
      this.weekList[index].restriction = updatedRestrictions;

      this.originalValues[item.day] = _.cloneDeep(updatedRestrictions);
      this.$forceUpdate();
    },
    hasFullDay(item) {
      let res = false;
      const restrictions = item.restriction;
      restrictions.forEach((restriction) => {
        if (restriction.isRestriction === 'fullDay') {
          res = true;
        }
      });
      return res;
    },
    canAddRule(item, indexRes) {
      let canAddRule = true;
      if (this.hasFullDay(item)) return false;
      if (indexRes !== 0) return false;
      item.restriction.forEach((res) => {
        if (!res.isRestriction) canAddRule = false;
      });
      return canAddRule;
    },
    updateDateTimeGroup(item) {
      this.$emit('updateDateTimeRestrictions', item);
    },
  },
};
</script>

<style lang="scss">
.v-input--radio-group__input {
  align-items: start;
}

.time-selector {
  display: flex !important;
  align-items: start !important;
  margin-right: 5px !important;
}

.time-selector .v-input--selection-controls__input {
  margin-top: 12px;
}

.invalid-from .v-input__slot fieldset {
  border: 1px solid red !important;
}
</style>

<style lang="scss" scoped>
.radio-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.radio-week {
  margin-top: 0px;
  padding-top: 0px;
}

.week-time, .week-day-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #333333;
  margin-bottom: 5px;
}

.week-time-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #888888;
}

.time-radio {
  width: 8rem;
  box-sizing: border-box;
  border-radius: 6px;
}

.add-time-button {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #888888;
  text-decoration: underline;
  position: relative;
  right: 20px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}
.weeklist-row {
 margin-bottom: 10px;
}
.rules-col {
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}
</style>
