import { business } from "@icabbi/api-gateway-client";
import apiInstance from "@/helpers/apiInstance";

const defaultState = {
  offerServiceClient: null,
};

const getters = {
  offerServiceClient:
    (state, getters, rootState, rootGetters) =>
    ({ dispatch }) => {
      if (!state.offerServiceClient) {
        state.offerServiceClient = apiInstance({
          dispatch,
          getters: rootGetters,
          Connector: business.OfferServiceConnector,
        });
      }
      return state.offerServiceClient;
    },
};

/**
 * @param {OfferListQuery} query
 * @returns {Promise<OfferListApiDto>}
 */
function getOffers(context, query) {
  return context.getters
    .offerServiceClient(context)
    .listByEtaAndCoordinates({
      lang: query.lang,
      pickupLatitude: query.coords.pickup.latitude,
      pickupLongitude: query.coords.pickup.longitude,
      destinationLatitude: query.coords.dropoff.latitude,
      destinationLongitude: query.coords.dropoff.longitude,
      corporationId: query.corporationId,
    });
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions: {
    getOffers,
  },
}