<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-progress-circular indeterminate />
    </v-layout>
  </v-container>
</template>

<script>
import { Authentication } from '@icabbi/vue-authentication';
import { mapGetters } from 'vuex';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';

export default {
  mixins: [bookingChannelComputed],
  async created() {
    try {
      await this.$store.dispatch('authentication/revokeToken', { token: this.token, client_id: this.clientId });
      localStorage.clear();
      await Authentication(this.$store).logout(this.idToken, this.postLogoutUrl);
    } catch (e) {
      this.error(e);
    }
  },
  computed: {
    ...mapGetters({
      token: 'authentication/token',
      idToken: 'authentication/idToken',
      postLogoutUrl: 'bookingChannel/postLogoutUrl',
      clientId: 'bookingChannel/oidcClientId',
    }),
  },
  methods: {
    error() {
      this.$router.push('/');
    },
  },
};
</script>
