/* eslint-disable no-unused-vars */
<template>
          <v-col cols="12" class="d-flex justify-start align-center pt-0 pb-0"
                 :class="{ 'rules-col': (item.restriction.length > 1 && indexRes === 0) }">
            <v-radio-group
              class="radio-week"
              v-model="restriction.isRestriction"
              row
              hide-details
              @change="updateRestriction"
            >
              <v-radio
                :label="$t('components.travelRestrictionDateTimePicker.noRestriction')"
                class="no-restriction mt-3"
                :value="false"
                @click="removeRule(item, index, indexRes)"
              ></v-radio>
              <v-radio
                :label="$t('components.travelRestrictionDateTimePicker.fullDay')"
                class="no-restriction mt-3"
                value="fullDay"
                @click="$forceUpdate()"
                :disabled="hasFullDay(item)"
              ></v-radio>
              <v-radio
                :disabled="restriction.isDisabled"
                :value="true"
                class="time-selector"
                @click="$forceUpdate()"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column" :class="{ 'pb-n2': item.restriction.length >= 2, 'radio-disabled': restriction.isDisabled }">
                    <div  class="d-flex initial-time-selector mr-0" >
                      <v-text-field
                        outlined
                        placeholder="00:00"
                        class="time-radio mr-2 employee-group-form-input clock-time-input--no-icon"
                        v-model="restriction.startTime"
                        background-color="#ffffff"
                        hide-details
                        min="00:00"
                        max="23:59"
                        type="time"
                        @change="updateRestriction"
                        :class="{
                          'clock-time-input--light-text': !restriction.isRestriction,
                          'clock-time-input--dark-text': restriction.isRestriction,
                        }"
                      />
                      <v-text-field
                        outlined
                        placeholder="00:00"
                        class="time-radio employee-group-form-input clock-time-input--no-icon"
                        :class="{
                        'invalid-from': !restriction.isValid,
                        'clock-time-input--light-text': !restriction.isRestriction,
                        'clock-time-input--dark-text': restriction.isRestriction,
                      }"
                        :error-messages="restriction.isValid ? $t('components.travelRestrictionDateTimePicker.validFromTime') : 'test'"
                        background-color="#ffffff"
                        hide-details
                        type="time"
                        v-model="restriction.endTime"
                        min="00:00"
                        max="23:59"
                        @input="(val) => validateField(val, restriction.startTime, restriction)"
                        @change="updateRestriction"
                      />
                    </div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <v-btn
              color="success"
              size="x-small"
              icon
              plain
              v-if="indexRes > 0"
              @click="deleteRule(item, index, indexRes)"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
            <v-btn
              color="success"
              icon
              plain
              @click="addNewTimeSlot(item)"
              :class="{ 'hidden': !canAddRule(item, indexRes)  }"
              v-if="indexRes === 0  && item.restriction.length < 2"
            >
              <v-icon >mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { initTravelRestrictionEntity } from '@/mixins/travelRestriction.mixins';
import _ from 'lodash';
import { weekRestriction } from './helpers/weekRestriction';

export default {
  name: 'travel-restriction-date-time-rule',
  mixins: [initTravelRestrictionEntity, weekRestriction],
  props: {
    restriction: {
      type: Object,
    },
    indexRes: {
      type: Number,
    },
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      timeArray: [],
      wholeDayDefaultValue: {
        startTime: { hours: 0, minutes: 0 },
        endTime: { hours: 0, minutes: 0 },
      },
    };
  },
  computed: {
    ...mapGetters('travelRestriction', ['getTravelRestrictionId', 'getTravelRestrictionRuleId']),
  },
  methods: {
    updateRestriction() {
      this.$forceUpdate();
      const newValue = { ...this.item, restriction: this.formatRestriction(this.item) };
      this.$emit('updateRestriction', newValue);
    },
    formatRestriction(rule) {
      let result = [];
      this.hasFullDay(rule);
      if (this.hasFullDay(rule)) {
        result = [
          {
            isRestriction: 'fullDay',
            startTime: {
              hours: Number(0),
              minutes: Number(0),
            },
            endTime: {
              hours: Number(0),
              minutes: Number(0),
            },
          },
        ];
      } else {
        rule.restriction.forEach((res) => {
          if (!res.isRestriction) return;
          result.push(
            {
              isRestriction: true,
              startTime: {
                hours: Number(res.startTime.split(':')[0]),
                minutes: Number(res.startTime.split(':')[1]),
              },
              endTime: {
                hours: Number(res.endTime.split(':')[0]),
                minutes: Number(res.endTime.split(':')[1]),
              },
            },
          );
        });
      }
      return result;
    },
    validateField(toTime, fromTime, item) {
      if (toTime < fromTime) {
        // eslint-disable-next-line no-param-reassign
        item.isValid = false;
        this.$forceUpdate();
        return;
      }
      // eslint-disable-next-line no-param-reassign
      item.isValid = true;
      this.$forceUpdate();
    },
    async removeRule(item, index, indexRes) {
      this.$emit('removeRule', item, index, indexRes);
    },
    async deleteRule(item, index, indexRes) {
      // eslint-disable-next-line no-param-reassign
      const travelRule = _.cloneDeep(item);
      travelRule.restriction = this.formatRestriction(item);
      this.$emit('deleteRule', travelRule, index, indexRes);
    },
    addNewTimeSlot(item) {
      item.restriction.push({
        startTime: '00:00', endTime: '00:00', isRestriction: false, isDisabled: false, isValid: true,
      });
    },
    hasFullDay(item) {
      let res = false;
      const restrictions = item.restriction;
      restrictions.forEach((restriction) => {
        if (restriction.isRestriction === 'fullDay') {
          res = true;
        }
      });
      return res;
    },
    canAddRule(item, indexRes) {
      let canAddRule = true;
      if (this.hasFullDay(item)) return false;
      if (indexRes !== 0) return false;
      item.restriction.forEach((res) => {
        if (!res.isRestriction) canAddRule = false;
      });
      return canAddRule;
    },
  },
};
</script>

<style lang="scss">
.v-input--radio-group__input {
  align-items: start;
}

.time-selector {
  display: flex !important;
  align-items: start !important;
  margin-right: 5px !important;
}

.time-selector .v-input--selection-controls__input {
  margin-top: 12px;
}

.invalid-from .v-input__slot fieldset {
  border: 1px solid red !important;
}
</style>

<style lang="scss" scoped>
.radio-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.radio-week {
  margin-top: 0px;
  padding-top: 0px;
}

.week-time, .week-day-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #333333;
  margin-bottom: 5px;
}

.week-time-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #888888;
}

.time-radio {
  box-sizing: border-box;
  border-radius: 6px;
}

.add-time-button {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #888888;
  text-decoration: underline;
  position: relative;
  right: 20px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}
.weeklist-row {
  margin-bottom: 10px;
}
.rules-col {
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}
</style>
