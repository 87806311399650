<template>
  <loader :is-loading="isInLoadingState" color="primary">
    <div class="user-profile-details-container px-3 px-md-0 mt-12 mt-md-0">
      <v-card class="user-profile-details-card pa-2">
        <v-card-title id="profile-title">
          <span style="width: auto;">{{ $t('components.userProfileDetails.title') }}</span>
        </v-card-title>

        <v-card-text class="pb-0">
          <span class="text-uppercase input-label user-profile-details-label pb-2">{{ $t(`components.employeeForm.default.firstName`) }}</span>
          <v-text-field
            :placeholder="$t(`components.employeeForm.default.givenName`)"
            :class="{ 'employee-field-error': $v.userProfile.givenName.$error }"
            v-model="userProfile.givenName"
            class="employee-field mt-5 user-profile-details-field"
            height="44"
            outlined
            required
            clearable
            hide-details
            @input="$v.userProfile.givenName.$touch()"
            @blur="$v.userProfile.givenName.$touch()"
          >
          <template v-if="$v.userProfile.givenName.$anyError" v-slot:append>
            <error-tooltip :validator="$v.userProfile.givenName"/>
          </template>
          </v-text-field>
        </v-card-text>

        <v-card-text class="pb-0">
        <span class="text-uppercase input-label user-profile-details-label pb-2">{{ $t(`components.employeeForm.default.lastName`) }}</span>

        <v-text-field
          :class="{ 'employee-field-error': $v.userProfile.familyName.$error }"
          :placeholder="$t(`components.employeeForm.default.familyName`)"
          v-model="userProfile.familyName"
          class="employee-field mt-5 user-profile-details-field"
          outlined
          clearable
          required
          hide-details
          no-filter
          @input="$v.userProfile.familyName.$touch()"
          @blur="$v.userProfile.familyName.$touch()"
        >
          <template v-if="$v.userProfile.familyName.$anyError" v-slot:append>
            <error-tooltip :validator="$v.userProfile.familyName"/>
          </template>
        </v-text-field>
        </v-card-text>

        <v-card-text class="pb-0">
        <span class="text-uppercase input-label user-profile-details-label">{{ $t(`components.employeeForm.default.email`) }}</span>

          <v-text-field
            :placeholder="$t(`components.employeeForm.default.emailPlaceholder`)"
            :class="{ 'employee-field-error': $v.userProfile.email.$error }"
            v-model="userProfile.email"
            class="employee-field email-input mt-5 user-profile-details-field"
            outlined
            required
            clearable
            hide-details
            no-filter
            @input="$v.userProfile.email.$touch()"
            @blur="$v.userProfile.email.$touch()"
          >
            <template v-if="$v.userProfile.email.$anyError" v-slot:append>
              <error-tooltip :validator="$v.userProfile.email"/>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-text class="pb-0">
          <v-divider />
        </v-card-text>

        <v-card-text class="pb-0">
          <div id="communication-language-container">
            <p class="user-profile-details-label-communication">{{ $t('components.userProfileDetails.communicationLanguage') }}</p>
            <v-select
              class="user-profile-details-select"
              v-model="userProfile.communicationLanguage"
              :items="languages"
              :menu-props="{ nudgeBottom: 40 }"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              value="value"
              hide-details="auto"
              outlined
              dense
            />
          </div>
        </v-card-text>

        <v-card-text class="pb-0">
          <p class="user-profile-details-label-communication">{{ $t('components.userProfileDetails.guestBookingCc') }}</p>
          <v-autocomplete
            ref="autocomplete"
            icon="$icons_product_corporation"
            outlined
            filled
            hide-details
            item-text="name"
            item-value="id"
            return-object
            :items="getCorporationsList"
            @change="selectCorporation($event)"
            :placeholder="$t('components.employeeForm.default.accountPlaceholder')"
          >
          </v-autocomplete>
        </v-card-text>

        <v-col v-if="guestBookingEmailCorporations.length" cols="12" class="corporation-list-wrapper">
          <div
            v-for="(corporation, index) in guestBookingEmailCorporations"
            :key="index"
            class="d-flex align-center justify-space-between corporation-item"
          >
            <div>
              <p class="mb-1 text-body-1">{{ corporation.name }}</p>
            </div>

            <v-btn
              @click="guestBookingEmailCorporations.splice(index, 1)"
              class="corporation-remove-btn"
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-card-actions class="mx-2 mt-5">
          <v-btn
            class="disable-button"
            color="grey darken-1"
            text
            @click="$router.back()"
          >
            {{ $t('components.userProfileDetails.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="user-profile-submit-button"
            @click="updateUserProfile"
            :disabled="disablePostUserProfile()"
            depressed
          >{{ $t('components.userProfileDetails.save') }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </div>
  </loader>
</template>

<script>
import { getRoles } from '@/mixins/authentication.mixins';
import { mapGetters } from 'vuex';
import { languageMethods, languageComputed } from '@/mixins/language.mixins';
import ErrorTooltip from "@/components/elements/ErrorTooltip.vue";
import userValidationData from "@/helpers/rules/userValidationData";
import {SafeAwait} from "@/core/util/SafeAwait";

export default {
  name: 'UserProfile',
  mixins: [getRoles, languageMethods, languageComputed],
  components: {
    ErrorTooltip
  },
  data() {
    return {
      isInLoadingState: true,
      userProfile: {
        email: '',
        givenName: '',
        familyName: '',
      },
      guestBookingEmailCorporations: [],
      corporations: [],
    };
  },
  validations: {
    ...userValidationData,
  },
  async created() {
    await this.reloadCurrentUser();
  },
  watch: {
    user(newValue) {
      this.userProfile = {
        id: newValue.id,
        givenName: newValue.givenName,
        familyName: newValue.familyName,
        email: newValue.email,
        communicationLanguage: newValue.communicationLanguage,
      };
      this.guestBookingEmailCorporations = newValue.guestBookingEmailCorporations ?? [];
    },
  },
  computed: {
    ...mapGetters({
      user: 'users/me',
      isInLoadingState: 'users/getMeLoading',
      getUserRole: 'users/getUserRole',
    }),
    ...mapGetters('corporations', ['getCorporationsList', 'getSelectedCorporationId']),
  },
  methods: {
    async reloadCurrentUser() {
      this.isInLoadingState = true;
      await this.$store.dispatch('users/getMe');
      this.isInLoadingState = false;
    },
    selectCorporation(event) {
      if (event) {
        this.$refs.autocomplete.internalValue = null;
        if (this.guestBookingEmailCorporations.some((corporation) => corporation.id === event.id)) {
          return;
        }
        this.guestBookingEmailCorporations.push(event);
      }
    },
    disablePostUserProfile() {
      return this.$v.userProfile.$invalid || this.isInLoadingState;
    },
    async updateUserProfile() {
      this.isInLoadingState = true;
      await this.$store.dispatch('users/getUserRole');
      const corporations = this.userRoles.map((role) => role.corporationId);

      const data = {
        ...this.userProfile,
        role: this.userRoles[0].name,
        corporations: corporations,
        guestBookingEmailCorporations: this.guestBookingEmailCorporations.map((corporation) => {
          return {
            id: corporation.id,
            name: corporation.name,
          };
        }),
      };

      const { error } = await SafeAwait.execute(async () => {
        await this.$store.dispatch('users/updateUser', data);
        await this.$store.dispatch('users/getMe');
      });
      this.isInLoadingState = false;

      if (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">

.loader-content, .loader-container {
  background-color: unset !important;
}

.user-profile-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-width: 500px;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  gap: 24px;
  transition: .2s;

  @media screen and (max-width: 767px) {
    width: 100% !important;
    min-width: 100%;
    padding: 0;
  }
}

.user-profile-details-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #888888;
}

.user-profile-details-card {
  width: 100%;
  border-radius: 8px !important;
}

.user-profile-details-phone {
  width: 100%;
  height: 48px;
}

.user-profile-details-label-communication {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.005em;
}

.user-profile-details-field {
  border: 1px solid #EEEEEE;
  background-color: #F8F8F8;
  border-radius: 6px;
}

.user-profile-details-select {
  background-color: #F8F8F8;
  border-radius: 6px;

  & .v-input__slot {
    height: 48px !important;
  }

  & .v-input__append-inner {
    margin-top: 12px !important;
  }
}

.user-profile-details-submit-button {
  color: #ffffff;
}

.btn-delete-account {
  text-transform: none!important;
}

.corporation-list-wrapper {
  max-height: 250px;
  overflow-y: auto;
  padding: 16px;
}

.corporation-item {
  background: #F8F8F8;
  border-radius: 6px;
  padding-inline: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #EEEEEE;

  & p {
    color: #333333;
  }

  .mdi:before, .mdi-set {
    font-size: 20px;
  }
}
</style>
