<template>
  <div class="bs-table-table">
    <v-row no-gutters>
      <employee-group-form
        v-if="isGroupCreation || selectedGroupItem"
        :selectedGroup="selectedGroupItem"
        @closeGroup="updateGroup"
      />
      <v-col v-else class="col-without-top-padding table-color">
        <v-data-table
          class="bs-table elevation-0"
          :headers="headers"
          :items="groups"
          :server-items-length="totalGroups"
          :multi-sort="false"
          :must-sort="true"
          :loading="groupsLoading"
          calculate-widths
          :options.sync="options"
          :page.sync="listRequest.page"
          :no-data-text="$t(`global.noData`)"
          :no-results-text="$t(`global.noResults`)"
          hide-default-footer
          @update:options="handleUpdateOptions"
        >
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <edit-icon @click="editGroup(item)" v-on="on" v-bind="attrs" class="mr-1 icon-control-list"/>
              </template>
              <span>{{ $t(`components.employeeList.tooltips.editGroup`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <delete-icon @click="$emit('deleteEmployeeGroupClick', item)" v-on="on" v-bind="attrs" v-if="!item.isDefault" class="mr-1 icon-control-list"/>
              </template>
              <span>{{ $t(`components.employeeList.tooltips.deleteGroup`) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
          <v-row no-gutters class="bs-per-page">
            <span>
              {{ $t(`global.perPage`) }}
            </span>
            <span
              v-for="item  in [10, 20, 30]"
              :key="item"
              class="px-1"
              :class="{ 'active-page-count': item === itemsPerPage }"
              @click="itemsPerPage = item"
            >
              {{ item }}
            </span>
          </v-row>
          <simple-paginator
            :page="listRequest.page + 1"
            :has-more="listRequest.hasMoreBookings"
            @goToFirst="this.firstPage"
            @previousPage="this.previousPage"
            @nextPage="nextPage"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';
import EmployeeGroupForm from '../EmployeeGroupForm.vue';

import SimplePaginator from "@/components/elements/SimplePaginator.vue";

export default {
  name: 'EmployeeGroupsList',
  components: {
    ...iconLoader.elements,
    EmployeeGroupForm,
    SimplePaginator,
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    groupsLoading: {
      type: Boolean,
      default: false,
    },
    totalGroups: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: {},
      listRequest: {
        page: 0,
        hasMoreBookings: true,
      },
      isGroupCreation: false,
      selectedGroupItem: null,
      itemsPerPage: 10,
    };
  },
  watch: {
    groups() {
      if (this.groups.length < this.itemsPerPage) {
        this.listRequest.hasMoreBookings = false;
      } else {
        this.listRequest.hasMoreBookings = true;
      }
    },
    itemsPerPage() {
      this.listRequest.page = 0;
      this.loadData();
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('components.employeeList.list.groupName'),
          align: 'left',
          sortable: false,
          value: 'name',
          class: 'text-uppercase',
        },
        {
          value: 'action',
          width: '10%',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
  methods: {
    handleUpdateOptions(options) {
      this.options = options;
      this.loadData();
    },
    async nextPage() {
      this.listRequest.page += 1;
      await this.loadData();
    },
    async previousPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page -= 1;
      await this.loadData();
    },
    async firstPage() {
      this.listRequest.hasMoreBookings = true;
      this.listRequest.page = 0;
      await this.loadData();
    },
    loadData() {
      this.$emit('loadData', {
        page: this.listRequest.page,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        itemsPerPage: this.itemsPerPage,
      });
    },
    toggleGroupCreation() {
      this.isGroupCreation = true;
    },
    async editGroup(item) {
      this.isGroupCreation = true;
      const { data } = await this.$store.dispatch('employee/getEmployeeGroup', item.id);
      this.selectedGroupItem = data;
    },
    updateGroup() {
      this.isGroupCreation = false;
      this.selectedGroupItem = null;
      this.$emit('updateGroup');
    },
  },
};
</script>
