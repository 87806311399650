import moment from 'moment';
import { mapGetters } from 'vuex';

export const initLocale = {
  beforeMount() {
    const locale = this.$store.getters['language/selectedLanguage'];
    this.$store.dispatch('language/setSelectedLanguage', locale);
    moment.locale(locale);
  },
};

export const languageList = {
  data() {
    return {
      languages: {
        en: 'English',
        fr: 'Français',
        sv: 'Svenska',
        fi: 'Suomi',
        nl: 'Dutch',
        es: 'Español',
      },
      languageOptions: [],
    };
  },
  methods: {
    populateLanguagesSelectList() {
      Object.entries(this.languages).forEach(([code, lang]) => {
        this.languageOptions.push({
          name: lang,
          value: code,
        });
      });
    },
  },
};

export const languageMethods = {
  methods: {
    getDefaultLanguage() {
      return 'en';
    },
    languageIsAvailable(language) {
      if (this.languages.map((l) => l.id).includes(language.toLowerCase())) return true;
      return false;
    },
    async selectLanguage(language) {
      return this.$store.dispatch('language/setSelectedLanguage', (this.languageIsAvailable(language) ? language.toLowerCase() : this.getDefaultLanguage()));
    },
  },
};

export const languageComputed = {
  computed: {
    ...mapGetters({
      storedLanguage: 'language/selectedLanguage',
    }),
    selectedLanguage() {
      if (this.languageIsAvailable(this.storedLanguage)) return this.storedLanguage;
      return this.getDefaultLanguage();
    },
    languages() {
      return this.$i18n.availableLocales
        .map((language) => ({ id: language, name: this.$i18n.t(`languages.${language}`) }));
    },
  },
};
