import { PaymentMethodDomainDelegate } from "@/domain/paymentMethod/PaymentMethodDomainDelegate";
export class BookingCreationDtoFactory {
  /**
   * @param {BookingFormModelDto} bookingFormModel
   * @return {PaymentApiDto}
   */
  static getPaymentMethod(bookingFormModel) {
    if (
      PaymentMethodDomainDelegate.isTypeCreditCard(bookingFormModel.paymentMethod.paymentMethodType)
    ) {
      return {
        type: "creditCard",
        paymentMethodId: bookingFormModel.paymentMethod.id,
        paymentProviderName: bookingFormModel.paymentProviderName,
      };
    }

    if (
      PaymentMethodDomainDelegate.isTypeBusinessDispatchInvoice(
        bookingFormModel.paymentMethod.paymentMethodType
      )
    ) {
      return {
        type: "businessAccount",
      };
    }

    if (PaymentMethodDomainDelegate.isTypeCash(bookingFormModel.paymentMethod.paymentMethodType)) {
      return {
        type: "cash",
      };
    }
  }
  /**
   *
   * @param {BookingFormModelDto} bookingFormModel
   * @return {CreateBookingApiDto}
   */
  static fromBookingFormModel(bookingFormModel) {
    const { employeeId, guestId } = bookingFormModel.createBookingRecipient;

    /**
     *
     * @type {PassengerBookingApiDto}
     */
    const passengerInfo = {
      passengerId: employeeId || guestId,
      passengerType: employeeId ? "employee" : "guest",
      corporationId: bookingFormModel.businessAccountId,
      employeeGroupId: bookingFormModel.businessGroupId,
    };

    if (bookingFormModel.createBookingRecipient.isAdhocGuest) {
      passengerInfo.shouldCreateGuest = true;
      passengerInfo.guestInfo = {
        phoneNumber: bookingFormModel.createBookingRecipient.phoneNumber || undefined,
        familyName: bookingFormModel.createBookingRecipient.familyName || undefined,
        givenName: bookingFormModel.createBookingRecipient.givenName || undefined,
        email: bookingFormModel.createBookingRecipient.email || undefined,
      };
    }

    return {
      requesterType: bookingFormModel.requesterType,
      pickupTime: bookingFormModel.pickupTime?.format("YYYY-MM-DDTHH:mm:ss") || undefined,
      origin: {
        address: bookingFormModel.origin.description,
        locationTypes: bookingFormModel.origin.locationTypes || [],
        latitude: bookingFormModel.origin.coordinates.latitude,
        longitude: bookingFormModel.origin.coordinates.longitude,
      },
      destination: {
        address: bookingFormModel.destination.description,
        locationTypes: bookingFormModel.destination.locationTypes || [],
        latitude: bookingFormModel.destination.coordinates.latitude,
        longitude: bookingFormModel.destination.coordinates.longitude,
      },
      waypoints: bookingFormModel.waypoints.map((waypoint) => ({
        address: waypoint.description,
        locationTypes: waypoint.locationTypes,
        latitude: waypoint.coordinates.latitude,
        longitude: waypoint.coordinates.longitude,
      })),
      numberOfVehicles: Number(bookingFormModel.numberOfVehicles) || 1,
      offerId: bookingFormModel.offerId,
      passengerInfo,
      payment: this.getPaymentMethod(bookingFormModel),
      notes: bookingFormModel.notes,
      fields: bookingFormModel.fields
        ? Object.entries(bookingFormModel.fields).reduce((updated, [key, value]) => {
            updated[key] = value.value;
            return updated;
          }, {})
        : null,
      bookingProperties: {
        flightInfo: {
          number: bookingFormModel.flightNumber,
        },
      },
      isDuplicateAcknowledged: bookingFormModel.isDuplicateBooking,
    };
  }

  /**
   * @param {BookingFormModel} bookingFormModel
   * @param {BookingCreationResponseApiDto} firstBookingConfirmResponseDto
   * @return {CreateBookingApiDto}
   */
  static fromBookingFormModelToReturnBooking({ bookingFormModel, firstBookingConfirmResponseDto }) {
    const { employeeId, guestId } = bookingFormModel.createBookingRecipient;

    /**
     *
     * @type {PassengerBookingApiDto}
     */
    const passengerInfo = {
      passengerId: employeeId || guestId || firstBookingConfirmResponseDto.businessGuestId,
      passengerType: employeeId ? "employee" : "guest",
      corporationId: bookingFormModel.businessAccountId,
      employeeGroupId: bookingFormModel.businessGroupId,
    };

    if (firstBookingConfirmResponseDto.businessGuestId) {
      bookingFormModel.createBookingRecipient.isAdhocGuest = false;
    }

    if (bookingFormModel.createBookingRecipient.isAdhocGuest) {
      passengerInfo.shouldCreateGuest = true;
      passengerInfo.familyName = bookingFormModel.createBookingRecipient.familyName || undefined;
      passengerInfo.givenName = bookingFormModel.createBookingRecipient.givenName || undefined;
      passengerInfo.phoneNumber = bookingFormModel.createBookingRecipient.phoneNumber;
    }

    return {
      requesterType: bookingFormModel.requesterType,
      isDuplicateAcknowledged: bookingFormModel.shouldBookReturnBooking,
      pickupTime: bookingFormModel.returnBookingTime || undefined,
      origin: {
        address: bookingFormModel.destination.description,
        locationTypes: bookingFormModel.destination.locationTypes || [],
        latitude: bookingFormModel.destination.coordinates.latitude,
        longitude: bookingFormModel.destination.coordinates.longitude,
      },
      destination: {
        address: bookingFormModel.origin.description,
        locationTypes: bookingFormModel.origin.locationTypes || [],
        latitude: bookingFormModel.origin.coordinates.latitude,
        longitude: bookingFormModel.origin.coordinates.longitude,
      },
      waypoints: bookingFormModel.waypoints.map((waypoint) => ({
        address: waypoint.description,
        locationTypes: waypoint.locationTypes,
        latitude: waypoint.coordinates.latitude,
        longitude: waypoint.coordinates.longitude,
      })),
      numberOfVehicles: Number(bookingFormModel.numberOfVehicles) || 1,
      offerId: bookingFormModel.offerId,
      passengerInfo,
      payment: this.getPaymentMethod(bookingFormModel),
      notes: bookingFormModel.notes,
      fields: bookingFormModel.fields
        ? Object.entries(bookingFormModel.fields).reduce((updated, [key, value]) => {
            updated[key] = value.value;
            return updated;
          }, {})
        : null,
      bookingProperties: {
        flightInfo: {
          number: bookingFormModel.returnFlightNumber,
        },
      },
    };
  }
}
