import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  favouriteAddressServiceConnector: null,
  favouriteAddresses: [],
};

const storeGetters = {
  favouriteAddressServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.favouriteAddressServiceConnector) {
      state.favouriteAddressServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.FavouriteAddressConnector });
    }
    return state.favouriteAddressServiceConnector;
  },
  favouriteAddresses: state => state.favouriteAddresses,
};

const actions = {
  getFavouriteAddresses: apiCall(async (context, { businessAccountId, payload }) => {
    const favouriteAddressServiceConnector = context.getters.favouriteAddressServiceConnector(context);
    const result = await favouriteAddressServiceConnector.listBusinessAccountFavouriteAddress({ id: businessAccountId, payload });

    context.commit('setFavouriteAddresses', result);
    return result;
  }),

  getFavouriteAddress: apiCall(async (context, { id, payload }) => {
    const favouriteAddressServiceConnector = context.getters.favouriteAddressServiceConnector(context);
    return favouriteAddressServiceConnector.getFavouriteAddress({ id, payload });
  }),

  createFavouriteAddress: apiCall(async (context, { payload }) => {
    const favouriteAddressServiceConnector = context.getters.favouriteAddressServiceConnector(context);
    return favouriteAddressServiceConnector.createFavouriteAddress({ payload });
  }),

  updateFavouriteAddress: apiCall(async (context, { id, payload }) => {
    const favouriteAddressServiceConnector = context.getters.favouriteAddressServiceConnector(context);
    return favouriteAddressServiceConnector.updateFavouriteAddress({ id, payload });
  }),

  deleteFavouriteAddress: apiCall(async (context, { id }) => {
    const favouriteAddressServiceConnector = context.getters.favouriteAddressServiceConnector(context);
    try {
      favouriteAddressServiceConnector.deleteFavouriteAddress({ id });
      return true;
    } catch (error) {
      console.log(error);
      return error;
    }
  }),
};

const mutations = {
  setFavouriteAddresses(state, favouriteAddresses) {
    state.favouriteAddresses = favouriteAddresses;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: storeGetters,
  actions,
  mutations,
};
