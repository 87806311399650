<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <corporations-list-table
      v-if="!showImportResult"
      :corporationsLoading="entityLoading"
      :totalCorporations="entityTotalCount.totalCount"
      :corporations="data"
      @callLoadData="loadData"
      @addCorporation="addCorporation"
      @editCorporation="editCorporation"
      @deleteCorporation="handleDelete"
      @import="showImportDialog = true"
    ></corporations-list-table>

    <accounts-import-result
      v-if="showImportResult"
      :importData="importData"
      :totalRows="(importData.length)"
    ></accounts-import-result>

    <dialog-wrapper ref="accountDialog" :width=400  @closeDialog="loadData({page: currentPage, sort: '-createdAt'})">
      <template v-slot:dialogContent>
        <corporations-form
          :corporation="corporation"
          :currentPage="currentPage"
          @closeDialog="closeFormDialog"
          @corporationAdded="loadData"
          @corporationUpdated="loadData"
          :fleets="fleets"
          :key="recreate"
        />
      </template>
    </dialog-wrapper>
    <v-dialog persistent width="680" :value="showImportDialog">
      <accounts-import
        @cancel="showImportDialog = false"
        :fleets="fleets"
        @startImport="startImport"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { entityLoader } from '@/mixins/entityLoader.mixins';
import CorporationsListTable from '@/components/corporations/CorporationsListTable.vue';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';
import CorporationsForm from '@/components/corporations/CorporationsForm.vue';
import AccountsImport from '@/components/corporations/AccountsImport.vue';
import { getRoles } from '@/mixins/authentication.mixins';
import AccountsImportResult from '@/components/corporations/AccountsImportResult.vue';

export default {
  name: 'accounts-view',
  mixins: [entityLoader, getRoles],
  components: {
    CorporationsForm,
    CorporationsListTable,
    DialogWrapper,
    AccountsImport,
    AccountsImportResult,
  },
  data() {
    return {
      corporation: null,
      recreate: 0,
      currentPage: 1,
      fleetAccounts: [],
      fleets: [],
      showImportDialog: false,
      importData: [],
      showImportResult: false,
    };
  },
  created() {
    this.listFleets();
  },
  methods: {
    loadData({
      sort, page, maxPerPage, name,
    }) {
      this.currentPage = page;
      this.getList('corporations/listCorporations', {
        sort,
        page,
        maxPerPage,
        name,
      });
    },
    async listFleets() {
      const { data } = await this.$store.dispatch('fleetAccounts/listFleets');
      this.fleets = data;
    },
    closeFormDialog() {
      this.$refs.accountDialog.close();
      this.recreate += 1;
    },
    editCorporation(data) {
      this.corporation = data;
      this.$refs.accountDialog.openDialog();
    },
    addCorporation() {
      this.corporation = null;
      this.$refs.accountDialog.openDialog();
      this.recreate += 1;
    },
    async handleDelete(data) {
      this.$refs.accountDialog.openDialog({ type: 'delete-account', id: data.id });
    },
    async startImport(data) {
      this.importData = data;
      this.showImportResult = true;
    },
  },
};
</script>
