import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';
import { notifyGeneralError } from '@/helpers/notification';
import { loadStripe } from '@stripe/stripe-js';

const defaultState = {
  paymentMethodServiceConnector: null,
  stripeClient: null,
  clientSecret: null,
  paymentProviderName: null,
};

const storeGetters = {
  paymentMethodServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.paymentMethodServiceConnector) {
      state.paymentMethodServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.PaymentMethodServiceConnector });
    }
    return state.paymentMethodServiceConnector;
  },
  paymentProviderName: (state, getters, rootState, rootGetters) => {
    if (!state.paymentProviderName) {
      state.paymentProviderName = rootGetters['bookingChannel/payment'].name;
    }
    return state.paymentProviderName;
  },
  stripeClient: async (state, getters, rootState, rootGetters) => {
    if (!state.stripeClient) {
      const { publishableKey } = rootGetters['bookingChannel/payment'].configuration;
      state.stripeClient = await loadStripe(publishableKey);
    }
    return state.stripeClient;
  },
};

const actions = {
  getPaymentMethods: apiCall(async (context, { corporationId }) => {
    const paymentMethodsServiceConnector = context.getters.paymentMethodServiceConnector(context);
    return paymentMethodsServiceConnector.list({ corporationId, paymentProviderName: context.getters.paymentProviderName });
  }),

  // eslint-disable-next-line consistent-return
  retrieveSecret: apiCall(async (context, corporationId) => {
    const client = context.getters.paymentMethodServiceConnector(context);
    const response = await client.getClientSecret({ corporationId, paymentProviderName: context.getters.paymentProviderName });
    context.commit('setSecret', response.secret);
  }),

  assignPaymentToGroup: apiCall(async (context, {
    corporationId,
    paymentMethodId,
    addedEmployeeGroupIds,
    removedEmployeeGroupIds,
    paymentProviderName,
  }) => {
    const paymentMethodsServiceConnector = context.getters.paymentMethodServiceConnector(context);

    const params = {
      corporationId,
      paymentMethodId,
      addedEmployeeGroupIds,
      removedEmployeeGroupIds,
      paymentProviderName,
    };

    return paymentMethodsServiceConnector.managePaymentMethodEmployeeGroups(params);
  }),

  getEmployeeGroupsForPaymentMethodId: apiCall(async (context, {
    corporationId,
    paymentMethodId,
    paymentProviderName,
  }) => {
    const paymentMethodsServiceConnector = context.getters.paymentMethodServiceConnector(context);

    const params = {
      corporationId,
      paymentMethodId,
      paymentProviderName,
    };

    return paymentMethodsServiceConnector.getEmployeeGroupsForPaymentMethodId(params);
  }),

  storeCard: apiCall(async (context, {
    cardElement, cardName, corporationId, postalCode,
  }) => {
    const client = context.getters.paymentMethodServiceConnector(context);
    const result = await context.state.stripeClient.confirmCardSetup(context.state.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardName,
          address: {
            postal_code: postalCode,
          },
        },
      },
    });

    if (result.error) {
      console.log(result.error); // eslint-disable-line no-console
      notifyGeneralError();
      return false;
    }

    return {
      result: client.storeCard({ id: result.setupIntent.payment_method, corporationId, paymentProviderName: context.getters.paymentProviderName }),
      paymentId: result.setupIntent.payment_method,
    };
  }),
  deleteCard: apiCall(async (context, { id, corporationId }) => {
    const client = context.getters.paymentMethodServiceConnector(context);
    return client.deleteCard({ id, corporationId, paymentProviderName: context.getters.paymentProviderName });
  }),
};

const mutations = {
  setSecret(state, clientSecret) {
    state.clientSecret = clientSecret;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: storeGetters,
  actions,
  mutations,
};
