

/**
 * @typedef {Object} BookingFormModelDto
 */
export class BookingFormModel {
  /**
   * @param {BookingFormModelDto} args
   */
  constructor(args) {
    this.id = args.id;
    this.requesterType = 'business';
    this.numberOfVehicles = args.numberOfVehicles;
    this.dispatchBookingId = args.dispatchBookingId;
    this.bookingChannelId = args.bookingChannelId;
    this.fleetId = args.fleetId;
    this.passengerId = args.passengerId;
    this.paymentProviderName = args.paymentProviderName;

    this.businessAccountId = args.businessAccountId;
    this.businessPassengerId = args.businessPassengerId;
    this.businessGuestId = args.businessGuestId;
    this.businessGroupId = args.businessGroupId;

    this.offerId = args.offerId;

    /**
     * // @TODO: this structure should be injected from constructor
     * @type {CreateBookingRecipient}
     */
    this.createBookingRecipient = {
      employeeId: null,
      guestId: null,

      givenName: null,
      familyName: null,
      phoneNumber: null,
      email: null,
      isAdhocGuest: false,
    };

    this.shouldSystemCreateBusinessGuest = true;

    // BEGIN: Relations
    this.bookingChannel = args.bookingChannel;
    this.fleet = args.fleet;
    this.passenger = args.passenger;

    this.offer = args.offer;
    this.businessAccountId = args.businessAccount;
    this.businessPassenger = args.businessPassenger;
    this.businessGroup = args.businessGroup;
    this.paymentMethod = args.paymentMethod;
    // END: Relations

    this.fareEstimate = args.fareEstimate;
    this.origin = args.origin;
    this.originConfig = args.originConfig;
    this.destination = args.destination;
    this.waypoints = args.waypoints;
    this.flightNumber = args.flightNumber;
    this.returnFlightNumber = args.returnFlightNumber;
    this.bookingProperties = args.bookingProperties;

    this.notes = args.notes;
    this.businessAccountConfig = args.businessAccountConfig;
    this.accountFields = args.accountFields;

    this.scheduleType = args.scheduleType;
    this.pickupTime = args.pickupTime;
    this.timezone = args.timezone;
    this.returnBookingTime = args.returnBookingTime;
    this.accountFieldsConfiguration = args.accountFieldsConfiguration;
    this.shouldBookReturnBooking = args.shouldBookReturnBooking || false;
    this.isDuplicateBooking = args.isDuplicateBooking || false;
  }

  /**
   *
   * @returns {CreateBookingApiDto}
   */
  toCreateApiDto() {
    return {
      pickupTime: this.scheduleType === 'ASAP' ? null : this.pickupTime.format('YYYY-MM-DDTHH:mm:ss'),
      requesterType: this.requesterType,
      origin: {
        address: this.origin.address,
      },
    };
  }

  /**
   *
   * @returns {UpdateBookingApiDto}
   */
  toUpdateApiDto() {
    return {
      pickupTime: this.scheduleType === 'ASAP' ? null : this.pickupTime.format('YYYY-MM-DDTHH:mm:ss'),
    }
  }
}
