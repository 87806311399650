<template>
  <div class="multiple-address-container">
    <v-timeline dense>
      <v-timeline-item
        small
        fill-dot
        right
        color="white"
        class="px-3"
      >
        <template v-slot:icon>
          <map-location class="icon-green" />
        </template>
        <v-col :cols="isShowFlightNumber ? 7 : 12" class="px-0">
          <multiselect
            v-model="pickup"
            ref="pickup"
            class="address-select"
            track-by="id"
            label="description"
            autofocus
            open-direction="bottom"
            :tabindex="0"
            :pre-select-first="true"
            :show-labels="false"
            :placeholder="$t('components.bookingForm.field.pickup')"
            :show-no-options="false"
            :close-on-select="true"
            :clear-on-select="false"
            :options="items.pickup"
            :internal-search="false"
            :searchable="true"
            :class="[targetValidator.pickup.address.$anyError ? 'error-border' : '', isShowFlightNumber ? 'is-flight-number' : '']"
            height="45px"
            @search-change="getAddressSuggestions($event, 'pickup')"
            @select="getAddressDetails($event, 'pickup')"
          >
            <span slot="caret"></span>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.type === 'favourite'" class="d-flex align-center">
                <favourites-icon style="width:24px" />
                <span class="ml-2">{{ props.option.alias }}</span>
              </div>

              <span v-else>{{ props.option.description }}</span>
            </template>
          </multiselect>
        </v-col>

        <!-- <v-spacer></v-spacer> -->

        <v-col v-if="isShowFlightNumber" cols="5" class="px-0">
          <v-text-field
            v-model="flightNumber"
            id="flightNumber"
            class="mt-0 ml-2 address-select flightNumberField"
            hide-details
            outlined
            :class="[targetValidatorFlightNumber.$anyError ? 'error-border' : '']"
            :placeholder="$t('components.bookingForm.field.flightNumber')"
            @change="onUpdateFlightNumber"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-fade-transition>
                    <v-icon
                      v-if="targetValidatorFlightNumber.$anyError"
                      class="validation-icon mt-1"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >mdi-exclamation</v-icon>
                  </v-fade-transition>
                </template>
                <div class="d-flex flex-column">
                  <span v-if="targetValidatorFlightNumber.required">
                    {{ $t('components.bookingForm.validation.flightNumber.required') }}
                  </span>
                  <span v-if="targetValidatorFlightNumber.maxLength">
                    {{ $t('components.bookingForm.validation.flightNumber.maxLength', { attribute: 6 }) }}
                  </span>
                  <span v-if="targetValidatorFlightNumber.minLength">
                    {{ $t('components.bookingForm.validation.flightNumber.minLength', { attribute: 3 }) }}
                  </span>
                </div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-timeline-item>
      <v-timeline-item
        v-for="(waypoint, index) in waypoints"
        :key="index"
        :index="index"
        small
        fill-dot
        right
        color="white"
        class="px-3"
      >
        <template v-slot:icon>
          <map-location />
        </template>
        <v-col class="px-0 d-flex">
          <multiselect
            v-model="waypoints[index]"
            :ref="`waypoint${index}`"
            class="address-select"
            track-by="id"
            label="description"
            :show-labels="false"
            :close-on-select="true"
            :clear-on-select="false"
            :placeholder="$t('components.bookingForm.field.waypoint')"
            autofocus
            open-direction="bottom"
            :options="items.waypoint"
            :internal-search="false"
            :show-no-options="false"
            :searchable="true"
            height="45px"
            @search-change="getAddressSuggestions($event, 'waypoint')"
            @select="getAddressDetails($event, 'waypoint', index)"
          >
            <span slot="caret"></span>
          </multiselect>
          <v-icon class="remove-icon cursor-pointer" @click="$emit('removeWaypoint', index)">mdi-close</v-icon>
        </v-col>
      </v-timeline-item>
      <v-timeline-item
        small
        fill-dot
        right
        color="white"
        class="px-3"
      >
        <template v-slot:icon>
          <map-location />
        </template>
        <v-col class="px-0">
          <multiselect
            v-model="dropoff"
            ref="dropoff"
            class="address-select"
            track-by="id"
            label="description"
            :close-on-select="true"
            :clear-on-select="false"
            :placeholder="$t('components.bookingForm.field.dropoff')"
            open-direction="bottom"
            :show-labels="false"
            :options="items.dropoff"
            :class="targetValidator.dropoff.address.$anyError ? 'error-border' : ''"
            :internal-search="false"
            :show-no-options="false"
            :searchable="true"
            height="45px"
            @search-change="getAddressSuggestions($event, 'dropoff')"
            @select="getAddressDetails($event, 'dropoff')"
          >
            <span slot="caret"></span>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.type === 'favourite'" class="d-flex align-center">
                <favourites-icon style="width:24px" />
                <span class="ml-2">{{ props.option.alias }}</span>
              </div>

              <span v-else>{{ props.option.description }}</span>
            </template>
          </multiselect>
        </v-col>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';
import { mapGetters } from 'vuex';

const SOURCE_GOOGLE = 'google';
const SOURCE_ICABBI = 'icabbi';

export default {
  name: 'address-picker',
  components: { ...iconLoader.booking, ...iconLoader.favourites },
  props: {
    waypoints: {
      type: Array,
      default: () => [],
    },
    pickup: {
      type: Object,
      default: () => ({}),
    },
    dropoff: {
      type: Object,
      default: () => ({}),
    },
    flight: {
      type: String,
      default: '',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    validationFlightNumber: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    query: '',
    items: {
      pickup: [],
      waypoint: [],
      dropoff: [],
    },
    flightNumber: '',
    isOpen: false,
  }),
  async mounted() {
    if (this.getSelectedCorporationId) {
      const options = {
        payload: { page: 0, maxPerPage: 100 },
        businessAccountId: this.getSelectedCorporationId,
      };

      this.$store.dispatch('favourites/getFavouriteAddresses', options);
    }
    this.$nextTick(() => this.$refs.pickup.$el.focus());
  },
  watch: {
    waypoints(newVal, oldVal) {
      if (newVal.length >= oldVal.length) {
        const count = this.waypoints.length;
        this.$nextTick(() => this.$refs[`waypoint${count - 1}`][0].$el.focus());
      }
    },
    getSelectedCorporationId(val) {
      const options = {
        payload: { page: 0, maxPerPage: 100 },
        businessAccountId: val,
      };

      this.$store.dispatch('favourites/getFavouriteAddresses', options);
    },
    favouriteAddresses(val) {
      if (val.results.length) {
        val.results.map((el) => {
          if (el.suggestForPickupAddress) {
            this.items.pickup.push({
              id: el.id,
              description: el.fullAddressText,
              alias: el.alias,
              source: SOURCE_ICABBI,
              detail: el,
              locationTypes: el.locationTypes || [],
              type: 'favourite',
            });
          }

          if (el.suggestForDestinationAddress) {
            this.items.dropoff.push({
              id: el.id,
              description: el.fullAddressText,
              alias: el.alias,
              source: SOURCE_ICABBI,
              detail: el,
              locationTypes: el.locationTypes || [],
              type: 'favourite',
            });
          }
        });
      }
    },
    flight(val) {
      this.flightNumber = val;
    },
    isShowFlightNumber(val) {
      this.targetValidatorFlightNumber.$touch();
      this.$emit('setFlightNumber', val);
    },
    flightNumber(val) {
      if (val) {
        this.targetValidatorFlightNumber.$touch();
      }
    },
  },
  computed: {
    ...mapGetters({
      favouriteAddresses: 'favourites/favouriteAddresses',
      getSelectedCorporationId: 'corporations/getSelectedCorporationId',
    }),
    targetValidator() {
      return this.validation;
    },
    targetValidatorFlightNumber() {
      return this.validationFlightNumber;
    },
    isShowFlightNumber() {
      return this.pickup && this.pickup.locationTypes && this.pickup.locationTypes.includes('airport');
    },
  },
  methods: {
    async getAddressSuggestions(search, type) {
      if (search) {
        const result = await this.$store.dispatch('geolocation/getSuggestions', { search, lang: this.$i18n.locale });

        this.items[type] = [...result];
        return this.items;
      }

      this.items[type] = [];
      this.injectFavouriteAdress(type);

      return this.items;
    },
    injectFavouriteAdress(type) {
      if (!this.favouriteAddresses.results) return;
      this.favouriteAddresses.results.map((el) => {
        if (el.suggestForPickupAddress && type === 'pickup') {
          this.items.pickup.unshift({
            id: el.id,
            description: el.fullAddressText,
            alias: el.alias,
            source: SOURCE_ICABBI,
            detail: el,
            type: 'favourite',
          });
        }

        if (el.suggestForDestinationAddress && type === 'dropoff') {
          this.items.dropoff.unshift({
            id: el.id,
            description: el.fullAddressText,
            alias: el.alias,
            source: SOURCE_ICABBI,
            detail: el,
            type: 'favourite',
          });
        }
      });
    },
    onUpdateFlightNumber(flightNumber) {
      this.flightNumber = flightNumber.toUpperCase();
      this.$emit('updateFlightNumber', this.flightNumber);
    },
    async getAddressDetails(val, type, index) {
      switch (val?.source) {
        case SOURCE_GOOGLE:
          // eslint-disable-next-line no-param-reassign
          val.detail = await this.$store.dispatch('geolocation/getSuggestionDetail', {
            addressId: val.id,
            lang: this.$i18n.locale,
          });
          break;
        case SOURCE_ICABBI:
          // eslint-disable-next-line no-param-reassign
          val.detail = {
            coordinates: this.getAdresseCoordinates(val),
            description: val.description,
            dispatchAddressId: val.dispatchAddressId,
            locationTypes: val.locationTypes,
          };
          break;
        default:
          break;
      }

      if (val) {
        switch (type) {
          case 'pickup':
            this.pickup = val;
            this.$nextTick(() => {
              if (this.waypoints.length === 0) {
                this.$refs.dropoff.$el.focus();
              } else {
                this.$refs.waypoint0[0].$el.focus();
              }
            });

            this.$store.commit('bookings/setAddress', { type: 'pickup', address: val });
            this.$store.dispatch('driverStates/getDrivers', {
              latitude: val.detail.coordinates.latitude,
              longitude: val.detail.coordinates.longitude,
              limit: 10,
              status: 'available',
            });

            this.$emit('pickup', val);
            break;
          case 'dropoff':
            this.dropoff = val;

            this.$store.commit('bookings/setAddress', { type: 'dropoff', address: this.dropoff });
            return this.$emit('dropoff', this.dropoff);
          case 'waypoint':
            this.waypoints[index] = val;

            this.$forceUpdate();
            this.$nextTick(() => {
              if (index === this.waypoints.length - 1) {
                this.$refs.dropoff.$el.focus();
              } else if (index < this.waypoints.length - 1) {
                this.$refs[`waypoint${index + 1}`][0].$el.focus();
              }
            });

            this.$store.commit('bookings/setAddress', { type: 'waypoints', address: this.waypoints });
            return this.$emit('waypoints', this.waypoints);
          default:
            break;
        }
      }

      return true;
    },
    getAdresseCoordinates(address) {
      if (address.type && address.type === 'favourite') {
        if (address.detail.latitude) {
          return { latitude: address.detail.latitude, longitude: address.detail.longitude };
        }
        if (address.detail.coordinates) {
          return { latitude: address.detail.coordinates.latitude, longitude: address.detail.coordinates.longitude };
        }
      }
      return address.info.coordinates;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-address-container {
  width: 100%;
  min-height: 8vh;

  .icon-green {
    color: #47d061
  }

  .remove-icon {
    position: absolute;
    right: 10px;
    top: 32%;
    z-index: 1000;

    &:hover {
      color: black;
    }
  }
}
.error-border {
  border: 1px solid #FF0000 !important;
  border-radius: 4px;
}

.validation-icon {
  position: absolute;
  right: 10px;
  top: 33%;
  z-index: 10;
  color:  #ff0000 !important;
}
</style>

<style lang="scss">
.flightNumberField {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em !important;
  color: #333333 !important;
  background-color: #f0f0f0 !important;

  & fieldset{
    border: 0px !important;
  }
}
.multiple-address-container {
  .address-select {
    .multiselect__input, .multiselect__single {
      background: initial !important;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      color: #333333;

      width: 350px;
      text-overflow: ellipsis !important;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 0px;
    }

    .multiselect__placeholder {
      background: initial !important;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
    }

    .multiselect__element {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.005em;
      color: #333333;
    }

    .multiselect__option--highlight {
      background: #47D061;
    }

    .multiselect__option {
      white-space: normal !important;
    }

    .multiselect__tags {
      background: rgba(0, 0, 0, 0.06);
      border: none !important;
      border-radius: 4px;
      padding: 8px 8px 0px 8px;

      display: flex;
      align-content: center;
      align-items: center;
      height: 45px;
    }

    .multiselect__content {
      padding-left: 0px;
    }
  }
}
.v-timeline {
  padding-top: 0px;
}

.v-timeline::before {
  top: 40px;
  height: var(--before-height, 63px);
}

.v-timeline-item__body {
  display: flex;
  align-items: center;
  align-content: center;
}

.v-timeline--dense .v-timeline-item {
  height: 64px;
  padding: 0px;
}

.v-timeline-item__divider {
  justify-content: flex-start;
  min-width: auto !important;
}

.multiple-address-container {
  .v-timeline::before {
    left: 5.2% !important;
  }
}

.v-timeline--dense .v-timeline-item__body {
  min-width: 375px;
  max-width: 390px;
  margin-left: 10px;
}
</style>
