<template>
  <v-container class="get-started-container" :class="{ 'is-mobile': isMobile }">
    <v-layout align-center class="mx-auto" text-center wrap>
      <v-flex lg12 xs12>
        <v-card
          outlined
          class="mx-auto pa-10 get-started-card"
          :class="{ 'is-mobile': isMobile }"
          color="white"
        >
          <v-img
            :src="logoUrl"
            class="mx-auto get-started-logo"
            :style="logoSizeStyle"
            :class="{ 'is-mobile': isMobile }"
          />
          <v-card-text
            class="get-started-card-title title mb-10"
          >{{ $t('components.getStarted.welcome', { label }) }}</v-card-text>
          <v-btn
            height="50"
            v-if="smsPersonalLoginEnabled"
            x-large
            block
            depressed
            color="primary"
            class="primaryFont--text mb-3 get-started-start-classic"
            @click="startClassic"
          >{{ $t('components.getStarted.classicLoginBtn') }}</v-btn>
          <v-btn
            height="50"
            v-if="businessLoginAvailable"
            x-large
            outlined
            color="primary"
            block
            class="mt-3 get-started-start-business"
            @click="startBusiness"
          >{{ isMobile ? $t('components.getStarted.businessLoginMobileBtn') : $t('components.getStarted.businessLoginBtn') }}</v-btn>
        </v-card>
      </v-flex>
      <v-flex lg12 xs12 class="ma-6">
        <v-select
          class="mx-auto get-started-language-selector"
          :items="languages"
          item-text="name"
          item-value="id"
          v-model="language"
          @change="changeLanguage"
        ></v-select>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const MOBILE_BREAKPOINTS = ['sm', 'xs', 'md'];
export default {
  data() {
    return {
      language: null,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
    businessLoginAvailable: {
      type: Boolean,
      default: false,
    },
    smsPersonalLoginEnabled: {
      type: Boolean,
      default: true,
    },
    logoSize: {
      type: String,
      default: '90px',
    },
  },
  async created() {
    this.language = this.selectedLanguage;
  },
  methods: {
    startClassic() {
      this.$emit('startClassic');
    },
    startBusiness() {
      this.$emit('startBusiness');
    },
    changeLanguage() {
      this.$emit('changeLanguage', this.language);
    },
  },
  computed: {
    isMobile() {
      if (!this.$vuetify.breakpoint) {
        return false;
      }
      return MOBILE_BREAKPOINTS.includes(this.$vuetify.breakpoint.name);
    },
    logoSizeStyle() {
      return `width: ${this.logoSize};`;
    },
  },
};
</script>

<style lang="sass" scoped>
  .get-started-container
    max-width: 480px
</style>
