<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <get-started
        :label="companyName"
        :logoUrl="logoURL"
        :selectedLanguage="selectedLanguage"
        :languages="languages"
        v-on:startClassic="startClassic"
        v-on:changeLanguage="changeLanguage"
      />
    </v-layout>
  </v-container>
</template>

<script>
import { Authentication } from '@icabbi/vue-authentication';

import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import { languageMethods, languageComputed } from '@/mixins/language.mixins';

import GetStarted from '@/components/auth/GetStarted.vue';

export default {
  components: { GetStarted },
  mixins: [bookingChannelComputed, languageComputed, languageMethods],
  data() {
    return {
      loginUrl: false,
    };
  },
  async created() {
    this.setLoginUrl(await this.getLoginUrl());
  },
  methods: {
    startClassic() {
      window.location = this.loginUrl;
    },
    async setLoginUrl(url) {
      this.loginUrl = url;
    },
    async getLoginUrl() {
      return Authentication(this.$store)
        .getLoginRedirectionUrl({
          clientId: this.oidcClientId,
          redirectUri: this.authCallbackUrl,
          locale: this.selectedLanguage,
          audience: process.env.VUE_APP_LOGIN_AUDIENCE,
          acrValues: process.env.VUE_APP_LOGIN_ACR_VALUES,
        });
    },
    async changeLanguage(language) {
      await this.selectLanguage(language);
      const loginUrl = await this.getLoginUrl();
      this.setLoginUrl(loginUrl);
    },
  },
};
</script>
