// eslint-disable-next-line import/prefer-default-export
export function useDefaultPaymentMethods() {
  const state = {
    formattedCards: [],
    defaultPaymentMethods: [
      { type: 'businessAccount', title: 'account', brand: 'business' },
      { type: 'cash', title: 'cash', brand: 'cash' },
    ],
  };

  function getDefaultPaymentFromStorage() {
    const data = localStorage.getItem('defaultPayment');
    if (data) {
      const localPayment = JSON.parse(data);

      if (localPayment.id) {
        state.formattedCards = state.formattedCards.map((el) => {
          if (el.id === localPayment.id) {
            // eslint-disable-next-line no-param-reassign
            el.defaultPayment = true;
          }

          return el;
        });
      } else {
        state.formattedCards = state.formattedCards.map((el) => {
          if (el.brand === localPayment.brand) {
            // eslint-disable-next-line no-param-reassign
            el.defaultPayment = true;
          }

          return el;
        });
      }
    }

    return state.formattedCards;
  }

  function generateCardsList(cards = []) {
    const params = state.defaultPaymentMethods.map((el) => ({
      type: el.type,
      brand: el.title,
      expiration: '',
      cardHolderName: '',
      staus: null,
    }));

    // eslint-disable-next-line no-param-reassign
    const mappedCards = cards.map((el) => ({ ...el, type: 'creditCard' }));
    state.formattedCards = [...params, ...mappedCards].map((el) => ({ ...el, defaultPayment: false }));
    return getDefaultPaymentFromStorage();
  }

  function setDefaultPayment(item) {
    state.formattedCards = state.formattedCards.map((el) => {
      // eslint-disable-next-line no-param-reassign
      el.defaultPayment = false;

      if (!item.id) {
        if (el.brand === item.brand) {
          // eslint-disable-next-line no-param-reassign
          el.defaultPayment = true;
        }

        return el;
      }

      if (el.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        el.defaultPayment = true;
      }

      return el;
    });

    if(checkIfSameDefaultPayment(item)) {
      removeDefaultPayment();
      return;
    } else {
      localStorage.setItem('defaultPayment', JSON.stringify(item));
    }

  }

  function checkIfSameDefaultPayment(item) {
    const defaultPayment = JSON.parse(localStorage.getItem('defaultPayment'));

    if (defaultPayment && defaultPayment.id === item.id) {
      return true;
    }

    if (defaultPayment && defaultPayment.brand === item.brand) {
      return true;
    }

    return false;
  }

  function removeDefaultPayment() {
    localStorage.removeItem('defaultPayment');
  }

  function removeDefaultPayment() {
    localStorage.removeItem('defaultPayment');
  }

  return {
    setDefaultPayment,
    removeDefaultPayment,
    generateCardsList,
  };
}
