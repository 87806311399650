<template>
  <div>
    <trips-history-table
      :corporationsLoading="entityLoading"
      :totalTrips="entityTotalCount.totalCount"
      :trips="data"
      @callLoadData="loadData"
      @showDownloadModal="showDownloadModal"
    ></trips-history-table>

    <dialog-wrapper ref="downloadModal" :width="600">
      <template v-slot:dialogContent>
        <trip-download-form
          @closeDialog="closeDialog"
        />
      </template>
    </dialog-wrapper>
  </div>

</template>

<script>
import { getRoles } from '@/mixins/authentication.mixins';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import TripsHistoryTable from '@/components/tripsHistory/TripsHistoryTable.vue';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';
import TripDownloadForm from '@/components/tripsHistory/TripDownloadForm.vue';
import { CHANNEL_ADMIN } from '@/helpers/userRoles';

export default {
  name: 'trips-view',
  mixins: [entityLoader, getRoles],
  components: {
    TripsHistoryTable, DialogWrapper, TripDownloadForm,
  },
  data() {
    return {
      CHANNEL_ADMIN,
    };
  },
  methods: {
    hasAccess(role) {
      return this.userRoles ? this.userRoles.filter((userRole) => userRole.name === role).length > 0 : false;
    },
    loadData(options) {
      const url = this.hasAccess(CHANNEL_ADMIN)
        ? 'tripsHistory/getFleetHistory'
        : 'tripsHistory/getCorporationsHistory';
      this.getList(url, options);
    },
    showDownloadModal() {
      this.$refs.downloadModal.openDialog();
    },
    closeDialog() {
      this.$refs.downloadModal.close();
    },
  },
};
</script>
