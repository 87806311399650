import Vue from 'vue';
import Vuex from 'vuex';

import { AuthenticationVuexModule } from '@icabbi/vue-authentication';

import bookingChannel from '@/store/bookingChannel';
import language from '@/store/language';
import tripsHistory from '@/store/tripsHistory';
import employee from '@/store/employee';
import guest from '@/store/guest';
import paymentMethods from '@/store/paymentMethods';
import corporations from '@/store/corporations';
import users from '@/store/users';
import bookings from '@/store/bookings';
import navigation from '@/store/navigation';
import geolocation from '@/store/geolocation';
import travelRestriction from '@/store/travelRestriction';
import offers from '@/store/offers';
import fleetAccounts from '@/store/fleetAccounts';
import fareEstimation from '@/store/fareEstimation';
import csvReport from '@/store/csvReport';
import businessAccount from '@/store/businessAccount';
import businessPassenger from '@/store/businessPassenger';
import businessGuest from '@/store/businessGuest';
import favourites from '@/store/favourites';
import driverStates from '@/store/driverStates';
import businessBookingRecipient from '@/store/api/businessBookingRecipient';
import addressSuggestions from '@/store/api/addressApiStore';
import paymentMethodsApiStore from "@/store/api/paymentApiStore";
import fareEstimationApiStore from "@/store/api/fareApiStore";
import offersApiStore from "@/store/api/offersApiStore";
import favouritesApiStore from '@/store/api/favouritesApiStore';
import bookingApiStore from '../store/api/bookingApiStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication: AuthenticationVuexModule,
    addressSuggestions,
    bookingChannel,
    bookingApiStore,
    businessBookingRecipient,
    tripsHistory,
    driverStates,
    users,
    language,
    employee,
    guest,
    bookings,
    corporations,
    navigation,
    geolocation,
    favourites,
    favouritesApiStore,
    offers,
    offersApiStore,
    paymentMethods,
    paymentMethodsApiStore,
    travelRestriction,
    fleetAccounts,
    fareEstimation,
    fareEstimationApiStore,
    csvReport,
    businessAccount,
    businessGuest,
    businessPassenger,
  },
});
