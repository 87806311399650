<template>
  <div class="d-flex flex-column w-full black--text">
    <p class="section-title mb-0">{{ $t("components.bookingForm.field.pickup") }}</p>
    <span class="booking-details-span">{{ details.origin.address }}</span>

    <div v-for="(waypoint, index) in details.waypoints" :key="index">
      <p class="booking-details-p mt-3 mb-0">{{ $t("components.bookingForm.field.waypoint") }}</p>
      <span class="booking-details-span">{{ waypoint.address }}</span>
    </div>

    <p class="booking-details-p mt-3 mb-0">{{ $t("components.bookingForm.field.dropoff") }}</p>
    <span class="booking-details-span">{{ details.destination.address }}</span>

    <p v-if="details.pickupTime" class="booking-details-p mt-3 mb-0">
      {{ $t("components.bookingForm.section.date") }}:
    </p>
    <span v-if="details.pickupTime" class="booking-details-span">{{ formattedDate }}</span>

    <p v-if="details.bookingProperties && details.bookingProperties.flightInfo" class="booking-details-p mt-3 mb-0">
      {{ $t("components.bookingForm.field.flightNumber") }}:
    </p>
    <span v-if="details.bookingProperties && details.bookingProperties.flightInfo" class="booking-details-span">
      {{ details.bookingProperties.flightInfo.number }}
    </span>

    <p class="booking-details-p mt-3 mb-0">{{ $t("components.bookingForm.field.passenger") }}</p>
    <span class="booking-details-span">
      {{ details.userInfo.givenName }} {{ details.userInfo.familyName || "" }}
    </span>

    <p class="booking-details-p mt-3 mb-0">{{ $t("components.bookingForm.field.phone") }}</p>
    <span class="booking-details-span">{{ details.userInfo.phone }}</span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BookingDetails",
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return moment(this.details.pickupTime).format("LLL");
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>

<style lang="scss" scoped>
.booking-details-span {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #333333;
}

.booking-details-p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #888;
  margin-bottom: 8px;
}
</style>
