<template>
  <div class="d-flex flex-column">
    <v-flex v-if="availableFleets && availableFleets.length > 1">
      <h5 class="section-title pb-2">
        {{ $t("components.bookingForm.section.company") }}
      </h5>
      <v-select
        :menu-props="{ nudgeBottom: 50 }"
        :loading="offersLoading"
        :items="availableFleets"
        :value="fleetId"
        :placeholder="$t('components.bookingForm.selectFleet')"
        hide-details="auto"
        color="default"
        outlined
        filled
        single-line
        class="select-high"
        append-icon="mdi-chevron-down"
        item-value="fleetId"
        @change="onFleetChange"
      >
        <template slot="selection" slot-scope="{ item }">
          <span>{{ item.companyName }}</span>
        </template>
        <template slot="item" slot-scope="{ item }">
          <span class="custom-text">{{ item.companyName }}</span>
        </template>
      </v-select>
    </v-flex>

    <v-divider v-if="availableFleets && availableFleets.length > 1" class="my-6" />

    <v-flex>
      <v-row>
        <v-col cols="7" class="pt-0">
          <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.vehicle") }}</h5>
          <v-select
            outlined
            filled
            hide-details
            single-line
            height="60"
            class="select-high select-company"
            item-text="name"
            item-value="id"
            :value="selectedOffer"
            :loading="offersLoading"
            :append-icon="`${offers.length ? 'mdi-chevron-down' : ''}`"
            :readonly="!offers.length"
            :label="$t('components.bookingForm.noneAvailable')"
            :menu-props="{ nudgeBottom: 60 }"
            :items="offersGroupedByFleet[fleetId]"
            @change="onOfferChange"
          >
            <template v-slot:prepend-inner>
              <car-default class="icon-car mr-3" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="5" class="pt-0">
          <h5 class="section-title pb-2">
            {{ $t("components.bookingForm.section.vehicleCount") }}
          </h5>
          <v-text-field
            outlined
            filled
            height="60"
            hide-details
            :value="numberOfVehicles"
            @input="onNumberOfVehicleChange"
          />
        </v-col>
      </v-row>
    </v-flex>

    <v-divider class="my-6" />

    <v-flex>
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.payment") }}</h5>

      <v-select
        outlined
        filled
        hide-details
        single-line
        return-object
        height="60"
        class="select-high select-payment"
        item-value="id"
        :value="selectedPaymentMethod"
        :item-text="formattedPaymentMethodName"
        :label="$t('components.bookingForm.noneAvailable')"
        :loading="paymentMethodsLoading"
        :append-icon="`${paymentMethods.length ? 'mdi-chevron-down' : ''}`"
        :menu-props="{ nudgeBottom: 60 }"
        :items="paymentMethods"
        @change="onPaymentMethodChange"
      >
        <template v-slot:prepend-inner>
          <component
            class="icon-payment mr-2"
            v-bind:is="creditCardIcon(getPaymentMethodIcon(selectedPaymentMethod || {}))"
          />
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :disabled="isBookingTimeMoreThan7Days(item.paymentMethodType)"
            :class="{ 'opcity-half': isBookingTimeMoreThan7Days(item.paymentMethodType) }"
          >
            <v-list-item-icon>
              <component v-bind:is="creditCardIcon(getPaymentMethodIcon(item))" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ formattedPaymentMethodName(item) }}
              </v-list-item-title>
              <v-list-item-title>
                {{ item.creditCardPaymentMethod?.cardHolderName || "" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </v-flex>

    <v-divider class="my-4" />

    <v-flex>
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.notes") }}</h5>
      <v-textarea
        ref="notesInput"
        class="notes-field"
        outlined
        filled
        hide-details
        rows="2"
        :rules="[(v) => v.length >= 3 || $t('components.bookingForm.validation.notesMinLength')]"
        v-model="bookingNotes"
      >
        <template v-slot:append>
          <v-tooltip v-if="bookingNotes && $refs.notesInput && $refs.notesInput.hasError" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                >mdi-help-circle-outline</v-icon
              >
            </template>
            <p v-for="(error, index) in $refs.notesInput.errorBucket" :key="index" class="mb-0">
              {{ $t(error) }}
            </p>
          </v-tooltip>
        </template>
      </v-textarea>
    </v-flex>

    <v-flex class="d-flex mt-4">
      <v-checkbox
        :disabled="offersLoading"
        :value="shouldBookReturnBooking"
        :ripple="false"
        dense
        hide-details
        class="mt-0 pt-0"
        @change="onToggleReturnBooking"
      ></v-checkbox>
      <span>{{ $t("components.bookingForm.returnBooking.returnQuestion") }}</span>
    </v-flex>

    <v-flex v-if="shouldBookReturnBooking" class="d-flex flex-column mt-4">
      <DateTimePicker
        :default-date="returnDate"
        :default-time="returnTime"
        return-time
        @pickupTimeChanged="onPickupTimeChanged"
      />

      <h5 v-if="isDestinationAirport" class="section-title pt-2 pb-1">{{ $t("components.bookingForm.field.flightNumber") }}</h5>
      <v-text-field
        v-if="isDestinationAirport"
        v-model="returnFlightNumber"
        id="returnFlightNumber"
        class="address-select flightNumberField"
        style="max-width: 47%;"
        hide-details
        outlined
        :class="[$v.returnFlightNumber.$invalid ? 'error-border' : '']"
        :placeholder="$t('components.bookingForm.field.flightNumber')"
      >
        <template v-slot:append>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-fade-transition>
                <v-icon
                  v-if="$v.returnFlightNumber.$invalid"
                  class="validation-icon mt-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  >mdi-alert-circle</v-icon
                >
              </v-fade-transition>
            </template>
            <div class="d-flex flex-column">
              <span v-if="!$v.returnFlightNumber.minLength">
                {{
                  $t("components.bookingForm.validation.flightNumber.minLength", {
                    attribute: 3,
                  })
                }}
              </span>
            </div>
          </v-tooltip>
        </template>
      </v-text-field>
    </v-flex>

    <loader :is-loading="isFareEstimationLoading" :size="30" :width="5" color="primary">
      <v-flex v-if="fareEstimation" class="d-flex mt-4">
        <p class="mb-0 fare-field">
          {{ `${$t("components.bookingForm.fare")}` }}
        </p>
        <v-spacer></v-spacer>
        <p v-if="isFareEstimationAvailable(fareEstimation)" class="mb-0 pr-2 fare-value">
          {{ `${currencySymbol} ${formattedFareByType(fareEstimation)}` }}
        </p>
        <p v-else class="mb-0 pr-2 no-fare-value">
          {{ $t("components.bookingForm.NoFareAvailable") }}
        </p>
      </v-flex>

      <v-flex v-else class="d-flex mt-4">
        <p class="mb-0 fare-field">
          {{ `${$t("components.bookingForm.fare")}` }}
        </p>
        <v-spacer></v-spacer>
        <p class="mb-0 pr-2 no-fare-value">
          {{ $t("components.bookingForm.NoFareAvailable") }}
        </p>
      </v-flex>

      <v-flex v-if="showFareFetchError">
        <p class="mb-0 fare-field red--text">
          {{ `${$t("components.bookingForm.requestErrors.fetchFareEstimate")}` }}
        </p>
      </v-flex>
    </loader>

    <v-flex class="mt-4 d-flex justify-space-between">
      <v-btn color="#333333" outlined @click="onBackClick">
        {{ $t("components.bookingForm.back") }}
      </v-btn>

      <v-btn
        v-if="!isFareEstimationLoading"
        color="#333333"
        class="white--text"
        depressed
        :loading="accountFieldsViewModel.isLoading || isFareEstimationLoading"
        :disabled="returnTimeInvalid || isNotesInvalid || $v.returnFlightNumber.$invalid"
        @click="onCompanyStepSubmit"
      >
        {{ submitButtonText }}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { uniqBy } from "lodash";
import moment from "moment-timezone";
import { minLength } from "vuelidate/lib/validators";

import { iconLoader } from "@/helpers/iconsLoader";
import { creditCardIcon } from "@/mixins/creditCardIcons.mixins";
import { FareApiDtoDelegate } from "@/core/api/fare/FareApiDtoDelegate";
import { PaymentMethodDomainDelegate } from "@/domain/paymentMethod/PaymentMethodDomainDelegate";
import VehiclePicker from "@/components/booking/components/VehiclePicker.vue";
import DateTimePicker from "@/components/booking/components/DateTimePicker.vue";
import { NumberFormatter } from "@icabbi/hi-dash";
import { SafeAwait } from "@/core/util/SafeAwait";

export default {
  name: "CompanyStepComponent",
  components: {
    ...iconLoader.booking,
    ...iconLoader.paymentMethod,
    VehiclePicker,
    DateTimePicker,
  },
  props: {
    presenter: {
      type: Object,
      required: true,
    },
  },
  mixins: [creditCardIcon],
  data: () => ({
    fleets: [],
    fleetId: null,
    payment: null,
    bookingNotes: "",

    /**
     * @type {Array<PaymentMethodDomainModel>}
     */
    paymentMethods: [],
    shouldBookReturnBooking: false,
    paymentMethodsLoading: false,
    fareEstimation: null,

    /**
     * @type {Array<OfferApiDto>}
     */
    offers: [],
    offersLoading: false,

    /**
     * @type {Record<string, OfferApiDto >}
     */
    offersGroupedByFleet: {},

    /**
     * @type {Array<{ id: string; name: string; }>}
     */
    availableFleets: [],

    /**
     * @type {OfferApiDto | null}
     */
    selectedOffer: null,

    /**
     * @type {PaymentMethodDomainModel | null}
     */
    selectedPaymentMethod: null,

    numberOfVehicles: 1,

    isFareEstimationLoading: false,

    returnDate: null,
    returnTime: null,
    returnFlightNumber: undefined,
    /**
     * @type {moment.Moment | null}
     */
    returnPickupTime: null,
    showFareFetchError: false,

    fleetAccountsViewModel: {
      isLoading: false,
      error: null,
      data: [],
    },

    accountFieldsViewModel: {
      isLoading: false,
      error: null,
      data: [],
    },
    returnTimeInvalid: false,
    areaRules: [
      (v) => !!v || "Message is required",
      (v) => v.length >= 4 || "Message must be at least 4 characters long",
    ],
  }),
  async created() {
    await Promise.all([this.loadOffers(), this.loadPaymentMethods()]);

    this.preselectFirstOffer();
    this.preselectFirstPaymentMethod();
    this.prefillReturnBookingIfAvailable();

    this.bookingNotes = this.presenter.interactor.bookingFormModel.notes;

    if (this.selectedOffer) {
      await this.fetchFareEstimation();
    }
  },
  computed: {
    ...mapGetters({
      currencySymbol: "bookingChannel/currencySymbol",
      defaultLocale: "bookingChannel/defaultLocale",
    }),
    submitButtonText() {
      if (this.selectedPaymentMethod === null) {
        return this.$t("components.bookingForm.next");
      }

      return PaymentMethodDomainDelegate.isTypeBusinessDispatchInvoice(
        this.selectedPaymentMethod.paymentMethodType
      )
        ? this.$t("components.bookingForm.next")
        : this.$t("components.bookingForm.book");
    },
    isNotesInvalid() {
      if (this.bookingNotes === "") {
        return false;
      }

      return this.bookingNotes && this.bookingNotes.length < 3;
    },
    isDestinationAirport() {
      return this.presenter.interactor.bookingFormModel.destination.locationTypes.includes("airport");
    },
    formattedFareByType() {
      return (fareEstimation) => {
        if (FareApiDtoDelegate.isFixedFare(fareEstimation.type)) {
          return NumberFormatter.stringValueDecimalSeparator(
            fareEstimation.roundedPrice.toFixed(2),
            this.defaultLocale
          );
        }

        if (FareApiDtoDelegate.isEstimationFare(fareEstimation.type)) {
          return NumberFormatter.stringValueDecimalSeparator(
            fareEstimation.roundedPrice.toFixed(2),
            this.defaultLocale
          );
        }

        if (FareApiDtoDelegate.isRangeFare(fareEstimation.type)) {
          const minFare = NumberFormatter.stringValueDecimalSeparator(
            fareEstimation.minimumFare.toFixed(2),
            this.defaultLocale
          );
          const maxFare = NumberFormatter.stringValueDecimalSeparator(
            fareEstimation.maximumFare.toFixed(2),
            this.defaultLocale
          );
          return `${minFare} - ${maxFare}`;
        }
      };
    },
  },
  methods: {
    preselectFirstOffer() {
      if (this.availableFleets.length) {
        this.fleetId = this.availableFleets[0].fleetId;
      }

      this.selectedOffer = this.offersGroupedByFleet[this.fleetId][0].id;
    },
    preselectFirstPaymentMethod() {
      const defaultPaymentMethod = JSON.parse(localStorage.getItem('defaultPayment') || '{}');

      if (defaultPaymentMethod && defaultPaymentMethod.id) {
        this.selectedPaymentMethod = this.paymentMethods.find(
          (paymentMethod) => paymentMethod.id === defaultPaymentMethod.id
        ) || this.paymentMethods[0];
      } else {
        this.selectedPaymentMethod = this.paymentMethods[0];
      }
    },
    prefillReturnBookingIfAvailable() {
      const { pickupTime, scheduleType } = this.presenter.getPickupTime();
      const { shouldBookReturnBooking, returnBookingTime } = this.presenter.getReturnBooking();

      if (shouldBookReturnBooking) {
        this.shouldBookReturnBooking = true;
        this.returnDate = moment(returnBookingTime).format("YYYY-MM-DD");
        this.returnTime = moment(returnBookingTime).format("HH:mm");
        return;
      }

      if (scheduleType === "PREBOOKING") {
        this.returnDate = moment(pickupTime).format("YYYY-MM-DD");
        this.returnTime = moment(pickupTime).add(10, "minute").format("HH:mm");
      } else {
        this.returnTime = moment().add(10, "minute").format("HH:mm");
      }
    },
    async loadOffers() {
      this.selectedOffer = null;
      this.offersLoading = true;

      const { error, result: offers } = await SafeAwait.execute(async () => {
        return this.presenter.fetchOffers();
      });

      this.offersLoading = false;

      if (error) {
        this.$notify({
          group: "actions-notification",
          type: "error",
          title: "Error",
          text: this.$t("components.bookingForm.requestErrors.loadOffers"),
          duration: 10000,
          data: {
            component: "CompanyStepComponent",
            type: "loadOffers",
            callback: this.loadOffers,
          },
        });
      } else {
        this.offers = offers;
        this.availableFleets = uniqBy(
          this.offers.map((offer) => ({
            fleetId: offer.fleetId,
            companyName: offer.properties.companyName,
          })),
          "fleetId"
        );

        this.offersGroupedByFleet = this.offers.reduce((acc, offer) => {
          if (!acc[offer.fleetId]) {
            acc[offer.fleetId] = [];
          }
          acc[offer.fleetId].push(offer);
          return acc;
        }, {});
      }
    },
    async loadPaymentMethods() {
      this.paymentMethodsLoading = true;

      const { error, result: paymentMethods } = await SafeAwait.execute(async () => {
        return this.presenter.fetchPaymentMethods();
      });

      this.paymentMethodsLoading = false;

      if (error) {
        this.$notify({
          group: "actions-notification",
          type: "error",
          title: "Error",
          text: this.$t("components.bookingForm.requestErrors.loadPaymentMethods"),
          duration: 10000,
          data: {
            component: "CompanyStepComponent",
            type: "loadPaymentMethods",
            callback: this.loadPaymentMethods,
          },
        });
        return;
      }

      this.paymentMethods = paymentMethods;
    },
    async fetchFareEstimation() {
      this.isFareEstimationLoading = true;

      try {
        const { result: fareEstimation } = await SafeAwait.execute(async () => {
          return this.presenter.fetchFareEstimation({
            offerId: this.selectedOffer,
            paymentMethodType: PaymentMethodDomainDelegate.isTypeBusinessDispatchInvoice(
              this.selectedPaymentMethod.paymentMethodType
            )
              ? "businessDispatchInvoice"
              : this.selectedPaymentMethod.paymentMethodType,
          });
        });

        this.fareEstimation = fareEstimation;
        this.isFareEstimationLoading = false;
      } catch (error) {
        this.isFareEstimationLoading = false;
        this.showFareFetchError = true;
        return;
      }
    },
    isBookingTimeMoreThan7Days(paymentMethodType) {
      if (!this.presenter.interactor.bookingFormModel.pickupTime) {
        return false;
      }

      return (
        this.presenter.interactor.bookingFormModel.pickupTime.isAfter(moment().add(7, "days")) &&
        PaymentMethodDomainDelegate.isTypeCreditCard(paymentMethodType)
      );
    },
    async onFleetChange(fleetId) {
      this.fleetId = fleetId;

      this.selectedOffer = this.offersGroupedByFleet[this.fleetId][0].id;
      this.preselectFirstPaymentMethod();
      await this.fetchFareEstimation();
    },
    onToggleReturnBooking() {
      this.shouldBookReturnBooking = !this.shouldBookReturnBooking;

      if (!this.shouldBookReturnBooking) {
        this.returnTimeInvalid = false;
      }
    },
    async onOfferChange(offerId) {
      this.selectedOffer = offerId;
      await this.fetchFareEstimation();
    },
    async onPaymentMethodChange(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod;

      if (this.selectedOffer) {
        await this.fetchFareEstimation();
      }
    },
    async onNumberOfVehicleChange(numberOfVehicles) {
      this.numberOfVehicles = numberOfVehicles;
      await this.fetchFareEstimation();
    },
    onPickupTimeChanged(payload) {
      console.log("[CompanyStepComponent] onPickupTimeChanged", payload);
      if (payload.isTimeValid) {
        this.returnTimeInvalid = false;
        const mt = moment.tz(`${payload.date} ${payload.time}`, "YYYY-MM-DD HH:mm", "UTC");

        if (moment(mt).isValid()) {
          this.returnDate = mt.format("YYYY-MM-DD");
          this.returnTime = mt.format("HH:mm");
          this.returnPickupTime = mt;
        } else {
          // Sanity check
          alert("Invalid date or time");
        }
      } else {
        this.returnTimeInvalid = true;
      }
    },
    /**
     * @param {PaymentMethodDomainModel} payment
     * @return {string}
     */
    formattedPaymentMethodName(payment) {
      switch (payment.paymentMethodType) {
        case "creditCard":
          return this.$t("components.bookingForm.cardEnding", {
            number: payment.creditCardPaymentMethod.last4,
          });
        case "businessAccount":
          return this.$t("components.bookingForm.paymentMethod.account");
        default:
          return this.$t("components.bookingForm.paymentMethod.cash");
      }
    },
    /**
     * @param {PaymentMethodDomainModel} payment
     * @return {string}
     */
    getPaymentMethodIcon(payment) {
      if (PaymentMethodDomainDelegate.isTypeCreditCard(payment.paymentMethodType)) {
        return payment.creditCardPaymentMethod.brand;
      }

      if (PaymentMethodDomainDelegate.isTypeBusinessDispatchInvoice(payment.paymentMethodType)) {
        return "businessAccount";
      }

      if (PaymentMethodDomainDelegate.isTypeCash(payment.paymentMethodType)) {
        return "cash";
      }
    },
    getReturnTimeForPresenter() {
      if (!this.shouldBookReturnBooking) {
        return null;
      }

      const mt = moment.tz(`${this.returnDate} ${this.returnTime}`, "YYYY-MM-DD HH:mm", "UTC");

      if (moment(mt).isValid()) {
        return mt;
      } else {
        alert("Invalid date or time");
      }
    },
    async getFleetAccounts() {
      this.fleetAccountsViewModel.isLoading = true;
      const { result: fleetAccountsResponse, error: fleetAccountsError } = await SafeAwait.execute(
        async () => {
          return this.presenter.fetchFleetAccounts();
        }
      );

      this.fleetAccountsViewModel.isLoading = false;
      if (fleetAccountsError) {
        this.fleetAccountsViewModel.error = fleetAccountsError;
        return {
          error: fleetAccountsResponse,
        };
      }

      return {
        fleetAccounts: fleetAccountsResponse.data,
      };
    },
    async onCompanyStepSubmit() {
      await this.presenter.onCompanyStepSubmit({
        fleetId: this.fleetId,
        offerId: this.selectedOffer,
        paymentMethod: this.selectedPaymentMethod,
        numberOfVehicles: this.numberOfVehicles,
        shouldBookReturnBooking: this.shouldBookReturnBooking,
        returnFlightNumber: this.returnFlightNumber,
        returnTime: this.returnPickupTime,
        bookingNotes: this.bookingNotes === "" ? null : this.bookingNotes,
      });

      this.resetFleetAccountFieldsViewModel();
      this.resetAccountFieldsViewModel();

      if (
        PaymentMethodDomainDelegate.isTypeBusinessDispatchInvoice(
          this.presenter.interactor.bookingFormModel.paymentMethod.paymentMethodType
        )
      ) {
        this.accountFieldsViewModel.isLoading = true;

        const { error, fleetAccounts } = await this.getFleetAccounts();

        this.accountFieldsViewModel.isLoading = false;

        if (error) {
          this.$notify({
            group: "actions-notification",
            type: "error",
            title: "Error",
            text: this.$t("components.bookingForm.requestErrors.loadFleetAccounts"),
            duration: 10000,
            data: {
              component: "CompanyStepComponent",
              type: "loadFleetAccounts",
              callback: this.getFleetAccounts,
            },
          });
          return;
        }

        this.accountFieldsViewModel.isLoading = true;

        const { result: accountFields, error: accountFieldsError } = await SafeAwait.execute(
          async () => {
            return this.presenter.fetchAccountFields(fleetAccounts[0].accountId);
          }
        );
        this.accountFieldsViewModel.isLoading = false;

        if (accountFieldsError) {
          this.accountFieldsViewModel.error = accountFieldsError;
          this.$notify({
            group: "actions-notification",
            type: "error",
            title: "Error",
            text: this.$t("components.bookingForm.requestErrors.loadAccountFields"),
            duration: 10000,
            data: {
              component: "CompanyStepComponent",
              type: "loadFleetAccounts",
              callback: this.getFleetAccounts,
            },
          });
          return;
        }

        if (accountFields.length > 0) {
          this.presenter.interactor.bookingFormModel.accountFields = accountFields;
          this.presenter.switchToAccountFieldsStep();
        } else {
          await this.startBookingCreationFlow();
        }
      } else {
        await this.startBookingCreationFlow();
      }
    },
    onBackClick() {
      this.presenter.onBackClick();
    },
    resetFleetAccountFieldsViewModel() {
      this.fleetAccountsViewModel.isLoading = false;
      this.fleetAccountsViewModel.error = null;
      this.fleetAccountsViewModel.data = [];
    },

    resetAccountFieldsViewModel() {
      this.accountFieldsViewModel.isLoading = false;
      this.accountFieldsViewModel.error = null;
      this.accountFieldsViewModel.data = [];
    },
    async startBookingCreationFlow() {
      await this.presenter.startBookingCreationFlow();
    },

    isFareEstimationAvailable(fareEstimation) {
      if (!fareEstimation.type) {
        return false;
      }

      if (FareApiDtoDelegate.isFixedFare(fareEstimation.type) && !fareEstimation.roundedPrice) {
        return false;
      }

      return true;
    },
  },
  validations() {
    const validation = {
      returnFlightNumber: {
        minLength: minLength(3),
      },
    };

    return validation;
  },
};
</script>

<style lang="scss">
.error-border {
  border: 1.6px solid #ff0000 !important;
  border-radius: 4px;
}

.validation-icon {
  color: #ff0000 !important;
}
.select-payment {
  .v-input__append-inner {
    margin-top: 18px !important;
  }
}

.select-company {
  .v-input__append-inner {
    margin-top: 18px !important;
  }
}

.opcity-half {
  opacity: 0.5;
}

.notes-field {
  & .v-input__slot {
    position: relative;
  }
  & .v-input__append-inner {
    position: absolute;
    right: 10px;
  }
}
</style>

<style scoped>
.icon-payment {
  z-index: 1;
  margin-top: 3px;
}

.icon-car {
  margin-top: 3px;
  z-index: 1;
}

.fare-value {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-align: right;
  color: #333333;
}

.no-fare-value {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-align: right;
  color: #333333;
}
</style>
