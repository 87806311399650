import { business } from '@icabbi/api-gateway-client';
import _ from 'lodash';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  travelRestrictionServiceClient: null,
  travelRestrictionAssociationConnector: null,
  createdRestrictionId: null,
  isResettedRestrictions: false,
  ruleIds: {
    dateTime: [],
    location: null,
    maxAmount: null,
    frequency: null,
  },
};

const getters = {
  // eslint-disable-next-line no-shadow
  travelRestrictionServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.travelRestrictionServiceClient) {
      state.travelRestrictionServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.TravelRestrictionServiceConnector });
    }
    return state.travelRestrictionServiceClient;
  },
  // eslint-disable-next-line no-shadow
  travelRestrictionAssociationConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.travelRestrictionAssociationConnector) {
      state.travelRestrictionAssociationConnector = apiInstance({ dispatch, getters: rootGetters, Connector: business.TravelRestrictionAssociationConnector });
    }
    return state.travelRestrictionAssociationConnector;
  },
  getTravelRestrictionId: (state) => state.createdRestrictionId,
  getTravelRestrictionRuleId: (state) => (type) => state.ruleIds[type],
  getResettedRestrictions: (state) => state.isResettedRestrictions,
};

const actions = {
  createTravelRestriction: apiCall(async (context, params) => context.getters.travelRestrictionServiceClient(context).createTravelRestriction({ data: { ...params } })),
  // eslint-disable-next-line arrow-body-style
  createTravelRestrictionRule: apiCall(async (context, params) => {
    return context.getters.travelRestrictionServiceClient(context).createTravelRestrictionRule(params.id, { data: { ...params.data } });
  }),
  // eslint-disable-next-line arrow-body-style
  updateTravelRestrictionRule: apiCall(async (context, params) => {
    return context.getters.travelRestrictionServiceClient(context).updateTravelRestrictionRule(params.travelRestrictionId, params.ruleId, { data: { ...params.data } });
  }),
  // eslint-disable-next-line arrow-body-style
  deleteTravelRestrictionRule: apiCall(async (context, params) => {
    return context.getters.travelRestrictionServiceClient(context).deleteTravelRestrictionRule(params.ids, { data: { type: params.type } });
  }),
  getTravelRestrictionById: apiCall(async (context, id) => context.getters.travelRestrictionServiceClient(context).getTravelRestriction(id)),
  getTravelRestrictionRulesById: apiCall(async (context, params) => context.getters.travelRestrictionServiceClient(context).listTravelRestrictionRules(params.id, params)),
  listTravelRestrictions: apiCall(async (context, {
    page, maxPerPage, sort, dynamicSearch, corporationId, ids,
  }) => {
    const params = {
      page,
      maxPerPage,
      sort,
      dynamicSearch,
      corporationId,
      ids,
    };

    const travelRestrictionServiceClient = context.getters.travelRestrictionServiceClient(context);
    return travelRestrictionServiceClient.listTravelRestrictions(params);
  }),
  // eslint-disable-next-line arrow-body-style
  associateGroupToRestriction: apiCall(async (context, params) => {
    return context.getters.travelRestrictionAssociationConnector(context).createTravelRestrictionAssociation({ data: { ...params } });
  }),
  deleteTravelRestrictionId: apiCall(async (context, params) => context.getters.travelRestrictionServiceClient(context).deleteTravelRestrictionRule(params)),
};

const mutations = {
  resetAction(state, payload) {
    state.isResettedRestrictions = payload;
  },
  setCreatedRestrictionId(state, payload) {
    state.createdRestrictionId = payload;
  },
  resetRuleIds(state) {
    const initialState = {
      dateTime: [],
      location: null,
      maxAmount: null,
      frequency: null,
    };

    Object.assign(state.ruleIds, initialState);
  },
  resetRuleId(state, type) {
    if (type === 'dateTime') {
      state.ruleIds[type] = [];
      return;
    }

    state.ruleIds[type] = null;
  },
  setRuleId(state, { type, value }) {
    if (type === 'dateTime') {
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(value)
        ? value.forEach((el) => state.ruleIds[type].push({ day: el.dateTimeRuleType, id: el.id }))
        : state.ruleIds[type].push({ day: value.dateTimeRuleType, id: value.id });

      state.ruleIds.dateTime = _.uniqWith(state.ruleIds.dateTime, _.isEqual);
    } else {
      state.ruleIds[type] = Array.isArray(value) ? value[0].id : value.id;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
