<template>
  <gmap-map ref="map" map-type-id="terrain"
    style="height: 100%; width: 100%"
    :center="center"
    :zoom="zoom"
    :options="mapOptions"
  >
    <div> <!-- this div here is needed because of a bug in gmap-custom-marker -->
      <gmap-custom-marker alignment="center" v-for="(point, index) in waypoints"
                          :key="`marker-${point.type}-${index}`"
                          :marker="point"
      >
        <map-location :class="`icon-${point.type === 'dropoff' ? 'gray' : 'green'}`" />
      </gmap-custom-marker>
    </div>

    <div v-if="driverStatesPositions.length">
      <gmap-custom-marker
        v-for="(driver, index) in driverStatesPositions"
        :key="index"
        :marker="driver"
        alignment="center"
      >
        <driver-state-car />
      </gmap-custom-marker>
    </div>

    <div v-if="!disableInfoWindow && !disableInfoText">
      <gmap-info-window v-for="(point, index) in waypoints"
                        :key="`tooltip-${point.type}-${index}`"
                        :options="{ pixelOffset: { height: -18 } }"
                        :opened="true"
                        :position="point"
      >
        <div>
          <h1>{{ $t(`components.map.waypointTypes.${point.type}`) }}</h1>
          <p>{{ point.address }}</p>
        </div>
      </gmap-info-window>
    </div>

    <gmap-polyline :options="{ strokeColor: '#fff', strokeWeight: 9, geodesic: true }" :path="waypoints" />
    <gmap-polyline :options="{ strokeColor: '#47d061', strokeWeight: 5, geodesic: true }" :path="waypoints" />
  </gmap-map>
</template>

<script>
import { mapGetters } from 'vuex';
import { getGoogleMapsAPI } from 'gmap-vue';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { iconLoader } from '@/helpers/iconsLoader';

const CENTER = { lat: 0, lng: 0 };
const ZOOM = 1.5;
const MAX_ZOOM = 15;
const MAP_CENTER_OFFSET_X = 538;
const COUNTRY_MAP = {
  ca: [54.0528335, -96.0861555, 5],
  uk: [55.378052, -3.435973, 8],
  gb: [55.378052, -3.435973, 8],
  us: [37.09024, -95.712891, 5],
  au: [-25.274398, 133.775136, 5],
};

export default {
  components: { ...iconLoader.booking, GmapCustomMarker },
  props: {
    waypoints: Array,
    disableInfoWindow: {
      type: Boolean,
      default: false,
    },
    disableInfoText: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      defualt: 'booking',
    },
    mapOptions: {
      type: Object,
      default: () => ({
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        keyboardShortcuts: false,
        clickableIcons: false,
      }),
    },
  },
  data() {
    return {
      map: null,
      center: CENTER,
      zoom: ZOOM,
    };
  },
  async mounted() {
    this.map = await this.$refs.map.$mapPromise;
    this.reset();

    if (this.waypoints.length && this.source !== 'booking') this.fitBounds();
  },
  watch: {
    waypoints(val) {
      if (!val.length) return this.reset();

      return this.fitBounds();
    },
  },
  computed: {
    ...mapGetters({
      country: 'bookingChannel/defaultCountry',
      defaultLocation: 'bookingChannel/defaultLocation',
      availableDrivers: 'driverStates/availableDrivers',
    }),
    defaultCenter() {
      if (this.defaultLocation) {
        const { latitude, longitude } = this.defaultLocation;
        return { lat: latitude, lng: longitude };
      }
      const coords = COUNTRY_MAP[this.country];
      if (coords) return { lat: coords[0], lng: coords[1] };
      return CENTER;
    },
    defaultZoom() {
      const coords = this.defaultLocation ? 15 : COUNTRY_MAP[this.country][2];
      if (coords) return coords;
      return ZOOM;
    },
    driverStatesPositions() {
      if (!this.availableDrivers) {
        return [];
      }

      const driverStatesPositions = [];
      this.availableDrivers.forEach((driver) => {
        const { latitude, longitude } = driver.driverLocation;

        driverStatesPositions.push(this.latLngToProviderFormat({ latitude, longitude }));
      });

      return driverStatesPositions;
    },
    google: getGoogleMapsAPI,
  },
  methods: {
    reset() {
      // the order of these is important
      this.setZoom(this.defaultZoom);
      this.setCenter(this.defaultCenter);
    },
    setCenter(center) {
      if (!center.lat || !center.lng) return;
      const centerLatLng = new this.google.maps.LatLng(parseFloat(center.lat), parseFloat(center.lng));

      this.map.setCenter({ lat: centerLatLng.lat(), lng: centerLatLng.lng() });
      if (!center.type) this.map.panBy(0 - MAP_CENTER_OFFSET_X / 2, 0);
    },
    setZoom(zoom) {
      this.zoom = zoom;
      this.map.setZoom(zoom);
    },
    fitBounds() {
      if (this.waypoints.length === 1) {
        this.setZoom(MAX_ZOOM);
        this.setCenter(this.waypoints[0]);
        return;
      }

      const bounds = new this.google.maps.LatLngBounds();

      const bookingParams = {
        left: MAP_CENTER_OFFSET_X + 25 + 115, top: 25, right: 25 + 115, bottom: 25,
      };

      this.waypoints.forEach((point) => bounds.extend(point));
      this.map.fitBounds(bounds, this.source === 'booking' ? bookingParams : undefined);

      if (this.map.getZoom() > MAX_ZOOM) this.setZoom(MAX_ZOOM);
    },
    latLngToProviderFormat(latLng) {
      return { lng: latLng.longitude, lat: latLng.latitude };
    },
  },
};
</script>

<style lang="sass" scoped>
.icon-gray
  color: #333333

.icon-green
  color: #47d061

.vue-map-container::v-deep
  .gm-style-iw
    padding-top: 16px
    padding-left: 16px

    button
      display: none !important

  .gm-style-iw-d
    p
      max-width: 230px
      font-weight: 600
      font-size: 12px
      line-height: 18px
      letter-spacing: 0.01em
      color: #333
      margin-block-end: 4px
    h1
      font-weight: 400
      font-size: 12px
      line-height: 14px
      letter-spacing: 1px
      text-transform: uppercase
      color: #888
      margin-bottom: 6px
</style>
