import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';

import { Authorization } from '@icabbi/vue-authentication';
import store from '@/plugins/vuex';

import GetStartedView from '@/views/auth/GetStarted.vue';
import OAuthCallbackView from '@/views/auth/OAuthCallback.vue';
import OAuthLogoutView from '@/views/auth/OAuthLogout.vue';
import EmailLoginRredirect from '@/views/auth/EmailLoginRredirect.vue';

import TripsView from '@/views/tripsHistory/TripsView.vue';
import EmployeeListView from '@/views/employee/v2/EmployeeListView.vue';
import PeopleListView from '@/views/people/v2/BusinessEntityListView.vue';
import BookingView from '@/views/booking/Booking.vue';
import CardListView from '@/views/paymentMethod/CardsListView.vue';
import CorporationsView from '@/views/corporations/CorporationsView.vue';
import TravelRestrictionsView from '@/views/travelRestrictions/TravelRestrictionsView.vue';
import FavouritesView from '@/views/favourites/FavouritesView.vue';
import UsersListView from '@/views/users/UsersListView.vue';

import { CHANNEL_ADMIN, CORP_ADMIN } from '@/helpers/userRoles';
import BookingFormContainer from '@/views/bookingForm/BookingFormContainer';
import UserProfileView from "@/views/userProfile/UserProfileView.vue";

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'login', component: GetStartedView },
  {
    path: '/trips',
    name: 'trip-list',
    redirect: {
      path: '/trips/all-trips',
      name: 'all-trips',
    },
    component: TripsView,
    meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] } },
    children: [
      {
        path: '/trips/all-trips',
        name: 'all-trips',
        component: TripsView,
        meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] }, tab: 0 },
      },
      {
        path: '/trips/in-progress',
        name: 'in-progress',
        component: TripsView,
        meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] }, tab: 1 },
      },
      {
        path: '/trips/pre-bookings',
        name: 'pre-bookings',
        component: TripsView,
        meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] }, tab: 2 },
      },
      {
        path: '/trips/completed',
        name: 'completed',
        component: TripsView,
        meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] }, tab: 3 },
      },
      {
        path: '/trips/cancelled',
        name: 'cancelled',
        component: TripsView,
        meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] }, tab: 4 },
      },
    ],
  },
  {
    path: '/users',
    name: 'users',
    component: UsersListView,
    meta: { sidebar: true, auth: { role: [CHANNEL_ADMIN] } },
  },
  {
    path: '/book',
    name: 'book',
    component: BookingView,
    meta: { sidebar: true, full: true, auth: { role: [CORP_ADMIN] } },
    children: [
      {
        path: '/create-v2',
        name: 'book-v2',
        component: BookingView,
        meta: { sidebar: true, full: true, auth: { role: [CORP_ADMIN] } },
      },
    ]
  },
  {
    path: '/book-dp',
    name: 'book-dp',
    component: BookingFormContainer,
    meta: { sidebar: true, full: true, auth: { role: [CORP_ADMIN] } },
  },
  {
    path: '/booking/:id/copy',
    name: 'booking-copy',
    component: BookingView,
    meta: { sidebar: true, full: true, auth: { role: [CORP_ADMIN] } },
  },
  {
    path: '/booking/:id/edit',
    name: 'booking-edit',
    component: BookingView,
    meta: {
      sidebar: true, full: true, auth: { role: [CORP_ADMIN] }, state: 'edit',
    },
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: FavouritesView,
    meta: {
      sidebar: true,
      auth: { role: [CORP_ADMIN] },
    },
  },
  {
    path: '/employees',
    name: 'employees',
    component: EmployeeListView,
    meta: {
      sidebar: true,
      auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] },
    },
    children: [
      {
        path: '/employees',
        name: 'users',
        component: EmployeeListView,
        meta: {
          sidebar: true,
          auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] },
        },
      },
    ],
  },
  {
    path: '/people',
    name: 'people',
    component: PeopleListView,
    meta: {
      sidebar: true,
    },
  },
  {
    path: '/travel-restrictions',
    name: 'travel-restrictions',
    component: TravelRestrictionsView,
    meta: {
      sidebar: true,
      auth: { role: [CORP_ADMIN] },
    },
  },
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: CardListView,
    meta: { sidebar: true, auth: { role: [CORP_ADMIN] } },
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: CorporationsView,
    meta: { sidebar: true, auth: { role: [CORP_ADMIN, CHANNEL_ADMIN] } },
  },
  { path: '/logout', name: 'logout', component: OAuthLogoutView },
  { path: '/oauth/callback', name: 'oauth-callback', component: OAuthCallbackView },
  { path: '/email/login-redirect', name: 'email-login-redirect', component: EmailLoginRredirect },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: UserProfileView,
    meta: { sidebar: true, auth: { role: [CORP_ADMIN] } },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const guards = [
  (to, from, next) => {
    if (to.meta?.auth) {
      return Authorization.validateUserRoles({
        roles: to.meta.auth.role,
        getters: store.getters,
        unauthorizedPage: { name: 'login' },
      })(to, from, next);
    }
    return next();
  },
];

router.beforeEach(multiguard(guards));

export default router;
