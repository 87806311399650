<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat id="employee-card" class="pa-5">
        <v-card-title id="employee-title" class="form-title">
          <span>{{ $t(`components.tripsHistory.default.title`) }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="forceClear">mdi-close</v-icon>
        </v-card-title>
        <v-card-text id="employee-form" class="form-content">
          <v-row>
            <v-col>
              <span class="text-uppercase input-label">{{ $t(`components.tripsHistory.default.accounts`) }}</span>
            </v-col>

            <v-col cols="12">
              <corporations-select @onSelectedItem="selectedCorporation" :setSelected="false" />
            </v-col>
            <v-col cols="12">
              <span class="text-uppercase input-label">{{ $t(`components.tripsHistory.default.dateRange`) }}</span>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu1"
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model.trim="$v.startDateInput.$model"
                    :class="{ 'employee-field-error': $v.startDateInput.$error }"
                    :placeholder="$t(`components.tripsHistory.default.startDate`)"
                    class="employee-field"
                    outlined
                    clearable
                    hide-details
                    no-filter
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-if="$v.startDateInput.$anyError" v-slot:append>
                      <error-tooltip :validator="$v.startDateInput"/>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :max="new Date().toISOString()"
                  :locale="$i18n.locale"
                  no-title
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu1"
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model.trim="$v.endDateInput.$model"
                    :class="{ 'employee-field-error': $v.endDateInput.$error }"
                    :placeholder="$t(`components.tripsHistory.default.endDate`)"
                    class="employee-field"
                    outlined
                    clearable
                    hide-details
                    no-filter
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-if="$v.endDateInput.$anyError" v-slot:append>
                      <error-tooltip :validator="$v.endDateInput"/>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  :min="minEndDate"
                  :max="new Date().toISOString()"
                  :locale="$i18n.locale"
                  :reactive="true"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-4">
          <v-btn
            class="text-capitalize"
            color="grey darken-1"
            text
            @click="forceClear"
          >
            {{ $t(`components.tripsHistory.default.cancel`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize white--text"
            color="#333333"
            depressed
            :disabled="validateForm"
            :loading="isLoading"
            @click="download"
          >
            {{ $t(`components.tripsHistory.default.download`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </loader>
</template>

<script>
import { getRoles } from '@/mixins/authentication.mixins';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import { languageMethods, languageList } from '@/mixins/language.mixins';
import { entityLoader } from '@/mixins/entityLoader.mixins';
import ErrorTooltip from '@/components/elements/ErrorTooltip.vue';
import tripDownloadValidationData from '@/helpers/rules/tripDownloadValidationData';
import CorporationsSelect from '@/components/corporations/CorporationsSelect.vue';
import moment from 'moment';

export default {
  name: 'trip-download-form',
  mixins: [bookingChannelComputed, getRoles, entityLoader, languageMethods, languageList],
  components: {
    ErrorTooltip, CorporationsSelect,
  },
  validations: {
    ...tripDownloadValidationData,
  },
  data() {
    return {
      isLoading: false,
      saveLoading: false,
      endDate: null,
      startDate: null,
      endDateInput: null,
      startDateInput: null,
      startDateMenu: false,
      endDateMenu: false,
      corporation: null,
      minEndDate: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    validateForm() {
      return this.$v.$invalid;
    },
  },
  watch: {
    startDate() {
      this.startDateInput = this.formatDate(this.startDate);
      this.minEndDate = this.startDate;
    },
    endDate() {
      this.endDateInput = this.formatDate(this.endDate);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      // const [year, month, day] = date.split('-');
      return moment(date).format('LL');
    },
    selectedCorporation(corporation) {
      this.corporation = corporation ? corporation.id : '';
    },
    async download() {
      const reportDownloadUrl = this.$store.getters['csvReport/getReportDownloadUrl']({
        filename: this.getFileName(),
        corporationId: this.corporation,
        startDate: moment(this.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        endDate: moment(this.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
      window.open(reportDownloadUrl, '_blank');
      if (false) {
        this.isLoading = true;
        const data = await this.$store.dispatch('csvReport/generateReport', {
          corporationId: this.corporation,
          startDate: moment(this.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
          endDate: moment(this.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
          filename: this.getFileName(),
        });
        this.isLoading = false;
        const fileURL = window.URL.createObjectURL(
          new Blob([data], {
            encoding: 'UTF-8',
            type: 'text/csv;charset=UTF-8',
          }),
        );
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;

        fileLink.setAttribute('download', this.getFileName());
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    forceClear() {
      this.endDate = null;
      this.startDate = null;
      this.endDateInput = null;
      this.startDateInput = null;
      this.startDateMenu = false;
      this.endDateMenu = false;
      this.$v.$reset();
      this.$emit('closeDialog');
    },
    getFileName() {
      return `report-${this.startDate}-to-${this.endDate}.csv`;
    },
  },
};
</script>
<style lang="scss" scoped>
  .input-label {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #888888;
  }

  .employee-field {
    background: #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    line-height: 15px;
    border: 1px solid #EEEEEE;
    letter-spacing: -0.005em;
    color: #333333;
  }

  .employee-field:focus-within {
    border-color: #47D061;
  }

  .employee-field-error {
    border: 1px solid #EB5757 !important;
  }

  .form-content {
    padding-bottom: 4px;
  }

  .loader-flex {
    margin: 0% !important;
    height: 528px;
  }

</style>
