import { mapGetters } from 'vuex';
import jwt from 'jsonwebtoken';

export const getRoles = {
  computed: {
    ...mapGetters({
      idToken: 'authentication/idToken',
    }),
    userRoles() {
      const decoded = jwt.decode(this.idToken);
      if (decoded) return decoded.roles || [];
      return [];
    },
  },
  methods: {
    getCorporationId() {
      const user = this.userRoles.filter((userRole) => userRole.name === 'corporationAdmin')[0];
      return user ? user.corporationId : null;
    },
    hasAccess(role) {
      return this.userRoles ? this.userRoles.filter((userRole) => userRole.name === role).length > 0 : false;
    },
  },
};
