<template>
  <v-card class="py-5 px-8">
    <v-card-title class="pa-0 mb-6">
      {{ $t('components.favourites.form.title') }}

      <v-spacer></v-spacer>

      <v-btn
        @click="closeAction()"
        height="36px"
        icon
        text
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-text-field
      v-model="alias"
      outlined
      filled
      hide-details
      required
      :placeholder="$t('components.favourites.form.namePlaceholder')"
      height="45px"
      class="address-field mb-5"
    ></v-text-field>

    <multiselect
      v-model="address"
      class="address-select mb-5"
      track-by="id"
      label="description"
      open-direction="bottom"
      :show-labels="false"
      :placeholder="$t('components.favourites.form.addressPlaceholder')"
      :show-no-options="false"
      :close-on-select="true"
      :clear-on-select="false"
      :options="suggestions"
      :internal-search="false"
      :searchable="true"
      height="45px"
      @search-change="getAddressSuggestions"
      @select="getAddressDetails($event)"
    >
      <span slot="caret"></span>
    </multiselect>

    <booking-map
      v-show="formattedAddress"
      ref="map"
      :disableInfoWindow="true"
      :waypoints="formattedAddress"
      :mapOptions="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        keyboardShortcuts: false,
        gestureHandling: 'none',
        clickableIcons: false,
      }"
      style="height: 300px;"
    />

    <v-card-actions class="px-0" :class="suggestions.length && !formattedAddress ? 'dynamic-height': 'mt-5'">
      <v-btn
        class="btn btn-flat"
        text
        @click="closeAction()"
      >
        {{ $t('components.favourites.form.cancel') }}
      </v-btn>

      <v-spacer />

      <v-btn
        class="btn btn-default"
        depressed
        @click="createFavouriteAddress"
      >
      {{ $t('components.favourites.form.continue') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingMap from '@/components/booking/Map.vue';

export default {
  name: 'add-favourite-form',
  components: {
    BookingMap,
  },
  data: () => ({
    address: null,
    alias: null,
    formattedAddress: null,
    suggestions: [],
  }),
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
  },
  watch: {
    formattedAddress: {
      handler() {
        this.$refs.map.reset();
      },
      deep: true,
    },
    alias(val) {
      if (val && val.length) {
        this.alias = val[0].toUpperCase() + val.slice(1);
      }
    },
  },
  methods: {
    async getAddressSuggestions(search) {
      if (search && search.length > 2) {
        this.suggestions = await this.$store.dispatch('geolocation/getSuggestions', { search, lang: this.$i18n.locale });
        return this.suggestions;
      }

      this.suggestions = [];
      return this.suggestions;
    },
    async getAddressDetails(address) {
      if (address.source === 'google') {
        const result = await this.$store.dispatch('geolocation/getSuggestionDetail', {
          addressId: address.id,
          lang: this.$i18n.locale,
        });

        this.formattedAddress = [{
          address: result.description,
          lat: parseFloat(result.coordinates.latitude),
          lng: parseFloat(result.coordinates.longitude),
          locationTypes: result.locationTypes,
          type: 'favourites',
        }];
      } else {
        this.formattedAddress = [{
          address: address.description,
          lat: parseFloat(address.info.coordinates.latitude),
          lng: parseFloat(address.info.coordinates.longitude),
          locationTypes: address.locationTypes,
          type: 'favourites',
        }];
      }
    },
    async createFavouriteAddress() {
      if (this.address === null || this.alias === null) {
        this.$notify({
          type: 'error',
          text: this.$t('components.favourites.form.error', { field: this.address === null ? 'Address' : 'Name' }),
        });
        return;
      }

      const result = await this.$store.dispatch('favourites/createFavouriteAddress', {
        payload: {
          entityId: this.getSelectedCorporationId,
          alias: this.alias,
          fullAddressText: this.formattedAddress[0].address,
          latitude: this.formattedAddress[0].lat,
          longitude: this.formattedAddress[0].lng,
          locationTypes: this.formattedAddress[0].locationTypes,
        },
      });

      if (result) {
        this.alias = null;
        this.address = null;
        this.$refs.map.reset();

        this.$emit('addFavourite');
      }
    },
    closeAction() {
      this.alias = null;
      this.address = null;
      this.$refs.map.reset();

      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.dynamic-height {
  margin-top: 300px !important;
}
.address-field .v-text-field input {
  background: initial !important;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
}
.address-select {
  .multiselect__input, .multiselect__single {
    background: initial !important;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
    color: #333333;

    width: 350px;
    text-overflow: ellipsis !important;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  .multiselect__placeholder {
    background: initial !important;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
  }

  .multiselect__element {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  .multiselect__option--highlight {
    background: #47D061;
  }

  .multiselect__option {
    white-space: normal !important;
  }

  .multiselect__tags {
    background: rgba(0, 0, 0, 0.06);
    border: none !important;
    border-radius: 4px;

    display: flex;
    align-content: center;
    align-items: center;
    height: 45px;
  }

  .multiselect__content {
    padding-left: 0px;
  }
}
</style>
