<template>
  <loader :is-loading="isLoading" color="primary" :full-page="true">
    <v-flex>
      <v-card flat class="pa-5">
        <v-card-title>
          <div class="d-flex justify-space-between w-full">
            <span class="pb-2 form-title">{{$t('components.addCard.title')}}</span>
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.addCard.card.name')}}</h5>
              <v-text-field
                outlined
                filled
                hide-details
                background-color="#F8F8F8"
                :placeholder="$t('components.addCard.card.name_placeholder')"
                :class="{ 'input-field-error': $v.cardName.$error }"
                v-model.trim="$v.cardName.$model">
                <template v-if="$v.cardName.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.cardName"/>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="section-title input-label mb-1">{{$t('components.addCard.card.number')}}</h5>
              <div class="card-container" ref="cardNumber" id="card-ref-input"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.addCard.card.expiry')}}</h5>
              <div class="card-container" ref="expiry" id="card-expiry-input"></div>
            </v-col>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.addCard.card.cvc')}}</h5>
              <div class="card-container" ref="cvc" id="card-cvc-input"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.addCard.card.postalCode')}}</h5>
              <v-text-field
                outlined
                filled
                hide-details
                maxLength="20"
                background-color="#F8F8F8"
                :placeholder="$t('components.addCard.card.postcode_placeholder')"
                :class="{ 'input-field-error': $v.postalCode.$error }"
                v-model.trim="$v.postalCode.$model">
                <template v-if="$v.postalCode.$anyError" v-slot:append>
                  <error-tooltip :validator="$v.postalCode"/>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.addCard.card.account')}}</h5>
              <v-select
                filled
                outlined
                background-color="#F8F8F8"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                v-model="corporation"
                :items="corporations"
                :loading="corporationsLoading"
                return-object
                :placeholder="$t('components.addCard.card.accountPlaceholder')"
              />
            </v-col>
          </v-row>
          <v-row v-if="!isAppInProductionEnvironment()">
            <v-col>
              <h5 class="section-title mb-1 input-label">{{$t('components.addCard.card.groups')}}</h5>
              <v-select
                filled
                outlined
                background-color="#F8F8F8"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                v-model="selectedGroups"
                :items="groups"
                :loading="groupsLoading"
                return-object
                multiple
                :placeholder="$t('components.addCard.card.groupPlaceholder')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-4">
          <v-btn
            class="btn btn-flat"
            text
            @click="closeDialog"
          >
            {{$t('components.addCard.actionButtons.cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-default"
            @click="addCard"
          >
            {{$t('components.addCard.actionButtons.add')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import { find } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { iconLoader } from '@/helpers/iconsLoader';
import { paymentMethod } from '@/mixins/paymentMethod.mixins';
import ErrorTooltip from '@/components/elements/ErrorTooltip.vue';
import { notifySuccess } from '@/helpers/notification';
import { Env } from '@/env';

export default {
  name: 'AddCard',
  components: { ...iconLoader.elements, ErrorTooltip },
  mixins: [paymentMethod],
  data() {
    return {
      cardName: '',
      postalCode: '',
      cardElements: {},
      isLoading: false,
      validRefs: {
        cardNumber: false,
        cvc: false,
        expiry: false,
      },
      formReset: false,
      corporations: [],
      groups: [],
      selectedGroups: [],
      corporation: null,
      corporationsLoading: false,
      groupsLoading: false,
    };
  },
  validations: {
    cardName: { required },
    postalCode: { required },
  },
  async mounted() {
    await this.getSecret(this.getSelectedCorporationId);
    await this.initCorporations();

    this.initStripePaymentComponents();
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
    ...mapGetters('bookingChannel', ['payment']),
  },
  watch: {
    getSelectedCorporationId() {
      this.resetCorporation();
    },
    corporation(val) {
      if (val) {
        this.getSecret(val.id);
        this.initGroups();
      }
    },
  },
  methods: {
    async initCorporations() {
      this.corporationsLoading = true;
      const { data } = await this.$store.dispatch('corporations/listCorporations', { maxPerPage: 100, sort: 'name' });
      this.corporations = data ?? [];
      this.resetCorporation();
      this.corporationsLoading = false;
    },
    async initGroups() {
      this.groupsLoading = true;
      const { data } = await this.$store.dispatch('employee/listEmployeeGroups', {
        sort: '-createdAt',
        corporationId: this.corporation.id,
        maxPerPage: 100,
      });
      this.groups = data ?? [];
      this.groupsLoading = false;
    },
    resetCorporation() {
      if (this.getSelectedCorporationId) this.corporation = find(this.corporations, { id: this.getSelectedCorporationId });
      else this.corporation = null;
    },
    async assignGroupsToPaymentMethod(paymentId) {
      const params = {
        paymentMethodId: paymentId,
        corporationId: this.corporation.id,
        addedEmployeeGroupIds: this.selectedGroups.map((el) => el.id),
        paymentProviderName: this.payment.name,
      };

      this.$store.dispatch('paymentMethods/assignPaymentToGroup', params);
    },
    listenForErrors() {
      this.cardElements.number.on('change', ({ complete }) => {
        this.handleError(complete, 'cardNumber');
      });

      this.cardElements.cvc.on('change', ({ complete }) => {
        this.handleError(complete, 'cvc');
      });

      this.cardElements.expiry.on('change', ({ complete }) => {
        this.handleError(complete, 'expiry');
      });
    },
    handleError(complete, ref) {
      this.validRefs[ref] = complete;
      if (complete) {
        this.$refs[ref].classList.remove('error-input');
      } else {
        this.$refs[ref].classList.add('error-input');
      }
    },
    initStripePaymentComponents() {
      const style = {
        base: {
          '::placeholder': {
            color: 'rgba(0, 0, 0, 0.28)',
          },
        },
      };
      const locale = this.$i18n.locale.substr(0, 2);
      const elements = this.stripeClient.elements({ locale });
      this.cardElements.number = elements.create('cardNumber', {
        style,
      });
      this.cardElements.number.mount('#card-ref-input');

      this.cardElements.expiry = elements.create('cardExpiry', {
        style,
      });
      this.cardElements.expiry.mount('#card-expiry-input');

      this.cardElements.cvc = elements.create('cardCvc', {
        style,
      });
      this.cardElements.cvc.mount('#card-cvc-input');

      this.listenForErrors();
    },
    // eslint-disable-next-line consistent-return
    hasErrors() {
      if (Object.values(this.validRefs).filter((val) => !val).length > 0) {
        Object.keys(this.validRefs).forEach((ref) => {
          if (!this.validRefs[ref]) {
            this.$refs[ref].classList.add('error-input');
          }
        });
        return true;
      }
    },
    // eslint-disable-next-line consistent-return
    async addCard() {
      if (this.hasErrors() || this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }
      try {
        this.isLoading = true;
        const response = await this.saveCard({
          cardElement: this.cardElements.number,
          cardName: this.cardName,
          corporationId: this.corporation.id,
          postalCode: this.postalCode,
        });
        if (response.result) {
          if (this.shouldUpsertGroupsToThePaymentMethod()) {
            await this.assignGroupsToPaymentMethod(response.paymentId);
          }

          notifySuccess(this.$t('components.addCard.notification.message'));
          this.$emit('methodAdded');
          this.closeDialog();
        }
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    isAppInProductionEnvironment() {
      return Env.isAppProductionEnvironment();
    },
    /**
     * This function will be removed in the coming days - linking to business passenger groups to
     * a payment method is not yet supported in Production. So for now we only allow this
     * feature if the application is deployed in the DEV environment.
     *
     * @return {boolean}
     */
    shouldUpsertGroupsToThePaymentMethod() {
      return this.selectedGroups.length > 0 && !this.isAppInProductionEnvironment();
    },
  },
  beforeDestroy() {
    this.cardElements.number.destroy('#card-ref-input');
    this.cardElements.expiry.destroy('#card-expiry-input');
    this.cardElements.cvc.destroy('#card-cvc-input');
  },

};
</script>

<style scoped>
.card-container {
  min-height: 42px;
  background: #f8f8f8;
  border: 1px solid #efefef;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  padding: 12px;
  border-radius: 4px;
}
.card-container.error-input{
  border: 1px solid red;
}
.v-input {
  font-weight: normal;
  font-size: 14px !important;
  color: #000 !important;
}
</style>
