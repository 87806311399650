import { business } from '@icabbi/api-gateway-client';
import apiInstance from '@/helpers/apiInstance';

const defaultState = {
  suggestionServiceClient: null,
};

const getters = {
  suggestionServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.suggestionServiceClient) {
      state.suggestionServiceClient = apiInstance({
        dispatch,
        getters: rootGetters,
        Connector: business.SuggestionServiceConnector
      });
    }
    return state.suggestionServiceClient;
  },
};

/**
 * @params {AddressQueryApiDto} query
 * @returns {AddressSuggestionApiDto[]}
 */
async function getAddressSuggestions(context, query) {
  const suggestionServiceClient = context.getters.suggestionServiceClient(context);
  const { suggestions, source } = await suggestionServiceClient.getSuggestions({ params: query });

  return { suggestions, source };
}

/**
 * @param {AddressQueryDetailsApiDto} query 
 * @returns {AddressItemDetailApiDto}
 */
async function getAddressDetails(context, query) {
  const { addressId, lang } = query;
  const suggestionServiceClient = context.getters.suggestionServiceClient(context);

  return await suggestionServiceClient.getSuggestionDetails(addressId, { params: { lang: lang } });
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions: {
    getAddressSuggestions,
    getAddressDetails,
  },
};
