<template>
  <div>
    <v-data-table
      class="bs-table employee-group-header elevation-0"
      calculate-widths
      :items="data"
      :headers="headers"
      :server-items-length="entityTotalCount.totalCount"
      :loading="entityLoading"
      :multi-sort="false"
      :must-sort="true"
      :single-expand="true"
      :page.sync="page"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :no-data-text="$t(`global.noData`)"
      :no-results-text="$t(`global.noResults`)"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template v-slot:no-data>
        <div class="d-flex flex-column my-12">
          <span class="noData-title mb-2">{{ $t("components.employeeGroupTable.noData") }}</span>
          <span class="noData-description">{{
            $t("components.employeeGroupTable.noDataDescription")
          }}</span>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon @click="$emit('deleteEmployeeGroup', item)">mdi-close</v-icon>
      </template>
    </v-data-table>
    <div class="d-flex justify-between align-center py-2 px-4 bs-table-footer">
      <v-row no-gutters class="bs-per-page">
        <span>
          {{ $t(`global.perPage`) }}
        </span>
        <span
          v-for="item in [10, 20, 30]"
          :key="item"
          class="px-1"
          :class="{ 'active-page-count': item === options.itemsPerPage }"
          @click="options.itemsPerPage = item"
        >
          {{ item }}
        </span>
      </v-row>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        color="#f2f2f2"
        class="bs-pagination"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { entityLoader } from "@/mixins/entityLoader.mixins";
import { mapGetters } from "vuex";

export default {
  name: "employee-group-table",
  mixins: [entityLoader],
  props: {
    employeeGroupId: {
      type: String,
    },
  },
  data: () => ({
    options: {},
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),
  computed: {
    ...mapGetters("corporations", ["getSelectedCorporationId"]),
    ...mapGetters("employee", ["getCreatedGroupId"]),
    headers() {
      return [
        {
          text: this.$t("components.employeeList.list.name"),
          align: "left",
          sortable: true,
          value: "givenName",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.email"),
          align: "left",
          sortable: true,
          value: "email",
          class: "text-uppercase",
        },
        {
          text: this.$t("components.employeeList.list.phone"),
          align: "left",
          sortable: true,
          value: "phone",
          class: "text-uppercase",
        },
        {
          value: "action",
          width: "10%",
          sortable: false,
          align: "end",
        },
      ];
    },
  },
  watch: {
    employeeGroupId(val) {
      this.loadEmployeeGroupMembers(val);
    },
    options: {
      handler() {
        this.loadEmployeeGroupMembers(this.employeeGroupId);
      },
      deep: true,
    },
  },
  methods: {
    async loadAllEmployeeGroupMembers() {
      const params = {
        page: 0,
        maxPerPage: Number.MAX_SAFE_INTEGER,
        sort: `-createdAt`,
        corporationId: this.getSelectedCorporationId,
        employeeGroupId: this.employeeGroupId,
      };

      const { data } = await this.$store.dispatch("employee/listEmployees", params);

      this.$emit("allEmployeesInGroup", data.results);
    },
    async loadEmployeeGroupMembers(employeeGroupId) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      const sort = sortBy[0] || this.getActiveTab ? "givenName" : "createdAt";

      const params = {
        page: page - 1,
        maxPerPage: itemsPerPage,
        sort: sortDesc[0] ? `-${sort}` : sort,
        corporationId: this.getSelectedCorporationId,
        employeeGroupId,
      };

      await this.getListFromPagination("employee/listEmployees", params);
      await this.loadAllEmployeeGroupMembers();
    },
  },
};
</script>
