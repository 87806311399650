import {business} from '@icabbi/api-gateway-client';
import apiInstance, {apiCall} from '@/helpers/apiInstance';

const defaultState = {
  employeeServiceClient: null,
  employeeGroupServiceClient: null,
  createdGroupId: null,
  createdGroupName: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  employeeServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.employeeServiceClient) {
      state.employeeServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.EmployeeServiceConnector });
    }
    return state.employeeServiceClient;
  },
  // eslint-disable-next-line no-shadow
  employeeGroupServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.employeeGroupServiceClient) {
      state.employeeGroupServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.EmployeeGroupServiceConnector });
    }
    return state.employeeGroupServiceClient;
  },
  getCreatedGroupId: (state) => state.createdGroupId,
  getCreatedGroupName: (state) => state.createdGroupName,
};

const actions = {
  findUsers: apiCall((context, params) => context.getters.employeeServiceClient(context).findUsers(params)),
  resendEmailInvitation: apiCall((context, params) => context.getters.employeeServiceClient(context).resendOnboardingEmail(params)),
  deleteEmployee: apiCall(async (context, id) => context.getters.employeeServiceClient(context).deleteEmployee({ id })),
  deleteEmployeeGroup: apiCall(async (context, id) => context.getters.employeeGroupServiceClient(context).deleteEmployeeGroup(id)),
  getEmployeeGroup: apiCall(async (context, id) => context.getters.employeeGroupServiceClient(context).getEmployeeGroup(id)),
  updateEmployeeGroup: apiCall(async (context, params) => context.getters.employeeGroupServiceClient(context).updateEmployeeGroup(params)),
  createEmployeeGroup: apiCall(async (context, params) => context.getters.employeeGroupServiceClient(context).createEmployeeGroup({ data: params })),
  createEmployee: apiCall(async (context, {
    givenName, familyName, email, phone, corporationIds, employeeGroupId
  }) => {
    const data = {
      givenName, familyName, email, corporationIds, employeeGroupId
    };

    if (phone) data.phone = phone;
    const employeeServiceClient = context.getters.employeeServiceClient(context);
    return employeeServiceClient.createEmployee({ data });
  }),
  updateEmployeeGroupEmployees: apiCall(async (context, {
    employeeGroupId, requestParams,
  }) => context.getters.employeeGroupServiceClient(context).updateEmployeeGroupEmployees({ employeeGroupId, requestParams })),
  updateEmployee: apiCall(async (context, {
    id, givenName, familyName, email, corporationId, employeeGroupId
  }) => {
    const data = {
      givenName, familyName, email, corporationId, employeeGroupId
    };
    const employeeServiceClient = context.getters.employeeServiceClient(context);
    return employeeServiceClient.updateEmployee(id, data);
  }),
  listEmployees: apiCall(async (context, {
    page, maxPerPage, sort, dynamicSearch, employeeGroupId, corporationId, travelRestrictionId,
  }) => {
    const employeeServiceClient = context.getters.employeeServiceClient(context);
    const params = {
      page,
      maxPerPage,
      orderBy: encodeURIComponent(sort),
      relations: 'businessPassengerGroups',
    };
    if (dynamicSearch) params.dynamicSearch = dynamicSearch;
    if (employeeGroupId) params.businessPassengerGroupId = employeeGroupId;
    if (corporationId) params.businessAccountIds = [corporationId];
    if (travelRestrictionId) params.restrictionId = travelRestrictionId;
    return employeeServiceClient.listEmployees(params);
  }),
  listEmployeeGroups: apiCall(async (context, {
    page, maxPerPage, sort, dynamicSearch, ids, corporationId, type, restrictionId, employeeId,
  }) => {
    const params = {
      page,
      maxPerPage,
      sort: encodeURIComponent(sort),
      dynamicSearch: dynamicSearch || undefined,
      ids,
      corporationId,
      type,
      restrictionId,
      employeeId,
    };

    return context.getters.employeeGroupServiceClient(context).listEmployeeGroups(params);
  }),
};

const mutations = {
  setCreatedGroup(state, payload) {
    state.createdGroupId = payload.id;
    state.createdGroupName = payload.name;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
