<template>
  <v-dialog persistent scrollable max-width="750" :value="showDialog">
    <v-card max-height="500">
      <v-card-title class="text-h5">
        <span class="passenger-search-input-title">{{ $t(`components.passengerSearch.title`) }}</span>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="searchInput"
          clearable
          hide-details=true
          @input="debouncedLoad()"
          outlined
          autofocus
          class="passenger-search-input my-4"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('components.passengerSearch.searchPlaceholder')"
        ></v-text-field>

        <template v-if="passengers.length !== 0">
          <v-divider></v-divider>
          <div class="fleet-list">
            <v-list>
              <v-list-item-group v-for="(passenger, index) in passengers" :key="index">
                <v-list-item three-line class="hoverable" @click="passengerSelected(passenger)">
                  <v-list-item-content>
                    <v-list-item-title class="title blue--text">
                      {{ passenger.givenName }} {{ passenger.familyName }}
                      <br>
                      <span class="subtitle-2 font-italic font-weight-bold text--primary">
                      {{ passenger.phoneNumber }} {{ passenger.email }}
                    </span>
                      <v-chip
                        class="ml-2"
                        small
                      >
                        {{ passenger.passengerType }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div class="d-flex flex-row align-center">
                      <span class="pr-1">
                       Created:
                      </span>
                        <span>
                        {{ passenger.createdAt }} UTC
                      </span>
                      </div>
                    </v-list-item-subtitle>

                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </template>

        <div class="shimmers" v-if="isLoading">
          <v-progress-linear
            indeterminate
          ></v-progress-linear>
          <br>
          <v-divider class="mt-1 white"></v-divider>
        </div>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from 'debounce';

export default {
  name: 'PassengerSearchDialog',
  components: {
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    businessAccountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      searchInput: '',
      passengers: [],
    };
  },
  created() {
    this.debouncedLoad = debounce(this.listPassengers, 500);
  },
  methods: {
    async listPassengers() {
      if (this.searchInput.length < 3) return;
      this.isLoading = true;
      const payload = {
        page: 0,
        maxPerPage: 100,
        dynamicSearch: this.searchInput,
      };
      const businessPassengers = await this.$store.dispatch('businessPassenger/getBusinessPassengerList', { ...payload, businessAccountIds: [this.businessAccountId] });
      const businessGuests = await this.$store.dispatch('businessGuest/getBusinessGuestList', { ...payload, businessAccountId: this.businessAccountId });
      const passengers = [...businessPassengers.results, ...businessGuests.results];
      const mappedPassengers = [];
      passengers.forEach((passenger) => {
        if (passenger.passenger) {
          mappedPassengers.push({ ...passenger.passenger, passengerType: 'PASSENGER' });
        } else {
          mappedPassengers.push({ ...passenger, passengerType: 'GUEST' });
        }
      });
      this.passengers = mappedPassengers;
      this.isLoading = false;
    },
    passengerSelected(passenger) {
      this.$emit('passengerSelected', passenger);
      this.passengers = [];
      this.searchInput = null;
    },
    closeDialog() {
      this.$emit('closeClick');
      this.passengers = [];
      this.searchInput = null;
    },
  },
};
</script>

<style lang="scss">
.passenger-search-input-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #333333;
}

.passenger-search-input {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #f8f8f8;
  color: #333333;
  line-height: 1.25rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #DDDDDD;

  input {
    padding: 1rem 2.5rem 1rem 0.4rem !important;
  }

  ::placeholder {
    color: #333333 !important;
  }

  .v-icon {
    color: #AAAAAA !important;
  }
}
</style>
