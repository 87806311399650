import { mapGetters } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export const initBookingChannel = {
  data: () => ({
    cookieEnabled: false,
    bookingChannelConfigured: false,
  }),
  created() {
    this.cookiesEnabled = navigator.cookieEnabled;

    if (this.cookiesEnabled && window.bookingChannelConfig) {
      this.bookingChannelConfigured = true;
      this.$store.commit('bookingChannel/setBookingChannelConfig', window.bookingChannelConfig);
    }
  },
};

export const bookingChannelComputed = {
  computed: {
    ...mapGetters({
      logoURL: 'bookingChannel/logoURL',
      companyName: 'bookingChannel/companyName',
      authCallbackUrl: 'bookingChannel/authCallbackUrl',
      postLogoutUrl: 'bookingChannel/postLogoutUrl',
      oidcClientId: 'bookingChannel/oidcClientId',
      defaultCountry: 'bookingChannel/defaultCountry',
      currencySymbol: 'bookingChannel/currencySymbol',
      networkOnly: 'bookingChannel/networkOnly',
      getChannelId: 'bookingChannel/id',
    }),
  },
};
