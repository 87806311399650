<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      :width="200"
      :permanent="true"
      :mini-variant="drawer"
      class="navigation-drawer"
      style="background-color: #ffffff"
    >
      <div class="d-flex align-center pl-2 mt-1">
        <img :src="logoURL" class="nav-logo mr-2" />
        <span v-if="!this.drawer">{{ companyName }}</span>
      </div>

      <v-list
        nav
        dense
        class="booking-menu-navigation"
      >
        <v-list-item active-class="active-border" link
          to="/trips/all-trips"
        >
          <v-list-item-icon class="navigation-link-icon">
            <trip-history-icon/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('components.navigation.bookings')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="active-border" link to="/employees">
          <v-list-item-icon class="navigation-link-icon">
            <team-icon/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('components.navigation.team')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="active-border" link to="/payment-methods">
          <v-list-item-icon class="navigation-link-icon">
            <payment-method-icon/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('components.navigation.paymentMethods')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="active-border" link to="/favourites">
          <v-list-item-icon class="navigation-link-icon">
            <favourites-icon style="width: 24px"/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('components.navigation.favouriteLocations')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <user-profile-navigation :displayName="displayName" :showBookTrip="true" @expandMenu="callExpandMenu" />

    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getRoles } from '@/mixins/authentication.mixins';
import { isMobile } from '@/mixins/responsive.mixins';
import { iconLoader } from '@/helpers/iconsLoader';
import { bookingChannelComputed } from '@/mixins/bookingChannel.mixins';
import { languageComputed } from '@/mixins/language.mixins';
import UserProfileNavigation from '@/components/navigation/UserProfileNavigation.vue';
import { CORP_ADMIN, CHANNEL_ADMIN } from '@/helpers/userRoles';

export default {
  mixins: [isMobile, bookingChannelComputed, languageComputed, getRoles],
  data() {
    return {
      drawer: false,
      bookingMenuActive: false,
      CORP_ADMIN,
      CHANNEL_ADMIN,
    };
  },
  components: { UserProfileNavigation, ...iconLoader.sideMenu },
  props: {
    displayName: {
      type: String,
    },
  },
  watch: {
    drawer(closed) {
      this.$store.commit('navigation/setDrawerOpen', !closed);
    },
  },
  computed: {
    ...mapGetters('bookingChannel', ['companyName']),
  },
  methods: {
    callExpandMenu() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style lang="scss" scoped>
  .navigation-drawer {
    .v-list {
      padding-left: 0px;
      padding-right: 0px;
      color: black;
    }
    .v-list-item__icon {
      padding-left: 7px;
      svg {
        color: #AAAAAA;
      }
    }
    .v-list-item::before {
      border-radius: 0;
    }
    .v-list-item {
      color: black !important;
    }
    .v-list-item__title {
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 15px !important;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      color: #333333;
    }
    .v-list--nav .v-list-item, .v-list--nav .v-list-item::before {
      border-radius: 0 !important;
    }
  }

  .navigation-link-icon {
    margin-right: 1em !important;
  }

  .header-bar {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  }
  .booking-menu-navigation {
    width: 100%;
    height: 50%;
  }
</style>

<style lang="scss">
.v-navigation-drawer--mini-variant {
  .navigation-link-icon {
    margin-right: 0px !important;
  }
}
.active-border {
  border-left: 3px solid #47D061 !important;
  .v-list-item__title {
    color: black !important;
  }
  svg {
    color: #333333 !important;
  }
}
.nav-logo {
  height: 40px;
}
.navigation-drawer .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}
.navigation-drawer .v-navigation-drawer__border {
  display: none;
}

.v-list-item--active, .v-list-group__header:hover, .v-list-item:hover {
  border-radius: 0 !important;
}
</style>
