import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  offerServiceClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  offerServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.offerServiceClient) {
      state.offerServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.OfferServiceConnector });
    }
    return state.offerServiceClient;
  },
};

const actions = {
  list: apiCall(async (context, { coords, corporationId, lang }) => {
    const offerServiceClient = context.getters.offerServiceClient(context);
    const { offers } = await offerServiceClient.listByEtaAndCoordinates({
      lang,
      pickupLatitude: coords.pickup.latitude,
      pickupLongitude: coords.pickup.longitude,
      destinationLatitude: coords.dropoff.latitude,
      destinationLongitude: coords.dropoff.longitude,
      corporationId,
    });
    return offers;
  }),
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};
