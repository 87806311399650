import apiInstance, { apiCall } from '@/helpers/apiInstance';
import { business } from '@icabbi/api-gateway-client';

const defaultState = {
  paxCoreBusinessPassengerClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  paxCoreBusinessPassengerClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.paxCoreBusinessPassengerClient) {
      state.paxCoreBusinessPassengerClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.PaxCoreConnector });
    }
    return state.paxCoreBusinessPassengerClient;
  },
  getBusinessPassengerList: (state) => state.businessPassengers,
};

const actions = {
  getBusinessPassengerList: apiCall((context, payload) => context.getters.paxCoreBusinessPassengerClient(context).getPassengerList({ payload })),
  createBusinessPassenger: apiCall(async (context, {
    givenName, familyName, email, phone, corporationIds, employeeGroupId
  }) => {
    const payload = {
      givenName, familyName, email, corporationIds, employeeGroupId
    };

    if (phone) payload.phone = phone;
    const paxCoreBusinessPassengerClient = context.getters.paxCoreBusinessPassengerClient(context);
    return paxCoreBusinessPassengerClient.createBusinessPassenger({ payload });
  }),
  updateBusinessPassenger: apiCall(async (context, {
    id, givenName, familyName, email, corporationId, employeeGroupId
  }) => {
    const payload = {
      givenName,
      familyName,
      email,
      corporationId, 
      employeeGroupId
    };

    const paxCoreBusinessPassengerClient = context.getters.paxCoreBusinessPassengerClient(context);
    return paxCoreBusinessPassengerClient.updateBusinessPassenger(id, { payload });
  }),
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};
