import moment from "moment";
import { ScheduleType } from "@/core/api/booking/BookingScheduleType";
import { BookingFormModel } from "@/views/bookingForm/BookingFormModel";

export class BookingFormModelFactory {
  static makeForBookingCreation({
    bookingChannelId,
    businessAccountId,
    tip,
    businessAccountConfig,
    paymentProviderName,
  }) {
    return new BookingFormModel({
      bookingChannelId,
      businessAccountId,
      paymentProviderName,
      tip,
      businessAccountConfig,
      pickupTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
      scheduleType: ScheduleType.ASAP,
    });
  }

  static makeFromV3GetByIdApiDto(args) {
    return new BookingFormModel({});
  }
}
