const NEW_STATUS = [
  'NEW',
  'ASSIGN',
  'DROPPINGOFF',
  'MADECONTACT',
  'PREBOOKED',
];

const CANCELLED_STATUS = [
  'ARCHIVE_CUSTOMER_CANCELLED',
  'CUSTOMER_CANCELLED',
  'DISPATCH_CANCELLED',
  'DRIVER_CANCELLED',
  'NOSHOW',
];

const COMPLETED_STATUS = [
  'COMPLETED',
  'COMPLETE',
  'ARCHIVE_COMPLETE',
];

export default {
  new: NEW_STATUS,
  cancelled: CANCELLED_STATUS,
  completed: COMPLETED_STATUS,
};
