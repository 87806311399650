import { mapGetters } from 'vuex';

export const paymentMethod = {
  data: () => ({
    data: [],
    entitySearchObject: {
      page: 0,
      maxPerPage: 10,
      sort: 'name',
    },
    methodLoading: false,
    totalCount: 0,
    stripeClient: null,
  }),
  computed: {
    ...mapGetters({
      loadStripeClient: 'paymentMethods/stripeClient',
    }),
  },
  watch: {
    data() {
      this.methodLoading = false;
    },
  },
  created() {
    this.initStripe();
  },
  methods: {
    async getMethods(options) {
      this.methodLoading = true;
      const data = await this.$store.dispatch('paymentMethods/getPaymentMethods', options);
      if (data) {
        this.data = data;
        this.totalCount = data.length;
      }
    },
    async saveCard(options) {
      return this.$store.dispatch('paymentMethods/storeCard', options);
    },
    async getSecret(corporationId) {
      return this.$store.dispatch('paymentMethods/retrieveSecret', corporationId);
    },
    async deleteCard(id, corporationId) {
      return this.$store.dispatch('paymentMethods/deleteCard', { id, corporationId });
    },
    async initStripe() {
      this.stripeClient = await this.loadStripeClient;
    },
  },
};
