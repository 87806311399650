import Cryptr from 'cryptr';
import { Env } from '@/env';

const cryptr = new Cryptr(Env.getEncryptKey(), {
  pbkdf2Iterations: 1000,
});

// eslint-disable-next-line import/prefer-default-export
export class AuthUtil {
  static encryptBearerToken(token) {
    return cryptr.encrypt(token);
  }
}
