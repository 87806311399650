<template>
  <v-combobox outlined filled hide-details hide-no-data return-object
              append-icon=""
              item-text="fullName"
              item-value="id"
              v-model="selected"
              :error-messages="errorMessages"
              :has-errors="hasErrors"
              :items="items"
              :search-input.sync="query"
              @input="select"
              :disabled="editMode"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ $t(`components.userAutoComplete.userTypes.${item.type}`) + ` ${item.corporationName ? `(${item.corporationName})` : ''}` }}</v-list-item-title>
        <v-list-item-title>{{ item.fullName }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-if="hasErrors" v-slot:append>
      <error-tooltip-auto :errors="errorMessages" />
    </template>
  </v-combobox>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  props: {
    errorMessages: Array,
    hasErrors: Boolean,
    initial: [Object, String],
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: null,
    items: [],
    query: '',
    skipFirstQuery: false,
    initialShown: false,
    corporationLookUp: [],
  }),
  watch: {
    initial(val) {
      if (!val || this.initialShown) return;

      this.skipFirstQuery = true;
      this.initialShown = true;
      this.items = [val];
      this.selected = val;
    },
    query: debounce(async function (val) { // eslint-disable-line func-names
      if (this.skipFirstQuery) { this.skipFirstQuery = false; return; }
      const items = (await this.getUserSuggestions(val)).data || [];
      this.items = items.map((attrs) => {
        const corporation = this.corporationLookUp.find((corp) => corp.id === attrs.corporationId);
        return { ...attrs, fullName: `${attrs.givenName} ${attrs.familyName}`, corporationName: corporation?.name || undefined };
      });
    }, 200),
  },
  mounted() {
    this.getCorporations();
  },
  computed: {
    ...mapGetters('corporations', ['getSelectedCorporationId']),
  },
  methods: {
    getUserSuggestions(search) {
      if (search) {
        return this.$store.dispatch('employee/findUsers', {
          dynamicSearch: search,
          page: 0,
          corporationId: this.getSelectedCorporationId,
        });
      }
      return [];
    },
    async select(val) {
      this.$emit('change', val);
    },
    async getCorporations() {
      const corporations = await this.$store.dispatch('corporations/listCorporations', { maxPerPage: 50, sort: 'name' });
      this.corporationLookUp = corporations.data.map((corporation) => ({
        id: corporation.id,
        name: corporation?.name,
      }));
    },
  },
};
</script>
