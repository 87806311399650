// eslint-disable-next-line import/prefer-default-export
export const listFleetAccounts = {
  data: () => ({
    fleetAccounts: [],
    fleetAccountsSearchObject: {
      page: 0,
      maxPerPage: 10,
      sort: '-createdAt',
    },
    fleetAccountsLoading: true,
    fleetAccountsListInfo: {
      totalCount: 0,
    },
  }),
  watch: {
    fleetAccounts() {
      this.fleetAccountsLoading = false;
    },
  },
  methods: {
    async listFleetAccounts(corporationId) {
      this.fleetAccountsLoading = true;
      this.fleetAccountsSearchObject.corporationId = corporationId || undefined;
      const { data, headers } = await this.$store.dispatch('fleetAccounts/listFleetAccounts', { ...this.fleetAccountsSearchObject });
      if (data) {
        this.fleetAccounts = data;
      }
      this.fleetAccountsListInfo = this.formatHeaders(headers);
      await Promise.all(this.fleetAccounts.map((fleetAccount) => this.validateAccountNumber(fleetAccount)));
    },
    formatHeaders(headers) {
      return {
        totalCount: Number(headers['x-total-count'] || 0),
      };
    },
    async validateAccountNumber({ accountNumber, fleetId }) {
      this.updateIsLoadingAccountValidation({ fleetId, isLoading: true });
      const isValid = await this.$store.dispatch('fleetAccounts/validateAccount', { accountNumber, fleetId });
      this.updateIsLoadingAccountValidation({ fleetId, isLoading: false, isValid });
    },
    async invalidateAccountNumber({ fleetId }) {
      this.updateIsLoadingAccountValidation({ fleetId, isLoading: false });
    },
    updateIsLoadingAccountValidation({ fleetId, isLoading, isValid }) {
      this.fleetAccounts = this.fleetAccounts.map((fleetAccount) => {
        if (fleetAccount.fleetId === fleetId) {
          return { ...fleetAccount, isLoading, isValid };
        }

        return fleetAccount;
      });
    },
    async saveFleetAccount(fleetAccount, corporationId) {
      const payload = { corporationId, ...fleetAccount };
      if (!fleetAccount.id) {
        this.$store.dispatch('fleetAccounts/createFleetAccount', payload);
      } else {
        this.$store.dispatch('fleetAccounts/updateFleetAccount', payload);
      }
    },
    async saveAllFleetAccounts(corporationId) {
      await Promise.all(this.fleetAccounts.map((fleetAccount) => this.saveFleetAccount(fleetAccount, corporationId)));
    },
  },
};
