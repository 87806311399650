// Get an instance of `PhoneNumberUtil`.
import { PhoneNumberUtil } from "@/core/util/PhoneNumberUtil";
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export const phoneNumberValidation = (phoneNumber, countryCode) => {
  if (!phoneNumber || !countryCode) {
    return false;
  }
  try {
    const number = PhoneNumberUtil.parseToGooglePhoneNumber({ phoneNumber, countryCode });
    return phoneUtil.isValidNumberForRegion(number, countryCode.toUpperCase());
  } catch (error) {
    return false;
  }
}
