import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiInstance';

const defaultState = {
  guestServiceClient: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  guestServiceClient: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.guestServiceClient) {
      state.guestServiceClient = apiInstance({ dispatch, getters: rootGetters, Connector: business.CorporationGuestServiceConnector });
    }
    return state.guestServiceClient;
  },
};

const actions = {
  createGuest: apiCall(async (context, {
    givenName, familyName, email, phone, corporationId,
  }) => {
    const data = {
      givenName, familyName, email, corporationId, phone,
    };
    const guestServiceClient = context.getters.guestServiceClient(context);
    return guestServiceClient.createGuest({ data });
  }),
  updateGuest: apiCall(async (context, {
    id, givenName, familyName, email, corporationId, phone, phoneNumber,
  }) => {
    const data = {
      givenName, familyName, email, corporationId, id, phone, phoneNumber,
    };
    const guestServiceClient = context.getters.guestServiceClient(context);
    return guestServiceClient.updateGuest(data);
  }),
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};
