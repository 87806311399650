<template>
  <div class="d-flex flex-column">
    <v-flex>
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.name") }}</h5>
      <v-combobox
        ref="nameInput"
        outlined
        filled
        hide-details
        hide-no-data
        return-object
        append-icon=""
        item-value="id"
        :rules="[
          (v) => !!v || $t('components.bookingForm.validation.required'),
          (v) =>
            /^[a-zA-Z0-9\s]*$/.test(v) ||
            $t('components.bookingForm.validation.acceptableCharacters'),
        ]"
        :item-text="getFullName"
        :value="getFullName(businessRecipient) || recipientNameInput"
        :placeholder="$t('components.userAutoComplete.placeholderName')"
        :items="formatSuggestions"
        :cache-items="false"
        :no-filter="true"
        @input="onRecipientSelected($event, 'givenName')"
        @update:search-input="onNameInputMutated"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content class="d-flex">
            <v-list-item-title>{{
              item.givenName + " " + (item.familyName || "")
            }}</v-list-item-title>
            <v-list-item-title>{{ item.phone }}</v-list-item-title>
            <v-list-item-title>{{ item.email }}</v-list-item-title>
          </v-list-item-content>

          <v-chip
            :color="item.type === 'employee' ? 'green' : 'secondary'"
            class="ma-2 white--text"
            small
          >
            {{ item.type }}
          </v-chip>
        </template>

        <template v-slot:append>
          <v-tooltip
            v-if="recipientNameInput && $refs.nameInput && $refs.nameInput.hasError"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                >mdi-help-circle-outline</v-icon
              >
            </template>
            <p v-for="(error, index) in $refs.nameInput.errorBucket" :key="index" class="mb-0">
              {{ $t(error) }}
            </p>
          </v-tooltip>
        </template>
      </v-combobox>
    </v-flex>

    <v-flex class="my-3">
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.mobile") }}</h5>
      <v-row>
        <v-col cols="3">
          <v-combobox
            ref="countryCodeInput"
            outlined
            filled
            hide-details
            hide-no-data
            append-icon=""
            :cache-items="false"
            :item-text="(item) => [item.dialCode, item.name]"
            :value="selectedCountryCode"
            :rules="[(v) => !!v || $t('components.bookingForm.validation.required')]"
            :items="allCountries"
            :placeholder="$t('components.userAutoComplete.placeholderCountryCode')"
            @input="onDialCodeSelectedFromList($event)"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content class="d-flex">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-title>{{ item.dialCode }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-slot:selection="{ item }">
              {{ item.dialCode || item }}
            </template>

            <template v-slot:append>
              <v-tooltip
                v-if="shouldShowInvalidCountryCodeError"
                bottom
                color="error"
                dark
                nudge-bottom="10"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <p class="mb-0">{{ $t("components.bookingForm.validation.invalidCountryCode") }}</p>
              </v-tooltip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="9">
          <v-combobox
            ref="phoneNumberInput"
            outlined
            filled
            hide-details
            hide-no-data
            return-object
            append-icon=""
            item-text="phoneNumber"
            item-value="id"
            :rules="[
              (v) => !!v || $t('components.bookingForm.validation.required'),
              (v) =>
                /^[+0-9]*$/.test(v.phoneNumber || v) ||
                $t('components.bookingForm.validation.mobile'),
            ]"
            :value="(businessRecipient && businessRecipient.phoneNumber) || phoneNumberInput"
            :placeholder="$t('components.userAutoComplete.placeholderPhone')"
            :items="formatSuggestions"
            @input="onRecipientSelected($event, 'phoneNumber')"
            @update:search-input="onPhoneNumberInputMutated"
          >
            <template v-slot:append>
              <v-tooltip
                v-if="phoneNumberInput && $refs.phoneNumberInput && $refs.phoneNumberInput.hasError"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <p
                  v-for="(error, index) in $refs.phoneNumberInput.errorBucket"
                  :key="index"
                  class="mb-0"
                >
                  {{ $t(error) }}
                </p>
              </v-tooltip>
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-content class="d-flex">
                <v-list-item-title>{{
                  item.givenName + " " + (item.familyName || "")
                }}</v-list-item-title>
                <v-list-item-title>{{ item.phone }}</v-list-item-title>
                <v-list-item-title>{{ item.email }}</v-list-item-title>
              </v-list-item-content>

              <v-chip
                :color="item.type === 'employee' ? 'green' : 'secondary'"
                class="ma-2 white--text text-capitalize"
                small
              >
                {{ item.type }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-flex>

    <v-flex>
      <h5 class="section-title pb-2">{{ $t("components.bookingForm.section.email") }}</h5>
      <v-combobox
        ref="emailInput"
        outlined
        filled
        hide-details
        hide-no-data
        return-object
        append-icon=""
        item-text="email"
        item-value="id"
        :value="(businessRecipient && businessRecipient.email) || emailInput"
        :rules="[
          (v) =>
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
            $t('components.bookingForm.validation.email'),
        ]"
        :placeholder="$t('components.userAutoComplete.placeholderEmail')"
        :items="formatSuggestions"
        @input="onRecipientSelected($event, 'email')"
        @update:search-input="onEmailInputMutated"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content class="d-flex">
            <v-list-item-title>{{
              item.givenName + " " + (item.familyName || "")
            }}</v-list-item-title>
            <v-list-item-title>{{ item.phone }}</v-list-item-title>
            <v-list-item-title>{{ item.email }}</v-list-item-title>
          </v-list-item-content>

          <v-chip
            :color="item.type === 'employee' ? 'green' : 'secondary'"
            class="ma-2 white--text"
            small
          >
            {{ item.type }}
          </v-chip>
        </template>

        <template v-slot:append>
          <v-tooltip v-if="emailInput && $refs.emailInput && $refs.emailInput.hasError" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mt-1" color="error" size="16" v-bind="attrs" v-on="on"
                >mdi-help-circle-outline</v-icon
              >
            </template>
            <p v-for="(error, index) in $refs.emailInput.errorBucket" :key="index" class="mb-0">
              {{ $t(error) }}
            </p>
          </v-tooltip>
        </template>
      </v-combobox>
    </v-flex>

    <v-flex align-self-end class="mt-4">
      <v-btn
        color="#333333"
        class="white--text"
        depressed
        :disabled="
          (!isPhoneValid ||
            !isNameValid ||
            shouldShowInvalidCountryCodeError) &&
          !businessRecipient
        "
        @click="onNextClick"
      >
        {{ $t("components.bookingForm.next") }}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import debounce from "debounce";
import { isNull, uniq } from "lodash";
import { mapGetters } from "vuex";
import { HiDash } from "@/core/util/HiDash";
import { SafeAwait } from "@/core/util/SafeAwait";
import { phoneNumberValidation } from "@/helpers/rules/phoneNumberValidation";
import allCountries from "country-codes-list";
import { BusinessBookingRecipientDtoDelegate } from "@/core/api/businessBookingRecipient/BusinessBookingRecipientDtoDelegate";
import {PhoneNumberUtil} from "@/core/util/PhoneNumberUtil";

export default {
  name: "PassengerStepComponent",
  props: {
    /**
     * @type {BookingStepsFormPresenter}
     */
    presenter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /**
       * @type {BusinessBookingRecipientV1Dto}
       */
      businessRecipient: null,
      /**
       * @type {Array<BusinessBookingRecipientV1Dto>}
       */
      passengerSuggestions: [],

      recipientNameInput: "",
      phoneNumberInput: "",
      /**
       * The country phone code, eg. +353, +1 etc
       * @type {String}
       */
      selectedCountryCode: null,
      emailInput: "",
      isComponentBootstrapped: false,
      allCountries: allCountries
        .all()
        .map((country) => {
          return {
            dialCode: `+${country.countryCallingCode}`,
            iso2: country.countryCode.toLowerCase(),
            name: country.countryNameEn,
            flag: country.flag,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    };
  },
  async created() {
    this.debouncedPassengerSearch = debounce(this.onFetchPassengersByQuery, 300);
    this.prefillCountryCode();
    this.prefillRecipient(this.presenter.getRecipient());
    this.isComponentBootstrapped = true;
  },
  computed: {
    ...mapGetters({
      country: "bookingChannel/defaultCountry",
      getSelectedCorporationId: "corporations/getSelectedCorporationId",
    }),
    countryCode() {
      return this.allCountries.find((country) => country.iso2 === this.country)?.dialCode;
    },
    formatSuggestions() {
      return this.passengerSuggestions?.map((suggestion) => ({
        ...suggestion,
        phoneNumber: suggestion.phone,
      }));
    },
    isPhoneValid() {
      // Phone number is optional. So, if the user has not entered a phone number, we consider it valid
      if (!this.phoneNumberInput) {
        return true;
      }
      // If the user entered a phone number, then country code is required
      if (this.phoneNumberInput && !this.selectedCountryCode) {
        return false;
      }

      // At this point, we know that the user has entered a phone number and a country code
      return !/[^\d]/.test(this.phoneNumberInput) && this.allCountries
        .filter((country) => country.dialCode === this.selectedCountryCode)
        .map((country) => phoneNumberValidation(this.phoneNumberInput, country.iso2))
        .some((isValid) => isValid);
    },
    shouldShowInvalidCountryCodeError() {
      if (!this.selectedCountryCode) {
        return false;
      }

      return !this.allCountries.find((country) => country.dialCode === this.selectedCountryCode)
        ?.iso2;
    },
    isCountryCodeValid() {
      return !!this.selectedCountryCode;
    },
    isNameValid() {
      return !!this.recipientNameInput;
    },
    getFullName() {
      return (item) => {
        if (!item) return null;
        return [item.givenName, item.familyName].join(" ").trim();
      };
    },
  },
  methods: {
    getCountryIsoFromPhoneCountryCode(phoneCountryCode) {
      return this.allCountries.find((country) => country.dialCode === phoneCountryCode)
        ?.iso2;
    },
    async onNameInputMutated(searchQuery) {
      if (!searchQuery) {
        this.resetSuggestions();
        return;
      }

      this.recipientNameInput = searchQuery?.trim();
      await this.debouncedPassengerSearch(searchQuery);
    },
    async onEmailInputMutated(searchQuery) {
      if (!searchQuery) {
        this.resetSuggestions();
        return;
      }

      this.emailInput = searchQuery?.trim();
      await this.debouncedPassengerSearch(searchQuery);
    },
    async onPhoneNumberInputMutated(searchQuery) {
      if (!searchQuery) {
        this.resetSuggestions();
        return;
      }

      this.phoneNumberInput = searchQuery?.trim();
      await this.debouncedPassengerSearch(searchQuery);
    },
    /**
     *
     * @param {String | null | undefined} code
     */
    onDialCodeSelectedFromList(code) {
      if (code && code.dialCode) {
        this.selectedCountryCode = code.dialCode;
        return;
      }

      if (code) {
        this.selectedCountryCode = code.startsWith('+') ? code : `+${code}`;
        return;
      }

      this.selectedCountryCode = code;
    },
    parseAndFormatThePhoneNumberForSubmission() {
      const cleanPhone = this.phoneNumberInput.trim();
      const countryIso = this.getCountryIsoFromPhoneCountryCode(this.selectedCountryCode);
      return PhoneNumberUtil.fromGooglePhoneNumberToE164(PhoneNumberUtil.parseToGooglePhoneNumber({
        countryCode: countryIso.toUpperCase(),
        phoneNumber: cleanPhone,
      }));
    },
    async onNextClick() {
      if (this.businessRecipient) {
        await this.presenter.onPassengerStepSubmit({
          employeeId: BusinessBookingRecipientDtoDelegate.isTypeEmployee(
            this.businessRecipient.type
          )
            ? this.businessRecipient.id
            : null,
          guestId: BusinessBookingRecipientDtoDelegate.isTypeGuest(this.businessRecipient.type)
            ? this.businessRecipient.id
            : null,
          familyName: this.businessRecipient.familyName,
          givenName: this.businessRecipient.givenName,
          email: this.businessRecipient.email,
          phoneNumber: this.businessRecipient.phone.trim(),
          isAdhocGuest: false,
        });
        this.presenter.setSelectedBusinessBookingRecipient(this.businessRecipient);
      } else {
        const fullNameParts = this.recipientNameInput?.trim()?.split(" ") || [];
        await this.presenter.onPassengerStepSubmit({
          givenName: fullNameParts[0] || null,
          familyName: fullNameParts[1] || null,
          phoneNumber: this.phoneNumberInput ? this.parseAndFormatThePhoneNumberForSubmission() : null,
          email: this.emailInput,
          isAdhocGuest: true,
        });
        this.presenter.setSelectedBusinessBookingRecipient(null);
      }
    },
    /**
     * Invoked when user either selects a recipient from the suggestions list or clears the input search box
     * @param {BusinessBookingRecipientV1Dto | null} recipient
     * @param field
     */
    onRecipientSelected(recipient, field) {
      if (this.businessRecipient) {
        this.selectedCountryCode = this.allCountries.find((country) =>
          this.businessRecipient.phone.startsWith(country.dialCode)
        )?.dialCode;
        this.phoneNumberInput = this.businessRecipient.phone.replace(this.selectedCountryCode, "");
      }

      // The recipient will be null if the user clears the input search box
      if (isNull(recipient)) {
        this.businessRecipient = null;
        // this.showCountryCode = true;

        if (field === "givenName") this.recipientNameInput = "";
        if (field === "phoneNumber") this.phoneNumberInput = "";
        if (field === "email") this.emailInput = "";

        return;
      }

      if (typeof recipient === "object") {
        this.businessRecipient = recipient;
        this.selectedCountryCode = this.allCountries.find((country) =>
          recipient.phone.startsWith(country.dialCode)
        )?.dialCode;
        this.businessRecipient.phoneNumber = recipient.phone.replace(this.selectedCountryCode, "");
      }
    },
    prefillCountryCode() {
      this.selectedCountryCode = this.allCountries.find(
        (country) => country.iso2 === this.country
      )?.dialCode;
    },
    /**
     *
     * @param {CreateBookingRecipient} recipient
     */
    prefillRecipient(recipient) {
      if (recipient.isAdhocGuest === false && (recipient.employeeId || recipient.guestId)) {
        // Prefill the businessRecipient object which models the fact that
        // the booking is for a recipient that already exists in the system
        this.businessRecipient = {
          familyName: recipient.familyName,
          givenName: recipient.givenName,
          email: recipient.email,
          phone: recipient.phoneNumber,
        };

        if (recipient.employeeId) {
          this.businessRecipient.id = recipient.employeeId;
        } else if (recipient.guestId) {
          this.businessRecipient.id = recipient.guestId;
        }
      }

      if (recipient.phoneNumber) {
        const googlePhoneNumber = PhoneNumberUtil.parseToGooglePhoneWithoutRegionCode(recipient.phoneNumber);
        this.selectedCountryCode = '+' + googlePhoneNumber.getCountryCode();
        this.phoneNumberInput = googlePhoneNumber.getNationalNumber().toString();
      }

      this.recipientNameInput = [recipient.givenName, recipient.familyName].join(' ').trim();
      this.emailInput = recipient.email;
    },
    async onFetchPassengersByQuery(searchQuery) {
      if (
        !this.isComponentBootstrapped ||
        !searchQuery ||
        (HiDash.isString(searchQuery) && searchQuery.length < 3)
      )
        return;

      if (HiDash.isString(searchQuery) && searchQuery.length === 0) {
        this.resetSuggestions();
        return;
      }

      const { error, result: recipients } = await SafeAwait.execute(async () => {
        return this.presenter.searchBusinessRecipients(searchQuery);
      });

      if (error) {
        this.$notify({
          type: "error",
          title: "Error",
          text: this.$t("components.bookingForm.requestErrors.fetchBusinessRecipient"),
        });
        return;
      }

      this.passengerSuggestions = uniq(recipients.data, (item) => item.givenName);
    },
    resetSuggestions() {
      this.passengerSuggestions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/phoneInput.scss";
</style>

<style lang="scss">
.section-title {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 10px;
  font-weight: 400;
  color: #888888;
}
.vue-tel-input::v-deep {
  .vti__input {
    font-size: 12px;
  }

  .vti__country-code {
    font-size: 12px;
  }
}
</style>
